import { Component, DestroyRef, Input, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormGroup } from "@angular/forms";
import { nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  RequiredVaccinationsEntryAt,
  RequiredVaccinationsEntryDe,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { cloneDeep } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { Table } from "primeng/table";
import { BehaviorSubject, Observable, combineLatest, debounceTime, map, tap } from "rxjs";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import {
  VaccinationFormAT,
  VaccinationFormDE,
  VaccinationFormEntryAT,
  VaccinationFormEntryDE,
} from "../required-vaccinations-form.model";
import { RequiredVaccinationsFormService, UniqueRequiredVaccineEntry } from "../required-vaccinations-form.service";

const nameof = nameofFactory<RequiredVaccinationsEntryAt | RequiredVaccinationsEntryDe>();

@Component({
  selector: "app-required-vaccinations-table",
  templateUrl: "./required-vaccinations-table.component.html",
  styleUrl: "./required-vaccinations-table.component.scss",
})
export class RequiredVaccinationsTableComponent implements OnInit {
  protected readonly employers$ = new BehaviorSubject<StaticDataModel[]>([]);

  @Input({ required: true })
  staticDataContext: StaticDataContextEntry;

  @Input({ required: true })
  form: VaccinationFormDE | VaccinationFormAT;

  @ViewChild("table")
  table: Table;

  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly formService: RequiredVaccinationsFormService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly destroyRef: DestroyRef,
    private readonly confirmationService: ConfirmationService,
    private readonly organizationService: OrganizationsService
  ) {}

  protected cols$ = this.getColumns();

  protected readonly StaticDataTypeEnum = StaticDataType;
  duplicate: UniqueRequiredVaccineEntry;

  get configs(): FormArray<VaccinationFormEntryDE> | FormArray<VaccinationFormEntryAT> {
    return this.form?.controls?.configs;
  }

  get maxLengthError(): boolean {
    return this.configs.controls.some((x) => x.controls.profession.errors?.maxlength);
  }

  get onlyAllError(): boolean {
    return this.configs.controls.some((x) => Object.values(x.controls).some((y) => y.errors?.onlyAll));
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(200),
        tap(() => this.setError())
      )
      .subscribe();

    combineLatest([
      this.staticDataService.getStaticData(StaticDataType.All, this.language, this.staticDataContext),
      this.organizationService.getCascadedLinkedOrganizations(this.staticDataContext.organizationId),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(([all, employers]) => [...all, ...employers.map((x) => ({ value: x.id, label: x.name }))])
      )
      .subscribe((x) => this.employers$.next(x));
  }

  addRow(): void {
    const row = this.formService.createRow(null);
    this.configs.push(row);
    this.form.markAsDirty();
  }

  duplicateRow(row: { value: RequiredVaccinationsEntryAt | RequiredVaccinationsEntryDe }): void {
    const index = this.configs.controls.findIndex((x: FormGroup) => x.controls.id.value == row.value.id);
    const newRow = this.formService.createRow({ ...row.value, id: null });

    this.configs.insert(index + 1, newRow);
    this.form.markAsDirty();
  }

  removeRow(event: Event, row: { controls: Record<string, FormGroup> }): void {
    this.confirmationService.confirm({
      target: event.target,
      message: translate("requiredVaccines.confirmDelete.title"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.configs.removeAt(this.configs.controls.findIndex((x) => x.controls.id.value == row.controls.id.value));
        this.form.markAsDirty();
      },
    });
  }

  protected getFieldName(fieldName: string): string {
    return `controls.${fieldName}.value`;
  }

  private setError(): void {
    if (this.form.controls.configs?.errors?.duplicate) {
      this.duplicate = cloneDeep(this.form.controls.configs?.errors?.duplicate);
      return;
    }
    this.duplicate = null;
  }

  private getColumns(): any[] {
    return [
      { header: "", includeInGlobalFilter: false, sortable: false, style: { width: "5%" } }, // Delete, Copy Button
      {
        header: translate("countryOfOrigin.title"),
        style: { width: "10%" },
        type: "static-data-multi-select",
        controlName: nameof("countryOfOrigin"),
        staticDataType: StaticDataType.Countries,
        staticDataContext: this.staticDataContext,
        options: this.getStaticData(StaticDataType.Countries),
        placeholder: translate("countryOfOrigin.placeholder"),
        sortable: true,
      },
      {
        header: translate("federalState.title"),
        style: { width: "15%" },
        type: "static-data-multi-select",
        controlName: nameof("federalState"),
        staticDataType: StaticDataType.FederalStates,
        staticDataContext: this.staticDataContext,
        options: this.getStaticData(StaticDataType.FederalStates),
        placeholder: translate("federalState.placeholder"),
        sortable: true,
      },
      {
        header: translate("profession.title"),
        style: { width: "15%" },
        type: "static-data-multi-select",
        controlName: nameof("profession"),
        staticDataType: StaticDataType.Profession,
        staticDataContext: this.staticDataContext,
        options: this.getStaticData(StaticDataType.Profession),
        placeholder: translate("profession.placeholder"),
        sortable: true,
        showToggleAll: false,
      },
      {
        header: translate("employer.title"),
        style: { width: "15%" },
        type: "employer-multi-select",
        controlName: nameof("employer"),
        options: this.employers$,
        placeholder: translate("employer.placeholder"),
        sortable: true,
        showToggleAll: false,
        toOutPut: (values: string[]) =>
          values?.map((value) => this.employers$.value.find((employer) => employer.value === value)?.label)?.join(", "),
      },
      {
        header: translate("vaccinations.name.title"),
        style: { width: "20%" },
        type: "static-data-multi-select",
        controlName: nameof("vaccines"),
        fieldName: `controls.${nameof("vaccines")}.value`,
        staticDataType: StaticDataType.Vaccination,
        staticDataContext: this.staticDataContext,
        options: this.getStaticData(StaticDataType.Vaccination, false),
        placeholder: translate("vaccinations.name.placeholder"),
        sortable: true,
      },
      {
        header: translate("requiredVaccines.vaccineFunction.title"),
        style: { width: "15%" },
        type: "static-data-multi-select",
        controlName: nameof("vaccineFunction"),
        staticDataType: StaticDataType.VaccineFunction,
        staticDataContext: this.staticDataContext,
        options: this.getStaticData(StaticDataType.VaccineFunction),
        placeholder: translate("requiredVaccines.vaccineFunction.placeholder"),
        sortable: true,
      },
      {
        header: translate("requiredVaccines.notNecessary.title"),
        style: { width: "5%" },
        controlName: nameof("notNecessary"),
        sortable: true,
      },
      {
        header: translate("requiredVaccines.mandatoryVaccines.title"),
        style: { width: "5%" },
        controlName: nameof("mandatoryVaccines"),
        sortable: true,
      },
    ];
  }

  private getStaticData(staticDataType: StaticDataType, includeAll = true): Observable<StaticDataModel[]> {
    if (!includeAll) return this.staticDataService.getStaticData(staticDataType, this.language, this.staticDataContext);

    return combineLatest([
      this.staticDataService.getStaticData(staticDataType, this.language, this.staticDataContext),
      this.staticDataService.getStaticData(StaticDataType.All, this.language, this.staticDataContext),
    ]).pipe(map(([other, all]) => [...all, ...other]));
  }
}
