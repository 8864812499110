<p-multiSelect
  *ngIf="criterion !== DocumentSelectionCriterion.Profession"
  [options]="options"
  [required]="true"
  [readonly]="readonly"
  [placeholder]="placeholder"
  [pTooltip]="label"
  [ngModel]="value"
  (onChange)="onValueChange($event.value, $event.itemValue.value)"
></p-multiSelect>

<app-profession-selector
  *ngIf="criterion === DocumentSelectionCriterion.Profession"
  [required]="true"
  [criteriaMode]="true"
  [multi]="true"
  [ngModel]="value"
  (changed)="onValueChange($event.value, $event.toggledValue)"
></app-profession-selector>
