import { customFieldLabelMapping, ICustomFieldsModel, nameofFactory } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { PrimeIcons } from "primeng/api";

const _dummy: ICustomFieldsModel = null;
const nameOfDeep = nameofFactory<typeof _dummy.talentCountry>();

export const talentCountryFactory: (requiredFields: string[]) => FormlyFieldConfig[] = (_requiredFields: string[]) => {
  return [
    {
      id: "talentCountry-custom",
      props: {
        label: translate("customFields.talentCountrySpecific.title"),
        icon: PrimeIcons.USER_EDIT,
      },
      fieldGroupClassName: "p-fluid",
      fieldGroup: [
        {
          fieldGroupClassName: "p-fluid formgrid grid",
          fieldGroup: [
            {
              key: nameOfDeep("numberOfResidents"),
              type: "input",
              className: "field col-12 lg:col-12",
              props: {
                type: "number",
                label: translate(customFieldLabelMapping.talentCountry.numberOfResidents),
              },
            },
          ],
        },
      ],
    },
  ];
};
