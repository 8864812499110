import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ImagePreloadDirective } from "../../shared/directives/image-preload.directive";
import { OrganizationLogoPipe } from "../../shared/pipes/organization-logo.pipe";
import { SettingsService } from "../../shared/services/settings.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, NgIf, ImagePreloadDirective, AsyncPipe, OrganizationLogoPipe],
})
export class FooterComponent {
  readonly isLicensed = this.settings.isLicensed;
  readonly organizationId = this.settings.organizationId;
  readonly creatorOrganizationId = this.settings.creatorOrganizationId;

  constructor(private readonly settings: SettingsService) {}
}
