import { Injectable } from "@angular/core";
import {
  GetCandidateInboxCountGQL,
  GetCandidateInboxGQL,
  GetUserInboxCountGQL,
  GetUserInboxGQL,
  InstanceStateEnum,
  TaskInboxFragment,
} from "@ankaadia/graphql";
import { BehaviorSubject, map, Observable, tap } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class TaskInboxService {
  private readonly counterSub = new BehaviorSubject<number>(0);

  readonly counter = this.counterSub.asObservable();

  constructor(
    private readonly candidateInbox: GetCandidateInboxGQL,
    private readonly userInbox: GetUserInboxGQL,
    private readonly candidateInboxCount: GetCandidateInboxCountGQL,
    private readonly userInboxCount: GetUserInboxCountGQL,
    private readonly settings: SettingsService
  ) {}

  updateInboxCount(): void {
    if (this.settings.isCandidate) {
      this.candidateInboxCount
        .fetch(null, { fetchPolicy: "network-only" })
        .subscribe((x) => this.counterSub.next(x.data.getCandidateInboxCount));
    } else if (!this.settings.isCandidate && this.settings.hasUserPermission) {
      this.userInboxCount
        .fetch(null, { fetchPolicy: "network-only" })
        .subscribe((x) => this.counterSub.next(x.data.getUserInboxCount));
    }
  }

  getInbox(
    candidateId: string,
    candidateOrganizationId: string,
    language: string,
    states: InstanceStateEnum[]
  ): Observable<TaskInboxFragment> {
    return this.settings.isCandidate
      ? this.candidateInbox.watch({ input: { language, states } }, { fetchPolicy: "network-only" }).valueChanges.pipe(
          tap((x) => this.counterSub.next(x.data.getCandidateInbox.tasks.length)), // Update Count
          map((x) => x.data.getCandidateInbox)
        )
      : this.userInbox
          .watch({ input: { candidateId, candidateOrganizationId, language } }, { fetchPolicy: "network-only" })
          .valueChanges.pipe(
            tap((x) => {
              // ALO, update only if no candidate is selected
              if (!candidateId && !candidateOrganizationId) {
                this.counterSub.next(x.data.getUserInbox.tasks.length);
              }
            }),
            map((x) => x.data.getUserInbox)
          );
  }
}
