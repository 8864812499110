import { NgFor, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { FieldArrayType, FormlyModule } from "@ngx-formly/core";
import { Button } from "primeng-v17/button";
import { MessageModule } from "primeng-v17/message";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";

@Component({
  selector: "app-formly-repeat",
  templateUrl: "./formly-repeat.component.html",
  imports: [FormElementMapModule, NgFor, MessageModule, FormlyModule, NgIf, Button],
})
export class FormlyRepeatComponent extends FieldArrayType {}
