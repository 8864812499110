import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-login-denied",
  templateUrl: "./login-denied.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, RouterLink],
})
export class LoginDeniedComponent {}
