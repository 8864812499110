import { Component, Input } from "@angular/core";
import {
  DocumentSetDateField,
  DocumentType,
  getSelectedSet,
  getSetFiles,
  SelectedSet,
} from "@ankaadia/ankaadia-shared";
import { Document } from "@ankaadia/graphql";
import { isNil } from "lodash";
import { AnyCandidateForm } from "../../candidates/candidate-form/candidate-form.model";
import { AdditionalForeignKeySourceData } from "../candidate-document-foreign-key-handler";

@Component({
  selector: "app-candidate-document-date",
  templateUrl: "./candidate-document-date.component.html",
})
export class CandidateDocumentDateComponent {
  protected readonly isNil = isNil;

  @Input({ required: true })
  candidateId: string;

  @Input({ required: true })
  candidateOrganizationId: string;

  @Input({ required: true })
  candidateForm: AnyCandidateForm;

  @Input()
  additionalForeignKeySourceData: AdditionalForeignKeySourceData;

  @Input({ required: true })
  label: string;

  @Input({ required: true })
  documentType: DocumentType;

  @Input({ required: true })
  dateField: DocumentSetDateField;

  @Input({ required: true })
  selectedSet: SelectedSet;

  @Input()
  inputId?: string;

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  shownDate: Date;

  getDateAndSetCurrent(document: Document): Date {
    this.shownDate = getSelectedSet(document, this.selectedSet)?.[this.dateField];
    return this.shownDate;
  }

  isChecked(document: Document): boolean {
    const set = getSelectedSet(document, this.selectedSet);
    return getSetFiles(set).length > 0 || !!set?.[this.dateField];
  }
}
