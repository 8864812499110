import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SwitchableOrganizationFragment } from "@ankaadia/graphql";
import { FilterService, PrimeTemplate } from "primeng-v17/api";
import { Dropdown, DropdownModule } from "primeng-v17/dropdown";
import { Subject, debounceTime } from "rxjs";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../shared/services/settings.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-organization-switch",
  templateUrl: "./organization-switch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateDirective,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    PrimeTemplate,
    NgIf,
  ],
})
export class OrganizationSwitchComponent implements OnInit {
  readonly isCandidate = this.settings.isCandidate;
  readonly switchableOrganizationCount = this.settings.switchableOrganizationCount;
  readonly maxOrganizationsBeforeShowingFilter = 10;
  readonly filter = OrganizationSwitchComponent.name;
  readonly filteredSub = new Subject<string>();

  @Input()
  organizations: SwitchableOrganizationFragment[];

  @Input()
  overlayOnly?: boolean;

  @Output()
  readonly filtered = new EventEmitter<string>();

  @Output()
  readonly switched = new EventEmitter<string>();

  @ViewChild(Dropdown)
  dropdown: Dropdown;

  constructor(
    private readonly settings: SettingsService,
    private readonly filterService: FilterService
  ) {}

  ngOnInit(): void {
    // always enabled because the organizations are filtered on the backend
    this.filterService.register(this.filter, () => true);

    this.filteredSub.pipe(debounceTime(100)).subscribe((x) => this.filtered.emit(x));
  }

  show(): void {
    this.dropdown.show();
  }
}
