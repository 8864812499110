import { Injectable } from "@angular/core";
import { uniq } from "lodash";
import { DefaultSpecific } from "../default/default-specifics";
import { SupportedTaskTypes } from "../organization-specific";

@Injectable({ providedIn: "root" })
export class AlfaSpecific extends DefaultSpecific {
  override getSupportedTaskTypes(): SupportedTaskTypes[] {
    return uniq(super.getSupportedTaskTypes().concat(["ProcessTaskAutoOperationModel"]));
  }
}
