import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GuetegemeinschaftOrganizationReCertificationInput } from "@ankaadia/graphql";
import { catchError, of } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { GuetegemeinschaftService } from "./guetegemeinschaft.service";
import { OrganizationCreationComponent } from "./gueteGemeinschaftOrganizationCreation.component";

@Component({
  selector: "app-guetegemeinschaft-link-handling",
  templateUrl: "./gueteGemeinschaftLinkHandling.component.html",
  styleUrl: "./gueteGemeinschaftLinkHandling.component.scss",
  imports: [NgIf, OrganizationCreationComponent],
})
export class GueteGemeinschaftLinkHandlingComponent implements OnInit {
  showInputForm = false;
  message: string;
  buttonLabel = "Zurück";
  backAction: "back" | "logout" | "home" | "jumpTo" = "back";
  jumpTarget: string;

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly guetegemeinschaftService: GuetegemeinschaftService
  ) {}

  ngOnInit(): void {
    const orgId = this.activeRoute.snapshot.params.orgId ?? this.activeRoute.snapshot.queryParams.orgId;
    if (orgId) {
      this.guetegemeinschaftService.createReCertificationProcess(orgId).subscribe((result) => {
        if (result.orgMismatch) {
          if (result.switchOrg) {
            this.settingsService.switchOrganizationKeepingCurrentUrl(orgId);
          } else {
            this.showInputForm = true;
            this.message = `<p>Mit ihrem aktuellen Ankaadia Benutzer haben Sie keinen Zugriff auf den für Sie gestarteten Rezertifizierungsprozess.</p>
          <p>Bitte melden Sie sich mit dem Ankaadia Benutzer an, mit dem Sie auch die Zertifizierung durchgeführt haben.</p>
          <p>Sollten Sie den Benutzer-Account nicht mehr wissen,  wenden Sie sich bitte an
          <a href='mailto:ann-christin.wedeking@kda.de'>Ann-Christin Wedeking</a> in der Geschäftsstelle der Gütegemeinschaft Anwerbung und Vermittlung von Pflegekräften aus dem Ausland e.V.</p>`;
            this.buttonLabel = "Mt anderem Benutzer anmelden";
            this.backAction = "logout";
            this.jumpTarget = orgId;
          }
        } else {
          this.jumpToProcess(result.organizationId, result.processId, result.taskId);
        }
      });
    } else {
      this.showInputForm = true;
    }
  }

  navigatedBack(): void {
    switch (this.backAction) {
      case "back":
        this.message = null;
        break;
      case "logout":
        {
          const urlString = window.location.href.split("/").slice(0, -1).join("/");
          const url = new URL(urlString);
          url.searchParams.set("orgId", this.jumpTarget);
          this.settingsService.logoutWithRedirecht(url.toString());
        }
        break;
      case "home":
        window.location.href = "https://www.ankaadia.com/";
        break;
      case "jumpTo":
        window.location.href = this.jumpTarget;
        break;
    }
  }

  organizationCreated(formValue: GuetegemeinschaftOrganizationReCertificationInput): void {
    this.guetegemeinschaftService
      .createOrganizationAndReCertificationProcess(formValue)
      .pipe(catchError((error) => (this.handleError(error), of(null))))
      .subscribe((x) => {
        if (x != null) {
          if (x.userAlreadyExisted) {
            this.message = `<p>Vielen Dank für Ihre Anmeldung. Ihr Rezertifizierungsprozess wurde gestartet.</p><p>Da Sie bereits bei Ankaadia registriert sind können sie unter nach dem einloggen in Ankaadia den Prozess direkt einsehen.</p>`;
            this.buttonLabel = "Zum Rezertifizierungsprozess";
            this.jumpTarget = x.taskLink;
            this.backAction = "jumpTo";
          } else {
            this.message = `<p>Vielen Dank für Ihre Anmeldung. Ihr Rezertifizierungsprozess wurde gestartet.</p><p>Zur Anmeldung an Ankaadia haben wir Ihnen den Link zum setzen Ihres Passwortes per E-Mail zugestellt.</p> `;
            this.buttonLabel = "Schließen";
            this.backAction = "home";
          }
        }
      });
  }

  handleError(_error: any): any {
    this.message = `<p>Ihre Organisation existiert bereits. Sollte Sie die Zertifizierung bereits über Ankaadia durchgeführt haben,
     so erhalten Sie automatisch eine E-Mail mit einem Link für die Durchführung des Rezertifizierungsprozesses.</p>
     <p> Sollten Sie keine E-Mail erhalten haben oder Ihre Zertifizierung nicht über Ankaadia durchgeführt haben, so wenden Sie sich bitte an
     <a href='mailto:ann-christin.wedeking@kda.de'>Ann-Christin Wedeking</a> in der Geschäftsstelle der Gütegemeinschaft Anwerbung und Vermittlung von Pflegekräften aus dem Ausland e.V.
     `;
  }

  private jumpToProcess(orgId: string, processId: string, taskId: string): void {
    window.location.href = this.router
      .createUrlTree([`/app/processes/view/${orgId}/${processId}/${taskId}`])
      .toString();
  }
}
