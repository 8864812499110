import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { FormElementMapService } from "../from-element-map/form-element-map.service";

@Injectable({ providedIn: "root" })
export class ClickCaptureService {
  private readonly cursorStyle = "crosshair";

  private _isOn = false;
  private toggleElement = null;
  private lastEnabledControl: AbstractControl = null;

  elementClicked = new Subject<HTMLElement>();
  formControlClicked = new Subject<AbstractControl>();
  onStateChanged = new Subject<boolean>();

  clickTroughForDropdown = true;
  enableAllFormControlsAtHovering = true;
  tabClickEnabled = false;
  buttonClickEnabled = false;
  lastElementCursorStyleChanged: HTMLElement;
  lastCursorStyle: string;
  lastToggleElementCursorStyle: string;

  constructor(private readonly elementMapService: FormElementMapService) {}

  get isOn(): boolean {
    return this._isOn;
  }

  turnOn(element: EventTarget): void {
    this.toggleElement = element;
    this.lastToggleElementCursorStyle = this.toggleElement.style.cursor;
    if (!this._isOn) {
      this._isOn = true;
      this.addEventListeners();
      this.onStateChanged.next(true);
    }
  }

  turnOff(): void {
    if (this._isOn) {
      this._isOn = false;
      this.removeEventListener();
      this.onStateChanged.next(false);
      this.toggleElement.style.cursor = this.lastToggleElementCursorStyle;
    }
  }

  toggleClickCapturing(element?: HTMLElement): void {
    if (this._isOn) {
      this.turnOff();
    } else {
      this.turnOn(element);
    }
  }

  getElementByFormControl(control: AbstractControl): HTMLElement {
    return this.elementMapService.getElementByFormControl(control);
  }

  suspend(): void {
    this._isOn = false;
  }

  resume(): void {
    this._isOn = true;
  }

  private readonly eventsToCapture = ["click", "dblclick", "keydown", "focus", "mousemove"];
  private readonly boundedHandleEvent = this.handleEvent.bind(this);
  private readonly listenerOptions = { capture: true };

  private addEventListeners(): void {
    for (const event of this.eventsToCapture) {
      window.addEventListener(event, this.boundedHandleEvent, this.listenerOptions);
    }
  }

  private removeEventListener(): void {
    for (const event of this.eventsToCapture) {
      window.removeEventListener(event, this.boundedHandleEvent, this.listenerOptions);
    }
  }

  private handleEvent(e: UIEvent): void {
    if (this.isOn) {
      if (e.type == "mousemove") {
        this.handleMouseMove(e as MouseEvent);
      }
      if (e.target !== this.toggleElement && this.stopPropagation(e)) {
        if (e.target instanceof HTMLElement && e.type == "click") {
          this.elementClicked.next(e.target);
        }
        e.stopPropagation();
        e.preventDefault();
      }
      if (e.type == "click") {
        this.checkIfNativeElementWithFormControlWasClickedAndNotify(e.target);
      }
    }
  }

  private handleMouseMove(e: MouseEvent): void {
    if (e.target instanceof HTMLElement && this.enableAllFormControlsAtHovering) {
      const control = this.checkAndGetNativeElementHasControl(e.target);
      if (control) {
        if (control.disabled) {
          this.lastEnabledControl?.disable();
          control.enable();
          this.lastEnabledControl = control;
        }
      } else {
        this.lastEnabledControl?.disable();
        this.lastEnabledControl = null;
      }
    }
    if (
      e.target instanceof HTMLElement &&
      this.lastElementCursorStyleChanged !== e.target &&
      e.target != this.toggleElement
    ) {
      if (this.lastElementCursorStyleChanged != null) {
        this.lastElementCursorStyleChanged.style.cursor = this.lastCursorStyle;
      }
      this.lastElementCursorStyleChanged = e.target;
      this.lastCursorStyle = e.target.style.cursor;
      e.target.style.cursor = this.cursorStyle;
      if (e.target == this.toggleElement) {
        e.target.style.cursor = "pointer";
      }
    }
  }

  private checkAndGetNativeElementHasControl(element: HTMLElement, numberOfTries = 0): AbstractControl {
    if (numberOfTries > 5 || element == null) {
      return null;
    }
    const control = this.elementMapService.getFormControlByElement(element);
    if (control != null) {
      return control;
    } else {
      return this.checkAndGetNativeElementHasControl(element.parentElement, numberOfTries + 1);
    }
  }

  private checkIfNativeElementWithFormControlWasClickedAndNotify(target: EventTarget): void {
    const control = this.checkAndGetNativeElementHasControl(target as HTMLElement);
    if (control) {
      this.formControlClicked.next(control);
    }
  }

  private stopPropagation(e: UIEvent): boolean {
    const whiteListKey = ["Escape"];
    const blackListKey = ["ArrowUp", "ArrowDown", "Up", "Down"];
    if (e.type == "keydown") {
      if (blackListKey.includes((e as KeyboardEvent).key)) {
        return true;
      }
      if (whiteListKey.includes((e as KeyboardEvent).key)) {
        return false;
      }
    }
    let result = true;
    if (e.type == "click" || e.type == "dblclick") {
      result = this.clickTroughForDropdown ? this.handleDropDownArteFacts(e.target) && result : result;
      result = this.tabClickEnabled ? result && this.handleTab(e.target) : result;
      result = this.buttonClickEnabled ? result && this.handleButton(e.target) : result;
    }
    return result;
  }

  private handleDropDownArteFacts(target: EventTarget, parentRun = false): boolean {
    const containsArtifacts = this.includesClass(target as HTMLElement, "p-dropdown");
    const stopPropagationAnyway =
      this.includesClass(target as HTMLElement, "p-dropdown-item") ||
      this.includesClass(target as HTMLElement, "p-dropdown-clear-icon");
    if (!containsArtifacts) {
      if (!parentRun) {
        return this.handleDropDownArteFacts((target as HTMLElement).parentElement, true);
      } else {
        return true;
      }
    } else {
      return containsArtifacts && stopPropagationAnyway;
    }
  }

  private handleTab(target: EventTarget): boolean {
    return !this.includesClass(target as HTMLElement, "p-tabview-nav-link");
  }

  private includesClass(element: HTMLElement, className: string): boolean {
    return element.classList.contains(className);
  }

  private handleButton(target: EventTarget): boolean {
    return !this.includesClass(target as HTMLElement, "p-button");
  }
}
