<ng-container *appTranslate="let t">
  <p-button
    [label]="t('process.reports')"
    *ngIf="showButton"
    [disabled]="disableButton"
    (onClick)="showReportSidebarClicked($event)"
  >
  </p-button>
  <app-report-templates
    *ngIf="showSideBar"
    [collectionId]="collectionId"
    [sharingId]="sharingId"
    [sharingOrganizationId]="sharingOrganizationId"
    [collectionOrganizationId]="organizationId"
    [selectedOrganizationId]="selectedOrganizationId"
    [showSelectionSidebar]="showSideBar"
    [customLazyLoadEvent]="customLazyLoadEvent"
    (closed)="onSideBarClose($event)"
  ></app-report-templates>
</ng-container>
