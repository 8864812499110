<ng-container *appTranslate="let t">
  <ng-container *ngIf="exams">
    <p-fieldset *ngIf="exams?.length">
      <ng-template pTemplate="header">
        <ng-container *ngIf="examGroup.level">{{
          examGroup.level | staticData: StaticDataType.LanguageModules | async
        }}</ng-container>
        <ng-container *ngIf="examGroup.institution">
          {{ examGroup.institution | staticData: StaticDataType.ExamInstitutions | async }}
        </ng-container>
        <ng-container *ngIf="examGroup.isFullyCertified">
          @if (latestIssueDate) {
            {{ t("languageSkills.fullyCertifiedAt", { date: latestIssueDate | date: "dd.MM.yyyy" }) }}
          } @else {
            {{ t("languageSkills.fullyCertified") }}
          }
        </ng-container>
      </ng-template>
      <ng-container *ngFor="let exam of exams; index as i">
        <div class="col formgrid grid mw-1 p-fluid">
          <div class="field col-12 lg:col-3">
            <label>{{ t("name.title") }}</label>
            <input pInputText [ngModel]="exam.name" [ngModelOptions]="{ standalone: true }" [readonly]="true" />
          </div>

          <div class="field col-12 lg:col-3">
            <label>{{ t("examDate.title") }}</label>
            <p-calendar
              [ngModel]="exam?.examDateAndTime?.date"
              [ngModelOptions]="{ standalone: true }"
              [readonly]="true"
              [showIcon]="false"
              [placeholder]="''"
              [showTime]="exam?.examDateAndTime?.hasTime"
            ></p-calendar>
          </div>

          <div class="field col-12 lg:col-3">
            <label>{{ t("passedExamModules.title") }}</label>
            <input
              pInputText
              [ngModel]="exam.passedModules | enum: 'ExamModuleType'"
              [readonly]="true"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>

          <div class="field col-12 lg:col-3">
            <app-candidate-document-date
              #issueDate
              [candidateId]="candidateId"
              [candidateOrganizationId]="organizationId"
              [candidateForm]="form"
              [additionalForeignKeySourceData]="{ certifiedExams: exams }"
              [readonly]="readonly || disabled"
              [disabled]="disabled"
              [label]="t(languageSkillLabelMapping.examCertificateDate)"
              [documentType]="exam?.documentType"
              [selectedSet]="{ foreignKey: exam.examResultId }"
              [dateField]="'issueDate'"
            ></app-candidate-document-date>
          </div>
        </div>

        <hr class="mt-0" *ngIf="i < exams.length - 1" />
      </ng-container>
    </p-fieldset>
  </ng-container>
</ng-container>
