import { NgModule } from "@angular/core";
import { DocumentsModule } from "../features/documents/documents.module";
import { MessageModule } from "../features/message/message.module";
import { RequiredVaccinationsModule } from "../features/required-vaccinations/required-vaccinations.module";
import { FormlyModule } from "../formly/formly.module";
import { SharedModule } from "../shared/shared.module";
import { BaseProfileComponent } from "./base-candidate-profile/base-profile.component";
import { DefaProfileComponent } from "./defa/defa-profile.component";
import { CardViewDurationPipe } from "./default/default-candidate-card/card-view-duration.pipe";
import { DefaultCardViewComponent } from "./default/default-candidate-card/default-card-view-component.component";
import { DefaultProfileComponent } from "./default/default-candidate-profile/default-profile.component";
import { GueteGemeinschaftLinkHandlingComponent } from "./guetegemeinschaft/gueteGemeinschaftLinkHandling.component";
import { OrganizationCreationComponent } from "./guetegemeinschaft/gueteGemeinschaftOrganizationCreation.component";
import { ReCertificationLinkComponent } from "./guetegemeinschaft/recertificationLink/recertificationLink.component";

@NgModule({
  imports: [SharedModule, FormlyModule, DocumentsModule, MessageModule, RequiredVaccinationsModule],
  declarations: [
    DefaultCardViewComponent,
    BaseProfileComponent,
    DefaultProfileComponent,
    DefaProfileComponent,
    GueteGemeinschaftLinkHandlingComponent,
    OrganizationCreationComponent,
    ReCertificationLinkComponent,
    CardViewDurationPipe,
  ],
  exports: [
    DefaultCardViewComponent,
    DefaultProfileComponent,
    DefaProfileComponent,
    ReCertificationLinkComponent,
    OrganizationCreationComponent,
    CardViewDurationPipe,
  ],
})
export class OrganizationSpecificModule {}
