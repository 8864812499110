import { NgIf } from "@angular/common";
import { ChangeDetectorRef, Component } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { PrimeTemplate } from "primeng-v17/api";
import { ButtonDirective } from "primeng-v17/button";
import { DialogService } from "primeng-v17/dynamicdialog";
import { EditorModule } from "primeng-v17/editor";
import { TooltipModule } from "primeng-v17/tooltip";
import Quill from "quill";
import { filter, startWith } from "rxjs";
import { CandidateTranslationDialogComponent } from "../../features/candidates/candidate-translation-dialog/candidate-translation-dialog.component";
import { TranslationMapping } from "../../features/metered-translations/metered-translations.model";
import { MeteredTranslationsService } from "../../features/metered-translations/metered-translations.service";

@Component({
  selector: "app-formly-rich-text",
  templateUrl: "./formly-rich-text.component.html",
  imports: [NgIf, ButtonDirective, TooltipModule, EditorModule, FormlyModule, PrimeTemplate],
})
export class FormlyRichTextComponent extends FieldType {
  editor: Quill;
  translationMapping: TranslationMapping;

  constructor(
    private readonly translationService: MeteredTranslationsService,
    private readonly dialogService: DialogService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  updateEditor(editor: Quill): void {
    if (editor) {
      this.editor = editor;
      const delta = this.getDeltaControl()?.value ?? this.getContentControl()?.value ?? null;
      this.editor.setContents(delta);

      this.translationService.candidatePropertyTranslationsLoaded$
        .pipe(
          startWith(this.translationService.candidatePropertyTranslationsLoaded$.value),
          filter((x) => x === this.props.candidateId)
        )
        .subscribe(() => this.setUpTranslation());
    }
  }

  updateHtml(): void {
    this.getContentControl().setValue(this.editor.getText());
    this.getDeltaControl().setValue(this.editor.getContents());
  }

  openDialog(event: Event): void {
    this.dialogService.open(CandidateTranslationDialogComponent, {
      closeOnEscape: false,
      dismissableMask: false,
      styleClass: "p-dialog-compact",
      modal: true,
      width: "50%",
      appendTo: this.getElementToAppend(event.target as Element),
      data: { sourceText: { delta: this.editor.getContents() }, itemId: this.translationMapping.id },
    });
  }

  private getElementToAppend(element: Element): Element {
    let lastElement: Element = element;
    while (element && !element.className.includes("layout-wrapper")) {
      lastElement = element;
      element = element.parentElement;
    }
    return element ?? lastElement;
  }

  setUpTranslation(): void {
    this.translationMapping = null;
    const experienceId = this.model?.id;

    if (!experienceId) return null;
    const currentTranslation = this.translationService
      .getFieldTranslationsWithState()
      .find(
        (t) =>
          t.propertyPath.some((p) => p === experienceId) &&
          t.propertyPath.some((p) => p === this.props.translationPrefixIdentifier)
      );

    if (currentTranslation) {
      this.translationMapping = { id: currentTranslation.id, formControl: this.field.formControl };
      this.changeDetectorRef.detectChanges();
    }
  }

  private getContentControl(): AbstractControl<string> {
    return this.form.get(this.props.contentControlName);
  }

  private getDeltaControl(): AbstractControl {
    return this.form.get(this.props.deltaControlName);
  }
}
