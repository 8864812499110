import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { StaticDataModel } from "@ankaadia/graphql";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { AutoCompleteModule } from "primeng-v17/autocomplete";
import { Button } from "primeng-v17/button";
import { SidebarModule } from "primeng-v17/sidebar";
import { BehaviorSubject, Observable, Subject, filter, merge, of, switchMap } from "rxjs";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { AutocompleteEmptySuggestionsFixDirective } from "../../shared/primeng/autocomplete-empty-suggestions-fix/autocomplete-empty-suggestions-fix.directive";
import { AutocompleteOptionsDirective } from "../../shared/primeng/autocomplete-options/autocomplete-options.directive";
import { AutocompleteReadonlyFixDirective } from "../../shared/primeng/autocomplete-readonly-fix/autocomplete-readonly-fix.directive";
import { SidebarAdditionalComponentDirective } from "../../shared/primeng/sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "../../shared/primeng/sidebar-options/sidebar-options.directive";
import { SettingsService } from "../../shared/services/settings.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { AnabinTableComponent } from "./enhanced-dropdown-table/anabin-table.component";

@Component({
  selector: "app-autocomplete",
  templateUrl: "./formly-enhanced-dropdown.component.html",
  imports: [
    TranslateDirective,
    AutoCompleteModule,
    AppendToBodyDirective,
    AutocompleteEmptySuggestionsFixDirective,
    AutocompleteOptionsDirective,
    AutocompleteReadonlyFixDirective,
    FormlyModule,
    FormsModule,
    NgIf,
    Button,
    SidebarModule,
    SidebarAdditionalComponentDirective,
    SidebarOptionsDirective,
    AnabinTableComponent,
    AsyncPipe,
  ],
})
export class FormlyEnhancedDropdownComponent extends FieldType implements OnInit {
  constructor(
    private readonly settings: SettingsService,
    private readonly chRef: ChangeDetectorRef
  ) {
    super();
  }

  displayInfoButton = false;

  showSidebar$ = new BehaviorSubject<boolean>(false);

  details$ = this.showSidebar$.pipe(
    switchMap((show) =>
      show ? (this.props.getDetails(this.formControl.value, this.props) as Observable<any>) : of(null)
    )
  );

  private readonly suggestionSubject$ = new Subject<string>();
  readonly suggestions$: Observable<StaticDataModel[]> = this.suggestionSubject$.pipe(
    switchMap((query) => this.props.getSuggestions(query, this.props) as Observable<StaticDataModel[]>)
  );

  private readonly firstSuggestionSubject$ = new BehaviorSubject<string>("");
  readonly firstSuggestion$: Observable<StaticDataModel[]> = this.firstSuggestionSubject$.pipe(
    filter((query) => query !== "" && !isNaN(+query)),
    switchMap((query) => this.props.firstSuggestion(query, this.props) as Observable<StaticDataModel[]>)
  );

  readonly resultSuggestions$: Observable<StaticDataModel[]> = merge(this.suggestions$, this.firstSuggestion$);

  get isCandidate(): boolean {
    return this.settings.isCandidate;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.displayInfoButton = !!(!isNaN(value) && value);
    });

    if (!isNaN(this.formControl.value) && this.formControl.value) {
      this.displayInfoButton = true;

      this.firstSuggestionSubject$.next(this.formControl.value);
    }
  }

  getSuggestions(query: string): void {
    this.suggestionSubject$.next(query);
  }

  openDetails(): void {
    this.showSidebar$.next(true);
  }

  getSelectedSuggestion(suggestions: StaticDataModel[]): any {
    return suggestions?.find((x) => x.value === this.formControl.value)?.label ?? this.formControl.value;
  }

  updateForm($event: any): void {
    this.formControl.setValue($event.value ?? $event);
    this.formControl.markAsDirty();
    this.chRef.detectChanges();
  }
}
