import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { TranslocoService } from "@jsverse/transloco";
import { pdfDefaultOptions } from "ngx-extended-pdf-viewer";
import { NgxSpinnerComponent } from "ngx-spinner";
import { PrimeNGConfig } from "primeng-v17/api";
import { Observable } from "rxjs";
import { ChatInitService } from "../features/chat/chat-init.service";
import { PromptUpdateService } from "../shared/services/promptUpdateService";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  imports: [NgxSpinnerComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  intializationFinished: Observable<boolean>;

  constructor(
    private readonly promptUpdateService: PromptUpdateService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly transloco: TranslocoService,
    private readonly authService: AuthService,
    private readonly chatInitService: ChatInitService
  ) {}

  ngOnInit(): void {
    this.promptUpdateService.checkForSoftwareUpdate();
    // AuthService must be here to be initialized before the app is loaded. Otherwise login fails
    this.authService.error$.subscribe((error) => {
      // eslint-disable-next-line no-console
      console.error("authService.error$", error);
    });

    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(this.transloco.translateObject("primeng"));
    pdfDefaultOptions.assetsFolder = "assets";

    this.chatInitService?.initChat()?.subscribe();
  }
}
