<ng-container *appTranslate="let t">
  <app-candidate-document-metadata-wrapper
    [candidateId]="candidateId"
    [candidateOrganizationId]="candidateOrganizationId"
    [candidateForm]="candidateForm"
    [additionalForeignKeySourceData]="additionalForeignKeySourceData"
    [documentType]="documentType"
    [selectedField]="{ field: dateField, selectedSet }"
    [readonly]="readonly"
    [disabled]="disabled"
  >
    <ng-template let-document>
      <div class="field-checkbox">
        <p-checkbox
          [inputId]="inputId + dateField + 'Exists'"
          [binary]="true"
          [ngModel]="isChecked(document)"
          [ngModelOptions]="{ standalone: true }"
          [readonly]="readonly"
          [disabled]="disabled || isNil(candidateId)"
        ></p-checkbox>
        <label for="inputId + dateField">{{ label }}</label>
      </div>

      <p-calendar
        [ngModel]="getDateAndSetCurrent(document)"
        [ngModelOptions]="{ standalone: true }"
        [readonly]="readonly"
        [disabled]="disabled || isNil(candidateId)"
      ></p-calendar>
    </ng-template>
  </app-candidate-document-metadata-wrapper>
</ng-container>
