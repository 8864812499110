<div class="flex flex-wrap align-items-center justify-content-center h-screen background-color" *appTranslate="let t">
  <p-card class="w-30rem">
    <p class="font-bold text-xl text-center">{{t("firstLogin.title")}}</p>
    <div class="flex flex-wrap align-items-center justify-content-center">
      <p-image [src]="loginLogoUrl | async" class="m-2" alt="Partner logo" width="150"></p-image>
      <p-image src="../../../assets/logo.png" class="m-2" alt="Ankaadia logo" width="150"></p-image>
      <p class="text-center">{{t("firstLogin.description")}}</p>
      <p class="font-bold text-xl text-center">{{t("firstLogin.signInWith")}}</p>
      <div class="flex justify-content-center w-full">
        <button pButton class="flex-1 m-2 h-4rem" iconPos="right" (click)="facebookLogin() ">
          <div class="flex flex-wrap flex-column align-items-center justify-content-center w-full">
            <i class="pi pi-facebook mt-2 text-2xl"></i>
            <p class="m-1">{{t("firstLogin.facebook")}}</p>
          </div>
        </button>
        <button pButton class="flex-1 m-2 h-4rem" iconPos="right" (click)="userNamePasswordLogin()">
          <div class="flex flex-wrap flex-column align-items-center justify-content-center w-full">
            <i class="pi pi-key mt-2 text-2xl"></i>
            <p class="m-1">{{t("firstLogin.email")}}</p>
          </div>
        </button>
      </div>
    </div>
  </p-card>
</div>
