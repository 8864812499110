import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input, output } from "@angular/core";
import { AnabinAssetType, StaticDataType } from "@ankaadia/graphql";
import { AccordionModule } from "primeng-v17/accordion";
import { PrimeTemplate } from "primeng-v17/api";
import { Button } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { AnabinDetails } from "../../../features/anabin/anabin.service";
import { StaticDataPipe } from "../../../shared/static-data/static-data.pipe";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-anabin-table",
  templateUrl: "./anabin-table.component.html",
  styleUrl: "./anabin-table.component.scss",
  imports: [
    NgIf,
    CardModule,
    TranslateDirective,
    PrimeTemplate,
    AccordionModule,
    NgFor,
    NgTemplateOutlet,
    Button,
    AsyncPipe,
    StaticDataPipe,
  ],
})
export class AnabinTableComponent {
  @Input() anabinData: AnabinDetails;
  @Input() language: string;

  readonly closeComponent = output();

  protected readonly AnabinAssetType = AnabinAssetType;
  protected readonly StaticDataType = StaticDataType;

  readonly tableConfig = {
    AnabinDegreesForInfoView: {
      ratings: {
        fields: ["equivalence", "degree", "comment"],
        labels: ["anabin.degree.ratings.equivalent", "anabin.degree.ratings.DEdegreeType", "comment.title"],
      },
      institutions: {
        fields: ["name", "type", "city", "country"],
        labels: [
          "anabin.degree.institution.name",
          "anabin.degree.institution.type",
          "anabin.degree.institution.city",
          "country.title",
        ],
      },
    },
    AnabinInstitutionInfoViewDetails: {
      alternativeNames: {
        fields: ["name", "language"],
        labels: ["name.title", "language.title"],
      },
      degrees: {
        fields: ["name", "type", "class", "country"],
        labels: ["anabin.degree.name", "anabin.degree.type", "anabin.degree.class", "country.title"],
      },
    },
  };
}
