export const logo = {
  root: {
    borderRadius: "50%",
    borderColor: "{surface.200}",
    borderSize: "1px",
    background: "{surface.0}",
    padding: "5px",
    sizeSmall: "48px",
    sizeNormal: "72px",
    sizeLarge: "96px",
    borderless: {
      borderColor: "{transparent}",
      padding: "0",
    },
  },
};
