import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  DoCheck,
  OnChanges,
  OnDestroy,
  OnInit,
  Type,
} from "@angular/core";

type AngularHooks = OnInit &
  OnDestroy &
  OnChanges &
  DoCheck &
  AfterContentInit &
  AfterContentChecked &
  AfterViewInit &
  AfterViewChecked;

/**
 * Provides a way to execute custom functionality during the Angular lifecycle hooks in a component.
 *
 * Example:
 * ```ts
 * overrideHook(MultiSelect, "ngOnInit", function (this) {
 *   console.log("Multiselect initialized!", this);
 * });
 *
 * overrideHook(MultiSelect, "ngOnChanges", function (this, changes) {
 *   console.log("Multiselect changed!", this, changes);
 * });
 * ```
 *
 * @param component Type of the component.
 * @param hook Name of the lifecycle hook.
 * @param fn Custom hook implementation.
 */
export function overrideHook<
  TComponent,
  THookName extends keyof AngularHooks,
  THookOrig extends AngularHooks[THookName],
  THookImpl extends (this: TComponent, ...args: Parameters<THookOrig>) => ReturnType<THookOrig>,
>(component: Type<TComponent>, hook: THookName, fn: THookImpl): void {
  const original: THookImpl = component.prototype[hook];
  component.prototype[hook] = function (this: TComponent, ...args: Parameters<THookOrig>): ReturnType<THookOrig> {
    fn.apply(this, args);
    return original?.apply(this, args);
  };
}
