import { Component, EventEmitter, Input, Output } from "@angular/core";

interface BtnInput {
  label?: string;
  disabled?: boolean;
}

interface ToolbarConfiguration {
  saveAndClose?: boolean;
  save?: boolean;
  cancel?: boolean;
}

@Component({
  selector: "app-edit-tool-bar",
  templateUrl: "./edit-tool-bar.component.html",
  styleUrl: "./edit-tool-bar.component.scss",
})
export class EditToolBarComponent {
  @Input()
  showBtn: ToolbarConfiguration;

  @Input()
  saveAndCloseBtn: BtnInput;

  @Input()
  saveBtn: BtnInput;

  @Input()
  cancelBtn: BtnInput;

  @Output()
  readonly savedAndClosed = new EventEmitter<MouseEvent>();

  @Output()
  readonly saved = new EventEmitter<MouseEvent>();

  @Output()
  readonly canceled = new EventEmitter<MouseEvent>();

  protected onSave(event: MouseEvent): void {
    this.saved.emit(event);
  }

  protected onCancel(event: MouseEvent): void {
    this.canceled.emit(event);
  }

  protected onSaveAndClose(event: MouseEvent): void {
    this.savedAndClosed.emit(event);
  }
}
