import { Injectable } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Observable } from "rxjs";
import userflow from "userflow.js";
import { environment } from "../../../environments/environment";
import { mobileAndTabletCheck } from "../../shared/services/mobileDevice.helper";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class UserFlowService {
  constructor(
    private readonly settings: SettingsService,
    private readonly transloco: TranslocoService
  ) {}

  init(): Observable<boolean> {
    return new Observable<boolean>((sub) => {
      userflow.init(environment.userFlowToken);
      userflow.setResourceCenterLauncherHidden(true);
      userflow.on("resourceCenterChanged", () => {
        const x = userflow.getResourceCenterState();
        sub.next(!!x);
      });
      void userflow.identify(this.settings.userOrCandidateId, {
        displayId: this.settings.userDisplayId,
        organization: this.settings.organizationName,
        role: this.settings.isCandidate ? "candidate" : "user",
        language: this.transloco.getActiveLang(),
        onMobile: mobileAndTabletCheck(),
        isLicensed: this.settings.isLicensed,
      });
    });
  }

  open(): void {
    userflow.openResourceCenter();
  }
}
