import { Component, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { PropertyTranslation, TranslatorType } from "@ankaadia/graphql";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import Quill from "quill";
import { Delta } from "quill/core";
import {
  MeteredTranslationsService,
  PropertyTranslationWithState,
} from "../../metered-translations/metered-translations.service";

@Component({
  selector: "app-candidate-translation-dialog",
  templateUrl: "./candidate-translation-dialog.component.html",
})
export class CandidateTranslationDialogComponent implements OnInit {
  translationItem: PropertyTranslationWithState;
  sourceText: string;
  sourceDelta: Delta;
  currentTranslation: string;
  // existingTranslation: string;
  manualChanges: boolean;

  translationResult: PropertyTranslation;
  quillEditor: Quill;

  constructor(
    private readonly dialog: DynamicDialogRef,
    private readonly config: DynamicDialogConfig,
    private readonly translationService: MeteredTranslationsService,
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.translationItem = this.translationService.getFieldTranslationById(this.config.data.itemId);
    if (typeof this.config.data.sourceText === "object") {
      this.sourceDelta = this.config.data.sourceText.delta;
    } else {
      this.sourceText = this.config.data.sourceText;
      this.currentTranslation = this.translationItem?.targetText;
    }
    if (this.translationItem?.translationProvider === TranslatorType.Human) {
      this.updateManualChangeState(true);
    }
  }

  updateEditor(editor: Quill): void {
    if (editor) {
      this.quillEditor = editor;
      editor.setContents(this.sourceDelta, "silent");
      const sourceTextElement = this.el.nativeElement.querySelector("#sourceText");
      if (sourceTextElement) {
        this.renderer.setProperty(sourceTextElement, "innerHTML", this.quillEditor.root.innerHTML);
      }
      this.quillEditor.setContents(this.translationItem.targetTextAsDelta, "silent");
    }
  }

  updateTranslation(): void {
    this.currentTranslation = this.quillEditor.getText();
    this.updateManualChangeState(true);
  }

  translate(): void {
    this.translationService
      .processUserRequestedTranslation({
        translationItemId: this.translationItem.translationItemId,
        organizationId: this.translationItem.organizationId,
        propertyPath: this.translationItem.propertyPath,
        sourceText: this.sourceText,
        sourceTextAsDelta: this.sourceDelta,
      })
      .subscribe((translation) => {
        this.translationResult = translation;
        this.currentTranslation = translation.targetText;
        if (translation.targetTextAsDelta) {
          this.quillEditor.setContents(translation.targetTextAsDelta, "silent");
        }
        this.updateManualChangeState(false);
      });
  }

  cancel(_event: MouseEvent): void {
    this.dialog.close(null);
  }

  submit(_event: MouseEvent): void {
    this.translationItem.isChanged = true;
    this.translationItem.targetText = this.currentTranslation;
    this.translationItem.sourceText = this.sourceText;
    this.translationItem.sourceTextAsDelta = this.sourceDelta;
    this.translationItem.targetTextAsDelta = this.quillEditor?.getContents();

    if (this.translationResult) {
      this.translationItem.targetLanguage = this.translationResult.targetLanguage;
      this.translationItem.sourceLanguage = this.translationResult.sourceLanguage;
    }
    this.translationItem.translationProvider =
      this.translationResult && this.currentTranslation === this.translationResult.targetText
        ? this.translationResult.translationProvider
        : TranslatorType.Human;

    this.dialog.close({
      translationItem: this.translationItem,
    });
  }

  updateManualChangeState(textChanged: boolean): void {
    this.manualChanges = textChanged;
  }
}
