<p-multiSelect
  *ngIf="!to.readonly || (to.readonly && !to.readonlyProxy)"
  [placeholder]="to.placeholder"
  [group]="to.group"
  [options]="
    to.group ? (to.options | groupItems: to.staticDataType | async) : (to.options | formlySelectOptions: field | async)
  "
  optionGroupChildren="group"
  [formControl]="$any(formControl)"
  [showToggleAll]="to.showToggleAll != null ? to.showToggleAll : true"
  [formlyAttributes]="field"
  [readonly]="to.readonly"
  [filter]="to.filter"
  [emptyValue]="to.emptyValue"
  [showClear]="props.showClear"
></p-multiSelect>

<p *ngIf="to.readonly && to.readonlyProxy">
  {{ getText(formControl.value, to.options | formlySelectOptions: field | async) }}
</p>
