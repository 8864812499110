import { base } from "./base/base";
import { logo } from "./logo/logo";
import { navitem } from "./navitem/navitem";
import { sidenav } from "./sidenav/sidenav";

export const Ankaadia = {
  ...base,
  components: {
    logo,
    navitem,
    sidenav,
  },
};
