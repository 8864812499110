<ng-container *appTranslate="let t">
  <app-table
    *ngIf="!showSelectionSidebar"
    [items]="availableReportTemplates"
    [columns]="reportColumns"
    [tableOperations]="tableOperations"
    [scrollable]="true"
    [showSearch]="true"
  >
  </app-table>
  <p-sidebar [visible]="showSidebar" styleClass="p-sidebar-lg" [fullScreen]="isFullScreen">
    <ng-template pTemplate="header"
      ><button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="isFullScreen"
        pButton
        type="button"
        (click)="isFullScreen = false"
        icon="pi pi-arrow-right"
      ></button>
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="!isFullScreen"
        pButton
        type="button"
        (click)="isFullScreen = true"
        icon="pi pi-arrow-left"
      ></button>
    </ng-template>
    <ng-container *ngIf="selectedTemplate && !reportTemplate">
      <app-document-template-preview-dialog
        [url]="selectedTemplate.downloadUrl"
        [fileName]="selectedTemplate.filename"
        (closed)="close()"
      ></app-document-template-preview-dialog>
    </ng-container>
    <ng-container *ngIf="(!selectedTemplate && reportTemplate) || showReportSelectionDropdown">
      <p-card *appTranslate="let t" [header]="reportTemplate?.displayName ?? t('process.reports')">
        <div *ngIf="showReportSelectionDropdown">
          <label>{{ t("process.report") }}</label>
          <p-dropdown
            [options]="availableReportTemplates"
            optionLabel="displayName"
            optionValue="templateId"
            [showClear]="true"
            [placeholder]="t('common.select')"
            [style]="{ width: '100%' }"
            [(ngModel)]="selectedReportTemplate"
            (onChange)="onSelectedReportTemplateChanged($event)"
          >
          </p-dropdown>
          <p class="mt-3">
            {{ t("dataStrategy.filterHint") }}
          </p>
          <p class="mb-3 font-semibold">{{ t("dataStrategy.filterHintBold") }}</p>
        </div>
        <app-report-data-strategy
          *ngIf="reportTemplate"
          [configurationForm]="reportConfigurationForm"
          mode="Generation"
          [strategyId]="reportStrategyId"
          [strategyOrganizationId]="reportStrategyOrganizationId"
          [reportTemplateId]="reportTemplate.templateId"
          [reportTemplateDescription]="reportTemplate.description"
          (modelChanged)="onReportConfigurationModelChanged($event)"
        >
        </app-report-data-strategy>
        <ng-template pTemplate="footer">
          <div class="flex justify-content-end">
            <p-button
              [label]="t('common.save')"
              icon="pi pi-check"
              [disabled]="isBusy || !reportConfigurationForm.valid || selectedReportTemplate == null"
              (onClick)="onGenerateFromGenerationConfig()"
              class="mr-2"
            ></p-button>
            <p-button
              closeOnEscape
              [label]="t('common.cancel')"
              icon="pi pi-times"
              [disabled]="isBusy"
              (onClick)="close()"
            ></p-button>
          </div>
        </ng-template>
      </p-card>
    </ng-container>
  </p-sidebar>
</ng-container>
