import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormElementMapModule } from "../from-element-map/form-element-map.module";
import { TestIdDirective } from "./test-id.directive";

@NgModule({
  imports: [CommonModule, FormsModule, FormElementMapModule],
  declarations: [TestIdDirective],
  exports: [TestIdDirective],
})
export class TestIdModule {}
