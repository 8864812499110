import { AsyncPipe, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { FormlySelectModule, FormlySelectOption } from "@ngx-formly/core/select";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectEmptyDirective } from "../../shared/primeng/multi-select-empty/multi-select-empty.directive";
import { MultiSelectReadonlyFixDirective } from "../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";
import { GroupItemsPipe } from "./group-items.pipe";

@Component({
  selector: "app-formly-multiselect",
  templateUrl: "./formly-multiselect.component.html",
  styleUrl: "./formly-multiselect.component.scss",
  imports: [
    NgIf,
    MultiSelectModule,
    AppendToBodyDirective,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    MultiSelectEmptyDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
    AsyncPipe,
    FormlySelectModule,
    GroupItemsPipe,
  ],
})
export class FormlyMultiSelectComponent extends FieldType {
  getText(value: string[], options: FormlySelectOption[]): string {
    if (value != null && Array.isArray(value)) {
      return (options ?? [])
        .filter((x) => value.includes(x.value))
        .map((y) => y.label)
        ?.join(", ");
    } else {
      return "";
    }
  }
}
