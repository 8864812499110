import { Injectable } from "@angular/core";
import { WorkExperiencePrecissionEnum } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import moment from "moment";
import { DateFormatterService } from "../../../shared/services/date-formatter.service";

@Injectable({ providedIn: "root" })
export class DefaultCardViewFormatterService {
  constructor(
    private readonly transloco: TranslocoService,
    private readonly dateFormatterService: DateFormatterService
  ) {}

  getDurationString(duration: { years: number; months: number }, kind: "long" | "short"): string {
    const years = duration.years;
    const month = duration.months;
    return [
      years > 0 ? `${years} ${this.transloco.translate(`experience.years${kind === "short" ? "Short" : ""}`)}` : "",
      month > 0 ? `${month} ${this.transloco.translate(`experience.month${kind === "short" ? "Short" : ""}`)}` : "",
      years === 0 && month === 0
        ? `${month} ${this.transloco.translate(`experience.month${kind === "short" ? "Short" : ""}`)}`
        : "",
    ]
      .filter((x) => x)
      .join(" ");
  }

  getPeriodString(startDate: Date, endDate: Date, workingExperiencePrecision: WorkExperiencePrecissionEnum): string {
    const formatDate = (date: Date): string =>
      workingExperiencePrecision === WorkExperiencePrecissionEnum.Month
        ? moment(date).format("MM/YYYY")
        : this.dateFormatterService.transformDate(date, { dateStyle: "short" });

    if (startDate && endDate) {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    } else if (startDate) {
      return `${this.transloco.translate("common.since")} ${formatDate(startDate)}`;
    }

    return "";
  }
}
