import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { InputNumberModule } from "primeng-v17/inputnumber";
import { InputTextModule } from "primeng-v17/inputtext";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { InputNumberOptionsDirective } from "../../shared/primeng/input-number-options/input-number-options.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-input",
  templateUrl: "./formly-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    InputNumberModule,
    InputNumberOptionsDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
    InputTextModule,
  ],
})
export class FormlyInputComponent extends FieldType {}
