import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { CertifiedEducationExamGroupFragment } from "@ankaadia/graphql";
import { FieldType } from "@ngx-formly/core";
import { FieldsetModule } from "primeng-v17/fieldset";
import { Observable, of } from "rxjs";
import { EducationExamService } from "../../features/education/education-exams/education-exam.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { FormlyCertifiedEducationExamsEntryComponent } from "./formly-certified-education-exams-entry.component";

@Component({
  selector: "app-formly-certified-education-exams",
  templateUrl: "./formly-certified-education-exams.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, NgIf, FieldsetModule, NgFor, FormlyCertifiedEducationExamsEntryComponent, AsyncPipe],
})
export class FormlyCertifiedEducationExamsComponent extends FieldType implements OnInit {
  examGroups$: Observable<CertifiedEducationExamGroupFragment[]>;

  constructor(
    private readonly educationExamService: EducationExamService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.examGroups$ = this.formState.candidateId ? this.getCertifiedExamData() : of(null);
    this.changeDetector.detectChanges();
  }

  private getCertifiedExamData(): Observable<CertifiedEducationExamGroupFragment[]> {
    return this.educationExamService.getCertified(this.formState.candidateId, this.formState.organizationId);
  }
}
