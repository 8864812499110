import { Injectable, Injector, PipeTransform, ProviderToken } from "@angular/core";
import { DateWithTime } from "@ankaadia/graphql";
import { castArray, get, isArray, isDate, toPairs } from "lodash";
import { SortEvent } from "primeng/api";
import { ObjectUtils } from "primeng/utils";
import { isObservable } from "rxjs";
import { PipeDescription, TableCache, TableColumn } from "./table.model";

/**
 * Service to handle the sorting and filtering of a table.
 * It caches the values of the pipes to avoid calling them multiple times and to handle observables.
 */
@Injectable({ providedIn: "root" })
export class TableService {
  constructor(private readonly injector: Injector) {}

  buildPipeCache(columns: TableColumn[], items: any[], language: string): TableCache {
    const cache: TableCache = { columns: null, entries: null, language: language };
    if (isArray(columns) && isArray(items)) {
      cache.columns = columns;
      cache.entries = columns
        .map((col) => {
          if (col.pipeDescription) {
            const pipeInstance = this.injector.get(col.pipeDescription.pipeType as ProviderToken<PipeTransform>, null);
            const values = items
              ?.filter((item) => get(item, col.fieldname))
              .reduce<
                Record<string, string>
              >((acc, v) => ((acc[Array.isArray(get(v, col.fieldname)) ? get(v, col.fieldname).join("") : get(v, col.fieldname).toString()] = col.pipeDescription.transform(pipeInstance, get(v, col.fieldname))), acc), {});

            return {
              pipeDescription: col.pipeDescription,
              pipeInstance: pipeInstance,
              values: values,
            };
          } else {
            return null;
          }
        })
        .filter((x) => x != null);
      // Resolve Observables from the pipes
      cache.entries.forEach((x) =>
        toPairs(x.values).forEach(([key, value]) => {
          if (isObservable(value)) {
            value.subscribe((v) => (x.values[key] = v?.toString()));
          }
        })
      );
    }
    return cache;
  }

  sort(event: SortEvent, cache: TableCache): void {
    // https://github.com/primefaces/primeng/blob/4e482687cc4b5d3aa006180294dd9787a87402b0/src/app/components/table/table.ts#L1045-L1057
    if (isArray(event.data)) {
      event.data.sort((data1, data2) => {
        const column = cache.columns?.find((x) => x.fieldname === event.field);

        const value1 = column.pipeDescription
          ? this.getPipeValue(ObjectUtils.resolveFieldData(data1, event.field), cache, column.pipeDescription)
          : isArray(ObjectUtils.resolveFieldData(data1, event.field))
            ? ObjectUtils.resolveFieldData(data1, event.field).join("")
            : ObjectUtils.resolveFieldData(data1, event.field);

        const value2 = column.pipeDescription
          ? this.getPipeValue(ObjectUtils.resolveFieldData(data2, event.field), cache, column.pipeDescription)
          : isArray(ObjectUtils.resolveFieldData(data2, event.field))
            ? ObjectUtils.resolveFieldData(data2, event.field).join("")
            : ObjectUtils.resolveFieldData(data2, event.field);

        let result = null;
        if (value1 == null && value2 != null) {
          result = -1;
        } else if (value1 != null && value2 == null) {
          result = 1;
        } else if (value1 == null && value2 == null) {
          result = 0;
        } else if (typeof value1 === "string" && typeof value2 === "string") {
          result = value1.localeCompare(value2, cache.language);
        } else {
          result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }

        return event.order * result;
      });
    }
  }

  // https://github.com/primefaces/primeng/blob/fdf00e989f64813af92d551fe873b60bb07dfd93/src/app/components/api/.ts#L42-L55
  filter(value: unknown, filter: unknown, locale: unknown, cache: TableCache): boolean {
    if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    const filterValue = ObjectUtils.removeAccents(filter.toString()).toLocaleLowerCase(locale);
    const stringValue = ObjectUtils.removeAccents(
      (this.getPipeValue(value, cache) ?? castArray(value).join("")).toString()
    ).toLocaleLowerCase(locale);

    return stringValue.indexOf(filterValue) !== -1;
  }

  private getPipeValue(
    value: unknown,
    cache: TableCache,
    pipeDescription?: PipeDescription<any>
  ): string | null | Date | DateWithTime {
    if (isDate(value)) return value;
    if (isDateWithTime(value)) return value.date;

    function getKey(value: unknown): string {
      return Array.isArray(value) ? value.join("") : value?.toString();
    }

    const pipeToUse = (cache.entries ?? []).find((x) => x.pipeDescription === pipeDescription);
    if (!pipeToUse) {
      for (const pipe of cache.entries ?? []) {
        const retValue = pipe.values[getKey(value)];
        if (retValue != null) {
          return retValue;
        }
      }
      return null;
    } else {
      return pipeToUse.values[getKey(value)];
    }
  }
}

function isDateWithTime(value: unknown): value is DateWithTime {
  return (value as DateWithTime)?.__typename === "DateWithTime";
}
