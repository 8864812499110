import { Injectable } from "@angular/core";
import { DefaultSpecific } from "../default/default-specifics";

import { WorkExperiencePrecissionEnum } from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { talentCountryFactory } from "./custom-formly-definition";

@Injectable({ providedIn: "root" })
export class TalentcountrySpecifics extends DefaultSpecific {
  override getCandidateProfileCustomFields(
    requiredFields?: string[],
    _workExperiencePrecission?: WorkExperiencePrecissionEnum,
    _educationPrecission?: WorkExperiencePrecissionEnum
  ): FormlyFieldConfig[] {
    return talentCountryFactory(requiredFields);
  }
}
