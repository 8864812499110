import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { enableProdMode, ErrorHandler, importProvidersFrom, Version } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter, RouteReuseStrategy, withInMemoryScrolling, withRouterConfig } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { ANKAADIA_VERSION, GITSHASHORT } from "@ankaadia/ankaadia-shared";
import {
  ApolloErrorHandler,
  apolloFactory,
  APP_SHA,
  APP_VERSION,
  FRONTEND_ENVIRONMENT_INJECTION_TOKEN,
  uiBlockInterceptor,
  uiLanguageInterceptor,
  versionInterceptor,
} from "@ankaadia/ankaadia-shared-frontend";
import { Ankaadia } from "@ankaadia/ui";
import { provideAuth0 } from "@auth0/auth0-angular";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { FormlyModule } from "@ngx-formly/core";
import { provideApollo } from "apollo-angular";
import "json.date-extensions";
import { provideSpinnerConfig } from "ngx-spinner";
import { ConfirmationService } from "primeng-v17/api";
import { DialogService } from "primeng-v17/dynamicdialog";
import { providePrimeNG } from "primeng/config";
import { formlyConfig } from "./app/formly/formly.config";
import { ApolloErrorHandlerService } from "./app/shared/apollo/apollo-error-handler.service";
import { possibleApolloReturnTypes } from "./app/shared/apollo/apollo-return-types";
import { authInterceptor } from "./app/shared/interceptors/auth.interceptor";
import { updateRecognizerInterceptor } from "./app/shared/interceptors/updateRecognizer.interceptor";
import { AppRouteReuseStrategy } from "./app/shared/services/route-reuse-strategy.service";
import { provideTransloco } from "./app/shared/transloco/transloco.provider";
import { AppComponent } from "./app/structure/app.component";
import { routes } from "./app/structure/app.routes";
import { NewUiAppComponent } from "./app/structure/new-ui-app.component";
import { environment } from "./environments/environment";

(<any>JSON).useDateParser();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(environment.newUI ? NewUiAppComponent : AppComponent, {
  providers: [
    provideAnimationsAsync(),

    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: "enabled" }),
      withRouterConfig({
        onSameUrlNavigation: "reload",
        paramsInheritanceStrategy: "always",
      })
    ),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },

    provideHttpClient(
      withInterceptors([
        uiBlockInterceptor, // Important to be the first
        authInterceptor, // Auth Interceptor emits two requests, but apparently cancelles one directly
        versionInterceptor,
        uiLanguageInterceptor,
        updateRecognizerInterceptor,
      ])
    ),

    ...(environment.newUI
      ? [
          providePrimeNG({
            theme: {
              preset: Ankaadia,
              options: {
                cssLayer: {
                  name: "primeng",
                  order: "theme, base, primeng",
                },
              },
            },
          }),
        ]
      : []),

    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.useServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),

    provideAuth0({
      clientId: environment.auth0_clientId,
      domain: environment.auth0_domain,
      errorPath: "/error",
      skipRedirectCallback: false,
      authorizationParams: {
        audience: environment.auth0_audience,
        redirect_uri: window.location.origin + "/callback",
      },
      httpInterceptor: {
        allowedList: [environment.graphqlURI],
      },
    }),

    provideSpinnerConfig({ type: "ball-scale-multiple" }),

    provideTransloco(),

    provideApollo(apolloFactory(environment, possibleApolloReturnTypes)),
    { provide: ApolloErrorHandler, useClass: ApolloErrorHandlerService },

    importProvidersFrom(FormlyModule.forRoot(formlyConfig)),

    ConfirmationService,
    DialogService,

    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService }, // must be a singleton and therefor placed in the app module

    { provide: FRONTEND_ENVIRONMENT_INJECTION_TOKEN, useValue: environment },
    { provide: APP_VERSION, useValue: new Version(ANKAADIA_VERSION) },
    { provide: APP_SHA, useValue: GITSHASHORT },
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
