import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { flatMapSuperDeep } from "@ankaadia/ankaadia-shared";
import { FieldType, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { compact, isEmpty } from "lodash";
import { PrimeTemplate } from "primeng-v17/api";
import { PanelModule } from "primeng-v17/panel";
import { TabView, TabViewModule } from "primeng-v17/tabview";
import { TooltipModule } from "primeng-v17/tooltip";
import { debounceTime, distinctUntilChanged, map, merge, startWith } from "rxjs";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TabViewRememberTabDirective } from "../../shared/primeng/tab-view-remember-tab/tab-view-remember-tab.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-tabs",
  templateUrl: "./formly-tabs.component.html",
  styleUrl: "./formly-tabs.component.scss",
  imports: [
    TabViewModule,
    TabViewRememberTabDirective,
    NgFor,
    NgIf,
    PrimeTemplate,
    TooltipModule,
    PanelModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
  ],
})
export class FormlyTabsComponent extends FieldType implements OnInit {
  invalidity: boolean[];

  @ViewChild(TabView)
  tabView: TabView;

  constructor(private readonly changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.setupInvalidityChange();
  }

  private setupInvalidityChange(): void {
    merge(this.options.fieldChanges, this.form.statusChanges)
      .pipe(
        startWith(undefined),
        debounceTime(100),
        map(() => this.calculateInvalidity()),
        distinctUntilChanged()
      )
      .subscribe((invalidity) => this.setInvalidity(invalidity));
  }

  private calculateInvalidity(): boolean[] {
    const isInvalid = (field: FormlyFieldConfig): boolean =>
      field.fieldGroup?.length && !field.fieldArray
        ? field.fieldGroup.some((f) => isInvalid(f))
        : field.formControl.invalid && (!!field.formControl.errors || hasNestedErrors(field));
    return this.field.fieldGroup.map((field) => isInvalid(field));
  }

  private setInvalidity(invalidity: boolean[]): void {
    this.invalidity = invalidity;
    this.changeDetector.detectChanges();
    this.tabView?.cd.detectChanges();
  }
}

// errors are not always propagated to the parent field, hence we also check the nested fields for errors
export function hasNestedErrors(field: FormlyFieldConfig): boolean {
  const nestedErrors = flatMapSuperDeep(field?.fieldGroup, (x) => x?.fieldGroup).map((x) => x?.formControl?.errors);
  return !isEmpty(compact(nestedErrors));
}
