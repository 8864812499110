import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Version } from "@angular/core";
import {
  BRANCHNAME,
  ENABLE_FACEBOOK,
  getLanguageConfiguration,
  GITSHASHORT,
  supportedUserInterfaceLanguages4LetterISOForCandidate,
  supportedUserInterfaceLanguages4LetterISOForUser,
} from "@ankaadia/ankaadia-shared";
import { APP_VERSION } from "@ankaadia/ankaadia-shared-frontend";
import { Organization } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { isNull } from "lodash";
import { ConfirmationService, PrimeIcons } from "primeng/api";
import { BehaviorSubject, filter, map, switchMap } from "rxjs";
import { ActivityService } from "../../features/activity/activity.service";
import { MessageService } from "../../features/message/message.service";
import { OrganizationSwitchService } from "../../features/organization-switch/organization-switch.service";
import { UserConsentService } from "../../features/user-consent/user-consent.service";
import { UsersService } from "../../features/users/users.service";
import { MessengerService } from "../../shared/services/messengerService.service";
import { MyAccountService } from "../../shared/services/myAccount.service";
import { PromptUpdateService } from "../../shared/services/promptUpdateService";
import { SettingsService } from "../../shared/services/settings.service";
import { MainComponent } from "../main/main.component";
import { CustomMenuItem } from "../menu/menu.model";
import { MenuService } from "../menu/menu.service";

type MyOrganization = Pick<
  Organization,
  | "id"
  | "name"
  | "code"
  | "creatorOrganizationId"
  | "creatorOrganizationName"
  | "isCreatorMasterOrganization"
  | "isLicensed"
>;

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrl: "./topbar.component.scss",
})
export class TopbarComponent implements OnInit {
  sections: CustomMenuItem[] = [];

  readonly availableLanguages = getLanguageConfiguration(
    this.settings.isCandidate
      ? supportedUserInterfaceLanguages4LetterISOForCandidate()
      : supportedUserInterfaceLanguages4LetterISOForUser()
  );

  readonly selectedLanguage = this.transloco.langChanges$.pipe(
    map((lang) => this.availableLanguages.find((l) => l.code === lang))
  );

  private readonly myOtherOrganizationSub = new BehaviorSubject<string>(null);
  readonly myOtherOrganizations$ = this.myOtherOrganizationSub.pipe(
    switchMap((x) => this.organizationSwitchService.list(x))
  );

  readonly showNotificationCount$ = this.activityService.notificationCount.pipe(map((x) => !isNull(x)));
  readonly notificationCount$ = this.activityService.notificationCount;

  readonly IS_FACEBOOK_LOGIN_ENABLED = ENABLE_FACEBOOK;
  readonly isMasterOrganization = this.settings.isMasterOrganization;
  readonly supportEmail = this.settings.supportEmail;
  readonly facebookUserId = this.settings.facebookUserId;
  readonly supportPhone = this.settings.supportPhone;
  readonly environmentName = this.settings.environmentName;
  readonly showEnvironmentName = this.settings.showEnvironmentName;
  readonly userDisplayName =
    this.settings.userDisplayName + (this.settings.isCandidate ? ` (${this.settings.userDisplayId})` : "");

  readonly userEmail = this.settings.userEmail;
  readonly myOrganization: MyOrganization = {
    id: this.settings.organizationId,
    name: this.settings.organizationName,
    code: this.settings.organizationCode,
    creatorOrganizationId: this.settings.creatorOrganizationId,
    creatorOrganizationName: this.settings.creatorOrganizationName,
    isCreatorMasterOrganization: this.settings.isCreatorMasterOrganization,
    isLicensed: this.settings.isLicensed,
  };

  readonly isCandidate = this.settings.isCandidate;
  readonly switchableOrganizationCount = this.settings.switchableOrganizationCount;

  readonly version =
    this.appVersion.full + (BRANCHNAME.startsWith("production") ? "" : ` (${BRANCHNAME}/${GITSHASHORT})`);

  readonly acceptedTC = this.consentService.userGaveConsent$;

  protected topBarConfirmationKey = "topBarConfirmationKey";

  protected get activeTopbarItem(): any {
    return this.main.activeTopbarItem;
  }

  protected get topbarMenuActive(): boolean {
    return this.main.topbarMenuActive;
  }

  protected get helpUrl(): string {
    return this.menuService.helpUrl;
  }

  constructor(
    private readonly main: MainComponent,
    private readonly settings: SettingsService,
    @Inject(APP_VERSION) private readonly appVersion: Version,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly transloco: TranslocoService,
    private readonly menuService: MenuService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly myAccountService: MyAccountService,
    private readonly consentService: UserConsentService,
    private readonly messengerService: MessengerService,
    private readonly userSvc: UsersService,
    private readonly organizationSwitchService: OrganizationSwitchService,
    private readonly activityService: ActivityService,
    protected readonly promptUpdateService: PromptUpdateService
  ) {}

  ngOnInit(): void {
    if (!this.availableLanguages.some((x) => x.code === this.transloco.getActiveLang())) {
      this.switchLanguage(<string>this.transloco.config.fallbackLang);
    }

    this.settings.isAuthorized$
      .pipe(filter((x) => x))
      .subscribe(() => (this.sections = this.menuService.getSections()));
  }

  switchLanguage(language: string): void {
    this.transloco.setActiveLang(language);
    this.document.defaultView.location.reload();
  }

  loadOrganizations(search: string): void {
    this.myOtherOrganizationSub.next(search);
  }

  switchOrganization(organizationId: string): void {
    this.settings.switchOrganization(organizationId);
  }

  onTopbarMenuButtonClick(event: Event): void {
    this.main.onTopbarMenuButtonClick(event);
  }

  onTopbarItemClick(event: Event, item: any): void {
    this.main.onTopbarItemClick(event, item);
  }

  openFeedback(): void {
    this.main.userFeedback.open();
  }

  openNotifications(): void {
    this.main.notifications.open();
  }

  resetPassword(event: Event): void {
    event.stopPropagation();
    this.confirmationService.confirm({
      target: event.target,
      message: translate("password.confirmReset"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.myAccountService.resetPassword().subscribe(() => {
          this.messageService.add({ severity: "success", summary: translate("password.resetSuccess") });
        });
      },
    });
  }

  toggleMessagesReception(change: { value: boolean; target: EventTarget }): void {
    this.confirmationService.confirm({
      target: change.target,
      message: change.value
        ? translate("messagesReception.confirmationYes")
        : translate("messagesReception.confirmationNo"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        this.userSvc.toggleMessagesReception(this.settings.isCandidate).subscribe(() => {
          this.messageService.add({ severity: "success", summary: translate("messagesReception.resetSuccess") });
        });
      },
      reject: () => this.userSvc.messagesReceptionChanged.next(),
    });
  }

  copyUserEmailToClipboard(): void {
    void navigator.clipboard.writeText(this.userEmail);
    this.messageService.add({ severity: "success", summary: translate("email.clipboard") });
  }

  copyEmailToClipboard(): void {
    void navigator.clipboard.writeText(this.supportEmail);
    this.messageService.add({ severity: "success", summary: translate("email.clipboard") });
  }

  copyPhoneToClipboard(): void {
    void navigator.clipboard.writeText(this.supportPhone);
    this.messageService.add({ severity: "success", summary: translate("phone.clipboard") });
  }

  sendTestMessage(): void {
    this.messengerService
      .sendMessageToCurrentUser(
        "This is a test message for validation purposes. In production messages will only be sent if important events have occured"
      )
      .subscribe();
  }

  loginNativeWithFacebook(): void {
    this.myAccountService.loginWithFacebookNative();
  }

  connectAccountWithFacebook(): void {
    this.confirmationService.confirm({
      message: translate("connectWithFacebook.message"),
      target: null,
      key: this.topBarConfirmationKey,
      header: translate("connectWithFacebook.header"),
      icon: PrimeIcons.FACEBOOK,
      accept: () =>
        this.myAccountService.linkLoggedInAccountWithFacebook().subscribe((x) =>
          this.messageService.add({
            severity: x ? "success" : "error",
            summary: x ? translate("connectWithFacebook.success") : translate("connectWithFacebook.failure"),
          })
        ),
    });
  }

  deleteCandidateData(event: Event): void {
    event.stopPropagation();
    this.confirmationService.confirm({
      target: event.target,
      message: translate("candidateDeletion.confirmOne"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        event.stopPropagation();
        this.myAccountService.deleteCandidateData().subscribe(() => {
          this.messageService.add({ severity: "success", summary: translate("candidateDeletion.succes") });
        });
      },
    });
  }

  disconnectAccountFromFacebook(): void {
    this.confirmationService.confirm({
      message: translate("disconnectFromFacebook.message"),
      target: null,
      key: this.topBarConfirmationKey,
      header: translate("disconnectFromFacebook.header"),
      icon: PrimeIcons.FACEBOOK,
      accept: () =>
        this.myAccountService.unLinkLoggedInAccountWithFacebook().subscribe((x) =>
          this.messageService.add({
            severity: x ? "success" : "error",
            summary: x ? translate("disconnectFromFacebook.success") : translate("disconnectFromFacebook.failure"),
          })
        ),
    });
  }
}
