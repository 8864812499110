import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { RatingModule } from "primeng-v17/rating";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-rating",
  templateUrl: "./formly-rating.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RatingModule, FormsModule, ReactiveFormsModule, FormElementMapModule, TestIdDirective, FormlyModule],
})
export class FormlyRatingComponent extends FieldType {
  override defaultOptions = {
    props: { options: [] },
  };
}
