import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from "@angular/core";
import { isSingleDocumentSet } from "@ankaadia/ankaadia-shared";
import { Document, DocumentFile, DocumentFreeFormatFile, DocumentMode, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, tap } from "rxjs";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { DocumentCriteriaService } from "../document-criteria-config/document-criteria.service";
import { FileType, getFileType } from "./document-preview-dialog.functions";

type PreviewableFreeFormatFile = Omit<DocumentFreeFormatFile, "blob">;
type PreviewableDocumentFile = Omit<DocumentFile, "blob">;
type PreviewableFile = PreviewableDocumentFile | PreviewableFreeFormatFile;

export interface IDialogPreviewData {
  documentFile: DocumentFile;
  url: string;
  fileName?: string;
  language?: string;
  showExternalLinkButton?: boolean;
  renderAsCard?: boolean;
  subheader?: string;
}

@Component({
  selector: "app-document-preview-dialog",
  templateUrl: "./document-preview-dialog.component.html",
  styleUrl: "./document-preview-dialog.component.scss",
})
export class DocumentPreviewDialogComponent implements OnInit, OnChanges {
  private readonly showPreviewSubject = new BehaviorSubject<boolean>(false);

  protected readonly language = this.transloco.getActiveLang();
  protected readonly StaticDataType = StaticDataType;

  // Some file preview components used in here only allow one existing instance at a time.
  // This observable allows us to detect elsewhere whether a preview dialog is open.
  // By this we can shutdown existing previews before opening a new one.
  protected readonly showPreview$ = this.showPreviewSubject.pipe(
    distinctUntilChanged(),
    tap(() => this.changeDetectorRef.detectChanges()),
    debounceTime(100)
  );

  @Input()
  document: Document;

  @Input()
  file: PreviewableFile;

  @Input()
  url: string;

  @Input()
  fileName: string;

  @Input()
  processLanguage?: string;

  @Input()
  showExternalLinkButton = true;

  @Input()
  renderAsCard = true;

  @Output()
  readonly closed = new EventEmitter<void>();

  @Input()
  subheader: string;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly documentCriteriaService: DocumentCriteriaService,
    private readonly httpClient: HttpClient,
    @Optional()
    protected readonly configData: DynamicDialogConfig,
    @Optional()
    protected readonly dialogRef: DynamicDialogRef
  ) {
    if (configData) {
      this.file = (<IDialogPreviewData>configData.data).documentFile;
      this.url = (<IDialogPreviewData>configData.data).url;
      this.fileName = (<IDialogPreviewData>configData.data).fileName;
      this.processLanguage = (<IDialogPreviewData>configData.data).language;
      this.processLanguage = configData.data.processLanguage;
      this.showExternalLinkButton = configData.data.showExternalLinkButton;
      this.renderAsCard = configData.data.renderAsCard;
      this.subheader = configData.data.subheader;
      this.updateSubheader(this.document, this.file, this.processLanguage);
    }
  }

  ngOnInit(): void {
    this.showPreviewSubject.next(true);
  }

  get fileType(): FileType {
    return getFileType(this.file, this.fileName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.document) {
      this.updateSubheader(changes.document.currentValue, this.file, this.processLanguage);
    }
    if (changes.file) {
      this.updateSubheader(this.document, changes.file.currentValue, this.processLanguage);
    }
    if (changes.processLanguage) {
      this.updateSubheader(this.document, this.file, changes.processLanguage.currentValue);
    }
  }

  updateSubheader(document: Document, file: PreviewableFile, processLanguage: string): void {
    if (!document || !file) return;

    if (isFreeFormatFile(file)) {
      this.subheader = `${file.name} (${this.transloco.translate("freeFormatFiles.title")})`;
      return;
    }

    if (document.mode === DocumentMode.Candidate) {
      combineLatest([
        this.staticDataService.getStaticData(StaticDataType.AllowedUploadFileTypes, processLanguage ?? this.language, {
          candidateId: document?.candidateId,
          organizationId: document?.organizationId,
        }),
        this.staticDataService.getStaticData(StaticDataType.DocumentFormats, processLanguage ?? this.language, {
          candidateId: document?.candidateId,
          organizationId: document?.organizationId,
        }),
      ]).subscribe(([types, formats]) => {
        // SK: The tag creation differs from the document-table tag creation.
        if (!isSingleDocumentSet(document.type)) {
          const type = (types ?? []).find((t) => t.value === document.type)?.label;
          const tags = (file.tags ?? []).map((tag) => formats.find((f) => f.value === tag)?.label);
          this.subheader = `${type} (${tags.join(", ")})`;
        } else {
          this.subheader = file.singleSetConfig
            .map((cfg) => {
              const type = (types ?? []).find((t) => t.value === cfg.type)?.label;
              const tags = (cfg.formats ?? []).map((fmt) => (formats ?? []).find((f) => f.value === fmt)?.label);
              return `${type} (${tags.join(", ")})`;
            })
            .join(", ");
        }
      });
    } else {
      this.documentCriteriaService.getFileTags(document, file as DocumentFile).subscribe((tags) => {
        this.subheader = `${document.displayName} (${tags.label})`;
      });
    }
  }

  openFile(): any {
    this.httpClient.get(this.url, { responseType: "blob" }).subscribe((blob) => {
      const _url = window.URL.createObjectURL(blob);
      window.open(_url, "_blank");
    });
  }

  protected closeDialog(): void {
    this.showPreviewSubject.next(false);
    this.dialogRef?.close();
  }
}

function isFreeFormatFile(file: PreviewableFile): file is PreviewableFreeFormatFile {
  return file.__typename === "DocumentFreeFormatFile";
}
