import { Injectable } from "@angular/core";
import { ANKAADIA_VERSION } from "@ankaadia/ankaadia-shared";
import { AuthService } from "@auth0/auth0-angular";
import { TranslocoService } from "@jsverse/transloco";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class WsConnectionParamsProvider {
  static _token: string;

  constructor(
    private readonly authService: AuthService,
    private readonly transloco: TranslocoService
  ) {}

  async getToken(withSchemaPrefix = true): Promise<string> {
    if (!WsConnectionParamsProvider._token) {
      WsConnectionParamsProvider._token = await firstValueFrom(this.authService.getAccessTokenSilently());
    }
    return withSchemaPrefix ? `Bearer ${WsConnectionParamsProvider._token}` : WsConnectionParamsProvider._token;
  }

  getAcceptLanguage(): string {
    return this.transloco.getActiveLang();
  }

  getXAppVersion(): string {
    return ANKAADIA_VERSION;
  }
}
