import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { ProfessionSelectorComponent } from "../../features/profession/profession-selector/profession-selector.component";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-profession-selector",
  templateUrl: "./formly-profession-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ProfessionSelectorComponent, FormsModule, ReactiveFormsModule, FormElementMapModule, TestIdDirective],
})
export class FormlyProfessionSelectorComponent extends FieldType {}
