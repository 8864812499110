import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core/lib/models";
import { Button } from "primeng/button";
import { Calendar } from "primeng/calendar";
import { Checkbox } from "primeng/checkbox";
import { Dropdown } from "primeng/dropdown";
import { InputNumber } from "primeng/inputnumber";
import { MultiSelect } from "primeng/multiselect";
import { TabPanel } from "primeng/tabview";
import { Observable, Subject } from "rxjs";
import { DateTimeComponent } from "../date-time/date-time.component";

export type FormComponent = Dropdown | InputNumber | MultiSelect | Checkbox | Calendar | DateTimeComponent;

@Injectable({ providedIn: "root" })
export class FormElementMapService {
  private static readonly controlMap = new WeakMap<HTMLElement, AbstractControl>();
  private static readonly elementMap = new WeakMap<AbstractControl, HTMLElement>();
  private static readonly componentMap = new WeakMap<AbstractControl, FormComponent>();
  private static readonly addButtonMap = new WeakMap<AbstractControl, Button>();
  private static readonly removeButtonMap = new WeakMap<AbstractControl, (Button | TabPanel)[]>();
  private static readonly fieldConfigMap = new WeakMap<AbstractControl, FormlyFieldConfig>();

  private readonly elementAddedSubject = new Subject<[HTMLElement, AbstractControl]>();
  private readonly componentAddedSubject = new Subject<[FormComponent, AbstractControl]>();

  get elementAdded(): Observable<[HTMLElement, AbstractControl]> {
    return this.elementAddedSubject.asObservable();
  }

  get componentAdded(): Observable<[FormComponent, AbstractControl]> {
    return this.componentAddedSubject.asObservable();
  }

  registerFormControlWithElement(
    element: HTMLElement,
    control: AbstractControl,
    ifAlreadyRegistered: "skip" | "override"
  ): void {
    if (ifAlreadyRegistered === "skip" && FormElementMapService.controlMap.has(element)) {
      return;
    }

    FormElementMapService.controlMap.set(element, control);
    FormElementMapService.elementMap.set(control, element);
    this.elementAddedSubject.next([element, control]);
  }

  getElementByFormControl(control: AbstractControl): HTMLElement {
    return FormElementMapService.elementMap.get(control);
  }

  getFormControlByElement(element: HTMLElement): AbstractControl {
    return FormElementMapService.controlMap.get(element);
  }

  registerComponent(component: FormComponent, control: AbstractControl): void {
    FormElementMapService.componentMap.set(control, component);
    this.componentAddedSubject.next([component, control]);
  }

  registerFormArrayAddButton(element: Button, control: AbstractControl): void {
    FormElementMapService.addButtonMap.set(control, element);
  }

  registerFormArrayRemoveButton(element: Button | TabPanel, control: AbstractControl): void {
    const buttons = FormElementMapService.removeButtonMap.get(control) || [];
    buttons.push(element);
    FormElementMapService.removeButtonMap.set(control, buttons);
  }

  registerFormlyFieldArrayConfiguration(control: AbstractControl, config: FormlyFieldConfig): void {
    if (control && config) {
      FormElementMapService.fieldConfigMap.set(control, config);
    }
  }

  getComponent(control: AbstractControl): FormComponent {
    return FormElementMapService.componentMap.get(control);
  }

  getAddButton(control: AbstractControl): Button {
    return FormElementMapService.addButtonMap.get(control);
  }

  getRemoveButtons(control: AbstractControl): (Button | TabPanel)[] {
    return FormElementMapService.removeButtonMap.get(control) ?? [];
  }

  getFieldConfig(control: AbstractControl): FormlyFieldConfig {
    return FormElementMapService.fieldConfigMap.get(control);
  }
}
