import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { isObservable, map, Observable, of } from "rxjs";

@Pipe({ name: "groupItems" })
export class GroupItemsPipe implements PipeTransform {
  constructor(private readonly transloco: TranslocoService) {}

  transform(items: any[] | Observable<any[]>, staticDataType: string): Observable<any[]> {
    if (!items || !staticDataType) {
      return of([]);
    }
    const groupItems = (items: any[]): any => {
      const groupedItems = items.reduce((acc, item) => {
        const groupValue = item["group"];
        if (!acc[groupValue]) {
          acc[groupValue] = [];
        }
        acc[groupValue].push(item);
        return acc;
      }, {});

      const result = Object.keys(groupedItems).map((group) => ({
        label: this.transloco.translate(`staticDataGroups.${staticDataType}.${group}`),
        value: group,
        group: groupedItems[group],
      }));
      return result;
    };

    if (isObservable(items)) {
      return items.pipe(map(groupItems));
    } else {
      return of(groupItems(items));
    }
  }
}
