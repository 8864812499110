import { Injectable } from "@angular/core";
import {
  CreateProfessionalExperienceGQL,
  DeleteProfessionalExperienceGQL,
  EditProfessionalExperienceGQL,
  GetProfessionalExperiencesByTypeGQL,
  ListProfessionalExperiencesGQL,
  ProfessionalExperience,
  ProfessionalExperienceCreateInput,
  ProfessionalExperienceDeleteInput,
  ProfessionalExperienceForEditFragment,
  ProfessionalExperienceForFilterFragment,
  ProfessionalExperienceForListFragment,
  ProfessionalExperienceForListFragmentDoc,
  ProfessionalExperienceGetByTypeInput,
  ProfessionalExperienceUpdateInput,
  UpdateProfessionalExperienceGQL,
} from "@ankaadia/graphql";
import { omit, pick } from "lodash";
import { map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProfessionalExperienceService {
  constructor(
    private readonly experienceList: ListProfessionalExperiencesGQL,
    private readonly experienceListByType: GetProfessionalExperiencesByTypeGQL,
    private readonly experienceEdit: EditProfessionalExperienceGQL,
    private readonly experienceCreate: CreateProfessionalExperienceGQL,
    private readonly experienceUpdate: UpdateProfessionalExperienceGQL,
    private readonly experienceDelete: DeleteProfessionalExperienceGQL
  ) {}

  list(professionalFieldId: string, organizationId: string): Observable<ProfessionalExperienceForListFragment[]> {
    return this.experienceList
      .watch({ input: { professionalFieldId, organizationId } })
      .valueChanges.pipe(map((x) => x.data.getProfessionalExperiences));
  }

  getByType(input: ProfessionalExperienceGetByTypeInput): Observable<ProfessionalExperienceForFilterFragment[]> {
    return this.experienceListByType.fetch({ input }).pipe(map((x) => x.data.getProfessionalExperiencesByType));
  }

  edit(id: string, organizationId: string): Observable<ProfessionalExperienceForEditFragment> {
    return this.experienceEdit
      .fetch({ input: { id, organizationId } })
      .pipe(map((x) => x.data.getProfessionalExperience));
  }

  create(experience: ProfessionalExperienceCreateInput): Observable<ProfessionalExperienceForListFragment> {
    return this.experienceCreate
      .mutate(
        { input: omit(experience, "id", "_etag", "changedAt", "changedBy") },
        {
          update: (cache, x) =>
            cache.modify({
              fields: {
                getProfessionalExperiences: (refs) => {
                  const ref = cache.writeFragment({
                    data: x.data.createProfessionalExperience,
                    fragment: ProfessionalExperienceForListFragmentDoc,
                  });
                  return [...(refs ?? []), ref];
                },
              },
            }),
        }
      )
      .pipe(map((x) => x.data.createProfessionalExperience));
  }

  update(experience: ProfessionalExperienceUpdateInput): Observable<ProfessionalExperienceForListFragment> {
    return this.experienceUpdate
      .mutate({ input: omit(experience, "changedAt", "changedBy") })
      .pipe(map((x) => x.data.updateProfessionalExperience));
  }

  delete(experience: ProfessionalExperienceDeleteInput): Observable<void> {
    return this.experienceDelete
      .mutate(
        { input: pick(experience, "id", "_etag", "organizationId") },
        {
          update: (cache) => {
            const typeName: ProfessionalExperience["__typename"] = "ProfessionalExperience";
            const id = cache.identify({ id: experience.id, __typename: typeName });
            cache.evict({ id: id });
            cache.gc();
          },
        }
      )
      .pipe(map((x) => x.data.deleteProfessionalExperience));
  }
}
