import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { CandidateTabConfiguration } from "@ankaadia/ankaadia-shared";
import { Candidate, DocumentMode } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FormlyFieldConfig, FormlyForm } from "@ngx-formly/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ProfileForm } from "../../features/candidates/candidate-form/candidate-form.model";
import { CandidateProfileComponent } from "../../features/candidates/candidate-profile-component.model";

@Component({
  selector: "app-base-candidate-profile",
  templateUrl: "./base-profile.component.html",
})
export class BaseProfileComponent implements CandidateProfileComponent, AfterViewInit {
  private _lastOrganizationId: string;
  private _readonly = true;
  private _isLoading = false;
  private _candidate: Candidate;
  private _tabs: CandidateTabConfiguration;
  private _profession: BehaviorSubject<string>;
  private _immigrationCountry: BehaviorSubject<string>;

  readonly DocumentMode = DocumentMode;
  readonly language = this.transloco.getActiveLang();
  readonly updateFields$ = new Subject<string>();

  form: ProfileForm;

  set readonly(readonly: boolean) {
    this._readonly = readonly;

    if (this.formly) {
      this.formly.options.formState.readonly = readonly;
      this.detectOptionChanges();
    }
  }

  get readonly(): boolean {
    return this._readonly;
  }

  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;

    if (this.formly) {
      this.formly.options.formState.isLoading = isLoading;
      this.detectOptionChanges();
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get profession(): BehaviorSubject<string> {
    return this._profession;
  }

  set profession(profession: BehaviorSubject<string>) {
    this._profession = profession;

    if (this.formly) {
      this.formly.options.formState.profession = profession;
      this.detectOptionChanges();
    }
  }

  get immigrationCountry(): BehaviorSubject<string> {
    return this._immigrationCountry;
  }

  set immigrationCountry(immigrationCountry: BehaviorSubject<string>) {
    this._immigrationCountry = immigrationCountry;

    if (this.formly) {
      this.formly.options.formState.immigrationCountry = immigrationCountry;
      this.detectOptionChanges();
    }
  }

  set candidate(candidate: Candidate) {
    this._candidate = candidate;

    if (this.formly) {
      this.formly.options.formState.candidateId = candidate.id;
      this.formly.options.formState.organizationId = candidate.organizationId;
      this.detectOptionChanges();
    }

    if (candidate && this._lastOrganizationId !== candidate.organizationId) {
      this._lastOrganizationId = candidate.organizationId;
    }
    this.updateFields(candidate.organizationId);
  }

  get candidate(): Candidate {
    return this._candidate;
  }

  set tabs(tabs: CandidateTabConfiguration) {
    this._tabs = tabs;

    if (this.formly) {
      this.formly.options.formState.sharedTabs = tabs;
      this.detectOptionChanges();
    }
  }

  get tabs(): CandidateTabConfiguration {
    return this._tabs;
  }

  fields: FormlyFieldConfig[];

  // normal update of the formState does not work in the immutable mode, which is enabled globally
  @ViewChild(FormlyForm)
  formly: FormlyForm;

  constructor(
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
    this.formly.options.formState.readonly = this.readonly;
    this.formly.options.formState.candidateId = this.candidate?.id;
    this.formly.options.formState.organizationId = this.candidate?.organizationId;
    this.formly.options.formState.sharedTabs = this.tabs;
    this.formly.options.formState.profession = this.profession;
    this.formly.options.formState.immigrationCountry = this.immigrationCountry;
    this.detectOptionChanges();
  }

  private updateFields(organizationId: string): void {
    this.updateFields$.next(organizationId);
  }

  // changes in the options are not always automatically detected by the formly form
  // so we need to manually trigger the change detection for the options here
  private detectOptionChanges(): void {
    this.formly.options.detectChanges(this.formly.field);
  }
}
