@if (routerLink()) {
  <a class="ui-navitem-link" [routerLink]="routerLink()">
    <ng-container *ngTemplateOutlet="contents"></ng-container>
  </a>
}

@if (url()) {
  <a class="ui-navitem-link" [attr.href]="url()" target="_blank">
    <ng-container *ngTemplateOutlet="contents"></ng-container>
  </a>
}

<ng-template #contents>
  @if (icon()) {
    <i class="{{ icon() }} ui-navitem-icon"></i>
  }

  @if (label()) {
    <span class="ui-navitem-label" [attr.data-label]="label()">{{ label() }}</span>
  }
</ng-template>
