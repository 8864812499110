import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Language, mapIso2CodeToIso2CodeLanguage } from "@ankaadia/ankaadia-shared";
import { translate } from "@jsverse/transloco";
import { PrimeTemplate } from "primeng-v17/api";
import { DropdownModule } from "primeng-v17/dropdown";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../../shared/services/settings.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { MessageService } from "../../message/message.service";
import { UsersService } from "../users.service";

@Component({
  selector: "app-communication-language",
  templateUrl: "./communication-language.component.html",
  imports: [
    TranslateDirective,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    PrimeTemplate,
    NgIf,
    AsyncPipe,
  ],
})
export class CommunicationLanguageComponent implements OnInit {
  protected preferredCommunicationLanguage?: Language;

  readonly availableLanguages$ = this.userSvc.getAvailableCommunicationLanguages(this.settings.isCandidate);

  @Input()
  label?: string;

  constructor(
    private readonly userSvc: UsersService,
    private readonly settings: SettingsService,
    private readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    if (this.settings.communicationLanguage)
      this.preferredCommunicationLanguage = mapIso2CodeToIso2CodeLanguage(this.settings.communicationLanguage);
  }

  onClick(event: Event): void {
    event.stopPropagation();
  }

  setCommunicationLanguage(): void {
    this.userSvc.setCommunicationLanguage(this.preferredCommunicationLanguage.code).subscribe(() => {
      this.messageService.add({ severity: "success", summary: translate("userLanguage.changedSuccess") });
    });
  }
}
