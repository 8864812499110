import { Injectable } from "@angular/core";
import {
  CustomSkillSet,
  FindOccupationSkillsGQL,
  GetEscoSkillsByProfessionGQL,
  GetProfessionAreasGQL,
  GetProfessionsGQL,
  GetProfessionSkillsGQL,
  GetProfessionSubAreasGQL,
  GetProfessionTreeGQL,
  OccupationSkillForFilterFragment,
  OccupationSkillSet,
  OccupationSkillSetFragment,
  OccupationSkillType,
  SearchOccupationSkillsGQL,
  StaticDataModel,
  TreeDataModel,
} from "@ankaadia/graphql";
import { FetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { map, Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProfessionService {
  constructor(
    private readonly areasGet: GetProfessionAreasGQL,
    private readonly subAreasGet: GetProfessionSubAreasGQL,
    private readonly professionsGet: GetProfessionsGQL,
    private readonly treeGet: GetProfessionTreeGQL,
    private readonly skillsGet: GetProfessionSkillsGQL,
    private readonly searchOccupationSkills: SearchOccupationSkillsGQL,
    private readonly findOccupationSkillsByIds: FindOccupationSkillsGQL,
    private readonly skillsByProfession: GetEscoSkillsByProfessionGQL
  ) {}

  getAreas(language: string, organizationId: string, all?: boolean): Observable<StaticDataModel[]> {
    return this.areasGet
      .fetch({ input: { language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionAreas));
  }

  getSubAreas(area: string, language: string, organizationId: string, all?: boolean): Observable<StaticDataModel[]> {
    if (!area) {
      return of(null);
    }
    return this.subAreasGet
      .fetch({ input: { area, language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionSubAreas));
  }

  getProfessions(
    area: string,
    subArea: string,
    language: string,
    organizationId: string,
    all?: boolean
  ): Observable<StaticDataModel[]> {
    return this.professionsGet
      .fetch(
        { input: { area, subArea, language, organizationId: organizationId, all } },
        { fetchPolicy: "cache-first" }
      )
      .pipe(map((x) => x.data.getProfessions));
  }

  getProfessionTree(language: string, organizationId: string, all?: boolean): Observable<TreeDataModel[]> {
    return this.treeGet
      .fetch({ input: { language, organizationId, all } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionTree));
  }

  getProfessionSkills(
    professionId: string,
    organizationId: string,
    language: string,
    fetchPolicy: FetchPolicy = "network-only"
  ): Observable<readonly [OccupationSkillSet[], CustomSkillSet[]]> {
    if (!professionId || !organizationId) return of([[], []]);
    return this.skillsGet
      .fetch({ professionId, organizationId, language }, { fetchPolicy: fetchPolicy })
      .pipe(map((x) => [x.data.standard, x.data.custom] as const));
  }

  searchSkills(
    query: string,
    skillType: OccupationSkillType,
    language: string
  ): Observable<OccupationSkillForFilterFragment[]> {
    return this.searchOccupationSkills
      .fetch({ input: { query, skillType, language } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.searchOccupationSkills.skills));
  }

  findOccupationSkills(
    skillIds: string[],
    skillType: OccupationSkillType,
    language: string
  ): Observable<OccupationSkillForFilterFragment[]> {
    return this.findOccupationSkillsByIds
      .fetch({ input: { skillIds, skillType, language } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.findOccupationSkills.skills));
  }

  getEscoSkillsByProfession(profession: string, language: string): Observable<OccupationSkillSetFragment[]> {
    return this.skillsByProfession
      .fetch({ input: { professionId: profession, language: language } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getEscoSkillsByProfession));
  }
}
