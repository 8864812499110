import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { NewUiNavigationComponent } from "../navigation/new-ui-navigation.component";

@Component({
  selector: "new-ui-main",
  templateUrl: "./new-ui-main.component.html",
  styleUrl: "./new-ui-main.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NewUiNavigationComponent, RouterOutlet],
})
export class NewUiMainComponent {
  protected navigationExpanded = false;
}
