import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Button } from "primeng-v17/button";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { UnsubscribeService } from "./unsubscribe.service";
@Component({
  selector: "app-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrl: "./unsubscribe.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, RouterLink, Button, NgIf],
})
export class UnsubscribeComponent {
  showSuccess = false;

  constructor(private readonly service: UnsubscribeService) {}

  onUnsubscribe(): void {
    this.showSuccess = true;
    this.service.resolveUnsubscribe().subscribe(() => ({}));
  }
}
