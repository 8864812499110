import { Injectable } from "@angular/core";
import {
  GetProfessionConfigurationGQL,
  GetProfessionDefaultsGQL,
  ProfessionConfiguration,
  ProfessionConfigurationSetInput,
  ProfessionDefaultsFragment,
  SetProfessionConfigurationGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Injectable({ providedIn: "root" })
export class ProfessionConfigurationService {
  constructor(
    private readonly settings: SettingsService,
    private readonly configGet: GetProfessionConfigurationGQL,
    private readonly defaultsGet: GetProfessionDefaultsGQL,
    private readonly configSet: SetProfessionConfigurationGQL
  ) {}

  get(): Observable<ProfessionConfiguration> {
    return this.configGet
      .fetch({ input: { organizationId: this.settings.organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionConfiguration));
  }

  getDefaults(organizationId: string): Observable<ProfessionDefaultsFragment> {
    return this.defaultsGet
      .fetch({ input: { organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((x) => x.data.getProfessionDefaults));
  }

  set(config: ProfessionConfigurationSetInput): Observable<ProfessionConfiguration> {
    return this.configSet
      .mutate(
        { input: config },
        {
          update: (cache) =>
            cache.modify({
              fields: {
                getProfessionAreas: (_, helpers) => helpers.DELETE,
                getProfessionSubAreas: (_, helpers) => helpers.DELETE,
                getProfessions: (_, helpers) => helpers.DELETE,
                getProfessionTree: (_, helpers) => helpers.DELETE,
                getProfessionDefaults: (_, helpers) => helpers.DELETE,
              },
            }),
        }
      )
      .pipe(map((x) => x.data.setProfessionConfiguration));
  }
}
