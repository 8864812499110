<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <div
    class="dropzone"
    (dropzone)="openDialog($event)"
    [ignoredOverlayElementTypes]="ignoredOverlayElementTypes"
    [(active)]="active"
    [(resize)]="dropzoneSize"
  >
    <ng-content />
    <div
      *ngIf="!disabled"
      class="dropzone-overlay"
      [ngClass]="{
        'dropzone-overlay-hidden': hidden || (!active && visibility === 'hover'),
        'dropzone-overlay-active': active,
        'dropzone-overlay-compact': visibility === 'compact',
      }"
    >
      <input #file type="file" multiple="true" [style.display]="'none'" (change)="onFilesSelected($event)" />
      <div [(resize)]="dropzoneContentSize" class="dropzone-content" (click)="file.click()" [style.cursor]="'pointer'">
        <i class="dropzone-icon pi pi-upload"></i>
        <div>{{ t("file.drop") }}</div>
      </div>
    </div>
  </div>
  <p-dialog
    [(visible)]="showDialog"
    [maximizable]="true"
    [closeOnEscape]="true"
    [appendTo]="dialogProxy"
    [style]="{ height: '85vh', width: '85vw' }"
    [header]="t('file.wizard')"
  >
    <ng-template pTemplate="header">
      <div class="flex gap-2 pr-2 w-full align-items-center">
        <div class="flex-1 text-xl">{{ t("file.wizard") }}</div>
        <p-button
          icon="pi pi-image"
          styleClass="p-button-text toggle-button"
          [label]="t('common.preview')"
          [ngClass]="{ 'button-active': !('ai-preview-hidden' | observe | async) }"
          (click)="localStorage.flip('ai-preview-hidden')"
        ></p-button>
        <p-button
          icon="pi pi-file-edit"
          styleClass="p-button-text toggle-button"
          [label]="t('common.details')"
          [ngClass]="{ 'button-active': !('ai-doc-edit-hidden' | observe | async) }"
          (click)="localStorage.flip('ai-doc-edit-hidden')"
        ></p-button>
      </div>
    </ng-template>
    <app-document-dropzone-table
      *ngIf="showDialog && form && store && candidateOrCollection"
      [form]="form"
      [store]="store"
      [processLanguage]="processLanguage"
      [candidateOrCollection]="candidateOrCollection"
      [allowedUploadFileTypes]="allowedUploadFileTypes"
      [showFamilyMemberSelection]="showFamilyMemberSelection"
      [ignoredOverlayElementTypes]="ignoredOverlayElementTypes"
    />
    <ng-template pTemplate="footer">
      <div class="progress-bar">
        <div class="progress-indicator" [style.width.%]="aiProgress * 100"></div>
      </div>
      <div class="dropzone-footer">
        <div class="dropzone-footer-left">
          {{ form?.controls?.rows?.length ?? 0 }} {{ t("documents.files") }}
          <i class="pi pi-exclamation-circle" [pTooltip]="t('documents.limit', { limit })"></i>
        </div>
        <div *ngIf="isNil(digitalDocumentRequirements)" class="field-checkbox" [style.margin]="0">
          <p-checkbox [binary]="true" [(ngModel)]="showFamilyMemberSelection" />
          <label>{{ t("family.showMembers") }}</label>
        </div>
        <div>
          <p-button
            [disabled]="!form.valid || submitDisabled || (savingIsBlocked | async)"
            [label]="t('common.save')"
            icon="pi pi-check"
            (click)="submit()"
          />
          <p-button
            closeOnEscape
            [label]="t('common.cancel')"
            icon="pi pi-times"
            class="p-button-secondary"
            (click)="closeDialog()"
          />
        </div>
      </div>
    </ng-template>
  </p-dialog>
</ng-container>
<div #dialogProxy></div>
