import { ChangeDetectionStrategy, Component } from "@angular/core";
import { KnownConfigTokens } from "@ankaadia/ankaadia-shared";
import { MessageService } from "../../../features/message/message.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { GuetegemeinschaftService } from "../guetegemeinschaft.service";

@Component({
  selector: "app-guetegemeinschaft-recertification-link",
  templateUrl: "./recertificationLink.component.html",
  styleUrl: "./recertificationLink.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReCertificationLinkComponent {
  readonly showButton = this.settingsService.configToken == KnownConfigTokens.GUETEGEMEINSCHAFT;

  constructor(
    private readonly gueteService: GuetegemeinschaftService,
    protected readonly settingsService: SettingsService,
    protected readonly messageService: MessageService
  ) {}

  copyToClipboard(): void {
    this.gueteService.getReCertificationLink().subscribe((link) => {
      void navigator.clipboard.writeText(link);
      this.messageService.add({
        severity: "success",
        summary: "Link kopiert",
        detail: "Der Link wurde in die Zwischenablage kopiert",
      });
    });
  }
}
