import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AnabinAssetType, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { FieldType } from "@ngx-formly/core";
import { BehaviorSubject, Observable, of, switchMap, take } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { AnabinDetails } from "../../anabin/anabin.service";

@Component({
  selector: "app-autocomplete",
  templateUrl: "./formly-enhanced-dropdown.component.html",
  styleUrl: "./formly-enhanced-dropdown.component.scss",
})
export class FormlyEnhancedDropdownComponent extends FieldType implements OnInit {
  constructor(
    private readonly settings: SettingsService,
    private readonly chRef: ChangeDetectorRef
  ) {
    super();
  }

  readonly tableConfig = {
    AnabinDegreesForInfoView: {
      ratings: {
        fields: ["equivalence", "degree", "comment"],
        labels: ["anabin.degree.ratings.equivalent", "anabin.degree.ratings.DEdegreeType", "comment.title"],
      },
      institutions: {
        fields: ["name", "type", "city", "country"],
        labels: [
          "anabin.degree.institution.name",
          "anabin.degree.institution.type",
          "anabin.degree.institution.city",
          "country.title",
        ],
      },
    },
    AnabinInstitutionInfoViewDetails: {
      alternativeNames: {
        fields: ["name", "language"],
        labels: ["name.title", "language.title"],
      },
      degrees: {
        fields: ["name", "type", "class", "country"],
        labels: ["anabin.degree.name", "anabin.degree.type", "anabin.degree.class", "country.title"],
      },
    },
  };

  protected readonly AnabinAssetType = AnabinAssetType;
  protected readonly StaticDataType = StaticDataType;

  selectedSuggestionDetail: AnabinDetails;

  displayInfoButton = false;

  showSidebar$ = new BehaviorSubject<boolean>(false);
  details$ = this.showSidebar$.pipe(
    switchMap((show) =>
      show ? (this.props.getDetails(this.formControl.value, this.props) as Observable<any>) : of(null)
    )
  );

  suggestions: StaticDataModel[] = [];

  get isCandidate(): boolean {
    return this.settings.isCandidate;
  }

  ngOnInit(): void {
    this.formControl.valueChanges.subscribe((value) => {
      this.displayInfoButton = !!(!isNaN(value) && value);
    });

    if (!isNaN(this.formControl.value) && this.formControl.value) {
      this.displayInfoButton = true;
      const suggestion$ = this.props.firstSuggestion(this.formControl.value, this.props) as Observable<
        StaticDataModel[]
      >;
      suggestion$.pipe(take(1)).subscribe((suggestion) => {
        this.suggestions = suggestion;
        this.chRef.detectChanges();
      });
    }

    if (isNaN(this.formControl.value) && this.formControl.value) {
      this.getSuggestions(this.formControl.value);
    }

    this.details$.subscribe((degree) => {
      this.selectedSuggestionDetail = degree;
      this.chRef.detectChanges();
    });
  }

  getSuggestions(query: string): void {
    const suggestions$ = this.props.getSuggestions(query, this.props) as Observable<StaticDataModel[]>;
    suggestions$.subscribe((suggestions) => {
      this.suggestions = suggestions;
      this.chRef.detectChanges();
    });
  }

  openDetails(): void {
    this.showSidebar$.next(true);
  }

  getSelectedSuggestion(suggestions: StaticDataModel[]): any {
    return suggestions?.find((x) => x.value === this.formControl.value)?.label ?? this.formControl.value;
  }

  updateForm($event: any): void {
    this.formControl.setValue($event.value ?? $event);
  }
}
