import { Pipe, PipeTransform } from "@angular/core";
import { map, Observable, switchMap } from "rxjs";
import { MyOrganizationService } from "../../features/organizations/my-organization/my-organization.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { SettingsService } from "../services/settings.service";

@Pipe({ name: "organizationName" })
export class OrganizationNamePipe implements PipeTransform {
  constructor(
    private readonly settings: SettingsService,
    private readonly organizationService: OrganizationsService,
    private readonly myOrganizationService: MyOrganizationService
  ) {}

  transform(organizationId: string): Observable<string> {
    if (this.settings.organizationId === organizationId) {
      return this.myOrganizationService.changed.pipe(
        switchMap(() => this.organizationService.getOrganization(organizationId)),
        map((x) => x.name)
      );
    } else {
      return this.organizationService.getOrganization(organizationId).pipe(map((x) => x.name));
    }
  }
}
