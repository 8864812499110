import { ChangeDetectorRef, Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  DefaultDocumentSetName,
  DocumentSetType,
  DocumentSetTypeNames,
  firstOrDefault,
  hasOne,
  isSingleDocumentSet,
  nameofFactory,
  single,
  singleOrDefault,
} from "@ankaadia/ankaadia-shared";
import {
  CandidateFamilyMember,
  CandidateId,
  DocumentAiExtractionResult,
  DocumentAiUrl,
  DocumentMode,
  StaticDataModel,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone, isEmpty, isNil, keys, uniq } from "lodash";
import { ConfirmationService, PrimeIcons, SortEvent } from "primeng/api";
import {
  BehaviorSubject,
  catchError,
  concat,
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  takeWhile,
  tap,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { mapToVoid, VOID } from "../../shared/functions/rxjs-extra";
import { FileUploadService } from "../../shared/services/file-upload.service";
import { valuesOf } from "../../shared/services/form.helper";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataContext, StaticDataService } from "../../shared/static-data/static-data.service";
import { DocumentAiService } from "../documents/document-ai/document-ai.service";
import { DocumentFileTagsControl } from "../documents/document-form.model";
import { getFileType } from "../documents/document-preview-dialog/document-preview-dialog.functions";
import {
  CandidateEntry,
  DocumentDropZoneForm,
  DocumentDropZoneRow,
  DocumentDropZoneRowForm,
  SetMetadata,
} from "./document-dropzone-form.model";
import { DocumentDropzoneOptionsStore } from "./document-dropzone-options-store.service";
import {
  areFilesEqual,
  areSetsEqual,
  isCandidateRecommended,
  isDocumentTypeRecommended,
  isFamilyMemberRecommended,
} from "./document-dropzone.functions";
import { CollectionInformation } from "./document-dropzone.model";
import { OverlayDetectorService, OverlayElementType } from "./overlay-detector.service";
import { SelectedStaticDataItems } from "./static-data-multi-selector/static-data-multi-selector.component";
import { SelectedStaticDataItem } from "./static-data-selector/static-data-selector.component";

const nameOf = nameofFactory<DocumentDropZoneTableComponent>();

interface RowState {
  exists: boolean;
  valid: boolean;
}

interface DropZoneRowExtraction {
  row: DocumentDropZoneRowForm;
  extraction: DocumentAiExtractionResult;
}

interface DropZoneRowPrefillRequest {
  row: DocumentDropZoneRowForm;
  candidateId?: CandidateId;
  familyMemberId?: string;
  documentType?: string;
}

@Component({
  selector: "app-document-dropzone-table",
  templateUrl: "./document-dropzone-table.component.html",
  styleUrl: "./document-dropzone-table.component.scss",
  providers: [FileUploadService],
})
export class DocumentDropZoneTableComponent implements OnInit, OnChanges {
  private readonly language = this.transloco.getActiveLang();
  private readonly context: StaticDataContext = this.settings.supportedImmigrationCountries.map((country) => ({
    immigrationCountry: country as SupportedImmigrationCountry,
    organizationId: this.settings.organizationId,
  }));

  protected readonly DocumentMode = DocumentMode;
  protected readonly isCandidateRecommended = isCandidateRecommended;
  protected readonly isFamilyMemberRecommended = isFamilyMemberRecommended;
  protected readonly isDocumentTypeRecommended = isDocumentTypeRecommended;
  protected readonly isEmpty = isEmpty;
  protected readonly clone = clone;
  protected readonly keys = keys;

  protected readonly documentSets: StaticDataModel[] = [
    { value: DefaultDocumentSetName, label: translate(`documentSet.default`) },
    ...DocumentSetTypeNames.map((type) => ({
      value: type,
      label: translate(`documentSet.types.${type}`),
    })),
  ];

  protected hasOverlay$: Observable<boolean> = this.overlayDetector.hasOverlay();
  protected searchInput$ = new BehaviorSubject<string>(null);
  protected filteredRows: DocumentDropZoneRowForm[] = [];
  protected selectedRow: DocumentDropZoneRowForm = null;
  protected previewableRow: DocumentDropZoneRow | null = null;
  protected filePreviewSubscription?: Subscription;
  protected documentTypes: StaticDataModel[] = [];
  protected documentFormats: StaticDataModel[] = [];

  @Input()
  ignoredOverlayElementTypes: OverlayElementType[] = [];

  @Input()
  processLanguage?: string = this.language;

  @Input({ required: true })
  form: DocumentDropZoneForm;

  @Input({ required: true })
  store: DocumentDropzoneOptionsStore;

  @Input({ required: true })
  candidateOrCollection: CandidateEntry | CollectionInformation;

  @Input({ required: true })
  allowedUploadFileTypes: Record<string, string>;

  @Input({ required: true })
  showFamilyMemberSelection: boolean;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly settings: SettingsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly transloco: TranslocoService,
    private readonly documentAiService: DocumentAiService,
    private readonly fileUploadService: FileUploadService,
    protected readonly overlayDetector: OverlayDetectorService,
    protected readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.updateLanguageDependentStuff();
    this.searchInput$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(200),
        map((searchInput) => this.filterDocumentForms(searchInput))
      )
      .subscribe((forms) => (this.filteredRows = forms));

    const rows = this.form.controls.rows.controls;
    const uploads$ = rows.map((row) => this.uploadFile(row).pipe(map((url) => ({ row, url }))));
    const rowSelections$ = concat(...uploads$).pipe(
      mergeMap(({ row, url }) => this.fetchRowExtraction(row, url)),
      tap(({ row, extraction }) => row.controls.extraction.setValue(extraction)),
      filter(({ extraction }) => extraction.done && extraction.success),
      map(({ row, extraction }) => ({ row, ...extraction.data }))
    );

    this.prefillRows(rowSelections$);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("form")] && this.form) {
      this.filteredRows = this.filterDocumentForms(this.searchInput$.value);
      this.selectedRow = null;
    }

    if (changes[nameOf("store")] || changes[nameOf("candidateOrCollection")]) {
      this.store.candidates$.subscribe((candidates) => {
        if (hasOne(candidates)) {
          const candidate = single(candidates);
          const candidateId = { id: candidate.id, orgId: candidate.organizationId };
          this.prefillRows(from(this.form.controls.rows.controls.map((row) => ({ row, candidateId }))));
        }
      });
    }

    if (changes[nameOf("allowedUploadFileTypes")]) {
      this.documentTypes = keys(this.allowedUploadFileTypes).map((key) => ({
        value: key,
        label: this.allowedUploadFileTypes[key],
      }));
    }

    if (changes[nameOf("processLanguage")]) {
      this.updateLanguageDependentStuff();
    }

    if (changes[nameOf("showFamilyMemberSelection")]) {
      this.updateFamilyMemberDependentData();
    }

    if (changes[nameOf("ignoredOverlayElementTypes")]) {
      this.hasOverlay$ = this.overlayDetector.hasOverlay(...this.ignoredOverlayElementTypes);
    }
  }

  private updateLanguageDependentStuff(): void {
    this.staticDataService
      .getStaticData(StaticDataType.DocumentFormats, this.processLanguage, this.context)
      .subscribe((documentFormats) => (this.documentFormats = documentFormats));
  }

  protected removeRow(row: DocumentDropZoneRowForm, rowState: RowState, event: Event): void {
    if (rowState.valid) {
      return;
    }

    this.confirmationService.confirm({
      target: event.target,
      message: translate("file.confirmDelete"),
      icon: PrimeIcons.EXCLAMATION_TRIANGLE,
      accept: () => {
        if (this.selectedRow === row) {
          this.selectedRow = null;
          this.previewableRow = null;
        }

        this.runOutsideChangeDetection(() => {
          this.form.controls.rows.removeAt(this.form.controls.rows.controls.indexOf(row));
          return this.store.fixupForm(this.form);
        });
      },
    });
  }

  protected rowState(row: DocumentDropZoneRowForm): Observable<RowState> {
    const { candidateEntry, documentType, documentSet } = row.getRawValue();
    return !candidateEntry || !documentType || !documentSet
      ? of({ exists: false, valid: false })
      : this.store.getDocumentMetadata(row).pipe(
          map(({ documentForm, experiencedErrors }) => {
            return documentForm
              ? { exists: true, valid: documentForm.valid && !experiencedErrors }
              : { exists: false, valid: false };
          })
        );
  }

  protected getFamilyMemberDisplayName(member: CandidateFamilyMember): string | null {
    return member ? `${member.firstName} ${member.lastName}` : null;
  }

  protected setSelectedRow(rowData: DocumentDropZoneRowForm): void {
    if (!isNil(rowData) && this.selectedRow !== rowData) {
      this.selectedRow = null;
      this.changeDetectorRef.detectChanges();
      this.selectedRow = rowData;

      let previous: SetMetadata = null;
      this.filePreviewSubscription?.unsubscribe();
      this.filePreviewSubscription = valuesOf(this.selectedRow.controls.documentSet)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((current) => {
          if (isEmpty(previous) || (isNil(previous) && !isNil(current)) || (!isNil(previous) && isNil(current))) {
            const row: DocumentDropZoneRow = this.selectedRow?.getRawValue();
            this.previewableRow = null;
            this.changeDetectorRef.detectChanges();
            this.previewableRow = getFileType(row?.file) ? row : null;
          }

          previous = current;
        });
    }
  }

  protected isSingleDocumentSet(row: DocumentDropZoneRowForm): boolean {
    const { documentType } = row.getRawValue();
    return isSingleDocumentSet(documentType);
  }

  protected tagsControl(form: DocumentDropZoneRowForm): Observable<DocumentFileTagsControl> {
    const { documentSet, file } = form.getRawValue();
    if (!documentSet || !file) {
      return of(null);
    }

    const metadata$ = this.store.getDocumentMetadata(form);
    return metadata$.pipe(
      map((metadata) => {
        const documentSetControls = metadata?.documentForm?.controls.documentSets.controls ?? [];
        const existingSet = singleOrDefault(documentSetControls, (set) => areSetsEqual(set.getRawValue(), documentSet));
        const fileControls = existingSet?.controls.files.controls ?? [];
        const foundFile = singleOrDefault(fileControls, (control) => areFilesEqual(control.getRawValue(), file));
        return foundFile?.controls.tags;
      })
    );
  }

  protected customSort(event: SortEvent): void {
    const order = event.order === 1 ? 1 : -1;
    const field = event.field as keyof DocumentDropZoneRow;

    event.data.sort((form1: DocumentDropZoneRowForm, form2: DocumentDropZoneRowForm) => {
      switch (field) {
        case "file": {
          return form1.value.file.name.localeCompare(form2.value.file.name) * order;
        }
        case "candidateEntry": {
          return (
            form1.value.candidateEntry?.displayName?.localeCompare(form2.value.candidateEntry?.displayName) * order
          );
        }
        case "familyMember": {
          const label1 = this.getFamilyMemberDisplayName(form1.value.familyMember);
          const label2 = this.getFamilyMemberDisplayName(form2.value.familyMember);
          return label1?.localeCompare(label2) * order;
        }
        default: {
          return (form1.value[field] > form2.value[field] ? 1 : -1) * order;
        }
      }
    });
  }

  protected dispatchUpdate(form: DocumentDropZoneForm, rowForm: DocumentDropZoneRowForm): void {
    this.runOutsideChangeDetection(() => {
      this.store.updateOptionObservables(rowForm);
      const { extraction } = rowForm.getRawValue();
      const extractionData = !isNil(extraction?.data) && extraction.done && extraction.success ? extraction.data : {};
      return this.prefillRow({ row: rowForm, ...extractionData }).pipe(mergeMap(() => this.store.fixupForm(form)));
    });
  }

  protected updateColumn(
    item: SelectedStaticDataItem | SelectedStaticDataItems,
    columnKey: keyof DocumentDropZoneRow | "documentFormat"
  ): void {
    const rowsToUpdate = this.form.controls.rows.controls.filter((row) => {
      return item.overwriteExisting || columnKey === "documentFormat" || row.getRawValue()[columnKey] === null;
    });

    if (!rowsToUpdate.length) {
      return;
    }

    const observables = rowsToUpdate.map((row) => this.setValueIfValid(row, columnKey, item));
    this.runOutsideChangeDetection(() =>
      safeForkJoin(observables, []).pipe(
        filter(() => columnKey !== "documentFormat"),
        mergeMap(() => this.store.fixupForm(this.form))
      )
    );
  }

  private filterDocumentForms(searchInput: string): DocumentDropZoneRowForm[] {
    const controls = this.form.controls.rows.controls;
    if (isNil(searchInput) || isEmpty(searchInput)) {
      return controls;
    }

    function isMatch(source: string): boolean {
      return source?.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()) ?? false;
    }

    return controls.filter((form) => {
      const candidate = form.controls.candidateEntry.value;
      const displayName = candidate?.displayName;
      const displayId = candidate?.displayId;
      const fileName = form.controls.file.value?.name;
      const documentType = form.controls.documentType.value;
      const documentTypeTranslation = this.allowedUploadFileTypes[documentType] ?? documentType;
      return isMatch(displayName) || isMatch(displayId) || isMatch(fileName) || isMatch(documentTypeTranslation);
    });
  }

  private fetchRowExtraction(row: DocumentDropZoneRowForm, url: DocumentAiUrl): Observable<DropZoneRowExtraction> {
    const requestId = uuidv4();

    if (isNil(url)) {
      const error = translate("documents.extractionError.noUrl");
      return of({ row, extraction: { requestId, progress: 100, done: true, success: false, error } });
    }

    return this.documentAiService
      .extractDocumentData(requestId, url, row.value.file.name, this.candidateOrCollection)
      .pipe(
        catchError(() => {
          const error = translate("documents.extractionError.noResult");
          return of({ requestId, progress: 100, done: true, success: false, error });
        }),
        takeUntilDestroyed(this.destroyRef),
        takeWhile((extraction) => !extraction.done, true),
        map((extraction) => ({ row, extraction }))
      );
  }

  private updateFamilyMemberDependentData(): void {
    this.changeDetectorRef.detectChanges();
    if (!this.showFamilyMemberSelection) {
      this.store.clearFamilyMembers(this.form);
      return;
    }

    const extractions = this.form.controls.rows.controls
      .map((row) => ({ row, extraction: row.controls.extraction.value }))
      .filter(({ extraction }) => extraction)
      .filter(({ extraction }) => extraction.done && extraction.success);

    this.prefillRows(from(extractions));
  }

  private prefillRows(prefillRequests$: Observable<DropZoneRowPrefillRequest>): void {
    prefillRequests$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.changeDetectorRef.detach()),
        mergeMap((prefillRequest) => this.prefillRow(prefillRequest)),
        debounceTime(100),
        mergeMap(() => this.store.fixupForm(this.form))
      )
      .subscribe({
        next: () => this.changeDetectorRef.reattach(),
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.changeDetectorRef.reattach();
        },
        complete: () => this.changeDetectorRef.reattach(),
      });
  }

  private prefillRow(prefillRequest: DropZoneRowPrefillRequest): Observable<void> {
    const { row, candidateId, familyMemberId, documentType } = prefillRequest;
    return this.prefillCandidateEntry(row, candidateId).pipe(
      mergeMap(() => this.prefillFamilyMember(row, familyMemberId)),
      mergeMap(() => this.prefillDocumentType(row, documentType)),
      mergeMap(() => this.prefillDocumentSet(row))
    );
  }

  private prefillCandidateEntry(row: DocumentDropZoneRowForm, candidateId?: CandidateId): Observable<void> {
    if (row.controls.candidateEntry.dirty) {
      return of(VOID);
    }

    return this.store.candidates$.pipe(
      distinctUntilChanged(),
      map((options) => {
        const { id, orgId } = candidateId ?? {};
        const validOption = options.find(
          (option) => id && orgId && option.id === id && option.organizationId === orgId
        );

        if (validOption) {
          row.controls.candidateEntry.setValue(validOption);
          this.store.updateOptionObservables(row);
        }
      })
    );
  }

  private prefillFamilyMember(row: DocumentDropZoneRowForm, familyMemberId?: string): Observable<void> {
    if (!this.showFamilyMemberSelection || row.controls.familyMember.dirty) {
      return of(VOID);
    }

    return this.store.familyMemberOptions(row).pipe(
      distinctUntilChanged(),
      map((options) => {
        const validOption = options.find((option) => option.id === familyMemberId);
        if (!isNil(validOption)) {
          row.controls.familyMember.setValue(validOption);
          this.store.updateOptionObservables(row);
        }
      })
    );
  }

  private prefillDocumentType(row: DocumentDropZoneRowForm, documentType?: string): Observable<void> {
    if (row.controls.documentType.dirty) {
      return of(VOID);
    }

    return this.store.documentTypeOptions(row, this.allowedUploadFileTypes).pipe(
      distinctUntilChanged(),
      map((options) => {
        const documentTypes = uniq(this.store.digitalTypesToUpload.map((x) => x.documentType));
        const validOption = hasOne(documentTypes)
          ? options.find((option) => option.value === single(documentTypes))
          : options.find((option) => option.value === documentType);

        if (!isNil(validOption)) {
          row.controls.documentType.setValue(validOption.value);
          this.store.updateOptionObservables(row);
        }
      })
    );
  }

  private prefillDocumentSet(row: DocumentDropZoneRowForm): Observable<void> {
    if (row.controls.documentSet.dirty) {
      return of(VOID);
    }

    return this.store.documentMetadataOptions(row).pipe(
      distinctUntilChanged(),
      map((documentMetadata) => {
        const selectableSets = documentMetadata.selectableSets;

        const defaultSet = selectableSets.find((set) => set.isDefaultSet);
        const preferredTypes: DocumentSetType[] = ["CurrentRecognitionPath", "SignedByAllParties"];
        const preferredSet = firstOrDefault(selectableSets, (set) => preferredTypes.includes(set.type));
        const requiredSet = this.getRequiredDocumentSet(row, selectableSets);

        const validOption = requiredSet ?? preferredSet ?? defaultSet;
        if (!isNil(validOption)) {
          row.controls.documentSet.setValue(validOption);
          this.store.updateOptionObservables(row);
        }
      })
    );
  }

  private getRequiredDocumentSet(row: DocumentDropZoneRowForm, selectableSets: SetMetadata[]): SetMetadata | null {
    const matchingTypesToUpload = this.store.digitalTypesToUpload.filter(
      (x) => x.documentType === row.controls.documentType.value
    );

    if (!hasOne(matchingTypesToUpload)) {
      return null;
    }

    const documentTypeToUpload = single(matchingTypesToUpload);
    if (isNil(documentTypeToUpload.documentSetType)) {
      return null;
    }

    return selectableSets.find(({ type }) => type === documentTypeToUpload.documentSetType) ?? null;
  }

  private uploadFile(row: DocumentDropZoneRowForm): Observable<DocumentAiUrl | null> {
    const { fileId, file } = row.getRawValue();
    return this.documentAiService.getDocumentAiUploadUrl(fileId, this.settings.organizationId).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(() => of(null)),
      switchMap((url) => {
        if (isNil(url)) {
          return of(null);
        }

        return this.fileUploadService.uploadAsObservable(url.url, file).pipe(
          catchError(() => of()),
          map(() => url)
        );
      })
    );
  }

  private setValueIfValid<T extends keyof DocumentDropZoneRow | "documentFormat">(
    row: DocumentDropZoneRowForm,
    columnKey: T,
    value: T extends keyof DocumentDropZoneRow ? SelectedStaticDataItem : SelectedStaticDataItems
  ): Observable<void> {
    switch (columnKey) {
      case "documentType":
        return this.setDocumentTypeIfValid(row, value as SelectedStaticDataItem);
      case "documentSet":
        return this.setDocumentSetIfValid(row, value as SelectedStaticDataItem);
      case "documentFormat":
        return this.setDocumentFormatIfValid(row, value as SelectedStaticDataItems);
      default:
        return of(VOID);
    }
  }

  private setDocumentTypeIfValid(row: DocumentDropZoneRowForm, documentType: SelectedStaticDataItem): Observable<void> {
    return this.store.documentTypeOptions(row, this.allowedUploadFileTypes).pipe(
      tap((options) => {
        const validOption = options.find((option) => option.value === documentType.value);
        if (validOption) {
          row.controls.documentType.setValue(validOption.value);
          this.store.updateOptionObservables(row);
        }
      }),
      mapToVoid()
    );
  }

  private setDocumentSetIfValid(row: DocumentDropZoneRowForm, documentSet: SelectedStaticDataItem): Observable<void> {
    return this.store.documentMetadataOptions(row).pipe(
      tap((documentMetadata) => {
        const selectableSets = documentMetadata.selectableSets;
        const validOption =
          documentSet.value === DefaultDocumentSetName
            ? (selectableSets.find((set) => set.isDefaultSet) ?? firstOrDefault(selectableSets))
            : selectableSets.find((set) => set.type === documentSet.value);

        if (validOption) {
          row.controls.documentSet.setValue(validOption);
          this.store.updateOptionObservables(row);
        }
      }),
      mapToVoid()
    );
  }

  private setDocumentFormatIfValid(
    row: DocumentDropZoneRowForm,
    documentFormats: SelectedStaticDataItems
  ): Observable<void> {
    if (this.isSingleDocumentSet(row)) {
      return of(VOID);
    }

    return this.tagsControl(row).pipe(
      tap((tags) => {
        if (isNil(tags)) {
          return;
        }

        const currentTags = clone(tags.value);
        if (!documentFormats.overwriteExisting) {
          documentFormats.values.forEach((documentFormat) => {
            if (!currentTags.includes(documentFormat)) {
              currentTags.push(documentFormat);
            }
          });
          if (tags.value.length !== currentTags.length) {
            tags.setValue(currentTags);
            tags.markAsDirty();
            tags.updateValueAndValidity();
          }
        } else {
          tags.setValue(documentFormats.values);
          tags.markAsDirty();
          tags.updateValueAndValidity();
        }
      }),
      mapToVoid()
    );
  }

  private runOutsideChangeDetection<T>(observable: () => Observable<T>): void {
    this.changeDetectorRef.detach();
    observable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.changeDetectorRef.reattach(),
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          this.changeDetectorRef.reattach();
        },
        complete: () => this.changeDetectorRef.reattach(),
      });
  }
}
