import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";

@Directive({ selector: "button[submitOnEnter]" })
export class EnterButtonDirective implements OnInit, OnDestroy {
  static buttons: HTMLButtonElement[] = [];
  static isListening = false;

  constructor(private readonly elementRef: ElementRef<HTMLButtonElement>) {}

  ngOnInit(): void {
    if (!EnterButtonDirective.buttons.includes(this.elementRef.nativeElement)) {
      EnterButtonDirective.buttons.push(this.elementRef.nativeElement);
    }

    if (!EnterButtonDirective.isListening) {
      window.addEventListener("keydown", (e) => {
        if (EnterButtonDirective.buttons.length > 0 && e.code === "Enter") {
          let index = -1;
          let maxZIndex = -1;
          for (let i = 0; i < EnterButtonDirective.buttons.length; i++) {
            let element: HTMLElement = EnterButtonDirective.buttons[i];
            while (element && element !== <EventTarget>window) {
              if (element.style?.zIndex) {
                if (+element.style.zIndex > maxZIndex) {
                  maxZIndex = +element.style.zIndex;
                  index = i;
                }
                break;
              }
              element = element.parentElement;
            }
          }
          if (index > -1) {
            if (!EnterButtonDirective.buttons[index].disabled) {
              EnterButtonDirective.buttons[index].click();
            }
          }
        }
      });
      EnterButtonDirective.isListening = true;
    }
  }

  ngOnDestroy(): void {
    const index = EnterButtonDirective.buttons.indexOf(this.elementRef.nativeElement);
    if (index > -1) {
      EnterButtonDirective.buttons.splice(index, 1);
    }
  }
}
