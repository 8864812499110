import { Directive, Input, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { Button } from "primeng/button";
import { FormElementMapService } from "./form-element-map.service";

@Directive({
  selector: "p-button[addBtnRef]",
})
export class FormArrayAddButtonMapDirective implements OnInit {
  @Input()
  addBtnRef: AbstractControl;

  constructor(
    private readonly button: Button,
    private readonly elementMapService: FormElementMapService
  ) {}

  ngOnInit(): void {
    if (this.addBtnRef) {
      this.elementMapService.registerFormArrayAddButton(this.button, this.addBtnRef);
      return;
    }
  }
}
