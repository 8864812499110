import { DOCUMENT } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import {
  ensure4LetterIsoLanguage,
  supportedLanguages4LetterISO,
  supportedUserInterfaceLanguages4LetterISO,
} from "@ankaadia/ankaadia-shared";
import {
  MissingKeyHandler,
  RelaxedTranslocoTranspiler,
  dirInitializer,
  translocoInitializer,
} from "@ankaadia/ankaadia-shared-frontend";
import {
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER,
  TranslocoModule as TranslocoCoreModule,
  TranslocoService,
  getBrowserCultureLang,
  getBrowserLang,
  provideTransloco,
} from "@jsverse/transloco";
import { TranslocoLocaleModule, provideTranslocoLocale } from "@jsverse/transloco-locale";
import { provideTranslocoPersistLang } from "@jsverse/transloco-persist-lang";
import { environment } from "../../../environments/environment";
import { TranslateDirective } from "./translate.directive";
import { TranslocoHttpLoader } from "./transloco.loader";

const DEFAULT_UI_LANGUAGE = "de-DE";

function getBrowserLanguage(): string {
  const browserLanguage = getBrowserCultureLang();
  const retVal = ensure4LetterIsoLanguage(
    supportedUserInterfaceLanguages4LetterISO().includes(browserLanguage) ? browserLanguage : getBrowserLang()
  );
  return supportedUserInterfaceLanguages4LetterISO().includes(retVal) ? retVal : DEFAULT_UI_LANGUAGE;
}

@NgModule({
  imports: [TranslocoCoreModule, TranslocoLocaleModule, TranslateDirective],
  providers: [
    { provide: APP_INITIALIZER, useFactory: translocoInitializer, deps: [TranslocoService], multi: true },
    { provide: APP_INITIALIZER, useFactory: dirInitializer, deps: [TranslocoService, DOCUMENT], multi: true },
    provideTransloco({
      config: {
        availableLangs: supportedLanguages4LetterISO(),
        defaultLang: getBrowserLanguage() ?? DEFAULT_UI_LANGUAGE,
        fallbackLang: DEFAULT_UI_LANGUAGE,
        missingHandler: { useFallbackTranslation: environment.production },
        flatten: { aot: false /* aot does not work for us*/ },
        prodMode: environment.production,
        reRenderOnLangChange: false,
      },
      loader: TranslocoHttpLoader,
    }),
    { provide: TRANSLOCO_TRANSPILER, useClass: RelaxedTranslocoTranspiler },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingKeyHandler },
    provideTranslocoLocale(),
    provideTranslocoPersistLang({ storage: { useValue: localStorage }, storageKey: "language" }),
    TranslateDirective,
  ],
  exports: [TranslocoCoreModule, TranslocoLocaleModule, TranslateDirective],
})
export class TranslocoModule {}
