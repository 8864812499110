import { Injectable } from "@angular/core";
import {
  GetCandidateFieldTranslationsGQL,
  MeteredTranslationInput,
  PropertyTranslation,
  PropertyTranslationInput,
  RequestTranslationGQL,
  UpsertCandidateFieldTranslationsGQL,
} from "@ankaadia/graphql";
import { map, Observable } from "rxjs";

export interface PropertyTranslationWithState extends PropertyTranslation {
  isChanged: boolean;
}

@Injectable({ providedIn: "root" })
export class MeteredTranslationsService {
  propertyTranslations: PropertyTranslationWithState[] = [];

  constructor(
    private readonly requestTranslation: RequestTranslationGQL,
    private readonly getFieldTranslations: GetCandidateFieldTranslationsGQL,
    private readonly upsertFieldTranslations: UpsertCandidateFieldTranslationsGQL
  ) {}

  processUserRequestedTranslation(translationInput: MeteredTranslationInput): Observable<PropertyTranslation> {
    return this.requestTranslation.fetch({ input: translationInput }).pipe(
      map((response) => {
        return response.data.requestTranslation;
      })
    );
  }

  getFieldTranslationById(id: string): PropertyTranslationWithState {
    return this.propertyTranslations.find((t) => t.id === id);
  }

  getFieldTranslationsWithState(): PropertyTranslationWithState[] {
    return this.propertyTranslations;
  }

  loadCandidateFieldTranslations(input: PropertyTranslationInput): Observable<PropertyTranslation[]> {
    return this.getFieldTranslations.fetch({ input }).pipe(
      map((response) => {
        this.propertyTranslations = response.data.getCandidateFieldTranslations.map((translation) => {
          return { ...translation, isChanged: false };
        });
        return response.data.getCandidateFieldTranslations;
      })
    );
  }

  upsertCandidateFieldTranslations(): Observable<any> {
    const cleanedInput = {
      translations: this.propertyTranslations
        .filter((x) => x.isChanged)
        .map((translation) => {
          return {
            id: translation.id,
            propertyPath: translation.propertyPath,
            targetText: translation.targetText,
            translationItemId: translation.translationItemId,
            organizationId: translation.organizationId,
            translationProvider: translation.translationProvider,
            sourceText: translation.sourceText,
            sourceTextAsDelta: translation.sourceTextAsDelta,
            targetTextAsDelta: translation.targetTextAsDelta,
          };
        }),
    };
    if (cleanedInput.translations.length === 0) {
      return new Observable((observer) => {
        observer.next();
        observer.complete();
      });
    }
    return this.upsertFieldTranslations
      .mutate({ input: cleanedInput })
      .pipe(map((result) => result.data.upsertCandidateFieldTranslations));
  }
}
