import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { omit } from "lodash";
import { CheckboxModule } from "primeng-v17/checkbox";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-checkbox",
  templateUrl: "./formly-checkbox.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
    NgIf,
  ],
})
export class FormlyCheckboxComponent extends FieldType {
  override defaultOptions = {
    props: {
      hideLabel: true,
    },
  };

  // otherwise the `id` attribute is also applied to the `p-checkbox`
  // which is wrong as the `id` attribute is unique per HTML page
  readonly fieldWithoutId = omit(this.field, "id");
}
