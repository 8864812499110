import { IQualificationEvaluationConfiguration, nameofFactory } from "@ankaadia/ankaadia-shared";
import { StaticDataType } from "@ankaadia/graphql";
import { TranslateParams, translate as transloco } from "@jsverse/transloco";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { combineLatest, map } from "rxjs";
import { CertificateValuationAuthorityService } from "../../../features/certificate-valuation-authorities/certificate-valuation-authorities.service";
import { valuesOf } from "../../../shared/services/form.helper";
import { StaticDataContextEntry, StaticDataService } from "../../../shared/static-data/static-data.service";

export function qualificationEvaluationConfigurationFormFactory(
  language: string,
  staticDataService: StaticDataService,
  certificateValuationAuthorityService: CertificateValuationAuthorityService,
  staticDataContext: StaticDataContextEntry
): FormlyFieldConfig[] {
  const federalStates = staticDataService.getStaticData(StaticDataType.FederalStates, language, staticDataContext);
  const authoritiesWithFederalState =
    certificateValuationAuthorityService.getCertificateValuationAuthoritiesWithFederalState(
      staticDataContext.immigrationCountry
    );
  const nameOf = nameofFactory<IQualificationEvaluationConfiguration>();
  const translate = <T>(key: TranslateParams, params?: Record<string, any>): T => transloco<T>(key, params, language);
  return [
    {
      fieldGroupClassName: "grid",
      fieldGroup: [
        {
          className: "col-12",
          key: nameOf("evaluatedQualification"),
          type: "textarea",
          props: {
            label: translate("evaluatedQualification.title"),
            placeholder: translate("evaluatedQualification.placeholder"),
            maxLength: 300,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("evaluatedQualification.required"),
            },
          },
        },
        {
          className: "col-12",
          type: "input",
          key: nameOf("responsibleAuthorityForQualificationInOrigin"),
          props: {
            label: translate("responsibleAuthorityForQualificationInOrigin.title"),
            type: "text",
            maxLength: 300,
            blocklyType: "String",
          },
          validation: {
            messages: {
              required: translate("responsibleAuthorityForQualificationInOrigin.required"),
            },
          },
        },
        {
          className: "col-12",
          type: "dropdown",
          key: nameOf("federalState"),
          props: {
            label: translate("federalState.title"),
            options: federalStates,
            filter: true,
            filterBy: "label",
            placeholder: translate("federalState.placeholder"),
            blocklyType: "field_staticdata_federalstates",
          },
        },
        {
          className: "col-12",
          type: "dropdown",
          key: nameOf("responsibleAuthorityForQualification"),
          props: {
            label: translate("responsibleAuthorityForQualification.title"),
            filter: true,
            filterBy: "label",
            placeholder: translate("responsibleAuthorityForQualification.placeholder"),
          },
          hooks: {
            onInit: (field): void => {
              const responsibleAuthorityForQualification = field.form.get("responsibleAuthorityForQualification");
              const federalState = field.form.get("federalState");

              combineLatest([authoritiesWithFederalState, valuesOf(federalState)])
                .pipe(
                  map(([authorities, federalStateValue]) =>
                    authorities.filter(
                      (authority) => !federalStateValue || authority.federalState === federalStateValue
                    )
                  )
                )
                .subscribe((filteredAuthorities) => {
                  field.props.options = filteredAuthorities;
                  const selectedValue = responsibleAuthorityForQualification.value;
                  const newValue = filteredAuthorities.find((authority) => authority.value === selectedValue)
                    ? selectedValue
                    : filteredAuthorities.length > 0 && federalState.value
                      ? filteredAuthorities[0].value
                      : null;
                  responsibleAuthorityForQualification.patchValue(newValue);
                });
            },
          },
        },
      ],
    },
  ];
}
