import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { BaseComponent } from "primeng/basecomponent";
import { NavitemStyle } from "./navitem.style";

@Component({
  selector: "ui-navitem",
  templateUrl: "./navitem.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, NgTemplateOutlet],
  host: {
    class: "ui-navitem",
  },
})
export class NavitemComponent extends BaseComponent {
  protected readonly _componentStyle = inject(NavitemStyle);

  readonly label = input<string>();
  readonly icon = input<string>();
  readonly url = input<string>();
  readonly routerLink = input<string>();
}
