import { Injectable } from "@angular/core";
import { AlwaysRequiredCandidateFields } from "@ankaadia/ankaadia-shared";
import {
  CreateNewPublicApiAccessTokenGQL,
  GetCurrentPublicApiAccessTokenGQL,
  GetSettingsForCandidateOrganizationGQL,
  GetSystemSettingGQL,
  SupportedImmigrationCountry,
  SystemSettingFragment,
  SystemSettingsUpdateInput,
  UpdateSystemSettingGQL,
  WorkExperiencePrecissionEnum,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

export interface OwnerSettings {
  requiredCandidateFields: string[];
  workingExperiencePrecision: WorkExperiencePrecissionEnum;
  educationPrecision: WorkExperiencePrecissionEnum;
}

@Injectable({ providedIn: "root" })
export class OrganizationSystemSettingsService {
  constructor(
    private readonly systemSettingGet: GetSystemSettingGQL,
    private readonly systemSettingCandidateOrgGet: GetSettingsForCandidateOrganizationGQL,
    private readonly systemSettingUpdate: UpdateSystemSettingGQL,
    private readonly getPublicApiAccessTokenQuery: GetCurrentPublicApiAccessTokenGQL,
    private readonly createPublicApiAccessTokenMutation: CreateNewPublicApiAccessTokenGQL
  ) {}

  getPublicApiAccessToken(organizationId: string): Observable<string> {
    return this.getPublicApiAccessTokenQuery
      .fetch({ organizationId: organizationId }, { fetchPolicy: "network-only" })
      .pipe(map((result) => result.data.getCurrentPublicAPIAccessToken));
  }

  createPublicApiAccessToken(organizationId: string): Observable<string> {
    return this.createPublicApiAccessTokenMutation
      .mutate({ organizationId: organizationId })
      .pipe(map((result) => result.data.createNewPublicAPIAccessToken));
  }

  get(organizationId: string): Observable<SystemSettingFragment> {
    return this.systemSettingGet
      .fetch({ input: { organizationId: organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((result) => result.data.getSystemSetting));
  }

  getCandidateOwnerSpecificSettings(organizationId: string): Observable<OwnerSettings> {
    return this.systemSettingCandidateOrgGet
      .fetch({ input: { organizationId: organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(
        map((result) => ({
          requiredCandidateFields: [
            ...AlwaysRequiredCandidateFields,
            ...(result.data.getSettingsForCandidateOrganization.requiredCandidateFields ?? []),
          ],
          workingExperiencePrecision: result.data.getSettingsForCandidateOrganization.precisionOfWorkexperience,
          educationPrecision: result.data.getSettingsForCandidateOrganization.precisionOfEducation,
        }))
      );
  }

  getStandardImmigrationCountry(organizationId: string): Observable<SupportedImmigrationCountry> {
    return this.systemSettingCandidateOrgGet
      .watch({ input: { organizationId: organizationId } })
      .valueChanges.pipe(map((result) => result.data.getSettingsForCandidateOrganization.standardImmigrationCountry));
  }

  update(settings: SystemSettingFragment): Observable<SystemSettingFragment> {
    const input = new SystemSettingsUpdateInput();
    input.id = settings.id;
    input._etag = settings._etag;
    input.isSystemLogoutEnabled = settings.isSystemLogoutEnabled;
    input.logoutPeriodMinutes = settings.logoutPeriodMinutes;
    input.requiredCandidateFields = settings.requiredCandidateFields;
    input.organizationId = settings.organizationId;
    input.precisionOfWorkexperience = settings.precisionOfWorkexperience;
    input.precisionOfEducation = settings.precisionOfEducation;
    input.standardImmigrationCountry = settings.standardImmigrationCountry;
    input.supportedImmigrationCountries = settings.supportedImmigrationCountries;
    input.enableCustomCandidateTags = settings.enableCustomCandidateTags;
    input.candidateTags = settings.candidateTags;
    input.isPubliclyVisible = settings.isPubliclyVisible;
    input.removeCandidatesFromAllListsIfDropout = settings.removeCandidatesFromAllListsIfDropout;
    input.candidateDeletionSetting = settings.candidateDeletionSetting;
    input.fileNameWithCandidateId = settings.fileNameWithCandidateId;
    input.fileNameWithFileFormat = settings.fileNameWithFileFormat;
    input.automaticRejectionEmailsEnabled = settings.automaticRejectionEmailsEnabled;
    return this.systemSettingUpdate.mutate({ input: input }).pipe(map((result) => result.data.updateSystemSetting));
  }
}
