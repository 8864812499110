import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  Candidate,
  CandidateForProcessFragment,
  CandidateRelation,
  DocumentMode,
  RequiredDocumentForListFragment,
  Sharing,
  StaticDataModel,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { of } from "rxjs";
import { SettingsService } from "../../../shared/services/settings.service";
import { CandidateForm } from "../../candidates/candidate-form/candidate-form.model";
import { OrganizationsService } from "../../organizations/organizations.service";
import { RequiredDocumentService } from "../../required-documents/required-document.service";
import { DocumentTableComponent } from "../document-table/document-table.component";

@Component({
  selector: "app-candidate-files",
  templateUrl: "./candidate-files.component.html",
  styleUrl: "./candidate-files.component.scss",
})
export class CandidateFilesComponent implements OnChanges, OnInit, AfterViewInit {
  readonly mode = DocumentMode.Candidate;

  readonly relations = [
    {
      label: translate("enum.CandidateRelation.Partner", null, this.processLanguage),
      value: CandidateRelation.Partner,
    },
    { label: translate("enum.CandidateRelation.Child", null, this.processLanguage), value: CandidateRelation.Child },
    { label: translate("enum.CandidateRelation.Mother", null, this.processLanguage), value: CandidateRelation.Mother },
    { label: translate("enum.CandidateRelation.Father", null, this.processLanguage), value: CandidateRelation.Father },
    { label: translate("enum.CandidateRelation.Other", null, this.processLanguage), value: CandidateRelation.Other },
  ];

  @Input({ required: true })
  model: CandidateForProcessFragment;

  @Input()
  displayTitle: boolean;

  @Input()
  processLanguage?: string = this.transloco.getActiveLang();

  @Input()
  readonly: boolean;

  @Input()
  selectedSharing?: Sharing;

  @Input()
  showInternalDoc?: boolean;

  @Input()
  showCompletionStatus?: boolean;

  @Input()
  candidateForm?: CandidateForm;

  @Input()
  candidateImmigrationCountry: string;

  @Input()
  candidateFunction: string;

  @Input()
  candidateSourceSharedRequiredDocuments: boolean;

  @Output()
  readonly saved = new EventEmitter<void>();

  @ViewChild(DocumentTableComponent)
  table: DocumentTableComponent;

  familyMembers: StaticDataModel[];
  selectedFamilyMemberId: string;
  confirmCreation: boolean;
  protected showOrgs = false;
  protected organizations: StaticDataModel[];
  protected selectedOrganizationId = new FormControl<string>(null);
  protected requiredDocumentSets: RequiredDocumentForListFragment[];
  protected selectedRequiredDocumentSet = new FormControl<string[]>(null);

  protected get stateKey(): string {
    return `ankaadia_document_requiredDocumentSet_${this.candidateImmigrationCountry}_${this.candidateFunction}`;
  }

  protected selectedDocumentSets: string[] = [];

  get personName(): string {
    return this.familyMembers?.find((m) => m.value === this.selectedFamilyMemberId)?.label ?? this.model?.displayName;
  }

  constructor(
    private readonly transloco: TranslocoService,
    private readonly changeDetector: ChangeDetectorRef,
    protected readonly settings: SettingsService,
    protected readonly orgService: OrganizationsService,
    protected readonly requiredDocumentService: RequiredDocumentService
  ) {}

  ngAfterViewInit(): void {
    this.loadOrganizations();
  }

  ngOnInit(): void {
    this.selectedOrganizationId.valueChanges.subscribe((orgId) => {
      if (orgId == null || this.model.id == null) return;
      this.requiredDocumentService
        .getAllForCandidate(this.model.id, this.model.organizationId, orgId)
        .subscribe((requiredDocumentSets) => {
          this.requiredDocumentSets = requiredDocumentSets;
          setTimeout(() => {
            // setTime because of RememberStateDirective
            this.selectedRequiredDocumentSet.setValue(null, { emitEvent: true });
          }, 5);
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.updateFamilyMembers(changes.model.currentValue);
      this.changeDetector.detectChanges();
    }
  }

  reload(): void {
    this.table.reload(this.table.organizationId, this.table.candidateId, this.table.familyMemberId);
  }

  private updateFamilyMembers(model: Candidate): void {
    this.familyMembers = [];
    this.selectedFamilyMemberId = null;
    this.confirmCreation = false;
    this.familyMembers = [
      { label: model?.displayName, value: model?.id },
      ...(model?.family?.members?.map((m) => ({
        label: this.formatDisplayName(m),
        value: m.id,
      })) ?? []),
    ];
    this.selectedFamilyMemberId = model?.id;
    this.confirmCreation = !!this.familyMembers?.length;
  }

  private formatDisplayName(person: { firstName: string; lastName: string; relation?: CandidateRelation }): string {
    const fullName = `${person.lastName}, ${person.firstName}`;
    if (!person.relation) return fullName;
    return `${fullName} (${this.localizeRelation(person.relation)})`;
  }

  private localizeRelation(relation: CandidateRelation): string | CandidateRelation {
    const relationItem = this.relations.find((x) => x.value === relation);
    if (relationItem) return relationItem.label;
    return relation;
  }

  private loadOrganizations(): void {
    this.organizations = [];
    if (
      this.mode == DocumentMode.Candidate &&
      !this.settings.isCandidate &&
      (this.settings.isLicensed || (!this.settings.isLicensed && this.candidateSourceSharedRequiredDocuments))
    ) {
      if (this.candidateSourceSharedRequiredDocuments && this.model.organizationId != this.settings.organizationId) {
        this.orgService
          .getOrganizationsSharingTabToCandidate(this.model.id, this.model.organizationId, "requiredDocument")
          .subscribe((orgs) => {
            this.organizations = [
              ...orgs.map((org) => ({ label: org.name, value: org.id })),
              ...(this.settings.isLicensed
                ? [{ label: this.settings.organizationName, value: this.settings.organizationId }]
                : []),
            ];
            if (this.organizations.length > 0) {
              setTimeout(() => {
                // setTime because of RememberStateDirective
                this.selectedOrganizationId.setValue(this.organizations[0].value);
              }, 5);
            }
          });
      } else {
        this.organizations = [{ label: this.settings.organizationName, value: this.settings.organizationId }];
        if (this.organizations.length > 0) {
          of(null).subscribe(() =>
            setTimeout(() => {
              // setTime because of RememberStateDirective
              this.selectedOrganizationId.setValue(this.organizations[0].value);
            }, 5)
          );
        }
      }
    }
  }
}
