import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { CustomMenuItem } from "./menu.model";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  imports: [NgFor, MenuItemComponent],
})
export class MenuComponent {
  @Input()
  items: CustomMenuItem[];

  @Output()
  readonly sidebarClosed = new EventEmitter<Event>();
}
