<ng-container *appTranslate="let t">
  <ng-container *ngIf="examGroups$ | async as exams">
    <p-fieldset *ngIf="exams?.length" [legend]="t('certifiedEducationExams.title')">
      <ng-container *ngFor="let exam of exams; index as i">
        <app-formly-certified-education-exams-entry
          [disabled]="to?.disabled"
          [readonly]="to?.readonly"
          [candidateId]="formState?.candidateId"
          [organizationId]="formState?.organizationId"
          [examGroup]="exam"
          [form]="form"
        ></app-formly-certified-education-exams-entry>

        <hr class="mt-0" *ngIf="i < exams.length - 1" />
      </ng-container>
    </p-fieldset>
  </ng-container>
</ng-container>
