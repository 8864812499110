import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-radio-button",
  templateUrl: "./formly-radio-button.component.html",
  styleUrl: "./formly-radio-button.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyRadioButtonComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    if (this.props.defaultValue != null && this.formControl.value == null) {
      this.formControl.setValue(this.props.defaultValue);
    }
  }

  get disabledControl(): FormControl {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }
}
