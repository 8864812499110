import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormArrayAddButtonMapDirective } from "./form-array-add-btn-map.directive";
import { FormArrayRemoveButtonMapDirective } from "./form-array-remove-btn-map.directive";
import { FormElementMapDirective } from "./form-element-map.directive";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [FormElementMapDirective, FormArrayAddButtonMapDirective, FormArrayRemoveButtonMapDirective],
  exports: [FormElementMapDirective, FormArrayAddButtonMapDirective, FormArrayRemoveButtonMapDirective],
})
export class FormElementMapModule {}
