import { Directive } from "@angular/core";
import { getLocaleDateString } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { Calendar } from "primeng/calendar";

@Directive({ selector: "p-calendar" })
export class CalendarOptionsDirective {
  constructor(
    private readonly calendar: Calendar,
    private readonly transloco: TranslocoService
  ) {
    this.calendar.dateFormat = getLocaleDateString(this.transloco.getActiveLang(), this.calendar.view);
    if (!this.calendar.placeholder) {
      const placeHolder = getLocaleDateString(this.transloco.getActiveLang(), "date_Placeholder");
      this.calendar.placeholder =
        this.calendar.selectionMode === "range" ? placeHolder + " - " + placeHolder : placeHolder;
    }
    this.calendar.showIcon = true;
    this.calendar.firstDayOfWeek = 1;
    this.calendar.populateYearOptions(1950, 2040);
    if (!this.calendar.dataType) {
      this.calendar.dataType = "date";
    }
  }
}
