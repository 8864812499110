import { Injectable } from "@angular/core";
import {
  GetLoginLogoUrlGQL,
  LinkAccountAfterLoginGQL,
  LinkAccountBeforeLoginGQL,
  LinkAccountInputAfterLogin,
  LinkAccountInputBeforeLogin,
  UnLinkFacebookAccountGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";
import { registerNoLoginOperation } from "../../shared/interceptors/auth.interceptor";

@Injectable({ providedIn: "root" })
export class SSOLoginService {
  constructor(
    private readonly linkBeforeLogin: LinkAccountBeforeLoginGQL,
    private readonly linkAfterLogin: LinkAccountAfterLoginGQL,
    private readonly unlink: UnLinkFacebookAccountGQL,
    private readonly loginUrl: GetLoginLogoUrlGQL
  ) {
    registerNoLoginOperation(this.loginUrl);
  }

  unlinkAccount(ssodId: string): Observable<boolean> {
    return this.unlink.mutate({ ssoId: ssodId }).pipe(map((result) => result.data.unLinkFacebookAccount.status));
  }

  linkAccountBeforeLogin(input: LinkAccountInputBeforeLogin): Observable<boolean> {
    return this.linkBeforeLogin
      .mutate({ input: input })
      .pipe(map((result) => result.data.linkAccountBeforeLogin.status));
  }

  linkAccountAfterLogin(input: LinkAccountInputAfterLogin): Observable<boolean> {
    return this.linkAfterLogin.mutate({ input: input }).pipe(map((result) => result.data.linkAccountAfterLogin.status));
  }

  getLoginLogoUrl(organizationId: string, creatorOrganizationId: string): Observable<string> {
    return this.loginUrl
      .fetch({ input: { organizationId, creatorOrganizationId: creatorOrganizationId } })
      .pipe(map((result) => result.data.getLoginLogoUrl));
  }
}
