import { Component, OnInit } from "@angular/core";
import { ICompetenciesModel, ICompetencyModel, nameofFactory } from "@ankaadia/ankaadia-shared";
import { CustomSkillSet, OccupationSkillSet, OccupationSkillType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FieldArrayType, FormlyFieldConfig } from "@ngx-formly/core";
import { cloneDeep, remove, set } from "lodash";
import { map, Observable, switchMap } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ProfessionService } from "../../features/profession/profession.service";

/**
 * Renders competencies of the candidate of a type declared via {@link FormlyFieldConfig.key}.
 */
@Component({
  selector: "app-formly-competencies",
  templateUrl: "./formly-competencies.component.html",
})
export class FormlyCompetenciesComponent extends FieldArrayType implements OnInit {
  /**
   * Skill sets that should be rendered by this component.
   */
  sets: (OccupationSkillSet | CustomSkillSet)[];

  private readonly language = this.transloco.getActiveLang();

  constructor(
    private readonly professionService: ProfessionService,
    private readonly transloco: TranslocoService
  ) {
    super();
  }

  /**
   * Whenever the candidate's profession changes:
   * - load the corresponding skill sets,
   * - fix the model up so that it has no outdated skills,
   * - update the form appearance.
   */
  ngOnInit(): void {
    (<Observable<string>>this.options.formState.profession)
      .pipe(switchMap((professionId) => this.loadSkillSets(professionId, this.options.formState.organizationId)))
      .subscribe((sets) => {
        this.sets = sets;
        this.rebuildModel();
        this.rebuildForm();
      });
  }

  /**
   * Checks whether the field is a part of the given skill set.
   */
  isInSet(field: FormlyFieldConfig, set: OccupationSkillSet | CustomSkillSet): boolean {
    const competency = <ICompetencyModel>field.model;
    return set.skills.some((x) => x.skillId === competency?.skillId);
  }

  /**
   * Returns skill sets of the given profession.
   *
   * The type of the skill sets (standard or custom, knowledge or skills) is deducted from the field's key.
   *
   * The implementation ensures that a single request is issued against the backend for all fields of this type.
   */
  private loadSkillSets(professionId: string, organizationId: string): Observable<typeof this.sets> {
    const nameOfCompetencies = nameofFactory<ICompetenciesModel>();
    return this.professionService.getProfessionSkills(professionId, organizationId, this.language).pipe(
      map(([standard, custom]) => {
        switch (this.key) {
          case nameOfCompetencies("knowledges"):
            return standard.filter((x) => x.skillType === OccupationSkillType.Knowledge);
          case nameOfCompetencies("skills"):
            return standard.filter((x) => x.skillType === OccupationSkillType.SkillCompetence);
          case nameOfCompetencies("customKnowledges"):
            return custom.filter((x) => x.skillType === OccupationSkillType.Knowledge);
          case nameOfCompetencies("customSkills"):
            return custom.filter((x) => x.skillType === OccupationSkillType.SkillCompetence);
          default:
            return null;
        }
      })
    );
  }

  /**
   * Ensures there are only those skills in the model that correspond to the selected profession.
   *
   * The same is done on the backend when saving and, perhaps, reading the candidate, but this one runs on the frontend when candidate's profession gets changed.
   * Essentially, it filters the skills from the previous profession out and creates entries for the new skills in the model.
   */
  private rebuildModel(): void {
    // if we're not editing the candidate, don't mess with the model
    if (this.props.readonly) return;

    // `model` is read-only, so direct assignment does not work
    // we would have used `assignFieldValue` but it's not exported by ngx-formly
    // so we're on our own here, trying to set it from the parent model
    if (!this.model) {
      const competencies = this.field.parent;
      if (!competencies.model) {
        // let's stop at the profile level -- it most likely isn't empty
        const profile = competencies.parent;
        set(profile.model, competencies.key, {});
      }
      set(competencies.model, this.key, []);
    }

    const newModel = <ICompetencyModel[]>this.model;
    const oldModel = cloneDeep(newModel);
    remove(newModel);

    for (const set of this.sets) {
      for (const skill of set.skills) {
        const existing = oldModel?.find((x) => x.skillId === skill.skillId);
        newModel.push(
          existing ?? { id: uuidv4(), skillId: skill.skillId, proficiency: null, additionalInformation: null }
        );
      }
    }

    this.field.options.build(this.field);
    this.field.options.detectChanges(this.field);
  }

  /**
   * Updates labels and tooltips of each skill.
   *
   * This relies on the model being up-to-date, so call {@link rebuildModel} first!
   */
  private rebuildForm(): void {
    const nameOfCompetency = nameofFactory<ICompetencyModel>();
    const skills = this.sets.flatMap((x) => x.skills);

    for (const fieldGroup of this.field.fieldGroup) {
      const competency = <ICompetencyModel>fieldGroup.model;
      const skill = skills?.find((x) => x.skillId === competency?.skillId);

      const proficiency = fieldGroup.get(nameOfCompetency("proficiency"));
      proficiency.props ??= {};
      proficiency.props.label = skill?.preferredLabel;
      proficiency.props.tooltip = skill?.description;

      const additionalInformation = fieldGroup.get(nameOfCompetency("additionalInformation"));
      if (additionalInformation) {
        additionalInformation.props ??= {};
        additionalInformation.expressions ??= {};
        const set = this.sets.find((x) => x.skills.some((y) => y.skillId === skill?.skillId));
        if (this.isCustomSkillSet(set)) {
          additionalInformation.props.requireAdditionalInformation = set.requireAdditionalInformation;
          additionalInformation.props.additionalInformationTypes = set.additionalInformationTypes;
          additionalInformation.props.label = set.additionalInformationLabel;
        } else {
          delete additionalInformation.props.requireAdditionalInformation;
          delete additionalInformation.props.additionalInformationTypes;
        }
      }
    }

    this.field.options.detectChanges(this.field);
  }

  private isCustomSkillSet(set: OccupationSkillSet | CustomSkillSet): set is CustomSkillSet {
    return set?.__typename === "CustomSkillSet";
  }
}
