import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CandidateFieldNotSharedDirective } from "./candidate-field-not-shared.directive";
import { EnterButtonDirective } from "./enter-button.directive";
import { EscapeButtonDirective } from "./escape-button.directive";
import { ImagePreloadDirective } from "./image-preload.directive";
import { LicensedDirective } from "./licensed.directive";
import { PermissionDirective } from "./permission.directive";
import { ScrollIntoViewDirective } from "./scroll-into-view.directive";
import { TrimDirective } from "./trim.directive";

const directives = [
  EnterButtonDirective,
  EscapeButtonDirective,
  ImagePreloadDirective,
  LicensedDirective,
  PermissionDirective,
  ScrollIntoViewDirective,
  TrimDirective,
  CandidateFieldNotSharedDirective,
];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class DirectivesModule {}
