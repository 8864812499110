import { Injectable } from "@angular/core";
import {
  DocumentTypeOverwrite,
  DocumentTypeOverwriteDeleteInput,
  DocumentTypeOverwriteUpsertInput,
  GetAllDocumentOverwritesGQL,
  GetDocumentTypeOverwriteGQL,
  RemoveDocumentTypeOverwriteGQL,
  UpsertDocumentTypeOverwriteGQL,
} from "@ankaadia/graphql";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomDocumentTypesService {
  constructor(
    private readonly allDocumentsGet: GetAllDocumentOverwritesGQL,
    private readonly addDocument: UpsertDocumentTypeOverwriteGQL,
    private readonly deleteDocument: RemoveDocumentTypeOverwriteGQL,
    private readonly getDocument: GetDocumentTypeOverwriteGQL
  ) {}

  getAll(organizationId: string): Observable<DocumentTypeOverwrite[]> {
    return this.allDocumentsGet
      .fetch({ organizationId: organizationId })
      .pipe(map((result) => result.data.getAllDocumentOverwrites));
  }

  get(id: string, organizationId: string): Observable<DocumentTypeOverwrite> {
    return this.getDocument
      .fetch({ input: { id: id, organizationId: organizationId } })
      .pipe(map((result) => result.data.getDocumentTypeOverwrite));
  }

  update(input: DocumentTypeOverwriteUpsertInput): Observable<DocumentTypeOverwrite> {
    return this.addDocument.mutate({ input: input }).pipe(map((x) => x.data.upsertDocumentTypeOverwrite));
  }

  delete(doc: DocumentTypeOverwrite): Observable<boolean> {
    const input = new DocumentTypeOverwriteDeleteInput();
    input.id = doc.id;
    input.organizationId = doc.organizationId;
    input._etag = doc._etag;
    return this.deleteDocument.mutate({ input: input }).pipe(map((x) => x.data.removeDocumentTypeOverwrite.status));
  }
}
