<ng-container *appTranslate="let t">
  <div class="flex flex-wrap gap-3" *ngIf="showSourceOrganizationControlSwitch && hasOrganizationOptions$ | async">
    <span class="align-content-center" [textContent]="t('vaccinations.overviewSource.title') + ':'"></span>
    <p-dropdown [formControl]="sourceOrganizationControlSwitch" [options]="organizationOptions$ | async"></p-dropdown>
  </div>

  <p-message
    *ngFor="let message of messages"
    styleClass="mb-3"
    [severity]="message.severity"
    [text]="message.detail"
  ></p-message>
</ng-container>
