import { Injectable, Type } from "@angular/core";
import { WorkExperiencePrecissionEnum } from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { DefaultSpecific } from "../default/default-specifics";
import { defaCustomFieldsFactory } from "./custom-formly-definition";
import { DefaProfileComponent } from "./defa-profile.component";

@Injectable({ providedIn: "root" })
export class DefaSpecific extends DefaultSpecific {
  override getCandidateImporterTemplateUrl(): string {
    return "/assets/static/defa/Kandidatenliste.xlsx";
  }

  override getCandidateProfileComponent(): Type<DefaProfileComponent> {
    return DefaProfileComponent;
  }

  override getCandidateProfileCustomFields(
    requiredFields?: string[],
    _workExperiencePrecission?: WorkExperiencePrecissionEnum,
    _educationPrecission?: WorkExperiencePrecissionEnum
  ): FormlyFieldConfig[] {
    return defaCustomFieldsFactory(requiredFields);
  }
}
