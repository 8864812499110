import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ensure4LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { Consent } from "@ankaadia/graphql";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: "app-tc-consent-dialog",
  templateUrl: "./tc-consent-dialog.component.html",
  styleUrl: "./tc-consent-dialog.component.scss",
})
export class TcConsentDialogComponent implements OnInit {
  consents: Consent[];
  agreedControl = new FormControl(false);
  viewLanguage: string;
  reRender = true;
  lastIndex = 0;

  constructor(
    private readonly dialogRef: DynamicDialogRef,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.consents = this.config.data;
    this.viewLanguage = ensure4LetterIsoLanguage(this.consents[0]?.language ?? "de-DE");
  }

  okClicked(): void {
    if (this.agreedControl.value) {
      this.dialogRef.close(true);
    }
  }

  onTabChanged(val: { language: string; tabIndex: number }): void {
    this.viewLanguage = ensure4LetterIsoLanguage(val.language ?? "de-DE");
    this.reRender = false;
    setTimeout(() => {
      this.lastIndex = val.tabIndex;
      this.reRender = true;
    }, 10);
  }
}
