import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ErrorCode, nameofFactory } from "@ankaadia/ankaadia-shared";
import {
  CandidateForProcessFragment,
  Document,
  DocumentAiPerformanceInput,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { ApolloError } from "@apollo/client/core";
import { translate, TranslocoService } from "@jsverse/transloco";
import { isEmpty, isNil, some, uniq } from "lodash";
import {
  catchError,
  distinctUntilChanged,
  finalize,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  throwError,
} from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { MessageDialogService } from "../../shared/message-dialog/message-dialog.service";
import { FileUploadService } from "../../shared/services/file-upload.service";
import { LocalStorageService } from "../../shared/services/local-storage.service";
import { safeForkJoin } from "../../shared/services/safe-fork-join";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataContext, StaticDataService } from "../../shared/static-data/static-data.service";
import { DocumentAiService } from "../documents/document-ai/document-ai.service";
import { DocumentFileForm, DocumentForm, toCleanDocument } from "../documents/document-form.model";
import { getFileType } from "../documents/document-preview-dialog/document-preview-dialog.functions";
import { DocumentsService } from "../documents/documents.service";
import { MessageService } from "../message/message.service";
import { DocumentDropZoneForm } from "./document-dropzone-form.model";
import { DocumentDropZoneFormService } from "./document-dropzone-form.service";
import {
  DocumentDropzoneOptionsStore,
  DocumentDropzoneOptionsStoreFactory,
} from "./document-dropzone-options-store.service";
import {
  CollectionInformation,
  DigitalDocumentRequirement,
  DocumentMetadata,
  DropzonePoolId,
  GetFormDocumentByType,
} from "./document-dropzone.model";
import { OverlayElementType } from "./overlay-detector.service";
import { Size } from "./resize.directive";

const nameOf = nameofFactory<DocumentDropZoneComponent>();

@Component({
  selector: "app-document-dropzone",
  templateUrl: "./document-dropzone.component.html",
  styleUrl: "./document-dropzone.component.scss",
  providers: [FileUploadService],
})
export class DocumentDropZoneComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly instances: DocumentDropZoneComponent[] = [];
  private readonly language = this.transloco.getActiveLang();
  private readonly context: StaticDataContext = this.settings.supportedImmigrationCountries.map((country) => ({
    immigrationCountry: country as SupportedImmigrationCountry,
    organizationId: this.settings.organizationId,
  }));

  protected readonly isEmpty = isEmpty;
  protected readonly isNil = isNil;

  protected form: DocumentDropZoneForm = this.documentDropzoneFormService.createForm();
  protected store: DocumentDropzoneOptionsStore;
  protected showFamilyMemberSelection = false;
  protected showDialog = false;
  protected submitDisabled = false;
  protected active = false;
  protected dropzoneSize: Size = { width: 0, height: 0 };
  protected dropzoneContentSize: Size = { width: 0, height: 0 };
  protected allowedUploadFileTypes: Record<string, string> = {};
  protected subscription: Subscription;
  protected limit: number;

  @Input()
  visibility: "hover" | "always" | "compact" = "hover";

  @Input()
  ignoredOverlayElementTypes: OverlayElementType[] = [];

  @Input()
  processLanguage?: string = this.language;

  @Input()
  digitalDocumentRequirements?: DigitalDocumentRequirement[];

  @Input({ required: true })
  poolId: DropzonePoolId;

  @Input({ required: true })
  candidateOrCollection: CandidateForProcessFragment | CollectionInformation;

  @Input()
  saveDocument?: (document: DocumentForm) => Observable<Document>;

  @Input()
  getDocumentByType?: GetFormDocumentByType;

  get disabled(): boolean {
    return !isNil(this.digitalDocumentRequirements) && isEmpty(this.digitalDocumentRequirements);
  }

  get hidden(): boolean {
    const isOtherInstanceActive = DocumentDropZoneComponent.instances.some((instance) => {
      return instance !== this && instance.active && instance.poolId === this.poolId;
    });

    return (
      isOtherInstanceActive ||
      this.dropzoneSize.height < this.dropzoneContentSize.height ||
      this.dropzoneSize.width < this.dropzoneContentSize.width
    );
  }

  get savingIsBlocked(): Observable<boolean> {
    return this.getValidDocumentMetadata().pipe(
      map((rows) => {
        if (isEmpty(rows)) {
          return true;
        }
        return some(rows, ({ documentForm, experiencedErrors }) => !documentForm.valid || experiencedErrors);
      }),
      distinctUntilChanged()
    );
  }

  get aiProgress(): number {
    const rows = this.form.controls.rows.controls;
    const total = rows.length;
    const done = rows.map((row) => row.controls.extraction.value.progress).reduce((acc, val) => acc + val, 0);
    return total > 0 ? done / total / 100 : 0;
  }

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly transloco: TranslocoService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly settings: SettingsService,
    private readonly messageService: MessageService,
    private readonly staticDataService: StaticDataService,
    private readonly documentDropzoneFormService: DocumentDropZoneFormService,
    private readonly storeFactory: DocumentDropzoneOptionsStoreFactory,
    private readonly fileUploadService: FileUploadService,
    private readonly documentService: DocumentsService,
    private readonly errorService: MessageDialogService,
    private readonly documentAiService: DocumentAiService,
    protected readonly localStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    DocumentDropZoneComponent.instances.push(this);
    this.updateLanguageDependentStuff();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[nameOf("processLanguage")]) {
      this.updateLanguageDependentStuff();
    }
  }

  ngOnDestroy(): void {
    DocumentDropZoneComponent.instances.splice(DocumentDropZoneComponent.instances.indexOf(this), 1);
  }

  protected onFilesSelected(event: Event): void {
    if (this.hidden || !(event.target instanceof HTMLInputElement)) {
      return;
    }

    const files = Array.from(event.target.files);
    if (!isEmpty(files)) {
      this.openDialog(files);
    }
  }

  protected openDialog(files: File[]): void {
    if (this.hidden) {
      return;
    }

    this.limit = this.settings.isMasterUser ? 100 : 20;
    const topFiles = files.slice(0, this.limit);

    this.subscription?.unsubscribe();
    const rows = topFiles.map((file) => ({
      file,
      fileId: uuidv4(),
      fileUrl: URL.createObjectURL(file),
      fileType: getFileType(file),
      extraction: { done: false, progress: 0, requestId: uuidv4() },
    }));

    this.form = this.documentDropzoneFormService.createForm(rows);
    this.store = this.storeFactory.create(
      this.changeDetectorRef,
      this.candidateOrCollection,
      this.processLanguage,
      this.digitalDocumentRequirements,
      this.getDocumentByType
    );

    this.showFamilyMemberSelection = false;
    this.showDialog = true;
  }

  protected closeDialog(): void {
    this.showDialog = false;
  }

  protected submit(): void {
    this.logDocumentAiPerformance();
    this.submitDisabled = true;
    this.getValidDocumentMetadata()
      .pipe(
        map((documents) => uniq(documents)),
        map((documents) => documents.map((document) => this.uploadDocument(document)))
      )
      .pipe(switchMap((uploads$) => safeForkJoin(uploads$, [])))
      .pipe(finalize(() => (this.submitDisabled = false)))
      .subscribe((documentMetadata) => {
        this.store.registerMetadata(this.form, ...documentMetadata);
        const errorResults = documentMetadata.filter((meta) => meta.experiencedErrors);
        if (!isEmpty(errorResults)) {
          this.errorService.showMessage(translate("user.concurrency.title"), translate("user.concurrency.message"));
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: translate("documents.updated.title"),
          detail: translate("documents.updated.message", document),
        });
        this.closeDialog();
      });
  }

  private uploadDocument(documentMetadata: DocumentMetadata): Observable<DocumentMetadata> {
    const uploadedDocument$ = isNil(this.saveDocument)
      ? this.sendDocumentToBackend(documentMetadata.documentForm)
      : this.saveDocument(documentMetadata.documentForm);

    const { requirement } = documentMetadata.documentForm.getRawValue();
    return uploadedDocument$.pipe(
      map((document) => ({ ...documentMetadata, documentForm: this.store.createDocumentForm(document, requirement) })),
      catchError((error: any) =>
        error instanceof ApolloError &&
        error.graphQLErrors.some((error) => error.extensions?.code === ErrorCode.CONCURRENCY_VIOLATION)
          ? of({ ...documentMetadata, experiencedErrors: true })
          : throwError(() => error)
      )
    );
  }

  private sendDocumentToBackend(documentForm: DocumentForm): Observable<Document> {
    const { organizationId, candidateId, writeMode } = documentForm.getRawValue();
    const fileForms = this.getFiles(documentForm);
    const fileUploads$ = fileForms.map((fileForm) => {
      const fileItem = fileForm.getRawValue();
      return this.documentService
        .getUploadUrl(fileItem.blob, organizationId, candidateId)
        .pipe(switchMap(({ url }) => this.fileUploadService.uploadAsObservable(url, fileItem.file)));
    });

    return safeForkJoin(fileUploads$, []).pipe(
      switchMap(() => {
        const document = toCleanDocument(documentForm);
        return document.id
          ? this.documentService.update(document, writeMode, true)
          : this.documentService.create(document, writeMode, true);
      })
    );
  }

  private getValidDocumentMetadata(): Observable<DocumentMetadata[]> {
    const rows = this.form.controls.rows.controls;
    const validRows = rows.filter((row) => {
      const { candidateEntry, documentType, documentSet } = row.getRawValue();
      return candidateEntry && documentType && documentSet;
    });

    if (isEmpty(validRows)) {
      return of([]);
    }

    const documents$ = validRows.map((row) => this.store.getDocumentMetadata(row));
    return safeForkJoin(documents$, []).pipe(map((documents) => documents.filter(({ documentForm }) => documentForm)));
  }

  private getFiles(documentForm: DocumentForm): DocumentFileForm[] {
    return documentForm.controls.documentSets.controls
      .flatMap((set) => set.controls.files.controls)
      .filter((file) => !isNil(file.controls.file.value));
  }

  private logDocumentAiPerformance(): void {
    const entries = this.getAiExtractionPerformanceData();
    this.documentAiService.logAiPerformance(entries);
  }

  private getAiExtractionPerformanceData(): DocumentAiPerformanceInput[] {
    const performanceEntries = this.form.controls.rows.controls
      .map((row) => row.getRawValue())
      .filter((row) => {
        const { candidateEntry, documentType, documentSet } = row;
        return candidateEntry && documentType && documentSet;
      })
      .map((rawValue) => {
        const { candidateEntry, documentType, extraction, familyMember, documentSet, file, fileId, fileType, fileUrl } =
          rawValue;
        return {
          organizationId: this.settings.organizationId,
          fileId: fileId,
          fileType: fileType,
          fileUrl: fileUrl,
          fileName: file.name,
          documentType: documentType,
          documentSetId: documentSet?.id,
          predictedDocumentType: extraction?.data?.documentType,
          candidateId: { id: candidateEntry.id, orgId: candidateEntry.organizationId },
          predictedCandidateId: extraction?.data?.candidateId
            ? { id: extraction.data.candidateId.id, orgId: extraction.data.candidateId.orgId }
            : undefined,
          familyMemberId: familyMember?.id,
          predictedFamilyMemberId: extraction?.data?.familyMemberId,
          predictionFinished: extraction?.done ?? false,
          predictionSuccess: extraction?.success ?? false,
        };
      });
    return performanceEntries;
  }

  private updateLanguageDependentStuff(): void {
    this.staticDataService
      .getStaticData(StaticDataType.AllowedUploadFileTypes, this.processLanguage, this.context)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((x) => x.reduce((result, item) => ({ ...result, [item.value]: item.label }), {}))
      )
      .subscribe((result) => (this.allowedUploadFileTypes = result));
  }
}
