import { RequiredTypeNames } from "@ankaadia/ankaadia-shared";
import { ProcessStateFragment, ProcessTaskFragment } from "@ankaadia/graphql";
import { PossibleTypesMap } from "@apollo/client/core";

export const possibleApolloReturnTypes: PossibleTypesMap = {
  ProcessTask: RequiredTypeNames<ProcessTaskFragment>()([
    "ProcessTaskInformationSendOut",
    "ProcessTaskInviteToPlatform",
    "ProcessTaskReminder",
    "ProcessTaskUpload",
    "ProcessTaskWork",
    "ProcessTaskDocumentCheck",
    "ProcessTaskOrganizationAudit",
    "ProcessTaskRoleSetup",
    "ProcessTaskAutoOperation",
    "ProcessTaskTriggerEvent",
    "ProcessTaskAssignmentException",
    "ProcessTaskEmailSend",
    "ProcessTaskInformationCollection",
    "ProcessTaskUpdateCandidateProfile",
    "ProcessTaskPdfFormGeneration",
    "ProcessTaskOnlineApplication",
    "ProcessTaskEnterMissingInformation",
    "ProcessTaskEnterMissingInformationV2",
    "ProcessTaskDeactivateProcess",
    "ProcessTaskTranslateDocuments",
  ]),
  ProcessState: RequiredTypeNames<ProcessStateFragment>()([
    "ProcessStateInformationSendOut",
    "ProcessStateInviteToPlatform",
    "ProcessStateReminder",
    "ProcessStateUpload",
    "ProcessStateWork",
    "ProcessStateDocumentCheck",
    "ProcessStateOrganizationAudit",
    "ProcessStateRoleSetup",
    "ProcessStateAutoOperation",
    "ProcessStateAssignmentException",
    "ProcessStateEmailSend",
    "ProcessStateInformationCollection",
    "ProcessStateUpdateCandidateProfile",
    "ProcessStatePdfFormGeneration",
    "ProcessStateOnlineApplication",
    "ProcessStateEnterMissingInformation",
    "ProcessStateEnterMissingInformationV2",
    "ProcessStateDeactivateProcess",
    "ProcessStateTranslateDocuments",
  ]),
  DocumentConfigurationBase: ["DocumentConfigurationDE", "DocumentConfigurationAT"],
  ProcessOperationResult: [
    "ProcessOperationCreateProcess",
    "ProcessOperationCreateDelegation",
    "ProcessOperationRemoveFromList",
    "ProcessOperationAddToList",
  ],
  BillingAccountResult: ["BillingAccount", "BillingAccountCandidate"],
};
