export const navitem = {
  link: {
    borderRadius: "8px",
    padding: "11px",
    gap: "9px",
  },
  icon: {
    fontSize: "1.5rem",
  },
  label: {
    fontWeight: "normal",
    hoverFontWeight: "bold",
  },
  colorScheme: {
    light: {
      link: {
        background: "{transparent}",
        hoverBackground: "{highlight.support}",
      },
      icon: {
        color: "{surface.600}",
        hoverColor: "{surface.600}",
      },
      label: {
        color: "{surface.600}",
        hoverColor: "{surface.600}",
      },
    },
  },
};
