<div class="menu-scroll-content">
  <ul class="navigation-menu">
    <li *ngFor="let item of items; let i = index">
      <app-menu-item
        [item]="item"
        [index]="i"
        [root]="true"
        (sidebarClosed)="sidebarClosed.emit($event)"
      ></app-menu-item>
    </li>
  </ul>
</div>
