import { Injectable } from "@angular/core";
import { ENABLE_TALENT_POOL, ensure2LetterIsoLanguage, UserPermission } from "@ankaadia/ankaadia-shared";
import { translate, TranslocoService } from "@jsverse/transloco";
import { compact, sum } from "lodash";
import { PrimeIcons } from "primeng/api";
import { combineLatest, map, Observable, OperatorFunction, Subject } from "rxjs";
import { TaskInboxService } from "../../features/process/task-inbox/task-inbox.service";
import { OrganizationFactoryService } from "../../organization-specific/organization-specific-factory.service";
import { SettingsService } from "../../shared/services/settings.service";
import { CustomMenuItem, IndexedMenuItem } from "./menu.model";

enum MenuSection {
  Inbox = "Inbox",
  TalentPool = "TalentPool",
  Tasks = "Tasks",
  Profile = "Profile",
  EmployerSearch = "EmployerSearch",
  Documents = "Documents",
  Candidates = "Candidates",
  Processes = "Processes",
  Education = "Education",
  Templates = "Templates",
  Settings = "Settings",
  Notifications = "Notifications",
}

@Injectable({ providedIn: "root" })
export class MenuService {
  private readonly totalInboxCount$ = this.getTotalInboxCount();
  private readonly taskInboxCount$ = this.getTaskInboxCount();

  private readonly MenuSectionMap: Record<MenuSection, CustomMenuItem[]> = {
    [MenuSection.Inbox]: [],
    [MenuSection.Tasks]: this.settings.menu?.tasks?.enabled
      ? [
          {
            label: translate("tasks.title"),
            icon: PrimeIcons.BRIEFCASE,
            routerLink: ["processes/tasks"],
            routerLinkActiveOptions: { exact: true },
            permissions: [UserPermission.Candidate],
            badge: this.taskInboxCount$,
          },
        ]
      : [],
    [MenuSection.Profile]: this.settings.menu?.profile?.enabled
      ? [
          {
            label: translate("candidate.profile"),
            icon: PrimeIcons.PENCIL,
            routerLink: ["profile"],
            routerLinkActiveOptions: { exact: true },
            permissions: [UserPermission.Candidate],
            testId: "menu-item-profile",
          },
        ]
      : [],
    [MenuSection.EmployerSearch]: this.settings.menu?.employerSearch?.enabled
      ? [
          {
            label: translate("employerSearch.title"),
            icon: PrimeIcons.LINK,
            routerLink: ["employers"],
            routerLinkActiveOptions: { exact: false },
            permissions: [UserPermission.Candidate],
          },
        ]
      : [],
    [MenuSection.Documents]: [
      {
        label: translate("attachments.title"),
        icon: PrimeIcons.FILE,
        routerLink: ["documents"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Candidate],
      },
    ],
    [MenuSection.Candidates]: [
      {
        label: translate("candidates.edit"),
        icon: PrimeIcons.PENCIL,
        routerLink: ["candidates/edit"],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.User],
        testId: "menu-item-candidates",
      },
      this.licensed({
        label: translate("collections.title"),
        icon: PrimeIcons.LIST,
        routerLink: ["collections/edit"],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.User],
        testId: "menu-item-collections",
      }),
      this.licensed({
        label: translate("candidates.events"),
        icon: PrimeIcons.CALENDAR,
        routerLink: ["candidates/events"],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.User],
        testId: "menu-item-candidate-events",
      }),
      {
        label: translate("candidates.view"),
        icon: PrimeIcons.ID_CARD,
        routerLink: ["candidates/view"],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.User],
        testId: "menu-item-candidate-view",
      },
    ],
    [MenuSection.Processes]: [
      this.licensed({
        label: translate("processes.edit"),
        icon: PrimeIcons.BRIEFCASE,
        routerLink: ["processes/edit"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.User],
      }),
      {
        label: translate("processes.view"),
        icon: PrimeIcons.TH_LARGE,
        routerLink: ["processes/view"],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.User],
      },
      this.licensed({
        label: translate("processModels.title"),
        icon: PrimeIcons.LIST,
        routerLink: [`process-models/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.ProcessAdministrator],
      }),
      this.licensed({
        label: translate("autoCollectionTemplate.title"),
        icon: PrimeIcons.BARS,
        routerLink: [`collections/collection-auto-filter-templates/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.ProcessAdministrator],
      }),
      ...(this.settings.isProcessActionEnabled || this.settings.isMasterOrganization
        ? [
            this.licensed({
              label: translate("processAction.title"),
              icon: PrimeIcons.BOLT,
              routerLink: [`process-actions/${this.settings.organizationId}`],
              routerLinkActiveOptions: { exact: false },
              permissions: [UserPermission.ProcessAdministrator],
            }),
          ]
        : []),
      this.licensed({
        label: translate("emailSendOutRule.title"),
        icon: PrimeIcons.ENVELOPE,
        routerLink: [`email-send-out-rules/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.ProcessAdministrator],
      }),
    ],
    [MenuSection.Templates]: [
      this.licensed({
        label: translate("documents.title"),
        icon: PrimeIcons.FILE,
        routerLink: ["documents/general"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.ContentAdministrator],
        tooltip: translate("documents.menuTooltip"),
      }),
      this.licensed({
        label: translate("documentTemplates.title"),
        icon: PrimeIcons.FOLDER,
        routerLink: ["documents/templates"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.ContentAdministrator],
        tooltip: translate("documentTemplates.menuTooltip"),
      }),
      this.licensed({
        label: translate("formTemplates.title"),
        icon: PrimeIcons.FILE_PDF,
        routerLink: ["documents/forms"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.ContentAdministrator],
        tooltip: translate("formTemplates.menuTooltip"),
      }),
      this.licensed({
        label: translate("requiredDocument.menuTitle"),
        icon: PrimeIcons.FILE,
        routerLink: [`documents/required/${this.settings.organizationId}`],
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("emailTemplates.title"),
        icon: PrimeIcons.ENVELOPE,
        routerLink: ["documents/emails"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.ContentAdministrator],
      }),
      this.licensed({
        label: translate("customDocumentTypes.title"),
        icon: PrimeIcons.FILE_EDIT,
        routerLink: [`documents/custom-types`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.ContentAdministrator],
      }),
    ],
    [MenuSection.Education]: [
      this.licensed({
        label: translate("educationModules.title"),
        icon: PrimeIcons.LIST,
        routerLink: ["education/modules"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator, UserPermission.CourseAdministrator, UserPermission.CourseTeacher],
      }),
      {
        label: translate("educationCourses.title"),
        icon: PrimeIcons.TICKET,
        routerLink: ["education/courses"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator, UserPermission.CourseAdministrator, UserPermission.CourseTeacher],
      },
      {
        label: translate("educationExams.title"),
        icon: PrimeIcons.CALENDAR,
        routerLink: ["education/exams"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator, UserPermission.CourseAdministrator, UserPermission.CourseTeacher],
      },
      {
        label: translate("educationExamResults.title"),
        icon: PrimeIcons.CALCULATOR,
        routerLink: ["education/exam-results/overview"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator, UserPermission.CourseAdministrator, UserPermission.CourseTeacher],
      },
      {
        label: translate("educationManagement.title"),
        icon: PrimeIcons.BOOK,
        routerLink: ["education"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Candidate],
      },
    ],
    [MenuSection.Settings]: [
      {
        label: translate("organization.my"),
        icon: PrimeIcons.MAP_MARKER,
        routerLink: ["settings/organizations/my"],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      },
      this.licensed(
        {
          label: translate("professions.my"),
          icon: PrimeIcons.ID_CARD,
          routerLink: [`settings/professions/my`],
          routerLinkActiveOptions: { exact: true },
          permissions: [UserPermission.Administrator],
        },
        "professions"
      ),
      this.licensed({
        label: translate("experiences.title"),
        icon: PrimeIcons.GRADUATION_CAP,
        routerLink: [`settings/professional-fields/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.Administrator],
      }),
      {
        label: translate("organizations.title"),
        icon: PrimeIcons.TH_LARGE,
        routerLink: [`settings/organizations/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.Administrator, UserPermission.PartnerAdministrator, UserPermission.CrmContributor],
      },
      {
        label: translate("users.title"),
        icon: PrimeIcons.USER,
        routerLink: [`settings/users/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: false },
        permissions: [UserPermission.Administrator],
      },
      {
        label: translate("userGroups.title"),
        icon: PrimeIcons.USERS,
        routerLink: [`settings/user-groups/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      },
      this.licensed({
        label: translate("consent.title"),
        icon: PrimeIcons.EXCLAMATION_CIRCLE,
        routerLink: [`settings/consent/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed(
        {
          label: translate("auditDefinitions.title"),
          icon: PrimeIcons.SHIELD,
          routerLink: [`settings/audit-definitions/${this.settings.organizationId}`],
          routerLinkActiveOptions: { exact: false },
          permissions: [UserPermission.Administrator],
        },
        "gueteSiegel"
      ),
      this.licensed({
        label: translate("documentRequirements.title"),
        icon: PrimeIcons.FILE_EDIT,
        routerLink: [`settings/document-requirements/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("vaccinationConfig.title"),
        icon: PrimeIcons.HEART,
        routerLink: [`settings/vaccination-config/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("profileAccess.title"),
        icon: PrimeIcons.USER_EDIT,
        routerLink: [`settings/candidate-profile-config/${this.settings.organizationId}/candidate`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("localPartnerProfileAccess.title"),
        icon: PrimeIcons.USER_EDIT,
        routerLink: [`settings/local-partner-profile-config/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("systemSettings.title"),
        icon: PrimeIcons.COG,
        routerLink: [`settings/organizations/system-settings/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator],
      }),
      this.licensed({
        label: translate("sharingPresets.title"),
        icon: PrimeIcons.OBJECTS_COLUMN,
        routerLink: [`settings/sharing-presets/${this.settings.organizationId}`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.Administrator, UserPermission.PartnerAdministrator],
      }),
      ...(this.settings.isMasterUser && this.settings.isMasterOrganization
        ? [
            {
              label: translate("ankaadiaTools.title"),
              icon: PrimeIcons.WRENCH,
              routerLink: [`settings/ankaadia-tools/${this.settings.organizationId}`],
              routerLinkActiveOptions: { exact: true },
              permissions: [UserPermission.Administrator],
            },
          ]
        : []),
    ],
    [MenuSection.Notifications]: [
      {
        label: translate("dashboard.title"),
        icon: PrimeIcons.HOME,
        routerLink: [`dashboard`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.User],
      },
      {
        label: translate("activityLog.title"),
        icon: PrimeIcons.INFO_CIRCLE,
        routerLink: [`activity`],
        routerLinkActiveOptions: { exact: true },
        permissions: [UserPermission.User],
      },
    ],
    [MenuSection.TalentPool]: [
      this.licensed(
        {
          label: translate("talentPools.view"),
          icon: PrimeIcons.USERS,
          routerLink: [`talent-pools/view`],
          routerLinkActiveOptions: { exact: true },
          permissions: [UserPermission.User],
        },
        "talentPool"
      ),
      this.licensed(
        {
          label: translate("talentPools.edit"),
          icon: PrimeIcons.ID_CARD,
          routerLink: ["talent-pools/edit"],
          routerLinkActiveOptions: { exact: true },
          permissions: [UserPermission.PartnerAdministrator],
        },
        "talentPool"
      ),
    ],
  };

  private readonly SectionData: Record<MenuSection, CustomMenuItem> = {
    [MenuSection.Candidates]: { label: translate("candidates.title"), icon: PrimeIcons.ID_CARD },
    [MenuSection.Processes]: { label: translate("processes.title"), icon: PrimeIcons.BRIEFCASE },
    [MenuSection.Education]: { label: translate("educationManagement.title"), icon: PrimeIcons.BOOK },
    [MenuSection.Templates]: { label: translate("templates.menuTitle"), icon: PrimeIcons.FOLDER_OPEN },
    [MenuSection.Settings]: { label: translate("settings.title"), icon: PrimeIcons.COG },
    [MenuSection.Notifications]: { label: translate("notifications.title"), icon: PrimeIcons.BELL },
    [MenuSection.Tasks]: { label: translate("tasks.title"), icon: PrimeIcons.BRIEFCASE, badge: this.taskInboxCount$ },
    [MenuSection.Profile]: { label: translate("candidate.profile"), icon: PrimeIcons.PENCIL },
    [MenuSection.EmployerSearch]: { label: translate("employerSearch.title"), icon: PrimeIcons.LINK },
    [MenuSection.Documents]: { label: translate("attachments.title"), icon: PrimeIcons.FILE },
    [MenuSection.TalentPool]: { label: translate("talentPool.title"), icon: PrimeIcons.USERS },
    [MenuSection.Inbox]: {
      label: translate("inbox.title"),
      icon: PrimeIcons.INBOX,
      routerLink: ["inbox"],
      permissions: [UserPermission.User],
      badge: this.totalInboxCount$,
    },
  };

  private readonly menuSource = new Subject<string>();
  private readonly resetSource = new Subject();

  helpUrl: string;
  menuSource$ = this.menuSource.asObservable();
  resetSource$ = this.resetSource.asObservable();

  constructor(
    private readonly settings: SettingsService,
    private readonly orgFactory: OrganizationFactoryService,
    private readonly taskInboxService: TaskInboxService,
    private readonly languageService: TranslocoService
  ) {
    this.orgFactory
      .getOrganizationSpecifics(this.settings.organizationId)
      .subscribe((x) => (this.helpUrl = this.getLocalizedUrl(x.getHelpUrl())));
  }

  onMenuStateChange(key: string): void {
    this.menuSource.next(key);
  }

  reset(): void {
    this.resetSource.next(undefined);
  }

  getSections(): IndexedMenuItem[] {
    return Object.values(MenuSection)
      .filter((x) => ENABLE_TALENT_POOL || x !== MenuSection.TalentPool)
      .map((section, i) => ({
        index: i,
        label: this.SectionData[section].label,
        icon: this.SectionData[section].icon,
        badge: this.SectionData[section].badge,
        items: this.filter(this.MenuSectionMap[section]),
        routerLink: this.SectionData[section].routerLink,
        routerLinkActiveOptions: this.SectionData[section].routerLinkActiveOptions,
        permissions: this.SectionData[section].permissions,
        name: section.toLowerCase(),
      }))
      .filter((x) => this.settings.hasAnyPermission(x.permissions));
  }

  private licensed<T extends CustomMenuItem>(
    item: T,
    module?: Exclude<keyof typeof this.settings.additionalModules, "__typename">
  ): T {
    return this.settings.isLicensed && (!module || this.settings.additionalModules?.[module]) ? item : null;
  }

  private filter<T extends CustomMenuItem>(items: T[]): T[] {
    return items.filter((x) => x).filter((x) => this.settings.hasAnyPermission(x.permissions));
  }

  private getLocalizedUrl(url: string): string {
    const defaultUrl = `${url}/en/home`;
    const lang = ensure2LetterIsoLanguage(this.languageService.getActiveLang())?.toLowerCase();
    const isCandidate = this.settings.isCandidate;

    if (!lang) return defaultUrl;

    if (isCandidate || lang === "de") {
      return `${url}/${lang}/home`;
    }

    return defaultUrl;
  }

  private getTotalInboxCount(): Observable<string> {
    return combineLatest([this.taskInboxService.counter]).pipe(
      map((counts) => sum(compact(counts))),
      this.asBadgeCounter()
    );
  }

  private getTaskInboxCount(): Observable<string> {
    return this.taskInboxService.counter.pipe(this.asBadgeCounter());
  }

  private asBadgeCounter(): OperatorFunction<number, string> {
    return (source) =>
      source.pipe(
        map((count) => {
          if (count === 0) return null;
          if (count > 10) return "10+";
          return count.toString();
        })
      );
  }
}
