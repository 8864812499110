<div class="h-full flex flex-col gap-[24px]">
  <div>
    <a class="inline-block relative pb-[24px]" routerLink="/app">
      <ui-logo src="../../../assets/layout/images/logo.png" size="small" [borderless]="true" />

      @if (organizationId | organizationLogo | async; as logo) {
        <ui-logo class="absolute top-[24px]" [src]="logo" size="small" />
      }
    </a>
  </div>

  <div>
    <ui-sidenav
      [items]="[
        { label: 'My organization', icon: 'pi pi-users', routerLink: '/app/settings' },
        { label: 'Users', icon: 'pi pi-book', routerLink: '/app/settings/users/' + organizationId },
        { label: 'Education', icon: 'pi pi-graduation-cap', routerLink: '/app/education/modules' },
        { label: 'Documents', icon: 'pi pi-file', routerLink: '/app/documents/general' },
      ]"
    />
  </div>

  <div class="mt-auto">
    <ui-sidenav
      [items]="[
        { label: 'Notifications', icon: 'pi pi-bell', routerLink: '/app/activity' },
        { label: 'Setings', icon: 'pi pi-cog', routerLink: '/app/settings/organizations/' + organizationId },
        { label: 'Help', icon: 'pi pi-globe', visible: !!(helpUrl$ | async), url: (helpUrl$ | async) },
        {
          label: userDisplayName,
          icon: 'pi pi-user',
          routerLink: '/app/settings/organizations/system-settings/' + organizationId,
        },
      ]"
    />
  </div>
</div>
