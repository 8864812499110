import { Injectable } from "@angular/core";
import { SelectedDocumentField } from "@ankaadia/ankaadia-shared";
import { Document } from "@ankaadia/graphql";
import { Subject } from "rxjs";
import { AdditionalForeignKeySourceData } from "../../features/candidate-document-metadata/candidate-document-foreign-key-handler";
import { AnyCandidateForm } from "../../features/candidates/candidate-form/candidate-form.model";

export interface DocumentDialogEvent {
  readonly document: Document;
  readonly selectedField?: SelectedDocumentField;
  readonly candidateForm?: AnyCandidateForm;
  readonly additionalForeignKeySourceData?: AdditionalForeignKeySourceData;
}

@Injectable({ providedIn: "root" })
export class CandidateProfileDialogService {
  documentDialog = new Subject<DocumentDialogEvent>();
}
