import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from "@angular/forms";
import { getLocaleDateString } from "@ankaadia/ankaadia-shared";
import { TranslocoService } from "@jsverse/transloco";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { PrimeTemplate } from "primeng-v17/api";
import { Calendar, CalendarModule } from "primeng-v17/calendar";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { CalendarOptionsDirective } from "../../shared/primeng/calendar-options/calendar-options.directive";
import { CalendarReadonlyDirective } from "../../shared/primeng/calendar-readonly/calendar-readonly.directive";
import { CalendarUtcFixDirective } from "../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

function validateRange(control: FormControl): ValidationErrors | null {
  return control.value?.length == 2 && control.value.every((x) => x) ? null : { range: true };
}
@Component({
  selector: "app-formly-datepicker",
  templateUrl: "./formly-datepicker.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CalendarModule,
    AppendToBodyDirective,
    CalendarOptionsDirective,
    CalendarUtcFixDirective,
    CalendarReadonlyDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
    NgIf,
    PrimeTemplate,
  ],
})
export class FormlyDatepickerComponent extends FieldType implements OnInit {
  readonly language = this.transloco.getActiveLang();

  @ViewChild(Calendar)
  calendar: Calendar;

  format: string;
  placeholder: string;
  protected currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(private readonly transloco: TranslocoService) {
    super();
  }

  ngOnInit(): void {
    this.format = getLocaleDateString(this.language, this.props.timeOnly ? "hour" : this.props.view);
    this.placeholder = this.props.timeOnly
      ? "hh:mm"
      : this.props.view == null || this.props.view == "date"
        ? getLocaleDateString(this.language, "date_Placeholder")
        : this.format;
    if (this.props.required && this.props.selectionMode == "range") this.formControl.addValidators(validateRange);
  }

  reset(event: Event): void {
    this.calendar.onClearButtonClick(event);
  }
}
