import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { NgxSpinnerComponent } from "ngx-spinner";

@Component({
  selector: "new-ui-app",
  templateUrl: "./new-ui-app.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgxSpinnerComponent, RouterOutlet],
})
export class NewUiAppComponent {
  // if the service is not injected, the whole authentication shitshow
  // is not happening, essentially bricking the whole application axaxa
  protected readonly uebanstvo = inject(AuthService);
}
