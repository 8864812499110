import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormElementMapModule } from "../from-element-map/form-element-map.module";
import { PrimeNGModule } from "../primeng/primeng.module";
import { TranslocoModule } from "../transloco/transloco.module";
import { DateTimeComponent } from "./date-time.component";

@NgModule({
  imports: [CommonModule, PrimeNGModule, TranslocoModule, ReactiveFormsModule, FormElementMapModule],
  declarations: [DateTimeComponent],
  exports: [DateTimeComponent],
})
export class DateTimeModule {}
