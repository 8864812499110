import { Type } from "@angular/core";
import { KnownFormToken } from "@ankaadia/ankaadia-shared";
import {
  Candidate,
  ProcessTaskInformationCollection,
  StaticDataModel,
  TabField,
  WorkExperiencePrecissionEnum,
} from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable } from "rxjs";
import { CandidateFilterGroup, StaticDataCountry } from "../features/candidate-filter/candidate-filter.model";
import { CandidateCardComponent } from "../features/candidates/candidate-card-component.model";
import { CandidateProfileComponent } from "../features/candidates/candidate-profile-component.model";
import { CandidateProfileFieldGroup } from "../features/candidates/candidate-profile-field-select/candidate-profile-field-configuration.model";
import { CandidateProfile } from "../features/candidates/candidates.service";

export interface CandidateProfileField {
  key: string;
  label: string;
}

export interface CandidateProfileTab {
  id: string;
  key?: string;
  label: string;
  icon: string;
  fields: (CandidateProfileField | FormlyFieldConfig)[];
  tabFields?: TabField[];
}

export type SupportedTaskTypes =
  | "ProcessTaskAutoOperationModel"
  | "ProcessTaskTriggerEventModel"
  | "ProcessTaskAssignmentExceptionModel"
  | "ProcessTaskDeactivateProcessModel"
  | "ProcessTaskDocumentCheckModel"
  | "ProcessTaskEnterMissingInformationModel"
  | "ProcessTaskInformationCollectionModel"
  | "ProcessTaskInformationSendOutModel"
  | "ProcessTaskInviteToPlatformModel"
  | "ProcessTaskPdfFormGenerationModel"
  | "ProcessTaskUpdateCandidateProfileModel"
  | "ProcessTaskUploadModel"
  | "ProcessTaskEmailSendModel"
  | "ProcessTaskWorkModel"
  | "ProcessTaskReminderModel"
  | "ProcessTaskRoleSetupModel"
  | "ProcessTaskOrganizationAuditModel"
  | "ProcessTaskEnterMissingInformationV2Model";

export interface OrganizationSpecific {
  getCandidateProfileComponent: () => Type<CandidateProfileComponent>;
  getCandidateProfileFormlyFields: (
    requiredFields?: string[],
    workExperiencePrecission?: WorkExperiencePrecissionEnum,
    educationPrecission?: WorkExperiencePrecissionEnum
  ) => FormlyFieldConfig[];
  getCandidateProfileCustomFields: (
    requiredFields?: string[],
    workExperiencePrecission?: WorkExperiencePrecissionEnum,
    educationPrecission?: WorkExperiencePrecissionEnum
  ) => FormlyFieldConfig[];
  getCandidateCardViewComponent: () => Type<CandidateCardComponent>;
  getHelpUrl: () => string;
  getInterviewLanguageLevels: (language: string) => Observable<StaticDataModel[]>;
  getTransferLanguageLevels: (language: string) => Observable<StaticDataModel[]>;
  getCandidateProfileTabs: (includeEmail?: boolean) => CandidateProfileTab[];
  loadProfile: (profile: CandidateProfile, tabs: CandidateProfileTab[]) => void;
  saveProfile: (profile: CandidateProfile, tabs: CandidateProfileTab[]) => void;
  getFormlyConfiguration: (
    taskOrFormToken: ProcessTaskInformationCollection | KnownFormToken,
    language: string
  ) => Observable<FormlyFieldConfig[]>;
  getCustomCandidateFilters: (countries: StaticDataCountry[]) => Observable<CandidateFilterGroup[]>;
  getCustomCandidateFields: () => Observable<CandidateProfileFieldGroup[]>;
  isSecondFeedbackPhaseEnabled: () => boolean;
  newCandidate: () => Partial<Candidate>;
  getCandidateImporterTemplateUrl: () => string;
  getCandidateProfileCustomFieldsTabName: () => string;
  getCandidateEmailAddress: (candidate: Candidate) => string;
  getBirthdate: (candidate: Partial<Candidate>) => Date;
  getSupportedTaskTypes: () => SupportedTaskTypes[];
  showCandidateFunctionFilter: () => boolean;
}
