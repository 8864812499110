import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { BaseComponent } from "primeng/basecomponent";
import { LogoStyle } from "./logo.style";

@Component({
  selector: "ui-logo",
  templateUrl: "./logo.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-logo",
    "[class.ui-logo-small]": "size() === 'small'",
    "[class.ui-logo-normal]": "size() === 'normal'",
    "[class.ui-logo-large]": "size() === 'large'",
    "[class.ui-logo-borderless]": "borderless()",
  },
})
export class LogoComponent extends BaseComponent {
  protected readonly _componentStyle = inject(LogoStyle);

  readonly src = input.required<string>();
  readonly borderless = input<boolean>();
  readonly size = input<"small" | "normal" | "large">("normal");
}
