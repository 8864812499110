import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { DropdownModule } from "primeng-v17/dropdown";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-dropdown",
  templateUrl: "./formly-dropdown.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    FormlyModule,
    AsyncPipe,
    FormlySelectModule,
  ],
})
export class FormlyDropdownComponent extends FieldType {
  override defaultOptions = {
    props: { options: [] },
  };
}
