<div class="footer" *appTranslate="let t">
  <div class="footer-inner">
    <div *ngIf="isLicensed" class="logo-wrap logo-wrap-left">
      <img class="logo" [src]="organizationId | organizationLogo | async" default="/assets/blank.jpeg" />
    </div>

    <ng-container *ngIf="!isLicensed">
      <div *ngIf="creatorOrganizationId" class="logo-wrap logo-wrap-left">
        <img class="logo" [src]="creatorOrganizationId | organizationLogo | async" default="/assets/blank.jpeg" />
      </div>

      <div class="logo-wrap logo-wrap-right">
        <img class="logo" [src]="organizationId | organizationLogo | async" default="/assets/blank.jpeg" />
      </div>
    </ng-container>
  </div>
</div>
