import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ExperienceType,
  ICandidateOSModel,
  ILanguageSkillModel,
  MedHealthCareCoreProfessions,
  WorkExperienceDuration,
  getDefaultSet,
  getFiles,
  isSupportedByDocumentPreview,
} from "@ankaadia/ankaadia-shared";
import {
  AnonymizationTypeEnum,
  AutoCvResponse,
  AutoCvResponseStatus,
  CandidateForViewFragment,
  Document,
  DocumentFile,
  DocumentMode,
  DocumentTypeDataFragment,
  RecognitionState,
  Sharing,
  StaticDataType,
  UserSalutation,
} from "@ankaadia/graphql";
import { TranslocoService, translate } from "@jsverse/transloco";
import { clone, isEmpty, trim } from "lodash";
import { MenuItem, PrimeIcons } from "primeng/api";
import { Observable, Subscription, catchError, finalize, map, of, retry } from "rxjs";
import { CandidateCardComponent, FilePreview } from "../../../features/candidates/candidate-card-component.model";
import { CandidateCardService } from "../../../features/candidates/candidate-card/candidate-card.service";
import { CandidatesService } from "../../../features/candidates/candidates.service";
import { DocumentsService } from "../../../features/documents/documents.service";
import { MessageService } from "../../../features/message/message.service";
import { OrganizationsService } from "../../../features/organizations/organizations.service";
import { OrganizationSystemSettingsService } from "../../../features/organizations/system-settings.service";
import { MigrationSpecificsFactory } from "../../../migration-specific/migration-specifics.factory";
import { DownloadService } from "../../../shared/services/download.service";
import { SettingsService } from "../../../shared/services/settings.service";
import { StaticDataRequest, StaticDataService } from "../../../shared/static-data/static-data.service";
import { DefaultCardViewFormatterService } from "./default-card-view-formatter.service";

@Component({
  selector: "app-default-card-view-component",
  templateUrl: "./default-card-view-component.component.html",
  styleUrl: "./default-card-view-component.component.scss",
})
export class DefaultCardViewComponent implements CandidateCardComponent, AfterViewInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  readonly Array = Array;
  readonly StaticDataType = StaticDataType;
  readonly language = this.transloco.getActiveLang();
  readonly MedHealthProfessions = MedHealthCareCoreProfessions;
  readonly ExperienceType = ExperienceType;
  readonly leftColumnResizeObserver = new ResizeObserver((entries) => {
    const includesLeftColumn = entries.some((x) => x.target === this.leftColumn?.nativeElement);
    if (includesLeftColumn) {
      this.leftColumnOffsetHeight = this.leftColumn?.nativeElement?.offsetHeight;
      this.changeDetector.detectChanges();
    }
  });

  constructor(
    private readonly documentService: DocumentsService,
    private readonly transloco: TranslocoService,
    private readonly downloadService: DownloadService,
    private readonly candidateCardService: CandidateCardService,
    private readonly candidateService: CandidatesService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly messageService: MessageService,
    private readonly http: HttpClient,
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService,
    private readonly orgService: OrganizationsService,
    private readonly systemSettingsService: OrganizationSystemSettingsService,
    private readonly migrationSpecificsFactory: MigrationSpecificsFactory,
    private readonly cardViewFormatterService: DefaultCardViewFormatterService
    // private readonly currentCollectionService: CurrentCollectionService
  ) {}

  fileMenu: MenuItem[];
  _data: any;
  _files: Document[];
  _sharing: Sharing;
  languages: (Partial<Omit<ILanguageSkillModel, "id">> & { skillLevelName?: string })[];
  experiences: any[];
  workExperience: any[];
  experiencetoShow: any[] = [];
  candidateGender: string;
  candidateAge: number;
  candidateBirthday: Date;
  furtherEducations: string[];
  preferredCareFacility: string[];
  preferredCareFacilityFlexibility: boolean;
  preferredWorkingField: string[];
  preferredWorkingFieldFlexibility: boolean;
  preferredLocationState: string[];
  preferredLocationStateFlexibility: boolean;
  preferredCity: string[];
  preferredCityFlexibility: boolean;
  preferredCandidates = "";
  preferredPathOfRecognition = "";
  preferredLevelOfInterview = "";
  preferredLevelOfTransfer = "";
  preferredStartDate = null;
  preferredEmploymentType = null;
  preferredTemporalScope = null;
  preferredFamilyReunion = null;
  preferredTypeOfImmigration = null;
  previewOpenDisabled: boolean;
  isNew = true;
  totalExperience: string;
  sharedOrganizationName: string;
  staticDataRequest: StaticDataRequest;
  recognitionState: RecognitionState;
  showQualificationHours: boolean;
  showExams: boolean;
  theoryHours: number;
  practiceHours: number;
  careSituations: number;
  isAnonymousPicture = false;

  @Input()
  selectedCollectionId: string;

  @Input()
  selectedOrganizationId: string;

  @Input()
  get files(): Document[] {
    return this._files;
  }

  set files(files: Document[]) {
    this._files = files;
    this.populateFileMenu();
  }

  @Input()
  get sharing(): Sharing {
    return this._sharing;
  }

  set sharing(sharing: Sharing) {
    this._sharing = sharing;
    this.isAnonymousPicture = sharing?.anonymizationOptions?.includes(AnonymizationTypeEnum.Picture);
    this.getSharedOrganizationName();
  }

  @Input()
  isPresentation: boolean;

  leftColumnOffsetHeight: number | undefined | null = null;

  @Input() get data(): Omit<CandidateForViewFragment, "os"> & { os: { profile: ICandidateOSModel } } {
    return this._data;
  }

  set data(val: typeof this.data) {
    this._data = val;
    if (this._data?.os?.profile != null) {
      this.languages = val.calculatedProperties?.languageSkills ?? [];
      this.experiences = val?.os?.profile.experiences;
      this.furtherEducations = (val?.os?.profile.furtherEducations ?? [])
        .map((x) => x.fieldOfEducation)
        .filter((x) => x);
      this.preferredCareFacility = val?.os?.profile.preferredCareFacility;
      this.preferredCareFacilityFlexibility = val?.os?.profile.preferredCareFacilityFlexibility;
      this.preferredWorkingField = val?.os?.profile.preferredWorkingField;
      this.preferredWorkingFieldFlexibility = val?.os?.profile.preferredWorkingFieldFlexibility;
      this.preferredLocationState = val?.os?.profile.preferredLocationState;
      this.preferredLocationStateFlexibility = val?.os?.profile.preferredLocationStateFlexibility;
      this.preferredCity = val?.os?.profile.preferredCity;
      this.preferredCityFlexibility = val?.os?.profile.preferredCityFlexibility;
      this.preferredCandidates = val?.os?.profile.preferredCandidates;
      this.preferredPathOfRecognition = val?.os?.profile.preferredPathOfRecognition;
      this.preferredLevelOfInterview = val?.os?.profile.preferredLanguageLevelForInterview;
      this.preferredLevelOfTransfer = val?.os?.profile.preferredLanguageLevelForTransfer;
      this.preferredStartDate = val?.os?.profile.preferredStartDate;
      this.preferredEmploymentType = val?.os?.profile.preferredEmploymentType;
      this.preferredTemporalScope = val?.os?.profile.preferredTemporalScope;
      this.preferredFamilyReunion = val?.os?.profile.preferredFamilyReunion;
      this.preferredTypeOfImmigration = val?.os?.profile.preferredTypeOfImmigration;

      this.totalExperience = this.cardViewFormatterService.getDurationString(
        WorkExperienceDuration.fromDuration(0, val?.os?.profile.totalExperienceInMonth ?? 0.0).asMonthAndYears(),
        "long"
      );

      if (val?.os?.profile.salutation != undefined) {
        if (val.os.profile.salutation === UserSalutation.Mr) {
          this.candidateGender = translate("gender.male");
        } else if (val.os.profile.salutation === UserSalutation.Mrs) {
          this.candidateGender = translate("gender.female");
        } else {
          this.candidateGender = translate("common.noDataAvailable");
        }
      }

      if (val?.os?.profile.birthdate != null) {
        this.calculateAge(val.os.profile.birthdate);
      } else {
        this.candidateAge = null;
      }

      this.calculateExperienceToShow(val);

      this.setRecognitionState(val);
    }
    this.populateFileMenu();
    this.staticDataRequest = { candidateId: val.id, organizationId: val.organizationId };
  }

  // private get selectedSharingId(): string {
  //   return this.isPresentation && this.settings.organizationId !== this.candidate.organizationId
  //     ? this.sharing?.id
  //     : null;
  // }

  private get hasSharedCV(): boolean {
    return this.settings.isLicensed && this.sharing && this.settings.organizationId !== this.sharing?.organizationId;
  }

  @Input()
  tag: string;

  @Output()
  readonly filePreview = new EventEmitter<FilePreview>();

  @ViewChild("image")
  imageRef: ElementRef<HTMLImageElement>;

  @ViewChild("leftColumn")
  leftColumn: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.candidateCardService.imageChanged.subscribe(() => {
        const temp = this.imageRef.nativeElement.src;
        const blank = this.imageRef.nativeElement.attributes.getNamedItem("default")?.value;
        if (!temp.endsWith(blank)) {
          this.imageRef.nativeElement.src = "";
          this.imageRef.nativeElement.click();
          this.changeDetector.detectChanges();
          this.imageRef.nativeElement.src = temp;
          this.imageRef.nativeElement.click();
          this.changeDetector.detectChanges();
        } else {
          this.candidateService
            .getThumbnailDownloadUrl(this.candidate.organizationId, this.candidate.id)
            .subscribe((url) => {
              this.imageRef.nativeElement.src = url;
              this.imageRef.nativeElement.click();
              this.changeDetector.detectChanges();
            });
        }
      })
    );
    this.leftColumnResizeObserver.observe(this.leftColumn.nativeElement);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  calculateAge(dateOfBirth: Date): void {
    this.candidateAge = 0;
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    this.candidateAge = age;
    this.candidateBirthday = birthDate;
  }

  get candidate(): typeof this.data {
    return this.data;
  }

  private populateFileMenu(): void {
    this.staticDataService
      .getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, this.staticDataRequest)
      .subscribe((data) => {
        this.fileMenu = (this.files ?? []).map((document) => {
          const files = getFiles(document);
          return {
            label: data.find((entry) => entry.value == document.type)?.label,
            command:
              files.length == 1
                ? (): void => {
                    if (this.canPreview(files[0])) {
                      this.openPreview(files[0], document);
                    } else {
                      this.downloadFile(files[0]);
                    }
                  }
                : undefined,
            icon: files.length == 1 ? PrimeIcons.FILE : undefined,
            items:
              files.length > 1
                ? files.map((file) => ({
                    file: file,
                    icon: PrimeIcons.FILE,
                    label: file.name,
                    command: (): void => {
                      if (this.canPreview(file)) {
                        this.openPreview(file, document);
                      } else {
                        this.downloadFile(file);
                      }
                    },
                  }))
                : undefined,
          };
        });
        this.addAutoGeneratedCV();
      });
  }

  private addAutoGeneratedCV(): void {
    if (this.sharing?.autoCVRequested || (this.sharing == null && this.settings.autoCVConfigured)) {
      const items: MenuItem[] = [
        this.openFor(this.settings.organizationId, this.settings.organizationName),
        ...(this.hasSharedCV && !this.isPresentation
          ? [this.openFor(this.sharing.organizationId, this.sharedOrganizationName)]
          : []),
      ];

      this.fileMenu = [...items, ...this.fileMenu];
    }
  }

  canPreview(file: DocumentFile): boolean {
    return !file || isSupportedByDocumentPreview(file.name, file.type);
  }

  downloadFile(file: DocumentFile): void {
    this.documentService
      .getDownloadUrl(file.blob, this.candidate.organizationId, this.candidate.id)
      .subscribe((url) => this.downloadService.downloadFile(file.name, url));
  }

  openAutoCVPreview(templateOrgId: string): void {
    this.candidateCardService
      .getAutoGeneratedCvUrl(
        this.candidate.id,
        this.candidate.organizationId,
        this._sharing?.organizationId,
        this.language,
        this._sharing?.id,
        templateOrgId,
        this.selectedOrganizationId,
        this.selectedCollectionId
      )
      .subscribe((response) => {
        if (response.state == AutoCvResponseStatus.Available) {
          this.filePreview.emit({
            url: response.url,
            fileName: this.getCVFileName(response.hideCandidateName),
            fileType: null,
          });
        }
        if (response.state == AutoCvResponseStatus.Processing) {
          this.doesCVAlreadyExists(response).subscribe((received) => {
            if (!received) {
              this.messageService.add({
                severity: "error",
                summary: translate("sharing.autoCvUnavailable"),
                detail: translate("sharing.autoCvUnavailableDetail"),
              });
            } else {
              this.filePreview.emit({
                url: response.url,
                fileName: this.getCVFileName(response.hideCandidateName),
                fileType: null,
              });
            }
          });
        }
        if (response.state == AutoCvResponseStatus.Unavailable) {
          this.messageService.add({
            severity: "error",
            summary: translate("sharing.autoCvUnavailable"),
            detail: translate("sharing.autoCvUnavailableDetail"),
          });
        }
      });
  }

  private openFor(orgId: string, name: string): MenuItem {
    return {
      icon: PrimeIcons.FILE,
      label: translate("sharing.cvOrganizationName", { organizationName: name }),
      command: (): void => this.openAutoCVPreview(this.selectOrganizationId(orgId)),
    };
  }

  private selectOrganizationId(orgId: string): string {
    if (this.isPresentation || !this.settings.isLicensed) {
      return this.sharing != null ? this.sharing.organizationId : this.candidate.organizationId;
    }
    return orgId;
  }

  private getSharedOrganizationName(): void {
    this.orgService
      .getOrganization(this.sharing?.organizationId ?? this.settings.organizationId)
      .subscribe((x) => (this.sharedOrganizationName = x.name));
  }

  private getCVFileName(hideCandidateName: boolean): string {
    const base = "CV";
    const displayID = this.data.displayId;
    if (this.data) {
      const lastName = this.data?.os?.profile.lastname;
      const firstName = this.data?.os?.profile.firstname;
      return hideCandidateName ? `${base}_${displayID}.pdf` : `${base}_${displayID}_${lastName}_${firstName}.pdf`;
    } else {
      return `${base}.pdf`;
    }
  }

  private doesCVAlreadyExists(response: AutoCvResponse): Observable<boolean> {
    return this.http.head(response.url, { observe: "response" }).pipe(
      retry({ delay: 1500, count: 15 }),
      map(() => true),
      catchError(() => of(false))
    );
  }

  openPreview(file: DocumentFile, document: Document): void {
    this.previewOpenDisabled = true;
    this.documentService
      .getDownloadUrl(file.blob, this.candidate.organizationId, this.candidate.id)
      .pipe(finalize(() => (this.previewOpenDisabled = false)))
      .subscribe((url) =>
        this.filePreview.emit({
          url: url,
          fileName: file.name,
          fileType: document.type,
        })
      );
  }

  getRecognitionNoticeKindTranslation(notice: string): string {
    if (!notice) return "recognitionNoticeKind.candidatePresentation.notAvailable";
    if (notice === "FULLRECOGNITIONNOTICE") return "recognitionNoticeKind.candidatePresentation.full";
    if (notice === "REJECTIONNOTICE") return "recognitionNoticeKind.candidatePresentation.rejection";
    if (notice === "DEFICITNOTICE") return "recognitionNoticeKind.candidatePresentation.deficit";
    return "";
  }

  private calculateExperienceToShow(candidate: typeof this.data): void {
    this.subscriptions.push(
      this.systemSettingsService.getCandidateOwnerSpecificSettings(candidate.organizationId).subscribe((settings) => {
        this.experiencetoShow = clone(candidate?.os?.profile?.experiences ?? [])
          .sort((a, b) => {
            //compare with sorting in defaultCVGenerator
            if (!a.experienceEndDate && !b.experienceEndDate) {
              return b.experienceStartDate?.getTime() - a.experienceStartDate?.getTime();
            } else if (!a.experienceEndDate) {
              return -1;
            } else if (!b.experienceEndDate) {
              return 1;
            } else {
              return b.experienceStartDate?.getTime() - a.experienceStartDate?.getTime();
            }
          })
          .map((val) => {
            const experience = WorkExperienceDuration.zero();
            if (val.experienceStartDate) {
              experience.add(
                WorkExperienceDuration.fromStartAndEndDate(
                  val.experienceStartDate,
                  val.experienceEndDate,
                  settings.workingExperiencePrecision
                )
              );
            } else {
              experience.add(WorkExperienceDuration.fromDuration(val.experienceYears ?? 0, val.experienceMonth ?? 0));
            }
            const durationString = this.cardViewFormatterService.getDurationString(
              experience.asMonthAndYears(),
              "short"
            );
            return {
              ...val,
              period: this.cardViewFormatterService.getPeriodString(
                val.experienceStartDate,
                val.experienceEndDate,
                settings.workingExperiencePrecision
              ),
              duration: durationString,
              _months: experience.asMonths(),
              experienceFields: val.experienceFields?.map((field) => field.experienceField),
            };
          });
      })
    );
  }

  //TODO: get the calculated recognition from backend
  private setRecognitionState(candidate: typeof this.data): void {
    this.documentService
      .getAllTypesAndValidity(DocumentMode.Candidate, this.data.organizationId, this.data.id)
      .subscribe((documents) => {
        const recognitionState = this.getRecognitionState(candidate, documents);
        const qualificationMeasure = this.migrationSpecificsFactory
          .getSpecifics(candidate.immigrationCountry)
          .getQualificationMeasure(candidate);

        const isDeficitNotice = recognitionState === RecognitionState.DeficitNotice;
        const qualificationMeasureIsKnowledgeTest = ["KNOWLEDGE", "ELIGIBILITY"].includes(
          qualificationMeasure?.qualificationMeasure
        );

        this.recognitionState = recognitionState;
        this.showQualificationHours = isDeficitNotice && !qualificationMeasureIsKnowledgeTest;
        this.showExams = isDeficitNotice && qualificationMeasureIsKnowledgeTest;
        this.theoryHours = qualificationMeasure?.theoryHours;
        this.careSituations = qualificationMeasure?.careSituations;
        this.practiceHours = qualificationMeasure?.practiceHours;
      });
  }

  /**
   * Prio 1: Current recognitionPath
   * Prio 2: Alternative recognitionPath
   * Prio 3: Documents
   */
  private getRecognitionState(candidate: typeof this.data, documents: DocumentTypeDataFragment[]): RecognitionState {
    const recognitionPath = this.migrationSpecificsFactory
      .getSpecifics(candidate.immigrationCountry)
      .getRecognitionPath(candidate);

    return [
      this.getRecognitionStateFromRrecognitionNoticeKind(recognitionPath?.current),
      this.getRecognitionStateFromRrecognitionNoticeKind(recognitionPath?.alternative),
      this.getRecognitionStateFromDocuments(documents),
      this.getRecognitionStateFromTags(recognitionPath?.current),
      this.getRecognitionStateFromTags(recognitionPath?.alternative),
    ].reduce(
      (previous, current) => (previous === RecognitionState.NoNoticeReceived ? current : previous),
      RecognitionState.NoNoticeReceived
    );
  }

  private getRecognitionStateFromTags(recognitionPathEntry: {
    recognitionReceived?: boolean;
    recognitionReceiveDate?: Date;
    recognitionStarted?: boolean;
    recognitionStartDate?: Date;
  }): RecognitionState {
    if (!recognitionPathEntry) return RecognitionState.NoNoticeReceived;

    if (recognitionPathEntry.recognitionReceived || recognitionPathEntry.recognitionReceiveDate)
      return RecognitionState.RecognitionNoticeReceived;

    if (recognitionPathEntry.recognitionStarted || recognitionPathEntry.recognitionStartDate)
      return RecognitionState.RecognitionStarted;

    return RecognitionState.NoNoticeReceived;
  }

  private getRecognitionStateFromRrecognitionNoticeKind(recognitionPathEntry: {
    recognitionNoticeKind?: string;
  }): RecognitionState {
    if (!recognitionPathEntry) return RecognitionState.NoNoticeReceived;

    switch (recognitionPathEntry.recognitionNoticeKind) {
      case "FULLRECOGNITIONNOTICE":
        return RecognitionState.FullNotice;
      case "REJECTIONNOTICE":
        return RecognitionState.RejectNotice;
      case "DEFICITNOTICE":
        return RecognitionState.DeficitNotice;
    }
    return RecognitionState.NoNoticeReceived;
  }

  private getRecognitionStateFromDocuments(documents: DocumentTypeDataFragment[]): RecognitionState {
    return (
      documents
        .map((document) => ({
          state: this.mapToReconditionPathState(document.type),
          validUntil: getDefaultSet(document)?.validUntil,
        }))
        .filter((x) => x.state !== RecognitionState.NoNoticeReceived)
        .sort((a, b) => b.validUntil?.valueOf() - a.validUntil?.valueOf())
        .at(0)?.state ?? RecognitionState.NoNoticeReceived
    );
  }

  private mapToReconditionPathState(documentType: string): RecognitionState {
    if (documentType === "RECDOC") {
      return RecognitionState.FullNotice;
    }
    if (documentType === "REJECTREC") {
      return RecognitionState.RejectNotice;
    }
    if (documentType === "DEFICITNOTE") {
      return RecognitionState.DeficitNotice;
    }
    return RecognitionState.NoNoticeReceived;
  }

  protected readonly isEmpty = isEmpty;
  protected readonly trim = trim;
}
