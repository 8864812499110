import { Pipe, PipeTransform } from "@angular/core";
import { DefaultCardViewFormatterService } from "./default-card-view-formatter.service";

@Pipe({ name: "cardViewDuration", pure: true })
export class CardViewDurationPipe implements PipeTransform {
  constructor(private readonly cardViewFormatterService: DefaultCardViewFormatterService) {}

  transform(duration: { years: number; months: number }, kind: "long" | "short"): string {
    return this.cardViewFormatterService.getDurationString(duration, kind);
  }
}
