import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivityFragment, NotificationSettingsFragment } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { PrimeTemplate } from "primeng-v17/api";
import { Button, ButtonDirective } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { OverlayPanelModule } from "primeng-v17/overlaypanel";
import { EscapeButtonDirective } from "../../../shared/directives/escape-button.directive";
import { AppDateTimePipe } from "../../../shared/pipes/date.pipe";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { ActivityTableComponent } from "../../activity/activity-table/activity-table.component";
import { NotificationConfigurationComponent } from "../notification-configuration/notification-configuration.component";

@Component({
  selector: "app-notification-list-dialog",
  templateUrl: "./notification-list-dialog.component.html",
  styleUrl: "./notification-list-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateDirective,
    CardModule,
    ButtonDirective,
    ActivityTableComponent,
    OverlayPanelModule,
    AppendToBodyDirective,
    PrimeTemplate,
    NotificationConfigurationComponent,
    Button,
    EscapeButtonDirective,
    AppDateTimePipe,
  ],
})
export class NotificationListDialogComponent {
  readonly language = this.transloco.getActiveLang();

  @Input()
  notifications: ActivityFragment[];

  @Input()
  since: Date;

  @Input()
  settings: NotificationSettingsFragment;

  @Input()
  isBusy: boolean;

  @Output()
  readonly settingsChanged = new EventEmitter<NotificationSettingsFragment>();

  @Output()
  readonly notificationCleared = new EventEmitter<ActivityFragment>();

  @Output()
  readonly allNotificationsCleared = new EventEmitter<void>();

  @Output()
  readonly activityLogOpened = new EventEmitter<void>();

  @Output()
  readonly closed = new EventEmitter<void>();

  constructor(private readonly transloco: TranslocoService) {}
}
