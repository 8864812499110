import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { CandidateDocumentDateComponent } from "../../features/candidate-document-metadata/metadata-wrapper-components/candidate-document-date.component";

@Component({
  selector: "app-formly-document-date",
  templateUrl: "./formly-document-date.component.html",
  imports: [CandidateDocumentDateComponent],
})
export class FormlyDocumentDateComponent extends FieldType {}
