<div class="consent-body" *appTranslate="let t">
  <div *ngIf="resultFetched" id="wrapper" class="card">
    <div>
      <div id="consentForm">
        <app-terms-and-conditions
          *ngIf="termsAndCondition"
          [consentGiverId]="consentGiverId"
          [consentIdentifier]="consentIdentifier"
          [organizationId]="organizationId"
          [termsAndCondition]="termsAndCondition"
          [standalone]="true"
        ></app-terms-and-conditions>
        <div *ngIf="!termsAndCondition" class="my-5">
          <div class="flex justify-content-center gap-5">
            <img class="max-h-5rem" src="assets/layout/images/logo_small.png" />
          </div>
          <span class="flex flex-row justify-content-center"> {{ t("termsAndConditions.noConsent") }}</span>
          <a class="logo" [routerLink]="['/app']">
            <u>Ankaadia</u>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
