<div class="layout-wrapper p-input-filled layout-overlay-menu" [class.layout-wrapper-menu-active]="sidebarActive">
  <app-topbar
    [activeTopbarItem]="activeTopbarItem"
    [topbarMenuActive]="topbarMenuActive"
    (menuClicked)="onTopbarMenuButtonClick($event)"
    (menuItemClicked)="onTopbarItemClick($event[0], $event[1])"
    (feedbackOpened)="openFeedback()"
    (notificationsOpened)="openNotifications()"
  ></app-topbar>

  <app-sidebar
    #sidebar
    [activeTabIndex]="activeTabIndex"
    (navigated)="onSidebarNavigation($event)"
    (sidebarClicked)="onSidebarClick()"
    (sidebarClosed)="closeSidebar($event)"
    (tabClicked)="onTabClick($event[0], $event[1])"
    (feedbackOpened)="openFeedback()"
    (activeTabIndexChange)="activeTabIndex = $event"
  ></app-sidebar>

  <div class="layout-main">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>

  <p-confirmPopup></p-confirmPopup>
  <p-confirmDialog key="confirmDeactivate"></p-confirmDialog>
  <app-user-feedback></app-user-feedback>
  <app-notifications></app-notifications>
  <app-message-error></app-message-error>
  <div #candidateEditDialogProxy></div>
</div>
