import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { Subscription, take } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "app-login",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private subscription: Subscription;

  constructor(
    private readonly settings: SettingsService,
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscription?.unsubscribe();
    this.subscription = this.settings.isAuthenticated$.pipe(take(1)).subscribe((x) => {
      if (!x) {
        this.auth.loginWithRedirect({
          appState: { target: this.route.snapshot.queryParams.target ?? undefined },
          authorizationParams: { login_hint: this.route.snapshot.queryParams.email ?? undefined },
        });
      } else {
        this.settings.navigateToMain();
      }
    });
  }
}
