import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import { ENABLE_TALENT_POOL, UserPermission } from "@ankaadia/ankaadia-shared";
import { BadgeModule } from "primeng-v17/badge";
import { TooltipModule } from "primeng-v17/tooltip";
import { combineLatest, filter } from "rxjs";
import { PermissionDirective } from "../../shared/directives/permission.directive";
import { SettingsService } from "../../shared/services/settings.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { MenuComponent } from "../menu/menu.component";
import { IndexedMenuItem } from "../menu/menu.model";
import { MenuService } from "../menu/menu.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrl: "./sidebar.component.scss",
  imports: [
    TranslateDirective,
    NgFor,
    NgIf,
    TooltipModule,
    NgTemplateOutlet,
    MenuComponent,
    PermissionDirective,
    RouterLink,
    BadgeModule,
    AsyncPipe,
  ],
})
export class SidebarComponent implements OnInit {
  readonly UserPermission = UserPermission;
  readonly ENABLE_TALENT_POOL = ENABLE_TALENT_POOL;

  @Input()
  activeTabIndex: number;

  @Output()
  readonly sidebarClicked = new EventEmitter<Event>();

  @Output()
  readonly sidebarClosed = new EventEmitter<Event>();

  @Output()
  readonly tabClicked = new EventEmitter<[Event, number]>();

  @Output()
  readonly feedbackOpened = new EventEmitter<Event>();

  @Output()
  readonly navigated = new EventEmitter<IndexedMenuItem>();

  @Output()
  readonly activeTabIndexChange = new EventEmitter<number>();

  get helpUrl(): string {
    return this.menuService.helpUrl;
  }

  sections: IndexedMenuItem[] = [];

  constructor(
    private readonly settings: SettingsService,
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.settings.isAuthenticated$.pipe(filter((x) => !!x)),
      this.settings.isAuthorized$.pipe(filter((x) => !!x)),
    ]).subscribe(() => {
      this.sections = this.menuService.getSections();
      this.setActiveItem();
    });
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.setActiveItem());
  }

  private setActiveItem(): void {
    if (this.router.url === "/app") {
      this.activeTabIndexChange.emit(null);
      return;
    }

    for (const section of this.sections) {
      if (section.routerLink) {
        if (this.isRouterLinkActive(section.routerLink, section.routerLinkActiveOptions)) {
          this.activeTabIndexChange.emit(section.index);
          break;
        }
      }

      if (section.items.some((i) => this.isRouterLinkActive(i.routerLink, i.routerLinkActiveOptions))) {
        this.activeTabIndexChange.emit(section.index);
        break;
      }
    }
  }

  private isRouterLinkActive(routerLink: any[], options: { exact: boolean }): boolean {
    return this.router.isActive(
      this.router.createUrlTree(routerLink, { relativeTo: this.route }),
      options?.exact
        ? { paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored" }
        : { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" }
    );
  }
}
