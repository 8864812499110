import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorCode, errorMessageKey, KeyOf } from "@ankaadia/ankaadia-shared";
import { ApolloErrorHandler, ApplicationInsightsService } from "@ankaadia/ankaadia-shared-frontend";
import { Mutation, StaticDataType } from "@ankaadia/graphql";
import { ErrorResponse } from "@apollo/client/link/error";
import { translate } from "@jsverse/transloco";
import { GraphQLFormattedError } from "graphql";
import { zipObject } from "lodash";
import { forkJoin, map, Observable, of } from "rxjs";
import { environment } from "../../../environments/environment";
import { MessageService } from "../../features/message/message.service";
import { MessageDialogService } from "../message-dialog/message-dialog.service";
import { PromptUpdateService } from "../services/promptUpdateService";
import { StaticDataPipe } from "../static-data/static-data.pipe";

@Injectable({ providedIn: "root" })
export class ApolloErrorHandlerService implements ApolloErrorHandler {
  constructor(private readonly injector: Injector) {}

  handle(response: ErrorResponse): void {
    // `this.injector.get` is used in this file so that there's no circular dependency reported
    // on `_Apollo` during the runtime; nobody understands why this is happening, though
    const messageService = this.injector.get(MessageService);
    const appInsights = this.injector.get(ApplicationInsightsService);

    response.graphQLErrors?.map((e) => {
      type ErrorHandler = (error: GraphQLFormattedError, response: ErrorResponse) => boolean;
      const errorHandlers: ErrorHandler[] = [
        this.handleForbidden,
        this.handleUserInputError,
        this.handleConcurrencyViolation,
        this.handleAggregateError,
        this.handleVersionError,
      ];

      const handled = errorHandlers.map((x) => x.bind(this)).some((x) => x(e, response));
      if (handled) {
        return;
      }

      const extensions: Record<string, any> = e.extensions;
      response.graphQLErrors?.forEach((x) => appInsights.logException(<any>x, response.operation));
      let message: string = response.operation.operationName + "\n";
      response.graphQLErrors?.map((val) => (message += val.message + "\n"));
      if (response.networkError?.message) {
        message += response.networkError?.message + "\n";
      }
      if (extensions?.code) {
        message += extensions?.code + "\n";
      }
      if (extensions?.exception?.response?.message) {
        message += extensions?.exception?.response?.message + "\n";
      }
      if (extensions?.exception?.stacktrace) {
        if (extensions?.exception?.stacktrace.join) {
          message += extensions?.exception?.stacktrace.join("\n") + "\n";
        } else {
          message += extensions?.exception?.stacktrace + "\n";
        }
      }
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error(message, response);
      }
      messageService.add({ severity: "error", summary: message });
    });

    if (response.networkError != null) {
      appInsights.logException(response.networkError, response.operation);
      let message: string = response.operation.operationName + "\n";
      response.graphQLErrors?.map((val) => (message += val.message + "\n"));
      if (response.networkError?.message) {
        message += response.networkError?.message + "\n";
      }
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error(message, response);
      }
      messageService.add({ severity: "error", summary: message });
    }
  }

  private handleForbidden(error: GraphQLFormattedError): boolean {
    const extensions: Record<string, any> = error.extensions;

    if (extensions?.exception?.status !== 403 && extensions?.code !== ErrorCode.FORBIDDEN) return false;

    const router = this.injector.get(Router);
    void router.navigate(["/accessdenied"]);
    return true;
  }

  private handleUserInputError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.BAD_USER_INPUT) return false;

    const appInsights = this.injector.get(ApplicationInsightsService);
    appInsights.logEvent("BAD_USER_INPUT");

    const extensions: Record<string, any> = error.extensions;
    const { errorKey, translatedMessages } = this.translateMessages(error);

    if (errorKey) {
      const msgDialogService = this.injector.get(MessageDialogService);
      translatedMessages.subscribe((errorMessages) => {
        msgDialogService.showMessage(translate("user.badInput.title"), translate(`graphql.${errorKey}`, errorMessages));
      });
    } else {
      const messageService = this.injector.get(MessageService);
      messageService.add({
        severity: "error",
        summary: extensions.response?.message?.join("\n") ?? error.message,
      });
    }

    return true;
  }

  private handleConcurrencyViolation(error: GraphQLFormattedError, response: ErrorResponse): boolean {
    if (error.extensions?.code !== ErrorCode.CONCURRENCY_VIOLATION) {
      return false;
    }

    // Ignore concurrency errors for specific operations
    type MutationOperarion = KeyOf<Mutation>;
    const ignoredOperations: MutationOperarion[] = ["updateDocument", "createDocument"];
    const operationName = response.operation.operationName as MutationOperarion;
    if (ignoredOperations.includes(operationName) && response.operation.variables?.input?.ignoreConcurrencyError) {
      return true;
    }

    const appInsights = this.injector.get(ApplicationInsightsService);
    appInsights.logEvent(ErrorCode.CONCURRENCY_VIOLATION);

    const msgDialogService = this.injector.get(MessageDialogService);
    msgDialogService.showMessage(translate("user.concurrency.title"), translate("user.concurrency.message"));

    return true;
  }

  private handleAggregateError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.AGGREGATEERROR) return false;

    const extensions: Record<string, any> = error.extensions;

    const appInsights = this.injector.get(ApplicationInsightsService);
    appInsights.logEvent(ErrorCode.AGGREGATEERROR);

    const errorMessages: {
      errorKey: errorMessageKey;
      translatedMessages: Observable<Record<string, string>>;
    }[] = extensions.errors?.map((error) => this.translateMessages(error) ?? []);

    const groupedMessages = forkJoin(
      errorMessages.map((x) =>
        x.translatedMessages.pipe(
          map((translatedMessages) => ({
            errorKey: x.errorKey,
            translatedMessages,
          }))
        )
      )
    );

    const msgDialogService = this.injector.get(MessageDialogService);

    groupedMessages.subscribe((groupedMessages) => {
      const errorMessagesString: string[] = [];
      groupedMessages
        .filter((x) => x.errorKey)
        .forEach((messages) => {
          const errorMessage = translate(`graphql.${messages.errorKey}`, messages.translatedMessages);
          errorMessagesString.push(errorMessage);
        });

      if (errorMessagesString.length === 0)
        errorMessagesString.push(extensions.response?.message?.join("\n") ?? error.message);

      msgDialogService.showMessage(translate("user.badInput.title"), errorMessagesString);
    });

    return true;
  }

  private handleVersionError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.VERSIONERROR) return false;
    // eslint-disable-next-line no-console
    console.log("New updates detected");
    // Can't  be injected in the constructor because of circular dependency or other reasons lost 4 hours on this
    this.injector.get(PromptUpdateService).checkForSoftwareUpdate();
    return true;
  }

  private translateMessages(userInputError: GraphQLFormattedError): {
    errorKey: errorMessageKey | null;
    translatedMessages: Observable<Record<string, string>>;
  } {
    if (userInputError.extensions?.code !== ErrorCode.BAD_USER_INPUT) throw new Error("Not a user input error");

    const extensions: Record<string, any> = userInputError.extensions;

    const properties = (extensions?.properties as { key: string; value: string }[]) ?? [];
    const messagesProperties = properties.filter((x) => x.key && x.value && x.key != "errorMessageKey");
    const errorKey = properties.find((x) => x.key === "errorMessageKey")?.value as errorMessageKey | null;

    const isAlreadyUploadedErrorKey = [
      errorMessageKey.DOCUMENTSALREADYUPLOADED,
      errorMessageKey.NOTARIZATIONSALREADYUPLOADED,
      errorMessageKey.TRANSLATIONSALREADYUPLOADED,
    ].includes(errorKey);

    const staticDataPipe = this.injector.get(StaticDataPipe);

    const translatedMessages = messagesProperties.reduce<
      Observable<{ messageKey: string; translatedMessage: string }>[]
    >((acc, pair: { key: string; value: string }) => {
      let translatedMessage = of(pair.value);
      if (pair.key.startsWith("STATICDATA:")) {
        const [, type, enumKey] = pair.value.split(":");
        translatedMessage = staticDataPipe
          .transform(enumKey, type as StaticDataType)
          .pipe(map((x) => (isAlreadyUploadedErrorKey && pair.key === "details" ? x : `"${x}"`)));
      }
      acc.push(translatedMessage.pipe(map((translatedMessage) => ({ messageKey: pair.key, translatedMessage }))));
      return acc;
    }, []);

    if (translatedMessages.length === 0) {
      return {
        errorKey,
        translatedMessages: of({}),
      };
    }

    return {
      errorKey,
      translatedMessages: forkJoin(translatedMessages).pipe(
        map((x) =>
          zipObject(
            x.map((x) => x.messageKey),
            x.map((x) => x.translatedMessage)
          )
        )
      ),
    };
  }
}
