import { Directive, ElementRef, Renderer2, ViewContainerRef } from "@angular/core";
import { Sidebar } from "primeng/sidebar";
import { SidebarAdditionalComponentService } from "./sidebar-additional-component.service";

@Directive({ selector: "p-sidebar" })
export class SidebarAdditionalComponentDirective {
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    protected readonly sidebar: Sidebar,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly sidebarAdditionalComponentService: SidebarAdditionalComponentService
  ) {
    this.sidebar.onShow.subscribe(() => {
      this.loadComponent();
    });
  }

  loadComponent(): void {
    // Create Component
    const additionalComponents = this.sidebarAdditionalComponentService.getComponentTypeList();
    for (const componentType of additionalComponents) {
      const instance = this.viewContainerRef.createComponent(componentType);

      // Move it
      const element = instance.location.nativeElement;
      const sideBarHeader = this.elementRef.nativeElement.querySelector(".p-sidebar-header") as HTMLElement;
      if (sideBarHeader) {
        if (sideBarHeader.children.length > 0) {
          const child = sideBarHeader.children.item(0);
          this.renderer.insertBefore(sideBarHeader, element, child);
        } else {
          this.renderer.appendChild(sideBarHeader, element);
        }
      }
    }
    // Add custom class
    //this.elementRef.nativeElement.classList.add("mat-load-button");
  }
}
