import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { get } from "lodash";
import { BlockableUI, FilterService, MenuItem, SortEvent } from "primeng/api";
import { Sidebar } from "primeng/sidebar";
import { Table } from "primeng/table";
import { Observable, Subject, Subscription, debounceTime } from "rxjs";
import { environment } from "../../../environments/environment";
import {
  SideBarSizeType,
  StartOperationType,
  TableCache,
  TableColumn,
  TableMenuItem,
  TableOperation,
  TableOperationMode,
  TableTag,
} from "./table.model";
import { TableService } from "./table.service";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent implements OnInit, OnDestroy, OnChanges, BlockableUI {
  readonly filterName = `custom-${Date.now()}`;
  readonly newOp = { id: "new" };
  readonly captionOp = { id: "caption" };
  readonly footerOp = { id: "footer" };
  readonly get = get;

  @Input() title: string;
  @Input() warningMessage: string;
  @Input() items: unknown[];
  @Input() columns: TableColumn[];
  @Input() tableOperations: TableOperation[];
  @Input() newOperations: TableOperation[];
  @Input() captionOperations: TableOperation[];
  @Input() footerOperations: TableOperation[];
  @Input() sidebarSize: SideBarSizeType = "medium";
  @Input() numberOfRows = 10;
  @Input() scrollable = true;
  @Input() sortField: string;
  @Input() sortOrder: number;
  @Input() showSearch = true;
  @Input() paginator = false;
  @Input() showArrows? = false;
  @Input() caption: string = null;
  @Input() styleClass?: string;
  @Input() cardClass?: string;
  @Input() selectionMode?: "single" | "multiple";
  @Input() selection?: any;
  @Input() language: string = this.transloco.getActiveLang();
  @Input() virtualScroll = false;

  sideBarSizeMapping = { medium: "p-sidebar-md", large: "p-sidebar-lg" };

  TableOperationMode: typeof TableOperationMode = TableOperationMode;

  globalFilterFields: string[];
  eventTarget: EventTarget;
  calculatedTags: Record<string, Observable<TableTag[]>>[];
  calculatedTooltips: Record<string, string>[];
  calculatedIcons: Record<string, string>[];
  calculatedButtonItems: Record<string, MenuItem[][]>;
  calculatedClassNames: Record<string, string>[];
  private _showSidebar: boolean;

  @Output() readonly selectionChange = new EventEmitter<any>();

  @Output() readonly showSidebarChange = new EventEmitter<any>();

  @Input() get showSidebar(): boolean {
    return this._showSidebar;
  }

  set showSidebar(value: boolean) {
    this._showSidebar = value;
    this.showSidebarChange.emit(value);
  }

  searchInput: string;

  @ViewChild("sidebar")
  sidebar: Sidebar;

  @ViewChild("dt")
  table: Table;

  @ContentChild("caption", { read: TemplateRef })
  captionTemplate: TemplateRef<any>;

  get toggleArrow(): boolean {
    return this.sidebarSize === "medium" ? true : false;
  }

  private readonly debounce = new Subject<StartOperationType>();
  private debounceSub: Subscription;
  private cache: TableCache;

  constructor(
    private readonly filterService: FilterService,
    private readonly transloco: TranslocoService,
    private readonly elementRef: ElementRef,
    private readonly tableService: TableService
  ) {}

  ngOnInit(): void {
    this.globalFilterFields = this.columns?.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);
    this.debounceSub = this.debounce.pipe(debounceTime(200)).subscribe((x) => x.operation(x.rowData, x.event));
    this.filterService.register(this.filterName, (value, filter, locale) =>
      this.tableService.filter(value, filter, locale, this.cache)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.columns) {
      this.validateItems(this.items, this.columns);
      this.cache = this.tableService.buildPipeCache(this.columns, this.items, this.language);
    }
    if (changes.items) {
      // Sort Table after items are added
      if (this.items?.length > 0 && this.table != null) {
        setTimeout(() => {
          this.table?.sortSingle();
        }, 0);
      }
      // Reset Searchbox if items are added only
      if (this.searchInput != null && changes.items.currentValue?.length > changes.items.previousValue?.length) {
        this.searchInput = null;
        this.table?.filterGlobal(null, this.filterName);
      }
      this.calculateExtraProperties(changes.items.currentValue, this.columns);
      this.calculateButtonItems(
        changes.items.currentValue,
        this.tableOperations,
        this.newOperations,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.columns) {
      this.globalFilterFields = this.columns?.filter((col) => col.includeInGlobalFilter).map((col) => col.fieldname);
      this.calculateExtraProperties(this.items, changes.columns.currentValue);
    }
    if (changes.tableOperations) {
      this.calculateButtonItems(
        this.items,
        changes.tableOperations.currentValue,
        this.newOperations,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.newOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        changes.newOperations.currentValue,
        this.captionOperations,
        this.footerOperations
      );
    }
    if (changes.captionOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        this.newOperations,
        changes.captionOperations.currentValue,
        this.footerOperations
      );
    }
    if (changes.footerOperations) {
      this.calculateButtonItems(
        this.items,
        this.tableOperations,
        this.newOperations,
        this.captionOperations,
        changes.footerOperations.currentValue
      );
    }
  }

  ngOnDestroy(): void {
    this.debounceSub.unsubscribe();
    delete this.filterService.filters[this.filterName];
  }

  resetPagination(): void {
    if (this.table) {
      this.table.first = 0;
    }
  }

  getButtonIcon(icon: string | ((item: unknown) => string), rowData: unknown): string {
    return typeof icon === "function" ? icon(rowData) : icon;
  }

  startOperation(event: Event, rowData: unknown, operation: (item: unknown, event?: unknown) => void): void {
    if (operation != null && typeof operation == "function") {
      this.debounce.next({
        event: this.eventTarget ? <Event>{ target: this.eventTarget } : event,
        rowData: rowData,
        operation: operation,
      });
    }
    this.eventTarget = null;
  }

  getDisabledState(item: unknown, disabledState: boolean | ((item: unknown) => boolean)): boolean {
    return typeof disabledState === "function" ? disabledState(item) : disabledState;
  }

  getBlockableElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  sort(event: SortEvent): void {
    this.tableService.sort(event, this.cache);
  }

  private calculateExtraProperties(items: unknown[], columns: TableColumn[]): void {
    type PartialTableColumn = Pick<TableColumn, "tags" | "tooltip" | "icon" | "className">;

    function calculate<
      PropertyName extends keyof PartialTableColumn,
      PropertyType = ReturnType<PartialTableColumn[PropertyName]>,
    >(prop: PropertyName): Record<string, PropertyType>[] {
      return columns?.map((c) =>
        c[prop]
          ? items?.reduce<Record<string, PropertyType>>((acc, v) => {
              acc[(<any>v).id] = c[prop](v) as any;
              return acc;
            }, {})
          : {}
      );
    }
    this.calculatedTags = calculate("tags");
    this.calculatedTooltips = calculate("tooltip");
    this.calculatedIcons = calculate("icon");
    this.calculatedClassNames = calculate("className");
  }

  private calculateButtonItems(
    items: unknown[],
    tableOperations: TableOperation[],
    newOperations: TableOperation[],
    captionOperations: TableOperation[],
    footerOperations: TableOperation[]
  ): void {
    const calculate = (ops: TableOperation[], rowData: unknown): any =>
      ops?.map((op) =>
        this.getTableMenuItem(op.items, rowData)
          ?.filter((x) => !x.canOperate || x.canOperate(rowData))
          .map((x) => ({ ...x, command: (e) => this.startOperation(e, rowData, x.operation) }))
      );
    const buttonItems = (items ?? [])
      .filter((x) => x != null)
      .reduce<Record<string, MenuItem[][]>>((acc, v) => ((acc[(<any>v).id] = calculate(tableOperations, v)), acc), {});
    buttonItems[this.newOp.id] = calculate(newOperations, this.newOp);
    buttonItems[this.captionOp.id] = calculate(captionOperations, this.captionOp);
    buttonItems[this.footerOp.id] = calculate(footerOperations, this.footerOp);
    this.calculatedButtonItems = buttonItems;
  }

  private getTableMenuItem(
    items: TableMenuItem[] | ((item: unknown) => TableMenuItem[]),
    rowData: unknown
  ): TableMenuItem[] {
    const isData = items instanceof Function;

    return isData ? items(rowData) : items;
  }

  /**
   * Items passed to the table should really have the `id` field, if they are using calculated properties like:
   * - {@link TableColumn.tags},
   * - {@link TableColumn.tooltip},
   * - {@link TableColumn.icon},
   * - {@link TableColumn.className}.
   *
   * @see {@link calculateExtraProperties} — how the `id` field is used for storing and retrieving results of calculated properties
   */
  private validateItems(items: unknown[], columns: TableColumn[]): void {
    if (environment.production) return;
    if (!items?.length) return;
    if (!columns?.length) return;
    if (!columns?.some((x) => x.tags || x.tooltip || x.icon || x.className)) return;

    if (items.some((x) => !(<any>x).id)) {
      throw new Error(
        "Items passed to the table have no id field, which is required if column definitions have calculated properties."
      );
    }
  }
}
