<ng-container *appTranslate="let t">
  <div class="layout-sidebar computer-only" (click)="sidebarClicked.emit($event)">
    <div class="layout-tabmenu">
      <ul class="layout-tabmenu-nav">
        <ng-container *ngFor="let section of sections">
          <li *ngIf="section.items.length > 0" [class.active-item]="activeTabIndex === section.index">
            <a
              href="javascript:void(0)"
              class="tabmenuitem-link"
              (click)="tabClicked.emit([$event, section.index])"
              [pTooltip]="section.label"
              [attr.data-testid]="'menu-item-' + section.name"
            >
              <ng-container
                *ngTemplateOutlet="sectionWithBadgeInformation; context: { section: section }"
              ></ng-container>
            </a>
          </li>
          <li
            *ngIf="section.items.length === 0 && section.routerLink"
            [class.active-item]="activeTabIndex === section.index"
          >
            <a
              href="javascript:void(0)"
              class="tabmenuitem-link"
              (click)="navigated.emit(section)"
              [pTooltip]="section.label"
              [attr.data-testid]="'menu-item-' + section.name"
            >
              <ng-container
                *ngTemplateOutlet="sectionWithBadgeInformation; context: { section: section }"
              ></ng-container>
            </a>
          </li>
        </ng-container>
      </ul>

      <div class="layout-tabmenu-contents">
        <ng-container *ngFor="let section of sections">
          <div
            *ngIf="section.items.length > 0"
            class="layout-tabmenu-content"
            [class.layout-tabmenu-content-active]="activeTabIndex === section.index"
          >
            <div class="layout-submenu-title clearfix">
              <span>{{ section.label }}</span>
              <a href="javascript:void(0)" class="menu-button pi pi-bars" (click)="sidebarClosed.emit($event)"></a>
            </div>
            <div class="layout-submenu-content">
              <app-menu [items]="section.items" (sidebarClosed)="sidebarClosed.emit($event)"></app-menu>
            </div>
          </div>
        </ng-container>
      </div>

      <ul class="layout-tabmenu-nav layout-tabmenu-nav-bottom">
        <ng-container *ngIf="ENABLE_TALENT_POOL">
          <li *appPermissions="UserPermission.User">
            <a class="tabmenuitem-link" [routerLink]="['/app/talent-pools/view']" [pTooltip]="t('talentPools.view')">
              <i class="pi pi-fw pi-users"></i>
            </a>
          </li>
        </ng-container>

        <li *ngIf="helpUrl">
          <a [href]="helpUrl" class="tabmenuitem-link" target="_blank" [pTooltip]="t('help.title')">
            <i class="pi pi-fw pi-question-circle"></i>
          </a>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            class="tabmenuitem-link"
            (click)="feedbackOpened.emit($event)"
            [pTooltip]="t('feedback.title')"
          >
            <i class="pi pi-fw pi-thumbs-up"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-template #sectionWithBadgeInformation let-section="section">
  <i *ngIf="(section.badge | async) == null" class="pi pi-fw {{ section.icon }}"></i>
  <i
    *ngIf="(section.badge | async) != null"
    pBadge
    [value]="section.badge | async"
    class="pi pi-fw {{ section.icon }}"
  ></i>
</ng-template>
