import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { APP_SHA } from "@ankaadia/ankaadia-shared-frontend";
import { Observable, tap } from "rxjs";
import { PromptUpdateService } from "../services/promptUpdateService";

let lastVersionCheck = Date.now();

export function updateRecognizerInterceptor(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const app_git_sha = inject(APP_SHA);
  const promptUpdateService = inject(PromptUpdateService);

  return next(req).pipe(
    tap((event) => {
      if ((event as any).headers != null) {
        const sha = (event as HttpResponse<any>).headers.get("X-App-Sha");
        if (sha != null && sha != app_git_sha) {
          if (Date.now() - lastVersionCheck > 1000 * 30) {
            // Check only every 30 seconds in case there is a mismatch
            promptUpdateService.checkForSoftwareUpdate();
            lastVersionCheck = Date.now();
          }
        }
      }
    })
  );
}
