<ng-container *appTranslate="let t">
  <p-button
    icon="pi pi-clock"
    styleClass="p-button-rounded p-button-text"
    [disabled]="isLoading"
    (onClick)="open()"
    [pTooltip]="t('task.showActivities')"
    tooltipPosition="left"
  ></p-button>

  <p-sidebar [visible]="show" styleClass="p-sidebar-lg sidebar-with-table">
    <!--This ngIf for the p-card is necessary otherwise the component is constructed even in case the sidebar is not shown-->
    <!--In this case the component is loading data which is never been shown, just because of component construction-->
    <p-card *ngIf="show" [header]="t('common.history')">
      <app-activity-table
        class="activities"
        [activities]="activities"
        [totalActivities]="totalActivities"
        [lazy]="true"
        [showCandidate]="false"
        [showProcess]="false"
        (pageChange)="onPageChange($event)"
      ></app-activity-table>

      <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
          <p-button closeOnEscape [label]="t('common.close')" icon="pi pi-times" (onClick)="close()"></p-button>
        </div>
      </ng-template>
    </p-card>
  </p-sidebar>
</ng-container>
