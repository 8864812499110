import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, Subscription } from "rxjs";
import { MainComponent } from "../main/main.component";
import { CustomMenuItem } from "../menu/menu.model";
import { MenuService } from "../menu/menu.service";

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  animations: [
    trigger("children", [
      state("void", style({ height: "0px" })),
      state("hiddenAnimated", style({ height: "0px" })),
      state("visibleAnimated", style({ height: "*" })),
      transition("visibleAnimated => hiddenAnimated", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
      transition("hiddenAnimated => visibleAnimated", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
      transition("void => visibleAnimated, visibleAnimated => void", animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")),
    ]),
  ],
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input() item: CustomMenuItem;

  @Input() index: number;

  @Input() root: boolean;

  @Input() parentKey: string;

  hover: boolean;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key: string;

  constructor(
    private readonly main: MainComponent,
    private readonly router: Router,
    private readonly menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe((key) => {
      // deactivate current active menu
      if (this.active && this.key !== key && !key.startsWith(this.key)) {
        this.active = false;
      }
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.item.routerLink) {
        this.updateActiveStateFromRoute();
      } else {
        this.active = false;
      }
    });

    this.key = this.parentKey ? this.parentKey + "-" + this.index : String(this.index);
  }

  updateActiveStateFromRoute(): void {
    this.active = this.router.isActive(
      this.item.routerLink[0],
      this.item.items
        ? { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" }
        : { paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored" }
    );
  }

  itemClick(event: Event): boolean {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return true;
    }

    // notify other items
    this.menuService.onMenuStateChange(this.key);

    // execute command
    if (this.item.command) {
      {
        const { badge: _b, items: _i, ...item } = this.item;
        this.item.command({ originalEvent: event, item: item });
      }
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;
    } else {
      // activate item
      this.active = true;

      // hide overlay menus
      // if (this.main.overlay || !this.main.isDesktop()) {
      this.main.sidebarActive = false;
      //}
    }

    return null;
  }

  ngOnDestroy(): void {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
