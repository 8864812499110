import { EnvironmentProviders, provideAppInitializer } from "@angular/core";
import {
  ensure4LetterIsoLanguage,
  supportedLanguages4LetterISO,
  supportedUserInterfaceLanguages4LetterISO,
} from "@ankaadia/ankaadia-shared";
import {
  dirInitializer,
  MissingKeyHandler,
  RelaxedTranslocoTranspiler,
  translocoInitializer,
} from "@ankaadia/ankaadia-shared-frontend";
import {
  getBrowserCultureLang,
  getBrowserLang,
  provideTransloco as provideTranslocoCore,
  provideTranslocoLoader,
  provideTranslocoMissingHandler,
  provideTranslocoTranspiler,
} from "@jsverse/transloco";
import { provideTranslocoLocale } from "@jsverse/transloco-locale";
import { provideTranslocoPersistLang } from "@jsverse/transloco-persist-lang";
import { environment } from "../../../environments/environment";
import { TranslocoHttpLoader } from "./transloco.loader";

export function provideTransloco(): (EnvironmentProviders | EnvironmentProviders[])[] {
  return [
    provideTranslocoCore({
      config: {
        availableLangs: supportedLanguages4LetterISO(),
        defaultLang: getBrowserLanguage() ?? DEFAULT_UI_LANGUAGE,
        fallbackLang: DEFAULT_UI_LANGUAGE,
        missingHandler: { useFallbackTranslation: environment.production },
        flatten: { aot: false /* aot does not work for us*/ },
        prodMode: environment.production,
        reRenderOnLangChange: false,
      },
    }),
    provideAppInitializer(translocoInitializer),
    provideAppInitializer(dirInitializer),
    provideTranslocoLoader(TranslocoHttpLoader),
    provideTranslocoTranspiler(RelaxedTranslocoTranspiler),
    provideTranslocoMissingHandler(MissingKeyHandler),
    provideTranslocoLocale(),
    provideTranslocoPersistLang({ storage: { useValue: localStorage }, storageKey: "language" }),
  ];
}

const DEFAULT_UI_LANGUAGE = "de-DE";

function getBrowserLanguage(): string {
  const browserLanguage = getBrowserCultureLang();
  const retVal = ensure4LetterIsoLanguage(
    supportedUserInterfaceLanguages4LetterISO().includes(browserLanguage) ? browserLanguage : getBrowserLang()
  );
  return supportedUserInterfaceLanguages4LetterISO().includes(retVal) ? retVal : DEFAULT_UI_LANGUAGE;
}
