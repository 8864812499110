import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { UserFeedbackCreateInput } from "@ankaadia/graphql";
import { translate } from "@jsverse/transloco";
import { SidebarModule } from "primeng-v17/sidebar";
import { finalize } from "rxjs";
import { SidebarAdditionalComponentDirective } from "../../shared/primeng/sidebar-additional-component/sidebar-additional-component.directive";
import { SidebarOptionsDirective } from "../../shared/primeng/sidebar-options/sidebar-options.directive";
import { MessageService } from "../message/message.service";
import { UserFeedbackEditDialogComponent } from "./user-feedback-edit-dialog/user-feedback-edit-dialog.component";
import { UserFeedbackService } from "./user-feedback.service";

@Component({
  selector: "app-user-feedback",
  templateUrl: "./user-feedback.component.html",
  imports: [
    SidebarModule,
    SidebarAdditionalComponentDirective,
    SidebarOptionsDirective,
    NgIf,
    UserFeedbackEditDialogComponent,
  ],
})
export class UserFeedbackComponent {
  showSidebar: boolean;
  disabled: boolean;

  constructor(
    private readonly service: UserFeedbackService,
    private readonly messages: MessageService
  ) {}

  open(): void {
    this.showSidebar = true;
  }

  close(): void {
    this.showSidebar = false;
  }

  save(feedback: UserFeedbackCreateInput): void {
    this.disabled = true;
    this.service
      .create(feedback)
      .pipe(finalize(() => (this.disabled = false)))
      .subscribe(() => {
        this.messages.add({ severity: "success", summary: translate("userFeedback.created") });
        this.close();
      });
  }
}
