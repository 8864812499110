import { AsyncPipe, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { emailPattern, nameofFactory } from "@ankaadia/ankaadia-shared";
import { GuetegemeinschaftOrganizationReCertificationInput, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { PrimeTemplate } from "primeng-v17/api";
import { Button } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { DropdownModule } from "primeng-v17/dropdown";
import { InputTextModule } from "primeng-v17/inputtext";
import { SplitterModule } from "primeng-v17/splitter";
import { Observable } from "rxjs";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SplitterFixDirective } from "../../shared/primeng/splitter-fix/splitter-fix.directive";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

const nameOF = nameofFactory<GuetegemeinschaftOrganizationReCertificationInput>();

@Component({
  selector: "app-guetegemeinschaft-organization-creation",
  templateUrl: "./gueteGemeinschaftOrganizationCreation.component.html",
  imports: [
    TranslateDirective,
    CardModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    FormElementMapModule,
    TestIdDirective,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    SplitterModule,
    SplitterFixDirective,
    PrimeTemplate,
    Button,
    AsyncPipe,
  ],
})
export class OrganizationCreationComponent implements OnInit {
  form: FormGroup;
  salutations$: Observable<StaticDataModel[]>;
  organizationTypes$: Observable<StaticDataModel[]>;
  nameOf = nameOF;

  @Input()
  message: string;

  @Input()
  buttonLabel: string;

  @Output()
  readonly organizationCreated = new EventEmitter<GuetegemeinschaftOrganizationReCertificationInput>();

  @Output()
  readonly navigatedBack = new EventEmitter<void>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.salutations$ = this.staticDataService.getStaticData(StaticDataType.Salutation);
    this.organizationTypes$ = this.staticDataService.getStaticData(StaticDataType.OrganizationType);
    this.form = this.formBuilder.group({
      [nameOF("organizationName")]: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      [nameOF("organizationType")]: [null, Validators.required],
      [nameOF("salutation")]: [null, Validators.required],
      [nameOF("firstName")]: [null, Validators.compose([Validators.required, Validators.maxLength(200)])],
      [nameOF("lastName")]: [null, [Validators.compose([Validators.required, Validators.maxLength(200)])]],
      [nameOF("emailAddress")]: [
        null,
        Validators.compose([Validators.pattern(emailPattern), Validators.maxLength(300)]),
      ],
      [nameOF("emailAddress2")]: [
        null,
        Validators.compose([Validators.pattern(emailPattern), Validators.maxLength(300)]),
      ],
    });
    this.form.addValidators((form) => {
      return (form.get(nameOF("emailAddress")).value as string)?.toLocaleLowerCase() ===
        (form.get(nameOF("emailAddress2")).value as string)?.toLocaleLowerCase()
        ? null
        : { emailAddressMismatch: true };
    });
  }

  submit(_event: any): void {
    if (this.form.valid) {
      this.organizationCreated.emit(this.form.value);
    }
  }

  back(): void {
    this.form.reset();
    this.navigatedBack.emit();
  }
}
