<ng-container *appTranslate="let t; lang: (processLanguage ?? language) + '|static'">
  <ng-container *ngIf="showPreview$ | async">
    <p-card [header]="fileName" [subheader]="subheader" *ngIf="renderAsCard">
      <ng-container *ngTemplateOutlet="previewComponent"> </ng-container>
      <ng-template pTemplate="footer">
        <div class="flex justify-content-end">
          <p-button closeOnEscape [label]="t('common.close')" icon="pi pi-check" (onClick)="closed.emit()"></p-button>
        </div>
      </ng-template>
    </p-card>
    <ng-container *ngIf="!renderAsCard">
      <p-button
        *ngIf="configData != null"
        closeOnEscape
        [style]="{ display: 'none' }"
        (onClick)="closeDialog()"
      ></p-button>
      <ng-container *ngTemplateOutlet="previewComponent"> </ng-container>
    </ng-container>
    <ng-template #previewComponent>
      <button
        pButton
        type="button"
        icon="pi pi-external-link"
        [class]="
          renderAsCard
            ? 'full-size-card p-button-rounded p-button-text'
            : 'full-size-non-card p-button-rounded p-button-text'
        "
        (click)="openFile()"
      ></button>
      <p *ngIf="!renderAsCard" class="full-size-non-card-Text">{{ fileName }}</p>
      <ngx-extended-pdf-viewer
        *ngIf="fileType == 'pdf'"
        [showDownloadButton]="true"
        [showPrintButton]="true"
        [showPresentationModeButton]="false"
        [showUnverifiedSignatures]="true"
        [showBorders]="false"
        [showInfiniteScrollButton]="false"
        [showBookModeButton]="false"
        [showDrawEditor]="false"
        [showTextEditor]="false"
        [showOpenFileButton]="false"
        [showStampEditor]="false"
        [textLayer]="true"
        [src]="url"
        [filenameForDownload]="fileName"
        [language]="processLanguage ?? language"
      ></ngx-extended-pdf-viewer>

      <app-image-viewer *ngIf="fileType == 'image'" [url]="url"></app-image-viewer>

      <ngx-doc-viewer
        *ngIf="
          fileType == 'docx' ||
          fileType == 'doc' ||
          fileType == 'xls' ||
          fileType == 'xlsx' ||
          fileType == 'ppt' ||
          fileType == 'pptx'
        "
        [url]="url"
        viewer="office"
        queryParams="ui={{ processLanguage ?? language }}"
        style="height: 100%"
      ></ngx-doc-viewer>
    </ng-template>
    <!-- Workaround for escape closing the sidebar as well -->
  </ng-container>
</ng-container>
