<div class="field-checkbox">
  <p-checkbox
    [inputId]="id"
    [binary]="true"
    [formControl]="$any(formControl)"
    [formlyAttributes]="fieldWithoutId"
    [readonly]="to.readonly"
    (onChange)="to.change && to.change(field, $event)"
  ></p-checkbox>
  <label *ngIf="!to.hideInlineLabel" [for]="id">{{ to.label }}</label>
</div>
