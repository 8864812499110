<ng-container *appTranslate="let t">
  <div>
    <p-chip
      *ngFor="let field of field.formControl.value"
      [id]="field.experienceField"
      [label]="field.experienceField | staticData: props.staticDataType | async"
      styleClass="mr-2 mb-2"
      (click)="displayOverlay($event, field)"
      [icon]="
        !props.readonly
          ? 'pi pi-pencil'
          : field.experienceNotes?.contents
            ? field.experienceNotes?.contents === '\n'
              ? ''
              : 'pi pi-info-circle'
            : ''
      "
    ></p-chip>
    <button
      *ngIf="!props.readonly"
      pButton
      type="button"
      icon="pi pi-pencil"
      class="p-button-rounded p-button-text vertical-align-middle"
      (click)="op.toggle($event)"
    ></button>
  </div>

  <p-overlayPanel #op [style]="{ width: '15vw' }">
    <label> {{ t("experienceFields.placeholder") }}</label>
    <p-multiSelect
      styleClass="w-full"
      [options]="props.options | formlySelectOptions: field | async"
      [filter]="true"
      [showClear]="true"
      [showToggleAll]="false"
      [ngModel]="selectedOptions"
      (onChange)="change($event)"
      (onClear)="clear()"
    ></p-multiSelect>
  </p-overlayPanel>
</ng-container>

<p-overlayPanel #chip>
  <button
    pButton
    type="button"
    pTooltip="translate"
    tooltipPosition="left"
    icon="pi pi-language"
    class="p-button-rounded p-button-text"
    (click)="openDialog($event)"
    *ngIf="translationMapping"
  ></button>
  <p-editor
    spellcheck="true"
    [lang]="props.language"
    [readonly]="props.readonly"
    (onInit)="updateEditor($event.editor)"
    (onTextChange)="updateHtml()"
    [style]="{ height: '290px' }"
  >
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <select class="ql-size"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
      </span>
      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-header" value="1"></button>
        <button class="ql-header" value="2"></button>
        <button class="ql-blockquote"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <button class="ql-indent" value="-1"></button>
        <button class="ql-indent" value="+1"></button>
      </span>
      <span class="ql-formats">
        <button class="ql-direction" value="rtl"></button>
        <select class="ql-align"></select>
      </span>
      <span class="ql-formats">
        <button class="ql-link"></button>
      </span>
    </ng-template>
  </p-editor>
</p-overlayPanel>
