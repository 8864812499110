import { Pipe, PipeTransform } from "@angular/core";
import { delay, Observable, switchMap } from "rxjs";
import { MyOrganizationService } from "../../features/organizations/my-organization/my-organization.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { SettingsService } from "../services/settings.service";

@Pipe({ name: "organizationLogo" })
export class OrganizationLogoPipe implements PipeTransform {
  constructor(
    private readonly settings: SettingsService,
    private readonly organizationService: OrganizationsService,
    private readonly myOrganizationService: MyOrganizationService
  ) {}

  transform(organizationId: string): Observable<string> {
    if (this.settings.organizationId === organizationId) {
      return this.myOrganizationService.changed.pipe(
        delay(100), // so that the file storage has enough time to realize the image was changed
        switchMap(() => this.organizationService.getOrganizationLogoDownloadURL(organizationId))
      );
    } else {
      return this.organizationService.getOrganizationLogoDownloadURL(organizationId);
    }
  }
}
