import { AsyncPipe, DatePipe, NgFor, NgIf } from "@angular/common";
import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  DocumentType,
  languageLevelGermanCertificateMapping,
  languageSkillLabelMapping,
} from "@ankaadia/ankaadia-shared";
import {
  CertifiedEducationExamFragment,
  CertifiedEducationExamGroupFragment,
  ExamModuleType,
  StaticDataType,
} from "@ankaadia/graphql";
import { compact, first, orderBy } from "lodash";
import { PrimeTemplate } from "primeng-v17/api";
import { CalendarModule } from "primeng-v17/calendar";
import { FieldsetModule } from "primeng-v17/fieldset";
import { InputTextModule } from "primeng-v17/inputtext";
import { CandidateDocumentDateComponent } from "../../features/candidate-document-metadata/metadata-wrapper-components/candidate-document-date.component";
import { AnyCandidateForm } from "../../features/candidate-form/candidate-form.model";
import { EnumPipe } from "../../shared/pipes/enum.pipe";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { CalendarOptionsDirective } from "../../shared/primeng/calendar-options/calendar-options.directive";
import { CalendarReadonlyDirective } from "../../shared/primeng/calendar-readonly/calendar-readonly.directive";
import { CalendarUtcFixDirective } from "../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { StaticDataPipe } from "../../shared/static-data/static-data.pipe";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-formly-certified-education-exams-entry",
  templateUrl: "./formly-certified-education-exams-entry.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FieldsetModule,
    PrimeTemplate,
    NgFor,
    FormsModule,
    InputTextModule,
    CalendarModule,
    AppendToBodyDirective,
    CalendarOptionsDirective,
    CalendarUtcFixDirective,
    CalendarReadonlyDirective,
    CandidateDocumentDateComponent,
    AsyncPipe,
    DatePipe,
    EnumPipe,
    StaticDataPipe,
  ],
})
export class FormlyCertifiedEducationExamsEntryComponent implements OnChanges {
  readonly StaticDataType = StaticDataType;
  readonly languageSkillLabelMapping = languageSkillLabelMapping;

  @Input() examGroup: CertifiedEducationExamGroupFragment;
  @Input() candidateId: string;
  @Input() organizationId: string;
  @Input() form: AnyCandidateForm;
  @Input() disabled: boolean;
  @Input() readonly: boolean;

  @ViewChildren(CandidateDocumentDateComponent) issueDates: QueryList<CandidateDocumentDateComponent>;

  exams: CertifiedExamData[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.examGroup) {
      this.exams = (this.examGroup?.exams ?? []).map((x) => this.toCertifiedExamData(x));
    }
  }

  get latestIssueDate(): Date | null {
    return first(
      orderBy(
        compact((this.issueDates ?? []).map((x: CandidateDocumentDateComponent) => x.shownDate)),
        (x) => x,
        "desc"
      )
    );
  }

  private toCertifiedExamData(exam: CertifiedEducationExamFragment): CertifiedExamData {
    return {
      ...exam,
      documentType: this.getSelectedSet(exam.languageLevel),
    };
  }

  private getSelectedSet(skillLevel: string): DocumentType {
    if (!skillLevel) return "LANGCERT";

    return languageLevelGermanCertificateMapping[skillLevel] ?? "LANGCERT";
  }

  protected readonly ExamModuleType = ExamModuleType;
}

interface DocumentData {
  documentType: DocumentType;
}

type CertifiedExamData = CertifiedEducationExamFragment & DocumentData;
