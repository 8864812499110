import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { InputSwitchModule } from "primeng-v17/inputswitch";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-input-switch",
  templateUrl: "./formly-inputSwitch.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InputSwitchModule, FormsModule, ReactiveFormsModule, FormElementMapModule, TestIdDirective, FormlyModule],
})
export class FormlyInputSwitchComponent extends FieldType {
  override defaultOptions = {
    props: {
      hideLabel: true,
    },
  };
}
