import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IVaccinationModel } from "@ankaadia/ankaadia-shared";
import { RequiredVaccinationsResultEntry, StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { FieldType } from "@ngx-formly/core";
import { first, groupBy, isEmpty, isEqual } from "lodash";
import { Message } from "primeng-v17/api";
import { DropdownModule } from "primeng-v17/dropdown";
import { MessageModule } from "primeng-v17/message";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  switchMap,
} from "rxjs";
import { CurrentCollectionService } from "../../features/candidates/current-collection.service";
import { OrganizationsService } from "../../features/organizations/organizations.service";
import { RequiredVaccinationsService } from "../../features/required-vaccinations/required-vaccinations.service";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { DropdownEditableColumnAutoFocusDirective } from "../../shared/primeng/dropdown-editable-column-auto-focus/dropdown-editable-column-auto-focus.directive";
import { DropdownHideFixDirective } from "../../shared/primeng/dropdown-hide-fix/dropdown-hide-fix.directive";
import { DropdownOptionsDirective } from "../../shared/primeng/dropdown-options/dropdown-options.directive";
import { DropdownPrePopulateSingleOptionDirective } from "../../shared/primeng/dropdown-pre-populate-single-option/dropdown-pre-populate-single-option.directive";
import { DropdownReadonlyFixDirective } from "../../shared/primeng/dropdown-readonly-fix/dropdown-readonly-fix.directive";
import { SettingsService } from "../../shared/services/settings.service";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-formly-vaccination-information",
  templateUrl: "./formly-vaccination-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateDirective,
    NgIf,
    DropdownModule,
    AppendToBodyDirective,
    DropdownEditableColumnAutoFocusDirective,
    DropdownHideFixDirective,
    DropdownOptionsDirective,
    DropdownPrePopulateSingleOptionDirective,
    DropdownReadonlyFixDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    NgFor,
    MessageModule,
    AsyncPipe,
  ],
})
export class FormlyVaccinationInformationComponent extends FieldType implements AfterViewInit {
  private readonly lang = this.transloco.getActiveLang();
  private readonly cacheKey = "vaccination-information-source-organization";

  sourceOrganizationControlSwitch = new FormControl<string>(undefined);

  organizationOptions$ = new BehaviorSubject<StaticDataModel[]>([]);
  hasOrganizationOptions$ = this.organizationOptions$.pipe(map((x) => !isEmpty(x)));

  vaccineStaticData$ = this.staticDataService.getStaticData(StaticDataType.Vaccination, this.lang);
  vaccineFunctionStaticData$ = this.staticDataService.getStaticData(StaticDataType.VaccineFunction, this.lang);

  messages: Message[] = [];
  showSourceOrganizationControlSwitch = false;

  constructor(
    private readonly settings: SettingsService,
    private readonly staticDataService: StaticDataService,
    private readonly transloco: TranslocoService,
    private readonly requiredVaccinesService: RequiredVaccinationsService,
    private readonly currentCollectionService: CurrentCollectionService,
    private readonly destroyRef: DestroyRef,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly organizationService: OrganizationsService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.getOptions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => this.organizationOptions$.next(x));

    this.organizationOptions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((options) => {
      const cached = localStorage.getItem(this.cacheKey);
      if (options.some((option) => option.value === cached)) this.sourceOrganizationControlSwitch.setValue(cached);
      else this.sourceOrganizationControlSwitch.setValue(first(options)?.value);
    });

    this.showSourceOrganizationControlSwitch =
      !this.settings.isCandidate && this.settings.organizationId != this.formState.organizationId;

    combineLatest([
      this.getRequiredVaccinations$(),
      this.getFullVaccines$(),
      this.vaccineStaticData$,
      this.vaccineFunctionStaticData$,
    ])
      .pipe(
        map(([required, current, vaccineStaticData, vaccineFunctionStaticData]) =>
          this.getMissingVaccines(required, current, vaccineStaticData, vaccineFunctionStaticData)
        ),
        distinctUntilChanged(isEqual),
        map((x) => this.getWarnings(x))
      )
      .subscribe((messages) => {
        this.messages = messages;
        this.changeDetector.detectChanges();
      });

    this.sourceOrganizationControlSwitch.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((vaccinationSource) => this.cacheVaccinationSource(vaccinationSource));
  }

  private getFullVaccines$(): Observable<string[]> {
    const formControl = this.field?.parent?.fieldGroup?.find((x) => x.key === "vaccinations")?.formControl;
    return formControl.valueChanges.pipe(
      startWith(formControl.value),
      map(
        (entry: IVaccinationModel[]) =>
          entry?.filter((x) => x.vaccinationStatus === "FULL")?.flatMap((v) => v.vaccinations) ?? []
      )
    );
  }

  private getRequiredVaccinations$(): Observable<RequiredVaccinationsResultEntry[]> {
    return this.getRequiredVaccinationsSourceOrgId$().pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(50),
      switchMap((x) => this.loadRequiredVaccinations$(x))
    );
  }

  private getRequiredVaccinationsSourceOrgId$(): Observable<string> {
    if (this.formState.organizationId == this.settings.organizationId) return of(this.settings.organizationId);

    return this.sourceOrganizationControlSwitch.valueChanges.pipe(
      startWith(this.sourceOrganizationControlSwitch.value)
    );
  }

  private loadRequiredVaccinations$(overviewOrganizationId: string): Observable<RequiredVaccinationsResultEntry[]> {
    if (!this.formState.organizationId || !this.formState.candidateId) return of([]);
    return this.requiredVaccinesService.get({
      overviewOrganizationId: overviewOrganizationId ?? this.formState.organizationId,
      candidateOrganizationId: this.formState.organizationId,
      candidateId: this.formState.candidateId,
    });
  }

  private getMissingVaccines(
    required: RequiredVaccinationsResultEntry[],
    current: string[],
    vaccineStaticData: StaticDataModel[],
    vaccineFunctionStaticData: StaticDataModel[]
  ): { type: string; missing: string[] }[] {
    const grouped: Record<string, RequiredVaccinationsResultEntry[]> = groupBy(required, (v) => v.vaccineFunction);

    return Object.entries(grouped)
      .map(([key, value]) => ({
        type: vaccineFunctionStaticData.find((v) => v.value === key)?.label,
        missing: value
          .flatMap((v) => v.vaccines)
          .filter((v) => !current.includes(v))
          .map((x) => vaccineStaticData.find((v) => v.value === x)?.label),
      }))
      .filter((x) => !isEmpty(x.missing));
  }

  private getWarnings(missing: { type: string; missing: string[] }[]): Message[] {
    return isEmpty(missing)
      ? []
      : missing.map((x) => ({
          severity: "warn",
          detail: `${translate("vaccinations.missing.title", { type: x.type })}: ${x.missing.join(", ")}`,
        }));
  }

  private getOptions(): Observable<StaticDataModel[]> {
    const ownerOption = {
      value: this.settings.organizationId,
      label: this.settings.organizationName,
    };

    if (this.isCandidateOwnerOrg()) return of([ownerOption]);

    const sharerOptions = this.getSharerOptions();

    return sharerOptions.pipe(map((x) => (this.settings.isLicensed ? [ownerOption, ...x] : x)));
  }

  private getSharerOptions(): Observable<StaticDataModel[]> {
    if (this.currentCollectionService.organizationId)
      return of([
        {
          value: this.currentCollectionService.organizationId,
          label: this.currentCollectionService.organizationName,
        },
      ]);

    if (!this.formState.candidateId || !this.formState.organizationId) return of([]);

    return this.organizationService
      .getOrganizationsSharingTabToCandidate(this.formState.candidateId, this.formState.organizationId, "vaccinations")
      .pipe(map((organizations) => organizations.map((org) => ({ value: org.id, label: org.name }))));
  }

  private isCandidateOwnerOrg(): boolean {
    return this.settings.organizationId === this.formState.organizationId;
  }

  private cacheVaccinationSource(vaccinationSource: string): void {
    if (!isEmpty(vaccinationSource)) localStorage.setItem(this.cacheKey, vaccinationSource);
  }
}
