import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { UserFeedbackEditDialogComponent } from "./user-feedback-edit-dialog/user-feedback-edit-dialog.component";
import { UserFeedbackComponent } from "./user-feedback.component";

@NgModule({
  imports: [SharedModule],
  declarations: [UserFeedbackComponent, UserFeedbackEditDialogComponent],
  exports: [UserFeedbackComponent, UserFeedbackEditDialogComponent],
})
export class UserFeedbackModule {}
