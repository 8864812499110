<button
  [disabled]="props.readonly"
  pButton
  type="button"
  [pTooltip]="props.translationTooltip"
  tooltipPosition="left"
  icon="pi pi-language"
  class="p-button-rounded p-button-text"
  (click)="openDialog($event)"
  *ngIf="translationMapping"
></button>

<p-editor
  spellcheck="true"
  [lang]="props.language"
  [style]="{ height: '120px' }"
  [readonly]="props.readonly"
  [formlyAttributes]="field"
  (onInit)="updateEditor($event.editor)"
  (onTextChange)="updateHtml()"
>
  <ng-template pTemplate="header">
    <span class="ql-formats">
      <select class="ql-size"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
    </span>
    <span class="ql-formats">
      <select class="ql-color"></select>
      <select class="ql-background"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-header" value="1"></button>
      <button class="ql-header" value="2"></button>
      <button class="ql-blockquote"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
      <button class="ql-indent" value="-1"></button>
      <button class="ql-indent" value="+1"></button>
    </span>
    <span class="ql-formats">
      <button class="ql-direction" value="rtl"></button>
      <select class="ql-align"></select>
    </span>
    <span class="ql-formats">
      <button class="ql-link"></button>
    </span>
  </ng-template>
</p-editor>
