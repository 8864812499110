import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { KnownConsentIdentifiers } from "@ankaadia/graphql";
import { TermsAndConditionsComponent } from "../../features/terms-and-conditions/terms-and-conditions.component";
import { TermsAndConditionsService } from "../../features/terms-and-conditions/terms-and-conditions.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-terms-and-conditions-page",
  templateUrl: "./terms-and-conditions-page.component.html",
  styleUrl: "./terms-and-conditions-page.component.scss",
  imports: [TranslateDirective, NgIf, TermsAndConditionsComponent, RouterLink],
})
export class TermsAndConditionsPageComponent implements OnInit {
  consentIdentifier: KnownConsentIdentifiers;
  consentGiverId: string;
  organizationId: string;

  termsAndCondition: any;
  resultFetched = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly termsAndConditionsService: TermsAndConditionsService
  ) {}

  ngOnInit(): void {
    this.setViewportHeight();
    window.addEventListener("resize", this.setViewportHeight);
    this.route.params.subscribe((params) => {
      this.consentGiverId = params["consentGiverId"];
      this.consentIdentifier = params["consentIdentifier"];
      this.organizationId = params["organizationId"];
    });
    this.termsAndConditionsService
      .getOutStandingConsents(this.consentIdentifier, this.consentGiverId, this.organizationId)
      .subscribe((consents) => {
        this.resultFetched = true;
        this.termsAndCondition = consents[0];
      });
  }

  setViewportHeight(): void {
    const vh100 = window.innerHeight;
    const vh90 = window.innerHeight * 0.9;
    const vw80 = window.innerWidth * 0.8;
    document.documentElement.style.setProperty("--vw80", `${vw80}px`);
    document.documentElement.style.setProperty("--vh100", `${vh100}px`);
    document.documentElement.style.setProperty("--vh90", `${vh90}px`);
  }
}
