import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FieldWrapper, FormlyModule } from "@ngx-formly/core";
import { CheckboxModule } from "primeng-v17/checkbox";
import { TooltipModule } from "primeng-v17/tooltip";

@Component({
  selector: "app-formly-field",
  templateUrl: "./formly-field.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, CheckboxModule, NgTemplateOutlet, TooltipModule, FormlyModule],
})
export class FormlyFieldComponent extends FieldWrapper {}
