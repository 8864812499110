import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType, FormlyModule } from "@ngx-formly/core";
import { InputTextareaModule } from "primeng-v17/inputtextarea";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-textarea",
  templateUrl: "./formly-textarea.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, InputTextareaModule, ReactiveFormsModule, FormElementMapModule, TestIdDirective, FormlyModule],
})
export class FormlyTextareaComponent extends FieldType {}
