<ng-container *appTranslate="let t">
  <div class="grid grid-nogutter">
    <div class="grid grid-nogutter col">
      <p-autoComplete
        class="col"
        styleClass="h-full"
        [readonly]="props.readonly"
        [suggestions]="resultSuggestions$ | async"
        [dropdown]="true"
        (completeMethod)="getSuggestions($event.query)"
        [formlyAttributes]="field"
        [ngModel]="getSelectedSuggestion(resultSuggestions$ | async)"
        (ngModelChange)="updateForm($event)"
      ></p-autoComplete>
      <div class="col-fixed flex justify-center align-items-center" *ngIf="displayInfoButton && !isCandidate">
        <p-button
          styleClass="p-button-rounded p-button-text"
          icon="pi pi-question-circle"
          (click)="openDetails()"
        ></p-button>
      </div>
    </div>
  </div>
</ng-container>

<p-sidebar [visible]="(showSidebar$ | async) && !!(details$ | async)" [closeOnEscape]="false" styleClass="p-sidebar-lg">
  <app-anabin-table
    [anabinData]="details$ | async"
    [language]="to.language"
    (closeComponent)="showSidebar$.next(false)"
  ></app-anabin-table>
</p-sidebar>
