import { Component, OnInit } from "@angular/core";
import { isNil } from "lodash";
import { DynamicDialogConfig } from "primeng-v17/dynamicdialog";
import { SettingsService } from "../../../shared/services/settings.service";
import { CandidateEditComponent } from "../candidate-edit/candidate-edit.component";
import { SelectedCandidateForEdit } from "../candidate-edit/candidate-edit.model";

@Component({
  selector: "app-candidate-flexible-edit",
  templateUrl: "./candidate-flexible-edit.component.html",
  styleUrl: "./candidate-flexible-edit.component.scss",
  imports: [CandidateEditComponent],
})
export class CandidateFlexibleEditComponent implements OnInit {
  candidateId: string;
  candidateOrganizationId: string;

  selectedCandidate: SelectedCandidateForEdit;

  constructor(
    private readonly settings: SettingsService,
    private readonly config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.selectedCandidate = this.getSelectedCandidate(
      this.config.data.candidateId,
      this.config.data.candidateOrganizationId
    );
  }

  private getSelectedCandidate(candidateId: string, candidateOrganizationId: string): SelectedCandidateForEdit {
    if (isNil(candidateId) || isNil(candidateOrganizationId)) return null;

    return {
      id: candidateId,
      organizationId: candidateOrganizationId,
      immigrationCountry: this.settings.standardImmigrationCountry,
    };
  }
}
