import { Component } from "@angular/core";
import { PrimeIcons } from "primeng/api";
import { TranslationProposalService } from "../translationProposal.service";

@Component({
  selector: "app-translation-proposal-button",
  templateUrl: "./translationProposalButton.component.html",
  styleUrl: "./translationProposalButton.component.scss",
})
export class TranslationProposalButtonComponent {
  protected icon = PrimeIcons.ERASER + " text-xl";

  constructor(protected readonly translationProposalService: TranslationProposalService) {}

  onClick(event: MouseEvent): void {
    this.translationProposalService.toggle(event.target);
    if (this.translationProposalService.isOn) {
      this.icon = PrimeIcons.POWER_OFF + " text-xl";
    } else {
      this.icon = PrimeIcons.ERASER + " text-xl";
    }
  }
}
