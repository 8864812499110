<ng-container *appTranslate="let t">
  <div class="topbar clearfix">
    <div class="logo">
      <a [routerLink]="['/app']">
        <img src="assets/layout/images/logo.png" />
      </a>
    </div>
    <a [routerLink]="['/app']">
      <img src="assets/layout/images/logo-text.svg" class="app-name" />
    </a>

    <a id="topbar-menu-button" href="javascript:void(0)" (click)="menuClicked.emit($event)">
      <i class="pi pi-bars"></i>
    </a>

    <a id="topbar-menu-button-2" href="javascript:void(0)">
      <app-message-success></app-message-success>
    </a>

    <ul class="topbar-menu fadeInDown" [class.topbar-menu-visible]="topbarMenuActive">
      <li #language class="language-item computer-only" [class.active-topmenuitem]="activeTopbarItem === language">
        <a href="javascript:void(0)" (click)="menuItemClicked.emit([$event, language])">
          <span class="topbar-icon flag flag-{{ (selectedLanguage | async).flag }}"></span>
          <span class="topbar-item-name">{{ userDisplayName }}</span>
        </a>

        <ul class="fadeInDown">
          <li role="menuitem" *ngFor="let language of availableLanguages">
            <a href="javascript:void(0)" class="white-space-nowrap" (click)="switchLanguage(language.code)">
              <span class="flag flag-{{ language.flag }}"></span>
              <span class="ml-2">{{ language.label }}</span>
            </a>
          </li>
        </ul>
      </li>

      <li #profile class="profile-item computer-only" [class.active-topmenuitem]="activeTopbarItem === profile">
        <a href="javascript:void(0)" (click)="menuItemClicked.emit([$event, profile])">
          <div class="profile-info" data-testid="profile-info" [attr.data-organization-code]="myOrganization.code">
            <span class="topbar-item-name profile-name">{{ userDisplayName }}</span>
            <span class="topbar-item-name profile-role" *ngIf="isCandidate">{{ myOrganization.name }}</span>
            <span class="topbar-item-name profile-role" *ngIf="!isCandidate">
              {{ myOrganization.name }} ({{ myOrganization.code }})
              <ng-container *ngIf="!myOrganization.isCreatorMasterOrganization && !myOrganization.isLicensed">
                {{ t("organization.providedBy", myOrganization) }}
              </ng-container>
            </span>
          </div>
        </a>

        <ul class="fadeInDown">
          <li role="menuitem">
            <a [routerLink]="['/app']" class="white-space-nowrap">
              <i class="pi pi-ankaadia"></i>
              <span>Ankaadia v{{ version }}</span>
            </a>
          </li>

          <li role="menuitem">
            <a href="javascript:void(0)" class="white-space-nowrap">
              <i class="pi pi-envelope"></i>
              <span (click)="copyUserEmailToClipboard()">{{ userEmail }}</span>
            </a>
          </li>

          <li *ngIf="acceptedTC | async" role="menuitem">
            <a [routerLink]="['/app/terms-and-conditions']" class="white-space-nowrap">
              <i class="pi pi-info-circle"></i>
              <span>{{ t("consent.title") }}</span>
            </a>
          </li>

          <li role="menuitem">
            <a href="javascript:void(0)" (click)="resetPassword($event)" class="white-space-nowrap">
              <i class="pi pi-replay"></i>
              <span>{{ t("password.reset") }}</span>
            </a>
          </li>

          <li role="menuitem" *ngIf="!isCandidate">
            <a
              href="javascript:void(0)"
              (click)="openOnlineApplicationCredentialHandling($event)"
              class="white-space-nowrap"
            >
              <i class="pi pi-desktop"></i>
              <span>{{ t("onlineApplication.credentials") }}</span>
            </a>
          </li>

          <li role="menuitem" *ngIf="!facebookUserId && IS_FACEBOOK_LOGIN_ENABLED">
            <a class="white-space-nowrap" (click)="connectAccountWithFacebook()" href="javascript:void(0)">
              <i class="pi pi-facebook"></i>
              <span>{{ t("firstLogin.connectFB") }}</span>
            </a>
          </li>

          <li role="menuitem" *ngIf="facebookUserId && IS_FACEBOOK_LOGIN_ENABLED">
            <a class="white-space-nowrap" (click)="disconnectAccountFromFacebook()" href="javascript:void(0)">
              <i class="pi pi-facebook"></i>
              <span>{{ t("firstLogin.disconnectFB") }}</span>
            </a>
          </li>

          <li role="menuitem" *ngIf="isMasterOrganization && IS_FACEBOOK_LOGIN_ENABLED">
            <a class="white-space-nowrap" (click)="sendTestMessage()" href="javascript:void(0)">
              <i class="pi pi-facebook"></i>
              <span>Send Testmessage to Facebook</span>
            </a>
          </li>

          <li role="menuitem" *ngIf="isMasterOrganization && IS_FACEBOOK_LOGIN_ENABLED">
            <a class="white-space-nowrap" (click)="loginNativeWithFacebook()" href="javascript:void(0)">
              <i class="pi pi-facebook"></i>
              <span>Login with Facebook Native</span>
            </a>
          </li>

          <li role="menuitem">
            <a class="white-space-nowrap" href="javascript:void(0)">
              <i class="pi pi-send"></i>
              <app-messages-reception (toggled)="toggleMessagesReception($event)"></app-messages-reception>
            </a>
          </li>

          <li role="menuitem">
            <a class="white-space-nowrap" href="javascript:void(0)">
              <i class="pi pi-language"></i>
              <app-communication-language></app-communication-language>
            </a>
          </li>

          <li role="menuitem" *ngIf="isCandidate">
            <a href="javascript:void(0)" (click)="deleteCandidateData($event)">
              <i class="topbar-icon pi pi-trash"></i>
              <span class="topbar-item-name">{{ t("candidateDeletion.title") }}</span></a
            >
          </li>

          <li role="menuitem">
            <a [routerLink]="['/logout']">
              <i class="pi pi-sign-out"></i>
              <span>{{ t("logout.title") }}</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="switchableOrganizationCount > 0" class="computer-only">
        <a
          href="javascript:void(0)"
          [pTooltip]="t('organization.switch')"
          data-testid="navbar-organization-switch"
          (click)="switch.show()"
        >
          <i class="pi pi-sitemap text-xl"></i>

          <app-organization-switch
            #switch
            [organizations]="myOtherOrganizations$ | async"
            [overlayOnly]="true"
            (filtered)="loadOrganizations($event)"
            (switched)="switchOrganization($event)"
          ></app-organization-switch>
        </a>
      </li>

      <li *ngIf="showNotificationCount$ | async" class="computer-only">
        <a href="javascript:void(0)" [pTooltip]="t('notifications.title')" (click)="notificationsOpened.emit($event)">
          <app-notification-badge [count]="notificationCount$ | async"></app-notification-badge>
        </a>
      </li>

      <li class="computer-only">
        <a href="javascript:void(0)">
          <app-translation-proposal-button></app-translation-proposal-button>
        </a>
      </li>

      <li class="computer-only">
        <a href="javascript:void(0)">
          <app-message-success></app-message-success>
        </a>
      </li>

      <li #user class="mobile-only" [class.active-topmenuitem]="activeTopbarItem === user">
        <a href="javascript:void(0)" (click)="menuItemClicked.emit([$event, user])">
          <span class="topbar-icon flag flag-small flag-{{ (selectedLanguage | async).flag }}"></span>
          <span class="topbar-item-name mobile-profile-name">{{ userDisplayName }}</span>
          <span class="topbar-item-name mobile-profile-role" *ngIf="isCandidate">{{ myOrganization.name }}</span>
          <span class="topbar-item-name mobile-profile-role" *ngIf="!isCandidate">
            {{ myOrganization.name }} ({{ myOrganization.code }})
            <ng-container *ngIf="!myOrganization.isCreatorMasterOrganization && !myOrganization.isLicensed">
              {{ t("organization.providedBy", myOrganization) }}
            </ng-container>
          </span>
        </a>

        <ul class="fadeInDown">
          <li role="menuitem" *ngFor="let language of availableLanguages">
            <a href="javascript:void(0)" class="white-space-nowrap" (click)="switchLanguage(language.code)">
              <span class="flag flag-small flag-{{ language.flag }}"></span>
              <span class="ml-2">{{ language.label }}</span>
            </a>
          </li>
        </ul>
      </li>

      <!-- <li class="mobile-only">
      <a [routerLink]="['/app']">
        <i class="topbar-icon pi pi-ankaadia"></i>
        <span class="topbar-item-name">Ankaadia v{{ version }}</span>
      </a>
    </li> -->

      <!-- <li class="mobile-only">
      <a href="javascript:void(0)">
        <i class="topbar-icon pi pi-envelope"></i>
        <span class="topbar-item-name" (click)="copyUserEmailToClipboard()">{{ userEmail }}</span>
      </a>
    </li> -->

      <li class="mobile-only" *ngIf="!facebookUserId && IS_FACEBOOK_LOGIN_ENABLED">
        <a class="white-space-nowrap" (click)="connectAccountWithFacebook()" href="javascript:void(0)">
          <i class="topbar-icon pi pi-facebook"></i>
          <span>{{ t("firstLogin.connectFB") }}</span>
        </a>
      </li>
      <li class="mobile-only" *ngIf="facebookUserId && IS_FACEBOOK_LOGIN_ENABLED">
        <a class="white-space-nowrap" (click)="disconnectAccountFromFacebook()" href="javascript:void(0)">
          <i class="topbar-icon pi pi-facebook"></i>
          <span>{{ t("firstLogin.disconnectFB") }}</span>
        </a>
      </li>

      <ng-container *ngFor="let section of sections">
        <li
          #item
          *ngIf="section.items.length > 0"
          class="mobile-only"
          [class.active-topmenuitem]="activeTopbarItem === item"
        >
          <ng-container *ngIf="!isCandidate">
            <a href="javascript:void(0)" (click)="menuItemClicked.emit([$event, item])">
              <i class="topbar-icon pi {{ section.icon }}"></i>
              <span class="topbar-item-name">{{ section.label }}</span>
              <span class="topbar-badge" *ngIf="section.badge | async as badge">{{ badge }}</span>
            </a>

            <ul class="fadeInDown">
              <li role="menuitem" *ngFor="let item of section.items">
                <a [routerLink]="item.routerLink">
                  <i class="pi pi-{{ item.icon }}"></i>
                  <span>{{ item.label }}</span>
                  <span class="topbar-submenuitem-badge" *ngIf="item.badge | async as badge">{{ badge }}</span>
                </a>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="isCandidate">
            <ng-container *ngIf="section.items[0] as item">
              <a [routerLink]="item.routerLink">
                <i class="topbar-icon pi {{ item.icon }}"></i>
                <span class="topbar-item-name">{{ item.label }}</span>
                <span class="topbar-badge" *ngIf="item.badge | async as badge">{{ badge }}</span>
              </a>
            </ng-container>
          </ng-container>
        </li>
      </ng-container>

      <li class="mobile-only">
        <a href="javascript:void(0)" (click)="feedbackOpened.emit($event)">
          <i class="topbar-icon pi pi-thumbs-up"></i>
          <span class="topbar-item-name">{{ t("feedback.title") }}</span>
        </a>
      </li>

      <li *ngIf="switchableOrganizationCount > 0" class="mobile-only">
        <a href="javascript:void(0)">
          <i class="topbar-icon pi pi-sitemap"></i>

          <app-organization-switch
            class="display-inline-block"
            [organizations]="myOtherOrganizations$ | async"
            (filtered)="loadOrganizations($event)"
            (switched)="switchOrganization($event)"
          ></app-organization-switch>
        </a>
      </li>

      <li *ngIf="helpUrl" class="mobile-only">
        <a [href]="helpUrl" target="_blank">
          <i class="topbar-icon pi pi-question-circle"></i>
          <span class="topbar-item-name">{{ t("help.title") }}</span>
        </a>
      </li>

      <li #item class="mobile-only" [class.active-topmenuitem]="activeTopbarItem === item">
        <a href="javascript:void(0)" (click)="menuItemClicked.emit([$event, item])">
          <i class="topbar-icon pi pi-comments"></i>
          <span class="topbar-item-name">{{ t("support") }}</span>
        </a>

        <ul class="fadeInDown support-contact">
          <li role="menuitem">
            <a>
              <i class="pi pi-envelope"></i>
              <span class="topbar-item-name cursor-copy" (click)="copyEmailToClipboard()"> {{ supportEmail }}</span>
            </a>
          </li>

          <li role="menuitem">
            <a>
              <i class="pi pi-phone"></i>
              <span class="topbar-item-name cursor-copy" (click)="copyPhoneToClipboard()">{{ supportPhone }}</span>
            </a>
          </li>
        </ul>
      </li>

      <li *ngIf="acceptedTC | async" class="mobile-only">
        <a [routerLink]="['/app/terms-and-conditions']">
          <i class="topbar-icon pi pi-info-circle"></i>
          <span class="topbar-item-name">{{ t("consent.title") }}</span>
        </a>
      </li>

      <li class="mobile-only">
        <a href="javascript:void(0)" (click)="resetPassword($event)">
          <i class="topbar-icon pi pi-replay"></i>
          <span class="topbar-item-name">{{ t("password.reset") }}</span>
        </a>
      </li>

      <li class="mobile-only">
        <a href="javascript:void(0)">
          <i class="topbar-icon pi pi-send"></i>
          <app-messages-reception (toggled)="toggleMessagesReception($event)"></app-messages-reception>
        </a>
      </li>

      <li class="mobile-only">
        <a href="javascript:void(0)">
          <i class="topbar-icon pi pi-language"></i>
          <app-communication-language [label]="t('userLanguage.titleShort')"></app-communication-language>
        </a>
      </li>

      <li class="mobile-only">
        <a [routerLink]="['/logout']">
          <i class="topbar-icon pi pi-sign-out"></i>
          <span class="topbar-item-name">{{ t("logout.title") }}</span>
        </a>
      </li>

      <li class="mobile-only" *ngIf="isCandidate">
        <a href="javascript:void(0)" (click)="deleteCandidateData($event)">
          <i class="topbar-icon pi pi-trash"></i>
          <span class="topbar-item-name">{{ t("candidateDeletion.title") }}</span>
        </a>
      </li>
    </ul>

    <div class="contact flex flex-row flex-wrap text-lg computer-only">
      <span class="pi pi-envelope m-1 text-lg support-contact"></span>
      <p class="cursor-copy support-contact" (click)="copyEmailToClipboard()">{{ supportEmail }}</p>
      <span class="pi pi-phone ml-4 m-1 text-lg support-contact"></span>
      <p class="cursor-copy support-contact" (click)="copyPhoneToClipboard()">{{ supportPhone }}</p>
      <p
        class="pl-5"
        *ngIf="promptUpdateService.minorUpdateAvailable$ | async"
        style="color: red"
        [pTooltip]="t('user.version.newVersionAvailableHint')"
      >
        {{ t("user.version.newVersionAvailable") }}
      </p>
      <p class="pl-5" *ngIf="showEnvironmentName" style="color: red">{{ environmentName }}</p>
    </div>

    <p-overlayPanel #delete [showCloseIcon]="true">
      <ng-template pTemplate>
        <div class="field">
          <label>{{ t("candidate.confirmNumber") }}:</label>
        </div>
      </ng-template>
    </p-overlayPanel>
  </div>

  <p-confirmDialog
    [key]="topBarConfirmationKey"
    [header]="t('common.confirm')"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>
</ng-container>
