import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { translate } from "@jsverse/transloco";
import { ConfirmDialogModule } from "primeng-v17/confirmdialog";
import { ConfirmPopupModule } from "primeng-v17/confirmpopup";
import { DialogService } from "primeng-v17/dynamicdialog";
import { ActivityService } from "../../features/activity/activity.service";
import { CandidateFlexibleEditComponent } from "../../features/candidates/candidate-flexible-edit/candidate-flexible-edit.component";
import { CandidateFlexibleEditService } from "../../features/candidates/candidate-flexible-edit/candidate-flexible-edit.service";
import { MessageErrorComponent } from "../../features/message/message-error/message-error.component";
import { NotificationsComponent } from "../../features/notifications/notifications.component";
import { TaskInboxService } from "../../features/process/task-inbox/task-inbox.service";
import { UserFeedbackComponent } from "../../features/user-feedback/user-feedback.component";
import { ConfirmPopupFixDirective } from "../../shared/primeng/confirm-popup-fix/confirm-popup-fix.directive";
import { DialogKeysDirective } from "../../shared/primeng/dialog-keys/dialog-keys.directive";
import { SettingsService } from "../../shared/services/settings.service";
import { FooterComponent } from "../footer/footer.component";
import { CustomMenuItem, IndexedMenuItem } from "../menu/menu.model";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { TopbarComponent } from "../topbar/topbar.component";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  imports: [
    TopbarComponent,
    SidebarComponent,
    RouterOutlet,
    FooterComponent,
    ConfirmPopupModule,
    ConfirmPopupFixDirective,
    DialogKeysDirective,
    ConfirmDialogModule,
    UserFeedbackComponent,
    NotificationsComponent,
    MessageErrorComponent,
  ],
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  private inboxTimer: number;
  private notificationTimer: number;

  activeTabIndex: number;
  sidebarActive: boolean;
  topbarMenuActive: boolean;
  sidebarClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  documentClickListener: any;

  @ViewChild(UserFeedbackComponent)
  userFeedback: UserFeedbackComponent;

  @ViewChild(NotificationsComponent)
  notifications: NotificationsComponent;

  @ViewChild("sidebar")
  sidebar: SidebarComponent;

  @ViewChild("candidateEditDialogProxy", { read: ElementRef })
  protected candidateEditDialogProxy: ElementRef;

  constructor(
    private readonly renderer: Renderer2,
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly settings: SettingsService,
    private readonly inboxService: TaskInboxService,
    private readonly activityService: ActivityService,
    private readonly candidateFlexibleEditService: CandidateFlexibleEditService,
    private readonly dialogService: DialogService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (this.settings.startUrl) {
      void this.router.navigate([this.settings.startUrl]);
    }

    const DELAY_2_SECONDS = 2 * 1000;
    const EVERY_5_MINUTES = 5 * 60 * 1000;
    const EVERY_30_MINUTES = 30 * 60 * 1000;

    window.setTimeout(() => this.inboxService.updateInboxCount(), DELAY_2_SECONDS);
    this.inboxTimer = window.setInterval(() => {
      this.inboxService.updateInboxCount();
    }, EVERY_30_MINUTES);

    if (!this.settings.isCandidate && this.settings.hasUserPermission) {
      window.setTimeout(() => this.activityService.updateNotificationCount(), DELAY_2_SECONDS);

      this.notificationTimer = window.setInterval(
        () => this.activityService.updateNotificationCount(),
        EVERY_5_MINUTES
      );
    }
  }

  ngAfterViewInit(): void {
    this.documentClickListener = this.renderer.listen("body", "click", () => {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.sidebarClick) {
        this.sidebarActive = false;
      }
      this.topbarItemClick = false;
      this.sidebarClick = false;
    });

    this.candidateFlexibleEditService
      .initOpenCandidateListener()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((candidate) => {
        this.dialogService
          .open(CandidateFlexibleEditComponent, {
            closeOnEscape: false,
            dismissableMask: false,
            resizable: true,
            closable: true,
            draggable: true,
            header: translate("candidate.edit.title"),
            data: candidate,
            maximizable: true,
            width: "80%",
            height: "80%",
            appendTo: this.candidateEditDialogProxy.nativeElement,
          })
          .onClose.subscribe(() => this.candidateFlexibleEditService.close());
      });
  }

  openSidebar(index: number): void {
    this.sidebarClick = true;
    this.sidebarActive = true;
    this.activeTabIndex = index;
    this.cd.detectChanges();
  }

  openFeedback(): void {
    this.userFeedback.open();
  }

  openNotifications(): void {
    this.notifications.open();
  }

  onTabClick(event: Event, index: number): void {
    if (this.settings.isCandidate) {
      const item = this.sidebar.sections.find((s) => s.index === index)?.items[0];
      if (item) this.navigateTo(item, index);
    } else {
      if (this.activeTabIndex === index) {
        this.sidebarActive = !this.sidebarActive;
      } else {
        this.activeTabIndex = index;
        this.sidebarActive = true;
      }
    }

    event.preventDefault();
  }

  onSidebarNavigation(item: IndexedMenuItem): void {
    this.navigateTo(item, item.index);
    this.sidebarActive = false;
  }

  navigateTo(item: CustomMenuItem, index: number): void {
    this.activeTabIndex = index;
    void this.router.navigate(item.routerLink, { relativeTo: this.route });
  }

  closeSidebar(event: Event): void {
    this.sidebarActive = false;
    event?.preventDefault();
  }

  onSidebarClick(): void {
    this.sidebarClick = true;
  }

  onTopbarMenuButtonClick(event: Event): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    event.preventDefault();
  }

  onTopbarItemClick(event: Event, item: any): void {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event: Event): void {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    clearInterval(this.inboxTimer);
    clearInterval(this.notificationTimer);
  }
}
