import { Routes } from "@angular/router";
import { ENABLE_APPLICATION_MASK, ENABLE_TALENT_POOL, ENABLE_UNIFIED_INBOX } from "@ankaadia/ankaadia-shared";
import { environment } from "../../environments/environment";
import { GueteGemeinschaftLinkHandlingComponent } from "../organization-specific/guetegemeinschaft/gueteGemeinschaftLinkHandling.component";
import { AccessDeniedComponent } from "../pages/access-denied/access-denied.component";
import { ErrorComponent } from "../pages/error/error.component";
import { LoginDeniedComponent } from "../pages/login-denied/login-denied.component";
import { FirstLoginComponent } from "../pages/login/firstLoginPage";
import { LoginComponent } from "../pages/login/login.component";
import { SSOLinkAccountAfterSSOLoginComponent } from "../pages/login/sso-linkaccount-after-sso-login";
import { SSOLoginRedirectComponent } from "../pages/login/sso-login-redirect.component";
import { LogoutComponent } from "../pages/logout/logout.component";
import { NotFoundComponent } from "../pages/not-found/not-found.component";
import { OrgSwitchComponent } from "../pages/org-switch/org-switch.component";
import { ProtectedShortLinkComponent } from "../pages/shortLink/protectedshortLink.component";
import { ShortLinkComponent } from "../pages/shortLink/shortLink.component";
import { TermsAndConditionsPageComponent } from "../pages/terms-and-conditions/terms-and-conditions-page.component";
import { UnsubscribeComponent } from "../pages/unsubscribe/unsubscribe.component";
import { PermissionGuard } from "../shared/guards/permission.guard";
import { Auth0CallbackComponent } from "./auth0Callback/auth0Callback.component";
import { LandingComponent } from "./landing/landing.component";
import { MainComponent } from "./main/main.component";
import { NewUiMainComponent } from "./main/new-ui-main.component";

export const routes: Routes = [
  { path: "consent/:consentIdentifier/:organizationId/:consentGiverId", component: TermsAndConditionsPageComponent },
  { path: "notfound", component: NotFoundComponent },
  { path: "accessdenied", component: AccessDeniedComponent },
  { path: "logindenied", component: LoginDeniedComponent },
  { path: "error", component: ErrorComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "callback", component: Auth0CallbackComponent },
  { path: "sso-login-redirect", component: SSOLoginRedirectComponent },
  { path: "firstlogin", component: FirstLoginComponent },
  {
    path: "guetegemeinschaft/recertification/:orgId",
    component: GueteGemeinschaftLinkHandlingComponent,
    canActivate: [PermissionGuard],
  },
  {
    path: "guetegemeinschaft/recertification",
    component: GueteGemeinschaftLinkHandlingComponent,
    canActivate: [PermissionGuard],
  },
  { path: "guetegemeinschaft/newRecertification", component: GueteGemeinschaftLinkHandlingComponent },
  { path: "switch/:orgId", component: OrgSwitchComponent, canActivate: [PermissionGuard] },
  { path: "switch/:orgId/:href", component: OrgSwitchComponent, canActivate: [PermissionGuard] },
  { path: "sso-link-account-afterlogin", component: SSOLinkAccountAfterSSOLoginComponent },
  { path: "unsubscribe", component: UnsubscribeComponent, canActivate: [PermissionGuard] },
  { path: "s/:hash", component: ShortLinkComponent },
  { path: "p/:hash", component: ProtectedShortLinkComponent, canActivate: [PermissionGuard] },
  ...(ENABLE_TALENT_POOL
    ? [
        {
          path: "talent-pool",
          loadChildren: () => import("../features/talent-pool/talent-pool-public.routes").then((m) => m.routes),
        },
      ]
    : []),
  ...(ENABLE_APPLICATION_MASK
    ? [
        {
          path: "candidate-application",
          loadChildren: () =>
            import("../features/candidate-application-mask/candidate-application-mask.routes").then((m) => m.routes),
        },
      ]
    : []),
  {
    path: "app",
    component: environment.newUI ? NewUiMainComponent : MainComponent,
    canActivate: [PermissionGuard],
    children: [
      { path: "", component: LandingComponent, canActivate: [PermissionGuard] },
      {
        path: "candidates",
        loadChildren: () => import("../features/candidates/candidates.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "collections",
        loadChildren: () => import("../features/collections/collections.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "settings",
        children: [
          {
            path: "organizations",
            loadChildren: () => import("../features/organizations/organizations.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "user-groups",
            loadChildren: () => import("../features/user-groups/user-groups.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "users",
            loadChildren: () => import("../features/users/users.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "consent",
            loadChildren: () => import("../features/consent/consents.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "audit-definitions",
            loadChildren: () => import("../features/audit-definitions/audit-definitions.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "document-requirements",
            loadChildren: () =>
              import("../features/document-requirements/document-requirement.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "system",
            loadChildren: () => import("../features/organizations/organizations.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "candidate-profile-config",
            loadChildren: () =>
              import("../features/candidate-profile-config/candidate-profile-config.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "local-partner-profile-config",
            loadChildren: () =>
              import("../features/local-partner-profile-config/local-partner-profile-config.routes").then(
                (m) => m.routes
              ),
            canActivate: [PermissionGuard],
          },
          ...(ENABLE_APPLICATION_MASK
            ? [
                {
                  path: "candidate-application-mask",
                  loadChildren: () =>
                    import(
                      "../features/candidate-application-mask-config/candidate-application-mask-config.routes"
                    ).then((m) => m.routes),
                  canActivate: [PermissionGuard],
                },
              ]
            : []),
          {
            path: "vaccination-config",
            loadChildren: () =>
              import("../features/required-vaccinations/required-vaccinations.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "process-models",
            loadChildren: () => import("../features/process-models/process-models.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "professions",
            loadChildren: () => import("../features/profession/profession.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "ankaadia-tools",
            loadChildren: () => import("../features/ankaadia-tools/ankaadia-tools.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "professional-fields",
            loadChildren: () =>
              import("../features/professional-field/professional-field.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "sharing-presets",
            loadChildren: () => import("../features/sharing-preset/sharing-preset.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "online-applications",
            loadChildren: () =>
              import("../features/online-application/online-application.routes").then((m) => m.routes),
          },
          {
            path: "add-on-services",
            loadChildren: () => import("../features/add-on-services/add-on-services.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
          {
            path: "api-config",
            loadChildren: () => import("../features/api-config/api-config.routes").then((m) => m.routes),
            canActivate: [PermissionGuard],
          },
        ],
      },
      {
        path: "activity",
        loadChildren: () => import("../features/activity/activity.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "dashboard",
        loadChildren: () => import("../features/dashboard/dashboard.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "processes",
        loadChildren: () => import("../features/process/process.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "process-models",
        loadChildren: () => import("../features/process-models/process-models.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "process-actions",
        loadChildren: () => import("../features/process-actions/process-actions.routes").then((m) => m.routes),
      },
      {
        path: "email-send-out-rules",
        loadChildren: () =>
          import("../features/email-send-out-rules/email-send-out-rules.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "profile",
        loadChildren: () => import("../features/candidate-profile/candidate-profile.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "employers",
        loadChildren: () => import("../features/employer/employer.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "documents",
        loadChildren: () => import("../features/candidate-documents/candidate-documents.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "terms-and-conditions",
        loadChildren: () => import("../features/user-consent/user-consent.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "documents",
        loadChildren: () => import("../features/documents/documents.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "labor-agreements",
        loadChildren: () => import("../features/labor-agreements/labor-agreement.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      {
        path: "education",
        loadChildren: () => import("../features/education/education.routes").then((m) => m.routes),
        canActivate: [PermissionGuard],
      },
      ...(ENABLE_TALENT_POOL
        ? [
            {
              path: "talent-pools",
              loadChildren: () => import("../features/talent-pool/talent-pool.routes").then((m) => m.routes),
              canActivate: [PermissionGuard],
            },
          ]
        : []),
      ...(ENABLE_UNIFIED_INBOX
        ? [
            {
              path: "inbox",
              loadChildren: () => import("../features/inbox/inbox.routes").then((m) => m.routes),
              canActivate: [PermissionGuard],
            },
          ]
        : []),
    ],
  },
  { path: "", redirectTo: "/app", pathMatch: "full" },
  { path: "**", redirectTo: "/notfound" },
];
