import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ensure2LetterIsoLanguage } from "@ankaadia/ankaadia-shared";
import { LogoComponent, SidenavComponent } from "@ankaadia/ui";
import { TranslocoService } from "@jsverse/transloco";
import { map, Observable } from "rxjs";
import { OrganizationFactoryService } from "../../organization-specific/organization-specific-factory.service";
import { OrganizationLogoPipe } from "../../shared/pipes/organization-logo.pipe";
import { SettingsService } from "../../shared/services/settings.service";

@Component({
  selector: "new-ui-navigation",
  templateUrl: "./new-ui-navigation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SidenavComponent, LogoComponent, RouterLink, AsyncPipe, OrganizationLogoPipe],
})
export class NewUiNavigationComponent {
  private readonly settings = inject(SettingsService);
  private readonly specificsFactory = inject(OrganizationFactoryService);
  private readonly transloco = inject(TranslocoService);

  protected readonly organizationId = this.settings.organizationId;
  protected readonly userDisplayName = this.settings.userDisplayName;
  protected readonly helpUrl$ = this.getHelpUrl();

  private getHelpUrl(): Observable<string | undefined> {
    return this.specificsFactory
      .getOrganizationSpecifics(this.settings.organizationId)
      .pipe(map((specifics) => this.getLocalizedUrl(specifics.getHelpUrl())));
  }

  private getLocalizedUrl(url: string): string {
    const defaultUrl = `${url}/en/home`;
    const lang = ensure2LetterIsoLanguage(this.transloco.getActiveLang())?.toLowerCase();
    const isCandidate = this.settings.isCandidate;

    if (!lang) return defaultUrl;

    if (isCandidate || lang === "de") {
      return `${url}/${lang}/home`;
    }

    return defaultUrl;
  }
}
