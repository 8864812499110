<ng-container *transloco="let t">
  <div class="p-fluid">
    <div class="field">
      <p-fieldset [legend]="t('translation.existing')">
        <p class="m-0" id="sourceText">
          {{ sourceText }}
        </p>
      </p-fieldset>
    </div>
    <p-message class="mt-2" *ngIf="manualChanges" severity="info" [text]="t('translation.hintManual')"></p-message>
    <div class="flex justify-content-center mt-2">
      <p-button
        [label]="t('translation.translate')"
        type="submit"
        icon="pi pi-language"
        class="mr-2"
        (onClick)="translate()"
      ></p-button>
    </div>

    <div class="field mt-2">
      <label for="translation">{{ t("translation.single") }}</label>
      <textarea
        *ngIf="sourceText"
        class="p-inputtextarea p-inputtext"
        id="translation"
        [(ngModel)]="currentTranslation"
        (input)="updateManualChangeState(true)"
      ></textarea>
      <p-editor
        *ngIf="sourceDelta"
        id="recommendationNote"
        spellcheck="false"
        [readonly]="false"
        (onInit)="updateEditor($event.editor)"
        (onTextChange)="updateTranslation()"
        [style]="{ height: '200px' }"
      >
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-size"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-header" value="1"></button>
            <button class="ql-header" value="2"></button>
            <button class="ql-blockquote"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-direction" value="rtl"></button>
            <select class="ql-align"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-link"></button>
          </span>
        </ng-template>
      </p-editor>
    </div>
    <div class="flex justify-content-end mt-4">
      <p-button
        [label]="t('common.ok')"
        type="submit"
        icon="pi pi-check"
        class="mr-2"
        [disabled]="
          currentTranslation == '' || currentTranslation == null || translationItem.targetText == currentTranslation
        "
        (onClick)="submit($event)"
      ></p-button>
      <p-button [label]="t('common.cancel')" type="cancel" icon="pi pi-times" (onClick)="cancel($event)"></p-button>
    </div>
  </div>
</ng-container>
