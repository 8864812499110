import { Injectable } from "@angular/core";
import { BaseStyle } from "primeng/base";

@Injectable({ providedIn: "root" })
export class NavitemStyle extends BaseStyle {
  override name = "navitem";

  override theme = ({ dt }: any): string => `
    .ui-navitem .ui-navitem-link {
      display: flex;
      align-items: center;
      user-select: none;
      gap: ${dt("navitem.link.gap")};
      padding: ${dt("navitem.link.padding")};
      border-radius: ${dt("navitem.link.border.radius")};
      background: ${dt("navitem.link.background")};
    }

    .ui-navitem .ui-navitem-link:link:hover {
      background: ${dt("navitem.link.hover.background")};
    }

    .ui-navitem .ui-navitem-icon {
      font-size: ${dt("navitem.icon.font.size")};
      color: ${dt("navitem.icon.color")};
    }

    .ui-navitem .ui-navitem-link:link:hover .ui-navitem-icon {
      color: ${dt("navitem.icon.hover.color")};
    }

    .ui-navitem .ui-navitem-label {
      color: ${dt("navitem.label.color")};
      font-weight: ${dt("navitem.label.font.weight")};
    }

    /* pre-bold so that there's no layout shift */
    .ui-navitem .ui-navitem-label::after {
      content: attr(data-label);
      display: block;
      font-weight: bold;
      overflow: hidden;
      visibility: hidden;
      height: 0;
    }
      
    .ui-navitem .ui-navitem-link:link:hover .ui-navitem-label {
      color: ${dt("navitem.label.hover.color")};
      font-weight: ${dt("navitem.label.hover.font.weight")};
    }
  `;

  override classes = {
    root: "ui-navitem",
    link: "ui-navitem-link",
    icon: "ui-navitem-icon",
    label: "ui-navitem-label",
  };
}
