import { Component, Input } from "@angular/core";
import { Activity } from "@ankaadia/graphql";
import { cloneDeep } from "lodash";
import { TableLazyLoadEvent } from "primeng/table";
import { finalize, Observable } from "rxjs";

@Component({
  selector: "app-activities-panel",
  templateUrl: "./activities-panel.component.html",
})
export class ActivitiesPanelComponent {
  private _pageFilter: TableLazyLoadEvent;
  private _reachedEnd = false;

  protected show = false;

  @Input({ required: true })
  source: (pageFilter: TableLazyLoadEvent) => Observable<Activity[]>;

  isLoading = false;
  activities: Activity[] = null;
  totalActivities = 0;

  open(): void {
    this.show = true;
  }

  onPageChange(filter: TableLazyLoadEvent): void {
    this._pageFilter = filter;
    this.load();
  }

  close(): void {
    this.show = false;
    this.activities = null;
    this.totalActivities = null;
  }

  private load(): void {
    if (!this._pageFilter) {
      return;
    }

    this.isLoading = true;
    this.source(this._pageFilter)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((nodes) => {
        this.activities = cloneDeep(nodes);
        this.updateTotalActivities(nodes, this._pageFilter);
      });
  }

  private updateTotalActivities(nodes: Activity[], filter: TableLazyLoadEvent): void {
    if (this._reachedEnd) {
      return;
    }

    if (nodes.length < filter.rows) {
      this._reachedEnd = true;
      this.totalActivities = filter.first + nodes.length;
    } else {
      this.totalActivities = Math.max(this.totalActivities, filter.first + 2 * filter.rows);
    }
  }
}
