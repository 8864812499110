<ng-container *appTranslate="let t; lang: processLanguage + '|static'">
  <p-splitter
    class="app-form"
    styleClass="app-form no-border email-splitter"
    [panelSizes]="[35, 65]"
    [minSizes]="[20, 20]"
    stateKey="ankaadia_email_splitter"
  >
    <ng-template pTemplate>
      <app-email-selector
        [emails]="emails"
        [showEventsDisabled]="showEventsDisabled"
        [showMessengerMessageDisabled]="showMessengerMessageDisabled"
        [showBannedVisible]="mode === 'outbox'"
        [processLanguage]="processLanguage"
        (openPreview)="openPreview($event)"
        (showEvents)="showEvents($event.email, $event.event)"
        (showMessengerMessages)="showMessengerMessage($event.email, $event.event)"
        (showBanned)="showBanned($event.email, $event.event)"
      >
        <ng-container *ngIf="nextOutboxRun">
          <span [pTooltip]="t('email.nextRun')" class="white-space-nowrap ml-3 mr-3 align-self-center">
            <span class="inline-block vertical-align-middle pr-1">
              {{ nextOutboxRun | appDateTime: { timeStyle: "short" } : processLanguage }}
            </span>
            <i class="pi pi-clock vertical-align-middle"></i>
          </span>
          <p-button
            *ngIf="directSending"
            icon="pi pi-send"
            class="align-self-center"
            [pTooltip]="t('emails.send')"
            [disabled]="isSending || readOnly"
            (onClick)="sendAll($event)"
          ></p-button>
        </ng-container>
      </app-email-selector>
    </ng-template>

    <ng-template pTemplate>
      <app-email-details
        [email]="selectedEmailForPreview"
        [outdated]="outdatedOutbox"
        [editable]="mode === 'outbox' && !readOnly"
        [processLanguage]="processLanguage"
        (update)="outdated.emit()"
        (send)="send($event.subject, $event.body)"
      ></app-email-details>
    </ng-template>
  </p-splitter>

  <p-overlayPanel #eventPanel>
    <ng-template pTemplate>
      <app-email-events [email]="selectedEmailForEvents" [processLanguage]="processLanguage"></app-email-events>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #messengerMessagePanel>
    <ng-template pTemplate>
      <app-email-messenger-message
        [messengerMessage]="messengerMessage"
        [lastState]="messengerMessageType | enum: 'MessengerEventType'"
        [lastSentDate]="messengerMessageLastSentDate"
        [errorMessage]="messengerErrorMessage"
        [processLanguage]="processLanguage"
      ></app-email-messenger-message>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #bannedPanel>
    <ng-template pTemplate>
      <app-email-banned [email]="selectedEmailForBanned" [processLanguage]="processLanguage"></app-email-banned>
    </ng-template>
  </p-overlayPanel>
</ng-container>
