import { Injectable } from "@angular/core";
import { BaseStyle } from "primeng/base";

@Injectable({ providedIn: "root" })
export class LogoStyle extends BaseStyle {
  override name = "logo";

  override theme = ({ dt }: any): string => `
    .ui-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      padding: ${dt("logo.padding")};
      border-radius: ${dt("logo.border.radius")};
      border-width: ${dt("logo.border.size")};
      border-color: ${dt("logo.border.color")};
      background: ${dt("logo.background")};
    }

    .ui-logo.ui-logo-borderless {
      border-color: ${dt("logo.borderless.border.color")};
      padding: ${dt("logo.borderless.padding")};
    }

    .ui-logo.ui-logo-small {
      width: ${dt("logo.size.small")};
      height: ${dt("logo.size.small")};
    }

    .ui-logo.ui-logo-normal {
      width: ${dt("logo.size.normal")};
      height: ${dt("logo.size.normal")};
    }

    .ui-logo.ui-logo-large {
      width: ${dt("logo.size.large")};
      height: ${dt("logo.size.large")};
    }

    .ui-logo .ui-logo-image {
      display: block;
      /* most logos are horizontal or square, so we fit to width */
      width: 100%;
    }
  `;

  override classes = {
    root: "ui-logo",
    image: "ui-logo-image",
  };
}
