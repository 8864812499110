import { ChangeDetectorRef, Component, DestroyRef, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { HighestPlacementEducationExamFragment, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { FieldType } from "@ngx-formly/core";
import { CalendarModule } from "primeng-v17/calendar";
import { InputTextModule } from "primeng-v17/inputtext";
import { TooltipModule } from "primeng-v17/tooltip";
import { combineLatest } from "rxjs";
import { EducationExamService } from "../../features/education/education-exams/education-exam.service";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { CalendarOptionsDirective } from "../../shared/primeng/calendar-options/calendar-options.directive";
import { CalendarReadonlyDirective } from "../../shared/primeng/calendar-readonly/calendar-readonly.directive";
import { CalendarUtcFixDirective } from "../../shared/primeng/calendar-utc-fix/calendar-utc-fix.directive";
import { StaticDataService } from "../../shared/static-data/static-data.service";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-formly-language-level-by-placement-test",
  templateUrl: "./formly-language-level-by-placement-test.component.html",
  imports: [
    TranslateDirective,
    TooltipModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    AppendToBodyDirective,
    CalendarOptionsDirective,
    CalendarUtcFixDirective,
    CalendarReadonlyDirective,
  ],
})
export class FormlyLanguageLevelByPlacementTestComponent extends FieldType implements OnInit {
  exam: HighestPlacementEducationExamFragment;
  languageLevelLabel: string = null;

  constructor(
    private readonly educationExamService: EducationExamService,
    private readonly transloco: TranslocoService,
    private readonly staticDataService: StaticDataService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formState.candidateId && this.formState.organizationId) {
      const language = this.transloco.getActiveLang();
      combineLatest([
        this.educationExamService
          .getHighestPlacementTest(this.formState.candidateId, this.formState.organizationId)
          .pipe(takeUntilDestroyed(this.destroyRef)),
        this.staticDataService.getStaticData(StaticDataType.LanguageModules, language),
      ]).subscribe(([exam, languageModules]) => {
        this.languageLevelLabel = languageModules.find((module) => module.value === exam?.languageLevel)?.label;
        this.exam = exam;
        this.changeDetector.detectChanges();
      });
    }
  }
}
