import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FieldWrapper, FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { PrimeTemplate } from "primeng-v17/api";
import { FieldsetModule } from "primeng-v17/fieldset";
import { TooltipModule } from "primeng-v17/tooltip";
import { debounceTime, distinctUntilChanged, map, merge, startWith } from "rxjs";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { hasNestedErrors } from "../tabs/formly-tabs.component";

@Component({
  selector: "app-formly-field-set",
  templateUrl: "./formly-field-set.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FieldsetModule, PrimeTemplate, NgIf, TooltipModule, FormElementMapModule, FormlyModule],
})
export class FormlyFieldSetComponent extends FieldWrapper implements OnInit {
  isInvalid = false;
  constructor(private readonly changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.props.showError) {
      this.setupInvalidityChange();
    }
  }

  private setupInvalidityChange(): void {
    merge(this.options.fieldChanges, this.form.statusChanges)
      .pipe(
        startWith(undefined),
        debounceTime(100),
        map(() => this.calculateInvalidity()),
        distinctUntilChanged()
      )
      .subscribe((invalidity) => this.setInvalidity(invalidity));
  }

  private calculateInvalidity(): boolean {
    const isInvalid = (field: FormlyFieldConfig): boolean =>
      field.fieldGroup?.length && !field.fieldArray
        ? field.fieldGroup.some((f) => isInvalid(f))
        : field.formControl.invalid && (!!field.formControl.errors || hasNestedErrors(field));
    return this.field.fieldGroup.some((field) => isInvalid(field));
  }

  private setInvalidity(invalidity: boolean): void {
    this.isInvalid = invalidity;
    this.changeDetector.detectChanges();
  }
}
