import { Injectable } from "@angular/core";
import { BaseStyle } from "primeng/base";

@Injectable({ providedIn: "root" })
export class SidenavStyle extends BaseStyle {
  override name = "sidenav";

  override theme = ({ dt }: any): string => `
    .ui-sidenav .ui-sidenav-list {
      display: flex;
      flex-direction: column;
      gap: ${dt("sidenav.list.gap")};
    }
  `;

  override classes = {
    root: "ui-sidenav",
    list: "ui-sidenav-list",
    item: "ui-sidenav-item",
  };
}
