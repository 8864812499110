import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, OnDestroy, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorCode, KeyOf, PERMISSION_EQUALITY, UserPermission, errorMessageKey } from "@ankaadia/ankaadia-shared";
import { ApplicationInsightsService } from "@ankaadia/ankaadia-shared-frontend";
import {
  AdditionalModules,
  GetImageTokenGQL,
  GetSettingsGQL,
  ImageAccessTokenResultFragment,
  Menu,
  Mutation,
  ProfileDataEditStatus,
  SharingSettings,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { ErrorResponse } from "@apollo/client/link/error";
import { AuthService } from "@auth0/auth0-angular";
import { translate } from "@jsverse/transloco";
import { GraphQLFormattedError } from "graphql";
import { isEmpty, zipObject } from "lodash";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  tap,
} from "rxjs";
import { environment } from "../../../environments/environment";
import { MessageService } from "../../features/message/message.service";
import { MessageDialogService } from "../message-dialog/message-dialog.service";
import { StaticDataPipe } from "../static-data/static-data.pipe";
import { IdleNotificationService } from "./idleNotification.service";
import { mobileAndTabletCheck } from "./mobileDevice.helper";
import { PromptUpdateService } from "./promptUpdateService";

interface KeyValuePair {
  key: string;
  value: string;
}

@Injectable({ providedIn: "root" })
export class SettingsService implements OnDestroy {
  static graphQLError$: Subject<ErrorResponse> = new Subject<ErrorResponse>();

  readonly userPermissions: UserPermission[] = [];
  auth0UserId: string;
  userOrCandidateId: string;
  userEmail: string;
  userDisplayName: string;
  userInitials: string;
  userGroups: string[];
  isCandidate: boolean;
  hasUserPermission: boolean;
  userDisplayId: string;
  organizationId: string;
  organizationName: string;
  organizationEmail: string;
  organizationCode: string;
  creatorOrganizationId: string;
  creatorOrganizationName: string;
  isCreatorMasterOrganization: boolean;
  isLicensed: boolean;
  isDemo: boolean;
  additionalModules: AdditionalModules;
  requireCandidateConsent: boolean;
  requireUserConsent: boolean;
  configToken: string;
  startUrl: string;
  autoCVConfigured: boolean;
  readonlyProfile: boolean;
  menu: Menu;
  sharingSettings: SharingSettings;
  isMasterOrganization: boolean;
  isMasterUser: boolean;
  maxFileSize: number;
  acceptedFileFormats: string;
  supportEmail: string;
  supportPhone: string;
  facebookUserId?: string;
  logoutPeriodInMinutes?: number;
  canEditOrganizationProfile: boolean;
  messagesReception;
  standardImmigrationCountry: SupportedImmigrationCountry;
  supportedImmigrationCountries: string[];
  communicationLanguage: string;
  profileDataEditStatus: ProfileDataEditStatus;
  removeCandidatesFromAllListsIfDropout: boolean;
  environmentName: string;
  showEnvironmentName: boolean;
  switchableOrganizationCount: number;
  isProcessActionEnabled: boolean;

  private readonly authSub: Subscription;
  private readonly graphQLErrorSub: Subscription;

  isAuthorized$ = new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean>;
  private internalAuthorized = false;
  private readonly imageSasTokens = new Map<string, ImageAccessTokenResultFragment>();

  hasAnyPermission(permissions: UserPermission[]): boolean {
    if (!permissions || permissions.length === 0) {
      return true;
    }
    return permissions.some(
      (p) => this.userPermissions?.includes(p) || this.userPermissions.includes(PERMISSION_EQUALITY[p])
    );
  }

  constructor(
    private readonly settings: GetSettingsGQL,
    private readonly staticDataPipe: StaticDataPipe,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly messageService: MessageService,
    private readonly msgDialogService: MessageDialogService,
    private readonly imageTokenQuery: GetImageTokenGQL,
    private readonly appInsights: ApplicationInsightsService,
    private readonly idleNotificationService: IdleNotificationService,
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.isAuthenticated$ = authService.isAuthenticated$;
    authService.isAuthenticated$
      .pipe(
        filter((auth) => !!auth),
        take(1)
      )
      .subscribe((auth) => this.doAuthorization(auth));
    this.graphQLError$.subscribe((error) => this.graphQLErrorHandling(error));
  }

  private graphQLErrorHandling(response: ErrorResponse): void {
    response.graphQLErrors?.map((e) => {
      type ErrorHandler = (error: GraphQLFormattedError, response: ErrorResponse) => boolean;
      const errorHandlers: ErrorHandler[] = [
        this.handleForbidden,
        this.handleUserInputError,
        this.handleConcurrencyViolation,
        this.handleAggregateError,
        this.handleVersionError,
      ];

      const handled = errorHandlers.map((x) => x.bind(this)).some((x) => x(e, response));
      if (handled) {
        return;
      }

      const extensions: Record<string, any> = e.extensions;
      response.graphQLErrors?.forEach((x) => this.appInsights.logException(<any>x, response.operation));
      let message: string = response.operation.operationName + "\n";
      response.graphQLErrors?.map((val) => (message += val.message + "\n"));
      if (response.networkError?.message) {
        message += response.networkError?.message + "\n";
      }
      if (extensions?.code) {
        message += extensions?.code + "\n";
      }
      if (extensions?.exception?.response?.message) {
        message += extensions?.exception?.response?.message + "\n";
      }
      if (extensions?.exception?.stacktrace) {
        if (extensions?.exception?.stacktrace.join) {
          message += extensions?.exception?.stacktrace.join("\n") + "\n";
        } else {
          message += extensions?.exception?.stacktrace + "\n";
        }
      }
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error(message, response);
      }
      this.messageService.add({ severity: "error", summary: message });
    });

    if (response.networkError != null) {
      this.appInsights.logException(response.networkError, response.operation);
      let message: string = response.operation.operationName + "\n";
      response.graphQLErrors?.map((val) => (message += val.message + "\n"));
      if (response.networkError?.message) {
        message += response.networkError?.message + "\n";
      }
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error(message, response);
      }
      this.messageService.add({ severity: "error", summary: message });
    }
  }

  private doAuthorization(authenticated: boolean): void {
    if (authenticated) {
      if (!this.internalAuthorized) {
        this.internalAuthorized = true;
        this.authService.user$
          .pipe(
            filter((user) => !!user),
            take(1)
          )
          .subscribe((user) => {
            if (user.sub.startsWith("auth0|")) {
              this.reload(true).subscribe();
            }
          });
      }
    }
  }

  getImageSasTokenAndOrigin(organizationId: string): Observable<{ origin: string; token: string }> {
    if (this.imageSasTokens.has(organizationId)) {
      const tokenInfo = this.imageSasTokens.get(organizationId);
      if (tokenInfo.expiresOn > new Date()) {
        return of({ origin: tokenInfo.origin, token: tokenInfo.sasToken });
      }
    }
    return this.fetchSassyToken(organizationId).pipe(
      tap((token) => this.imageSasTokens.set(organizationId, token)),
      map((token) => ({ origin: token.origin, token: token.sasToken }))
    );
  }

  fetchSassyToken(organizationId: string): Observable<ImageAccessTokenResultFragment> {
    return this.isAuthorized$.pipe(
      filter((x) => x),
      take(1),
      switchMap(() =>
        this.imageTokenQuery.fetch({ input: { organizationId } }).pipe(map((x) => x.data.getImageAccessToken))
      )
    );
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.graphQLErrorSub?.unsubscribe();
  }

  navigateToMain(): void {
    void this.router.navigate(["/"]);
  }

  navigateToNotFound(): void {
    void this.router.navigate(["/notfound"]);
  }

  navigateToAccessDenied(): void {
    void this.router.navigate(["/accessdenied"]);
  }

  navigateToLoginDenied(): void {
    void this.router.navigate(["/logindenied"]);
  }

  switchOrganization(organizationId: string): void {
    void this.router.navigate(["/switch", organizationId]);
  }

  switchOrganizationKeepingCurrentUrl(organizationId: string): void {
    void this.router.navigate(["/switch", organizationId, this.document.defaultView.location.href]);
  }

  get graphQLError$(): Subject<ErrorResponse> {
    return SettingsService.graphQLError$;
  }

  // Thr url must be white listed in the auth0 application settings
  logoutWithRedirecht(redirectUrl: string): void {
    this.appInsights.clearUserId();
    this.authService.logout({ logoutParams: { returnTo: redirectUrl } });
  }

  logout(): void {
    this.appInsights.clearUserId();
    // Thr url must be white listed in the auth0 application settings
    this.authService.logout({ logoutParams: { returnTo: window.location.origin } });
  }

  reload(firstTime = false, forceReload = false): Observable<void> {
    return new Observable((obs) => {
      this.settings
        .fetch({ firstLoadInSession: firstTime }, { fetchPolicy: forceReload ? "network-only" : "cache-first" })
        .subscribe({
          next: (val) => {
            if (val.data.settings.reloadAndReAuthenticate) {
              this.document.defaultView.location.href = this.route.snapshot.params.href ?? "/";
            }
            const settings = val.data.settings;
            this.userPermissions.length = 0;
            this.userPermissions.push(...(<UserPermission[]>settings.userPermissions));
            this.organizationId = settings.organizationId;
            this.auth0UserId = settings.auth0UserId;
            this.userOrCandidateId = settings.candidateOrUserId;
            this.organizationCode = settings.organizationCode;
            this.organizationName = settings.organizationName;
            this.organizationEmail = settings.organizationEmail;
            this.configToken = settings.configToken;
            this.creatorOrganizationId = settings.creatorOrganizationId;
            this.creatorOrganizationName = settings.creatorOrganizationName;
            this.isCreatorMasterOrganization = settings.isCreatorMasterOrganization;
            this.isCandidate = settings.isCandidate;
            this.isLicensed = settings.licensed;
            this.additionalModules = settings.additionalModules;
            this.requireCandidateConsent = settings.requireCandidateConsent;
            this.requireUserConsent = settings.requireUserConsent;
            this.userGroups = settings.userGroups;
            this.startUrl = settings.startUrl;
            this.autoCVConfigured = settings.autoCVConfigured;
            this.profileDataEditStatus = settings.profileDataEditStatus;
            this.readonlyProfile = settings.profileDataEditStatus === ProfileDataEditStatus.ReadOnly;
            this.menu = settings.menu;
            this.sharingSettings = settings.sharingSettings;
            this.isDemo = settings.isDemo;
            this.maxFileSize = settings.maxSize;
            this.acceptedFileFormats = settings.formats;
            this.isMasterOrganization = settings.isMasterOrganization;
            this.supportEmail = settings.supportEmail;
            this.supportPhone = settings.supportPhone;
            this.facebookUserId = settings.facebookUserId;
            this.userDisplayId = settings.userDisplayId;
            this.logoutPeriodInMinutes = settings.logoutPeriodInMinutes;
            this.canEditOrganizationProfile = settings.canEditOrganizationProfile;
            this.messagesReception = settings.messagesReception;
            this.standardImmigrationCountry = settings.standardImmigrationCountry;
            this.supportedImmigrationCountries = settings.supportedImmigrationCountries;
            this.userEmail = settings.userEmail;
            this.isMasterUser = settings.isMasterUser;
            this.userDisplayName = settings.userDisplayName;
            this.userInitials = settings.userInitials;
            this.hasUserPermission = this.hasAnyPermission([UserPermission.User]);
            this.communicationLanguage = settings.communicationLanguage;
            this.removeCandidatesFromAllListsIfDropout = settings.removeCandidatesFromAllListsIfDropout;
            this.showEnvironmentName = settings.showEnvironmentName;
            this.environmentName = settings.environmentName;
            this.switchableOrganizationCount = settings.switchableOrganizationCount;
            this.isProcessActionEnabled = settings.isProcessActionEnabled;

            if (isEmpty(this.userPermissions)) {
              return this.navigateToAccessDenied();
            }
            if (firstTime) {
              this.appInsights.setUserId(this.userOrCandidateId);
              this.isAuthorized$.next(true);
              this.internalAuthorized = true;
              if (!this.isCandidate) {
                if (this.logoutPeriodInMinutes && this.logoutPeriodInMinutes > 0) {
                  this.idleNotificationService.startWatching(this.logoutPeriodInMinutes).subscribe(() => this.logout());
                }
              } else {
                // in case of candidate and not mobile device we logout after 15 minutes
                if (!mobileAndTabletCheck()) {
                  this.idleNotificationService.startWatching(15).subscribe(() => this.logout());
                }
              }
            }
            obs.next();
            obs.complete();
          },
          error: (e) => {
            if (firstTime) {
              this.internalAuthorized = false;
              this.isAuthorized$.next(false);

              if (e.graphQLErrors?.some((x) => x?.extensions?.code === ErrorCode.FORBIDDEN)) {
                this.navigateToLoginDenied();
              }
            }
            obs.error();
            obs.complete();
          },
        });
    });
  }

  private handleForbidden(error: GraphQLFormattedError): boolean {
    const extensions: Record<string, any> = error.extensions;

    if (extensions?.exception?.status !== 403 && extensions?.code !== ErrorCode.FORBIDDEN) return false;

    this.navigateToAccessDenied();
    return true;
  }

  private handleUserInputError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.BAD_USER_INPUT) return false;

    this.appInsights.logEvent("BAD_USER_INPUT");

    const extensions: Record<string, any> = error.extensions;
    const { errorKey, translatedMessages } = this.translateMessages(error);

    if (errorKey) {
      translatedMessages.subscribe((errorMessages) => {
        this.msgDialogService.showMessage(
          translate("user.badInput.title"),
          translate(`graphql.${errorKey}`, errorMessages)
        );
      });
    } else {
      this.messageService.add({
        severity: "error",
        summary: extensions.response?.message?.join("\n") ?? error.message,
      });
    }

    return true;
  }

  private handleConcurrencyViolation(error: GraphQLFormattedError, response: ErrorResponse): boolean {
    if (error.extensions?.code !== ErrorCode.CONCURRENCY_VIOLATION) {
      return false;
    }

    // Ignore concurrency errors for specific operations
    type MutationOperarion = KeyOf<Mutation>;
    const ignoredOperations: MutationOperarion[] = ["updateDocument", "createDocument"];
    const operationName = response.operation.operationName as MutationOperarion;
    if (ignoredOperations.includes(operationName) && response.operation.variables?.input?.ignoreConcurrencyError) {
      return true;
    }

    this.appInsights.logEvent(ErrorCode.CONCURRENCY_VIOLATION);
    this.msgDialogService.showMessage(translate("user.concurrency.title"), translate("user.concurrency.message"));

    return true;
  }

  private handleAggregateError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.AGGREGATEERROR) return false;

    const extensions: Record<string, any> = error.extensions;

    this.appInsights.logEvent(ErrorCode.AGGREGATEERROR);

    const errorMessages: {
      errorKey: errorMessageKey;
      translatedMessages: Observable<Record<string, string>>;
    }[] = extensions.errors?.map((error) => this.translateMessages(error) ?? []);

    const groupedMessages = forkJoin(
      errorMessages.map((x) =>
        x.translatedMessages.pipe(
          map((translatedMessages) => ({
            errorKey: x.errorKey,
            translatedMessages,
          }))
        )
      )
    );

    groupedMessages.subscribe((groupedMessages) => {
      const errorMessagesString: string[] = [];
      groupedMessages
        .filter((x) => x.errorKey)
        .forEach((messages) => {
          const errorMessage = translate(`graphql.${messages.errorKey}`, messages.translatedMessages);
          errorMessagesString.push(errorMessage);
        });

      if (errorMessagesString.length === 0)
        errorMessagesString.push(extensions.response?.message?.join("\n") ?? error.message);

      this.msgDialogService.showMessage(translate("user.badInput.title"), errorMessagesString);
    });

    return true;
  }

  private handleVersionError(error: GraphQLFormattedError): boolean {
    if (error.extensions?.code !== ErrorCode.VERSIONERROR) return false;
    // eslint-disable-next-line no-console
    console.log("New updates detected");
    // Can't  be injected in the constructor because of circular dependency or other reasons lost 4 hours on this
    inject(PromptUpdateService).checkForSoftwareUpdate();
    return true;
  }

  private translateMessages(userInputError: GraphQLFormattedError): {
    errorKey: errorMessageKey | null;
    translatedMessages: Observable<Record<string, string>>;
  } {
    if (userInputError.extensions?.code !== ErrorCode.BAD_USER_INPUT) throw new Error("Not a user input error");

    const extensions: Record<string, any> = userInputError.extensions;

    const properties = (extensions?.properties as KeyValuePair[]) ?? [];
    const messagesProperties = properties.filter((x) => x.key && x.value && x.key != "errorMessageKey");
    const errorKey = properties.find((x) => x.key === "errorMessageKey")?.value as errorMessageKey | null;

    const isAlreadyUploadedErrorKey = [
      errorMessageKey.DOCUMENTSALREADYUPLOADED,
      errorMessageKey.NOTARIZATIONSALREADYUPLOADED,
      errorMessageKey.TRANSLATIONSALREADYUPLOADED,
    ].includes(errorKey);

    const translatedMessages = messagesProperties.reduce<
      Observable<{ messageKey: string; translatedMessage: string }>[]
    >((acc, pair: KeyValuePair) => {
      let translatedMessage = of(pair.value);
      if (pair.key.startsWith("STATICDATA:")) {
        const [, type, enumKey] = pair.value.split(":");
        translatedMessage = this.staticDataPipe
          .transform(enumKey, type as StaticDataType)
          .pipe(map((x) => (isAlreadyUploadedErrorKey && pair.key === "details" ? x : `"${x}"`)));
      }
      acc.push(translatedMessage.pipe(map((translatedMessage) => ({ messageKey: pair.key, translatedMessage }))));
      return acc;
    }, []);

    if (translatedMessages.length === 0) {
      return {
        errorKey,
        translatedMessages: of({}),
      };
    }

    return {
      errorKey,
      translatedMessages: forkJoin(translatedMessages).pipe(
        map((x) =>
          zipObject(
            x.map((x) => x.messageKey),
            x.map((x) => x.translatedMessage)
          )
        )
      ),
    };
  }
}
