<ng-container *appTranslate="let t; lang: language + '|static'">
  <p-card [ngClass]="cardClass">
    <div class="flex flex-column app-form p-card-content">
      <p-toolbar *ngIf="title || newOperations?.length" styleClass="mb-4">
        <ng-template *ngIf="title" pTemplate="left">
          <h3>{{ title }}</h3>
        </ng-template>
        <ng-template *ngIf="newOperations?.length" pTemplate="right">
          <ng-container *ngFor="let op of newOperations; index as i">
            <ng-container *ngIf="!op.canOperate || op.canOperate(null)">
              <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
                <ng-container
                  *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: newOp }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
                <ng-container
                  *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: newOp, i: i }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="op.mode == TableOperationMode.Menu">
                <ng-container
                  *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: newOp, i: i }"
                ></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </p-toolbar>

      <p-table
        #dt
        [value]="items | tableSortable"
        [columns]="columns"
        [rows]="numberOfRows"
        [paginator]="paginator"
        [globalFilterFields]="globalFilterFields"
        [rowHover]="true"
        [scrollable]="scrollable"
        [sortField]="sortField ?? columns?.[0].fieldname"
        [sortOrder]="sortOrder ?? 1"
        [customSort]="true"
        (sortFunction)="sort($event)"
        [selectionMode]="selectionMode"
        [selection]="selection"
        (onRowSelect)="selectionChange.emit($event.data)"
        (onRowUnselect)="selectionChange.emit(null)"
        dataKey="id"
        class="app-form"
        styleClass="p-datatable app-form {{ selectionMode ? 'no-focus' : '' }}"
        [ngClass]="styleClass"
        [virtualScroll]="virtualScroll"
      >
        >
        <ng-template pTemplate="caption">
          <div *ngIf="warningMessage">
            <p class="p-error">{{ warningMessage }}</p>
          </div>
          <div
            class="flex flex-column md:flex-row justify-content-between md:align-items-center table-header"
            [class.md:justify-content-end]="!caption"
          >
            <div class="flex-1 mr-2 mw-1" *ngIf="captionTemplate">
              <div class="grid">
                <div class="col flex-1">
                  <ng-container *ngTemplateOutlet="captionTemplate"></ng-container>
                </div>
                <div class="col-fixed flex" style="min-width: 40%">
                  <ng-container *ngTemplateOutlet="search"></ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="caption" class="mr-2">
              {{ caption }}
            </div>
            <ng-container *ngIf="!captionTemplate">
              <ng-container *ngTemplateOutlet="search"></ng-container>
            </ng-container>

            <ng-container *ngFor="let op of captionOperations; index as i">
              <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
                <ng-container
                  *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: captionOp }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
                <ng-container
                  *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: captionOp, i: i }"
                ></ng-container>
              </ng-container>
              <ng-container *ngIf="op.mode == TableOperationMode.Menu">
                <ng-container
                  *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: captionOp, i: i }"
                ></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let col of columns">
              <th *appPermissions="col.permission" [pSortableColumn]="col.sortable ? col.fieldname : null">
                {{ col.header }}
                <p-sortIcon *ngIf="col.sortable" [field]="col.fieldname"></p-sortIcon>
              </th>
            </ng-container>
            <th *ngIf="tableOperations?.length"></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr *ngIf="selectionMode" [pSelectableRow]="rowData">
            <ng-container *ngTemplateOutlet="tableRow; context: { columns: columns, rowData: rowData }"></ng-container>
          </tr>
          <tr *ngIf="!selectionMode">
            <ng-container *ngTemplateOutlet="tableRow; context: { columns: columns, rowData: rowData }"></ng-container>
          </tr>
        </ng-template>

        <ng-template *ngIf="footerOperations?.length" pTemplate="summary">
          <ng-container *ngFor="let op of footerOperations; index as i">
            <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
              <ng-container
                *ngTemplateOutlet="button; context: { op: op, label: op.label, rowData: footerOp }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
              <ng-container
                *ngTemplateOutlet="splitButton; context: { op: op, label: op.label, rowData: footerOp, i: i }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="op.mode == TableOperationMode.Menu">
              <ng-container
                *ngTemplateOutlet="menu; context: { op: op, label: op.label, rowData: footerOp, i: i }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </p-table>
    </div>
  </p-card>

  <p-sidebar
    #sidebar
    [(visible)]="showSidebar"
    [styleClass]="sideBarSizeMapping[sidebarSize]"
    [fullScreen]="sidebarSize == 'full'"
    [closeOnEscape]="false"
  >
    <ng-template pTemplate="header">
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="showArrows && !toggleArrow"
        pButton
        type="button"
        (click)="sidebarSize = 'medium'"
        icon="pi pi-arrow-right"
      ></button>
      <button
        class="mr-2 p-button-rounded p-button-text"
        *ngIf="showArrows && toggleArrow"
        pButton
        type="button"
        (click)="sidebarSize = 'full'"
        icon="pi pi-arrow-left"
      ></button>
    </ng-template>

    <ng-content></ng-content>
  </p-sidebar>

  <ng-template #tableRow let-rowData="rowData" let-columns="columns">
    <ng-container *ngFor="let col of columns; index as i">
      <td *appPermissions="col.permission" [class.flex-wrap]="!!col.tags" [class]="calculatedClassNames[i][rowData.id]">
        <span [pTooltip]="calculatedTooltips[i][rowData.id]" tooltipStyleClass="p-tooltip-fluid">
          <ng-container *ngIf="col.isLoading && col.isLoading(rowData); else notLoading">
            {{ t("loading") }}
          </ng-container>
          <ng-template #notLoading>
            <ng-container *ngIf="col.routeLink && col.routeLink(rowData); else plain">
              <ng-container *ngIf="col.pipeDescription">
                <ng-container *ngIf="col.includeFlag">
                  <app-flag
                    class="mr-2"
                    *ngIf="rowData.id"
                    [country]="rowData?.countryValue ?? rowData?.candidateOriginCountry"
                    [small]="true"
                  ></app-flag
                ></ng-container>
                <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">
                  {{
                    get(rowData, col.fieldname) ?? col.default
                      | dynamic: col.pipeDescription.pipeType : col.pipeDescription.pipeArguments
                      | async
                  }}
                </a>
              </ng-container>
              <ng-container *ngIf="!col.pipeDescription">
                <ng-container *ngIf="col.includeFlag">
                  <app-flag
                    class="mr-2"
                    *ngIf="rowData.id"
                    [country]="rowData?.country ?? rowData?.candidateOriginCountry"
                    [small]="true"
                  ></app-flag>
                </ng-container>
                <a href="javascript:void(0)" [routerLink]="col.routeLink(rowData)">
                  {{ get(rowData, col.fieldname) }}
                </a>
              </ng-container>
            </ng-container>
            <ng-template #plain>
              <ng-container *ngIf="col.icon">
                <ng-container *ngIf="calculatedIcons[i][rowData.id] as icon">
                  <ng-container
                    *ngIf="col.iconClick && (!col.canIconClick || col.canIconClick(rowData)); else plainIcon"
                  >
                    <a href="javascript:void(0)" (click)="col.iconClick(rowData, $event)">
                      <i class="text-2xl vertical-align-middle" [ngClass]="icon"></i>
                    </a>
                  </ng-container>
                  <ng-template #plainIcon>
                    <i class="text-2xl vertical-align-middle" [ngClass]="icon"></i>
                  </ng-template>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(!col.tags && !col.icon) || col.forceDisplayField">
                <ng-container *ngIf="col.pipeDescription">
                  <span class="vertical-align-middle">
                    {{
                      get(rowData, col.fieldname) ?? col.default
                        | dynamic: col.pipeDescription.pipeType : col.pipeDescription.pipeArguments
                        | async
                    }}
                  </span>
                </ng-container>
                <ng-container *ngIf="!col.pipeDescription">
                  <span class="vertical-align-middle">
                    {{ get(rowData, col.fieldname) }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="col.tags">
                <app-table-tag
                  *ngFor="let tag of calculatedTags[i][rowData.id] | async"
                  [column]="col"
                  [rowData]="rowData"
                  [tag]="tag"
                />
              </ng-container>
            </ng-template>
          </ng-template>
        </span>
      </td>
    </ng-container>
    <td *ngIf="tableOperations?.length" class="button-cell">
      <ng-container *ngFor="let op of tableOperations; index as i">
        <ng-container *ngIf="!op.canOperate || op.canOperate(rowData)">
          <ng-container *ngIf="!op.mode || op.mode == TableOperationMode.Button">
            <ng-container *ngTemplateOutlet="button; context: { op: op, rowData: rowData }"></ng-container>
          </ng-container>
          <ng-container *ngIf="op.mode == TableOperationMode.SplitButton">
            <ng-container *ngTemplateOutlet="splitButton; context: { op: op, rowData: rowData, i: i }"></ng-container>
          </ng-container>
          <ng-container *ngIf="op.mode == TableOperationMode.Menu">
            <ng-container *ngTemplateOutlet="menu; context: { op: op, rowData: rowData, i: i }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </td>
  </ng-template>

  <ng-template #button let-op="op" let-rowData="rowData" let-label="label">
    <p-button
      *appPermissions="op.permission"
      [pTooltip]="op.label !== label ? op.label : null"
      [label]="label"
      [icon]="getButtonIcon(op.icon, rowData)"
      class="p-button-success mr-2"
      [styleClass]="op.styleClass"
      (onClick)="startOperation($event, rowData, op.operation)"
      [disabled]="getDisabledState(rowData, op.disabled)"
    ></p-button>
  </ng-template>

  <ng-template #splitButton let-op="op" let-rowData="rowData" let-label="label" let-i="i">
    <p-splitButton
      #splitBtn
      *appPermissions="op.permission"
      [pTooltip]="op.label !== label ? op.label : null"
      [label]="label"
      [icon]="getButtonIcon(op.icon, rowData)"
      class="inline-block p-splitbutton-success mr-2"
      styleClass="p-splitbutton-success {{ op.styleClass ?? '' }}"
      [disabled]="getDisabledState(rowData, op.disabled)"
      (onClick)="eventTarget = null; startOperation($event, rowData, op.operation)"
      (onDropdownClick)="eventTarget = splitBtn?.containerViewChild?.nativeElement"
      [model]="calculatedButtonItems[rowData.id][i]"
    ></p-splitButton>
  </ng-template>

  <ng-template #search>
    <div class="flex-1 p-input-icon-left mr-2 table-search" *ngIf="showSearch">
      <i class="pi pi-search"></i>
      <input
        pInputText
        [(ngModel)]="searchInput"
        type="text"
        (input)="dt.filterGlobal($any($event.target).value, filterName)"
        [placeholder]="t('common.search')"
      />
    </div>
  </ng-template>

  <ng-template #menu let-op="op" let-rowData="rowData" let-label="label" let-i="i">
    <p-menu #menu [popup]="true" [model]="calculatedButtonItems[rowData.id][i]"></p-menu>
    <p-button
      *appPermissions="op.permission"
      [pTooltip]="op.label !== label ? op.label : null"
      [label]="label"
      [icon]="getButtonIcon(op.icon, rowData)"
      class="p-button-success mr-2"
      [styleClass]="op.styleClass"
      (onClick)="eventTarget = $event.target; menu.toggle($event)"
      [disabled]="getDisabledState(rowData, op.disabled)"
    ></p-button>
  </ng-template>
</ng-container>
