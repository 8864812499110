<p-card *ngIf="anabinData" [header]="anabinData.description[0].value">
  <ng-container *appTranslate="let t; lang: language">
    <ng-template pTemplate="body">
      <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
        <p-accordionTab [header]="t('description.title')">
          <table class="w-full">
            <tr *ngFor="let description of anabinData.description">
              <td *ngIf="anabinData.__typename === 'AnabinDegreesForInfoView'" class="font-bold">
                {{ t($any("anabin.degree." + description.key)) }}
              </td>
              <td *ngIf="anabinData.__typename === 'AnabinInstitutionInfoViewDetails'" class="font-bold">
                {{ t($any("anabin.institution." + description.key)) }}
              </td>
              <td *ngIf="description.key === 'country'">
                {{ description.value | staticData: StaticDataType.Countries | async }}
              </td>
              <td *ngIf="description.key !== 'country'">
                {{ description.value }}
              </td>
            </tr>
          </table>
        </p-accordionTab>
        <ng-container *ngIf="anabinData.__typename === 'AnabinDegreesForInfoView'">
          <p-accordionTab [header]="t('anabin.degree.ratings.title')" *ngIf="anabinData.ratings.length > 0">
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'ratings', t: t }"></ng-container>
          </p-accordionTab>

          <p-accordionTab [header]="t('anabin.degree.institution.title')" *ngIf="anabinData.institutions.length > 0">
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'institutions', t: t }"></ng-container>
          </p-accordionTab>
        </ng-container>

        <ng-container *ngIf="anabinData.__typename === 'AnabinInstitutionInfoViewDetails'">
          <p-accordionTab [header]="t('anabin.institution.altName')" *ngIf="anabinData.alternativeNames.length > 0">
            <ng-container
              *ngTemplateOutlet="anabinTable; context: { purpose: 'alternativeNames', t: t }"
            ></ng-container>
          </p-accordionTab>

          <p-accordionTab [header]="t('anabin.institution.degrees')" *ngIf="anabinData.degrees.length > 0">
            <ng-container *ngTemplateOutlet="anabinTable; context: { purpose: 'degrees', t: t }"></ng-container>
          </p-accordionTab>
        </ng-container>
      </p-accordion>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="flex flex-row justify-content-end">
        <p-button
          (click)="closeComponent.emit()"
          [label]="t('common.close')"
          icon="pi pi-times"
          class="p-button-secondary"
        ></p-button>
      </div>
    </ng-template>
  </ng-container>
</p-card>

<ng-template #anabinTable let-purpose="purpose" let-t="t">
  <table class="w-full">
    <thead>
      <tr>
        <th *ngFor="let label of tableConfig[anabinData.__typename][purpose].labels">{{ t(label) }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of anabinData[purpose]">
        <td *ngFor="let field of tableConfig[anabinData.__typename][purpose].fields">
          <ng-container *ngIf="field === 'country'">
            {{ item[field] | staticData: StaticDataType.Countries | async }}
          </ng-container>
          <ng-container *ngIf="field !== 'country'">
            {{ item[field] }}
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
