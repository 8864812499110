import { ChangeDetectionStrategy, Component, inject, input } from "@angular/core";
import { BaseComponent } from "primeng/basecomponent";
import { NavitemComponent } from "../navitem/navitem.component";
import { SidenavItem } from "./sidenav.model";
import { SidenavStyle } from "./sidenav.style";

@Component({
  selector: "ui-sidenav",
  templateUrl: "./sidenav.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NavitemComponent],
  host: {
    class: "ui-sidenav",
  },
})
export class SidenavComponent extends BaseComponent {
  protected readonly _componentStyle = inject(SidenavStyle);

  readonly items = input.required<SidenavItem[]>();
}
