import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { BadgeModule } from "primeng-v17/badge";

@Component({
  selector: "app-notification-badge",
  templateUrl: "./notification-badge.component.html",
  styleUrl: "./notification-badge.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, BadgeModule],
})
export class NotificationBadgeComponent {
  @Input()
  count: number;
}
