export const base = {
  primitive: {
    colorScheme: {
      light: {
        surface: {
          0: "#ffffff",
          200: "#e2e8f0",
          600: "#475569",
        },
        highlight: {
          support: "#ffedd4",
        },
        transparent: "transparent",
      },
    },
  },
};
