import { Injectable, Injector } from "@angular/core";
import {
  GetPublicStaticDataGQL,
  GetStaticDataGQL,
  StaticDataInputContext,
  StaticDataModel,
  StaticDataModelInput,
  StaticDataResult,
  StaticDataType,
  SupportedImmigrationCountry,
} from "@ankaadia/graphql";
import { TranslocoService, translate, translateObject } from "@jsverse/transloco";
import { castArray, isEmpty, keys, pick, uniq, uniqBy, without } from "lodash";
import { Observable, combineLatest, map, of, switchMap, tap } from "rxjs";
import { CandidatesService } from "../../features/candidates/candidates.service";
import { AuthInterceptor } from "../interceptors/auth.interceptor";
import { SettingsService } from "../services/settings.service";

export interface StaticDataRequestEntry {
  id?: never; // making sure a normal candidate model is not passed here
  candidateId?: string;
  organizationId: string;
}

export interface StaticDataContextEntry {
  id?: never; // making sure a normal candidate model is not passed here
  immigrationCountry?: SupportedImmigrationCountry;
  organizationId?: string;
}

export type StaticDataRequest = StaticDataRequestEntry | StaticDataRequestEntry[];
export type StaticDataContext = StaticDataContextEntry | StaticDataContextEntry[];
export type EnumType = Record<string, string>;

@Injectable({ providedIn: "root" })
export class StaticDataService {
  private readonly contextAwareCache: Partial<Record<StaticDataType, string[]>> = {};

  constructor(
    private readonly getStaticDataGQL: GetStaticDataGQL,
    private readonly getPublicStaticDataGQL: GetPublicStaticDataGQL,
    private readonly injector: Injector,
    private readonly trans: TranslocoService,
    private readonly authInterceptor: AuthInterceptor
  ) {
    this.authInterceptor.registerNoLoginOperation(this.getPublicStaticDataGQL);
  }

  getStaticDataLabel<T extends string | string[]>(
    value: T,
    datatype: StaticDataType,
    language: string,
    staticDataRequest?: StaticDataRequest | StaticDataContext,
    useAutoContext = true
  ): Observable<T | null> {
    return value == null || language == null
      ? of(null)
      : !Array.isArray(value)
        ? this.getStaticData(datatype, language, staticDataRequest, useAutoContext).pipe(
            map((data) => data.find((entry) => entry.value === value)?.label as T)
          )
        : this.getStaticData(datatype, language, staticDataRequest, useAutoContext).pipe(
            map((data) => value.map((v) => data.find((entry) => entry.value === v)?.label) as T)
          );
  }

  /**
   * @param enumName the name of the enum to translate
   * @param enumType the enum to translate
   * @param options  options to filter the enum and specify the translation language, incude can also be used to define a sort order
   * @returns a list of static data model containing the enum value and its translation
   */

  transformEnumToStaticDataModel(
    enumName: string,
    enumType: EnumType,
    options?: { language?: string; include?: string[]; exclude?: string[] }
  ): StaticDataModel[] {
    if (enumName == null || enumType == null) {
      return null;
    }

    const language = options?.language ?? this.trans.getActiveLang();
    let enumValues = options?.include ?? Object.values(enumType); //enum value is used for identification... Graphql transform the keys to PascalCase...

    if (options?.exclude) {
      enumValues = without(enumValues, ...options.exclude);
    }

    const translatedObject = translateObject<string>(`enum.${enumName}`, null, language);

    return uniq([
      ...keys(translatedObject).filter((translated) => enumValues.find((enumKey) => enumKey == translated)),
      ...enumValues,
    ]) // in order to take the order from the translation
      .map((key) => ({
        label: translatedObject[key] ?? key,
        value: key,
      }));
  }

  getStaticData(
    type: StaticDataType,
    language?: string,
    staticDataRequest?: StaticDataRequest | StaticDataContext,
    useAutoContext = true
  ): Observable<StaticDataModel[]> {
    if (type == null) return null;
    if (
      (!Array.isArray(staticDataRequest) && (<StaticDataContextEntry>staticDataRequest)?.immigrationCountry != null) ||
      (Array.isArray(staticDataRequest) &&
        (staticDataRequest as any[]).every((re: any) => (<StaticDataContextEntry>re).immigrationCountry != null))
    ) {
      return this.getStaticDataWithContexts(
        type,
        language,
        Array.isArray(staticDataRequest) ? staticDataRequest : [staticDataRequest]
      );
    } else {
      language ??= this.trans.getActiveLang();
      return this.getContext(<StaticDataRequest>staticDataRequest).pipe(
        switchMap((context) => this.getStaticDataWithContexts(type, language, useAutoContext ? context : null))
      );
    }
  }

  private getStaticDataWithContexts(
    type: StaticDataType,
    language?: string,
    contexts?: StaticDataInputContext[]
  ): Observable<StaticDataModel[]> {
    if (type == null) return null;
    return this.fetchStaticData({
      type,
      language: (language ??= this.trans.getActiveLang()),
      contexts: this.buildContextToUse(type, contexts),
    }).pipe(
      tap((staticData) => this.cacheContextSensitiveFields(type, staticData)),
      map((staticData) => staticData.data)
    );
  }

  fetchStaticData(input: StaticDataModelInput): Observable<StaticDataResult> {
    const settingsService = this.injector.get(SettingsService);
    if (settingsService.userOrCandidateId != null) {
      return this.getStaticDataGQL
        .fetch({ input }, { fetchPolicy: "cache-first" })
        .pipe(map((result) => result.data.getStaticData));
    } else {
      return this.getPublicStaticDataGQL
        .fetch({ input }, { fetchPolicy: "cache-first" })
        .pipe(map((result) => result.data.getPublicStaticData));
    }
  }

  //TODO: We have to refactor this to use static data
  getExperienceTypeData(): Observable<StaticDataModel[]> {
    return of([
      { value: "Normal", label: translate("experienceType.normal") },
      { value: "OtherActivities", label: translate("experienceType.otherActivities") },
    ]);
  }

  private buildContextToUse(type: StaticDataType, contexts: StaticDataInputContext[]): StaticDataInputContext[] {
    if (isEmpty(contexts) || !this.contextAwareCache[type]) {
      return contexts;
    }
    const result = contexts.map((context) =>
      this.contextAwareCache[type].reduce((object, field) => ((object[field] = context[field]), object), {})
    );
    return result;
  }

  private cacheContextSensitiveFields(type: StaticDataType, result: StaticDataResult): void {
    if (!isEmpty(result.contextSensitiveFields)) {
      this.contextAwareCache[type] = result.contextSensitiveFields;
    }
  }

  private getContext(staticDataRequest: StaticDataRequest): Observable<StaticDataInputContext[]> {
    if (staticDataRequest == null) {
      const supportedImmigrationCountry = this.injector.get(SettingsService).supportedImmigrationCountries;
      const orgId = this.injector.get(SettingsService).organizationId;
      return of(
        supportedImmigrationCountry.map((immigrationCountry) => ({
          immigrationCountry: immigrationCountry as SupportedImmigrationCountry,
          organizationId: orgId,
        }))
      );
    }

    const requestEntries = castArray(staticDataRequest);

    const candidateService = this.injector.get(CandidatesService);
    const fragments = requestEntries
      .filter((request) => request != null)
      .map((candidate) =>
        candidateService.readCandidateContextInfoFromApolloCache(candidate.candidateId, candidate.organizationId)
      )
      .filter((candidateOS) => candidateOS != null);
    const result = combineLatest(fragments).pipe(
      map((fragments) => {
        return uniqBy(
          fragments.map((fragment) => pick(fragment, ["immigrationCountry", "organizationId"])),
          (fragment) => JSON.stringify(fragment)
        );
      })
    );
    return result;
  }
}
