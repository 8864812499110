<div class="field" [controlRef]="formControl" [fieldConfig]="this">
  <label *ngIf="props.label" [for]="key" [class]="showError ? 'p-error' : ''">{{ props.label }}</label>

  <ng-container *ngIf="showError">
    <formly-validation-message #validation [hidden]="true" [field]="field"></formly-validation-message>
    <p-message
      styleClass="validation-message mb-3"
      severity="error"
      [text]="validation.errorMessage$ | async"
    ></p-message>
  </ng-container>

  <div [id]="key">
    <div class="tab-container">
      <p-button
        icon="pi pi-plus"
        class="add-tab-button"
        [styleClass]="'p-button-rounded p-button-text ml-2' + (isAddEnabled ? ' p-button-active' : '')"
        [disabled]="!isAddEnabled"
        (onClick)="handleAdd($event)"
        [addBtnRef]="formControl"
      ></p-button>

      <p-tabView class="repeat-tabs" [controlClose]="!props.readonly" (onClose)="handleClose($event)">
        <p-tabPanel
          *ngFor="let tab of field.fieldGroup; let i = index"
          [disabled]="isDisabled"
          [headerStyleClass]="validity[i] === false ? 'p-error' : ''"
          [closable]="!props.readonly && formControl.enabled && i >= props.minCount && !props?.disabledRemoveButton"
          [header]="props.formatLabel(model, i)"
          [removeBtnRef]="formControl"
        >
          <p-panel>
            <formly-field [field]="tab"></formly-field>
          </p-panel>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
