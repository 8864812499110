import { Injectable } from "@angular/core";
import { KnownFormToken } from "@ankaadia/ankaadia-shared";
import { ProcessTaskInformationCollection } from "@ankaadia/graphql";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { Observable, of } from "rxjs";
import { DefaultSpecific } from "../default/default-specifics";
import { SupportedTaskTypes } from "../organization-specific";
import { recruitingConceptFormFactory } from "./forms/recruitingConcept-form.factory";

@Injectable({ providedIn: "root" })
export class GueteSpecific extends DefaultSpecific {
  override getFormlyConfiguration(
    taskOrFormToken: ProcessTaskInformationCollection | KnownFormToken,
    language: string
  ): Observable<FormlyFieldConfig[]> {
    const formToken = (
      typeof taskOrFormToken === "string" ? taskOrFormToken : taskOrFormToken.formToken
    ) as KnownFormToken;

    switch (formToken) {
      case KnownFormToken.RecruitingConcept:
        return recruitingConceptFormFactory({ staticDataService: this.staticDataService }, language);
      default:
        return of([]);
    }
  }

  override getSupportedTaskTypes(): SupportedTaskTypes[] {
    return super
      .getSupportedTaskTypes()
      .concat(["ProcessTaskOrganizationAuditModel", "ProcessTaskDeactivateProcessModel", "ProcessTaskRoleSetupModel"]);
  }
}
