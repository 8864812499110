<div [controlRef]="formControl" [fieldConfig]="this">
  <p-fieldset *ngFor="let set of sets" [toggleable]="true">
    <ng-template pTemplate="header">
      {{ set.preferredLabel }}
      <i *ngIf="set.description" class="pi pi-info-circle ml-2" [pTooltip]="set.description"></i>
    </ng-template>

    <div [class]="field.fieldGroupClassName">
      <ng-container *ngFor="let field of field.fieldGroup">
        <ng-container *ngIf="isInSet(field, set)">
          <formly-field [field]="field"></formly-field>
        </ng-container>
      </ng-container>
    </div>
  </p-fieldset>
</div>
