import { Injectable } from "@angular/core";
import { PictureType, getImageDownlodURI } from "@ankaadia/ankaadia-shared";
import {
  AddCandidateToCollectionsGQL,
  AddCandidatesToCollectionsResult,
  BlockUnBlockCandidateGQL,
  Candidate,
  CandidateContextInfoFragment,
  CandidateContextInfoFragmentDoc,
  CandidateCreateCurrentSelection,
  CandidateCreateInput,
  CandidateDeleteInput,
  CandidateDeletionRequest,
  CandidateForEditFragment,
  CandidateForViewFragment,
  CandidateFragmentDoc,
  CandidateIdInput,
  CandidateSystemOnboarding,
  CandidateUpdateInput,
  CandidateUpdateInputSharing,
  CollectionForCandidateItemFragment,
  CollectionId,
  CollectionListItemFragment,
  Comment,
  CommentCreateInput,
  CommentDeleteInput,
  CommentUpdateInput,
  CreateCandidateGQL,
  CreateCommentGQL,
  CreateFeedbackGQL,
  DeleteCandidateGQL,
  DeleteCommentGQL,
  Feedback,
  FeedbackCreateInput,
  FeedbackUpdateInput,
  GenerateCvAsDocxGQL,
  GetAllCandidateDeletionRequestsGQL,
  GetAllCandidatesGQL,
  GetAllCandidatesQuery,
  GetAllFeedbackForViewGQL,
  GetAllFeedbackForViewQuery,
  GetAssignableCollectionsForCandidateGQL,
  GetCandidateDeletionRequestGQL,
  GetCandidateForEditGQL,
  GetCandidateForViewGQL,
  GetCandidateGQL,
  GetCandidateOptionsInput,
  GetCandidateStaticDataContextInformationGQL,
  GetCandidatesOfCollectionForViewGQL,
  GetCandidatesOfCollectionGQL,
  GetCollectionForPreselectionGQL,
  GetCollectionsForCandidateGQL,
  GetFeedbackGQL,
  GetProcessCandidatesGQL,
  GetProcessCandidatesQuery,
  GetReplacementsForCandidateGQL,
  InviteCandidateGQL,
  MutationResult,
  RemovCandidateFromCollectionGQL,
  RemoveCandidateDeletionRequestGQL,
  ReplacementForCandidateFragment,
  ResetCandidatePasswordGQL,
  Sharing,
  UnInviteCandidateGQL,
  UpdateCandidateGQL,
  UpdateCandidateProfileGQL,
  UpdateCommentGQL,
  UpdateFeedbackGQL,
} from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { Apollo } from "apollo-angular";
import { clone } from "lodash";
import { Observable, map, of, tap } from "rxjs";
import { SettingsService } from "../../shared/services/settings.service";
import { CustomLazyLoadEvent } from "../collections/collection-edit-assigned-candidates/custom-filter.model";

export type CandidatePage = GetAllCandidatesQuery["getAllCandidates"];
export type CandidatePageEntry = CandidatePage["nodes"][0];
export type CandidatesForProcess = GetProcessCandidatesQuery["getProcessCandidates"];
export type FeedbacksForView = GetAllFeedbackForViewQuery["getAllFeedbackForView"];
export type CandidateProfile = any;

export const ALL_CANDIDATES = 1000000; // a large enough `limit` value

@Injectable({ providedIn: "root" })
export class CandidatesService {
  readonly language = this.transloco.getActiveLang();

  private readonly candidateContextCache = new Map<string, CandidateContextInfoFragment>();

  constructor(
    private readonly settings: SettingsService,
    private readonly allCandidates: GetAllCandidatesGQL,
    private readonly singleCandidate: GetCandidateGQL,
    private readonly update: UpdateCandidateGQL,
    private readonly create: CreateCandidateGQL,
    private readonly remove: DeleteCandidateGQL,
    private readonly getCForC: GetCollectionsForCandidateGQL,
    private readonly getAssignableCForC: GetAssignableCollectionsForCandidateGQL,
    private readonly rc: RemovCandidateFromCollectionGQL,
    private readonly acs: AddCandidateToCollectionsGQL,
    private readonly allFeedbackForView: GetAllFeedbackForViewGQL,
    private readonly feedback: GetFeedbackGQL,
    private readonly feedbackUpdate: UpdateFeedbackGQL,
    private readonly feedbackCreate: CreateFeedbackGQL,
    private readonly commentUpdate: UpdateCommentGQL,
    private readonly commentCreate: CreateCommentGQL,
    private readonly commentDelete: DeleteCommentGQL,
    private readonly candidatesOfCollection: GetCandidatesOfCollectionGQL,
    private readonly candidateForView: GetCandidateForViewGQL,
    private readonly candidatesOfCollectionForView: GetCandidatesOfCollectionForViewGQL,
    private readonly candidatesOfProcess: GetProcessCandidatesGQL,
    private readonly candidateInvite: InviteCandidateGQL,
    private readonly candidateUnInvite: UnInviteCandidateGQL,
    private readonly blockUnblockCandidate: BlockUnBlockCandidateGQL,
    private readonly candidatePasswordReset: ResetCandidatePasswordGQL,
    private readonly profileUpdate: UpdateCandidateProfileGQL,
    private readonly genDocx: GenerateCvAsDocxGQL,
    private readonly transloco: TranslocoService,
    private readonly removeCandidateDeletionRequest: RemoveCandidateDeletionRequestGQL,
    private readonly getCandidateDeletion: GetCandidateDeletionRequestGQL,
    private readonly collectionForPreselection: GetCollectionForPreselectionGQL,
    private readonly getAllCandidateDeletion: GetAllCandidateDeletionRequestsGQL,
    private readonly candidateForEdit: GetCandidateForEditGQL,
    private readonly getCandidateStaticDataContextInformation: GetCandidateStaticDataContextInformationGQL,
    private readonly getReplacementsForCandidate: GetReplacementsForCandidateGQL,
    private readonly apollo: Apollo
  ) {
    // In case the loggedin user is a candidate we need to get a candidate record in order to make sure that all static data are loaded correctly
    this.settings.isAuthorized$.subscribe((isAuthorized) => {
      if (isAuthorized && this.settings.isCandidate) {
        // Todo: What the hell is this? Why are we loading the candidate and not storing it?
        this.getCandidate(this.settings.userOrCandidateId, this.settings.organizationId).subscribe(); // OK, because logged in user is a candidate.
      }
    });
  }

  getCandidateEditLink(candidateId: string, organizationId: string): string[] {
    return ["/app/candidates/edit", organizationId, candidateId];
  }

  generateCVAsDocx(
    candidateId: string,
    organizationId: string,
    sharingOrganizationId: string,
    language: string
  ): Observable<{ url: string; fileName: string }> {
    return this.genDocx
      .fetch({
        candidateId: candidateId,
        candidateOrganizationId: organizationId,
        sharingOrganizationId: sharingOrganizationId,
        language: language,
      })
      .pipe(map((x) => x.data.generateCVAsDocx));
  }

  unInviteCandidate(candidateId: string, organizationId: string): Observable<CandidateSystemOnboarding> {
    return this.candidateUnInvite
      .mutate({ candidateId: candidateId, organizationId: organizationId })
      .pipe(map((x) => x.data.unInviteCandidate));
  }

  blockUnBlockCandidate(
    candidateId: string,
    organizationId: string,
    blockState: boolean
  ): Observable<CandidateSystemOnboarding> {
    return this.blockUnblockCandidate
      .mutate({ candidateId: candidateId, organizationId: organizationId, blocked: blockState })
      .pipe(map((x) => x.data.blockUnblockCandidate));
  }

  resetCandidatePassword(candidateId: string, organizationId: string): Observable<CandidateSystemOnboarding> {
    return this.candidatePasswordReset
      .mutate({
        candidateId: candidateId,
        organizationId: organizationId,
        invitationCandidateId: this.settings.organizationId,
      })
      .pipe(map((x) => x.data.resetCandidatePassword));
  }

  inviteCandidate(candidateId: string, organizationId: string): Observable<CandidateSystemOnboarding> {
    return this.candidateInvite
      .mutate({
        candidateId: candidateId,
        organizationId: organizationId,
        invitationCandidateId: this.settings.organizationId,
      })
      .pipe(map((x) => x.data.inviteCandidate));
  }

  getCandidateDeletionRequest(candidateId: string, organizationId: string): Observable<CandidateDeletionRequest> {
    return this.getCandidateDeletion
      .fetch({
        input: { candidateId: candidateId, organizationId: organizationId },
      })
      .pipe(map((x) => x.data.getCandidateDeletionRequest));
  }

  getAllCandidateDeletionRequests(organizationId?: string): Observable<CandidateDeletionRequest[]> {
    return this.getAllCandidateDeletion
      .fetch({ organizationId: organizationId ?? this.settings.organizationId })
      .pipe(map((x) => x.data.getAllDeletionRequests));
  }

  removeDeletionRequest(request: CandidateDeletionRequest): Observable<void> {
    return this.removeCandidateDeletionRequest
      .mutate({
        input: {
          id: request.id,
          organizationId: request.organizationId,
          _etag: request._etag,
          candidateId: request.candidateId,
        },
      })
      .pipe(map((x) => x.data.removeCandidateDeletionRequest));
  }

  /**
   *
   *
   * @param candidateId
   * @param organizationId
   * @param sharingId - must be provided in case the candidate is not the logged in user or the candidate is not owned by the logged in user
   * @param sharingOrganizationId - must be provided in case the candidate is not the logged in user or the candidate is not owned by the logged in userr
   * @param candidateStatus
   * @param candidateFunction
   * @param collectionId - -in case sharingId is not provided it must be provided in case the candidate is not the logged in user or the candidate is not owned by the logged in user
   * @returns
   */

  getCandidate(
    candidateId: string,
    organizationId: string,
    sharingId?: string,
    sharingOrganizationId?: string,
    options?: GetCandidateOptionsInput
  ): Observable<Candidate> {
    return this.singleCandidate
      .fetch({ input: { organizationId, candidateId, sharingId, sharingOrganizationId, options } })
      .pipe(map((result) => result.data.getCandidate));
  }

  getProcessCandidates(processId: string, organizationId: string): Observable<CandidatesForProcess> {
    return this.candidatesOfProcess
      .fetch({ processId: processId, organizationId: organizationId })
      .pipe(map((val) => val.data.getProcessCandidates));
  }

  getFilteredCandidates(
    collectionId?: string,
    organizationId?: string,
    event: CustomLazyLoadEvent = { rows: ALL_CANDIDATES },
    sharingId?: string
  ): Observable<CandidatePage> {
    // watches are important here, otherwise edit candidate form is not updated properly
    return collectionId
      ? this.candidatesOfCollection
          .watch({
            input: {
              organizationId: organizationId ?? this.settings.organizationId,
              collectionId,
              selectedSharingId: sharingId,
              first: event.first,
              rows: event.rows,
              filters: event.filters,
              sortField: event.sortField as string,
              sortOrder: event.sortOrder,
              language: this.language,
            },
          })
          .valueChanges.pipe(map((val) => val.data.getCandidatesOfCollection))
      : this.allCandidates
          .watch({
            input: {
              organizationId: organizationId,
              first: event.first,
              rows: event.rows,
              filters: event.filters,
              sortField: event.sortField as string,
              sortOrder: event.sortOrder,
              language: this.language,
            },
          })
          .valueChanges.pipe(map((val) => val.data.getAllCandidates));
  }

  getCandidateForView(candidateId: CandidateIdInput): Observable<CandidateForViewFragment> {
    return this.candidateForView.fetch({ input: candidateId }).pipe(map((val) => val.data.getCandidateForView));
  }

  getFilteredCandidatesForView(
    collectionId: string,
    organizationId?: string,
    sharingId?: string
  ): Observable<CandidateForViewFragment[]> {
    return this.candidatesOfCollectionForView
      .fetch({
        input: {
          orgId: organizationId ?? this.settings.organizationId,
          colId: collectionId,
          selectedSharingId: sharingId,
        },
      })
      .pipe(map((val) => val.data.getCandidatesOfCollectionForView));
  }

  createCandidate(
    input: CandidateCreateInput,
    currentCollection?: CandidateCreateCurrentSelection
  ): Observable<Candidate> {
    delete input["id"];
    delete input["displayId"];
    delete input["creationDate"];
    delete input["_etag"];
    return this.create
      .mutate(
        { input, currentCollection },
        {
          update: (cache, mutationResult) =>
            cache.modify({
              fields: {
                getCandidatesOfCollection(refs, helper) {
                  updateAppolloCache(refs, helper, input, cache, mutationResult);
                },
                getAllCandidates(refs, helper) {
                  updateAppolloCache(refs, helper, input, cache, mutationResult);
                },
              },
            }),
        }
      )
      .pipe(map((val) => val.data.createCandidate));
  }

  updateCandidate(candidateInput: CandidateUpdateInput, sharing: CandidateUpdateInputSharing): Observable<Candidate> {
    delete candidateInput["creationDate"];
    delete candidateInput["deletionDate"];
    delete candidateInput["displayId"];
    return this.update.mutate({ input: candidateInput, sharing: sharing }).pipe(map((val) => val.data.updateCandidate));
  }

  updateCandidateProfile(profile: CandidateProfile, candidate: Candidate): Observable<Candidate> {
    return this.profileUpdate
      .mutate({
        input: {
          profile: profile,
          id: candidate.id,
          organizationId: candidate.organizationId,
          _etag: candidate._etag,
        },
      })
      .pipe(map((val) => val.data.updateCandidateProfile));
  }

  removeCandidate(delInput: CandidateDeleteInput, sharing?: Sharing): Observable<boolean> {
    const input = new CandidateDeleteInput();
    input.id = delInput.id;
    input.organizationId = delInput.organizationId;
    input._etag = delInput._etag;
    input.sharingId = sharing?.id;
    input.sharingOrganizationId = sharing?.organizationId;
    input.sharingEtag = sharing?._etag;

    const proxy = { id: delInput.id, __typename: new Candidate().__typename };
    return this.remove
      .mutate(
        { input: input },
        {
          update: (cache, result) =>
            result?.data.deleteCandidate.status && (<any>cache).data.delete(cache.identify(proxy)),
        }
      )
      .pipe(map((val) => val.data.deleteCandidate.status));
  }

  getThumbnailDownloadUrl(organizationId: string, candidateId: string): Observable<string> {
    return this.settings.getImageSasTokenAndOrigin(organizationId).pipe(
      map((tokenInfo) => {
        const uri = getImageDownlodURI(organizationId, candidateId, "thumbnail");
        return tokenInfo.origin + "/" + uri + tokenInfo.token;
      })
    );
  }

  getPictureDownloadURL(organizationId: string, candidateId: string, pictureType: PictureType): Observable<string> {
    return this.settings.getImageSasTokenAndOrigin(organizationId).pipe(
      map((tokenInfo) => {
        const url = getImageDownlodURI(organizationId, candidateId, pictureType);
        return tokenInfo.origin + "/" + url + tokenInfo.token;
      })
    );
  }

  getAssignableCollectionsForCandidate(
    candidateId: string,
    candidateOrganizationId: string,
    organizationId: string
  ): Observable<CollectionListItemFragment[]> {
    return this.getAssignableCForC
      .fetch({
        input: {
          candidateId: candidateId,
          candidateOrganizationId: candidateOrganizationId,
          organizationId: organizationId,
        },
      })
      .pipe(map((x) => x.data.getAssignableCollectionsForCandidate));
  }

  getCollectionsForCandidate(
    candidateId: string,
    organizationId: string
  ): Observable<CollectionForCandidateItemFragment[]> {
    return this.getCForC
      .fetch({ candidateId: candidateId, organizationId: organizationId })
      .pipe(map((x) => x.data.getCollectionsForCandidate));
  }

  addCandidateToCollections(
    candidateId: string,
    candidateOrgId: string,
    sourceCollectionId: string,
    sourceCollectionOrgId: string,
    collections: CandidateCreateCurrentSelection[]
  ): Observable<AddCandidatesToCollectionsResult> {
    return this.acs
      .mutate({
        input: {
          candidateId: candidateId,
          organizationId: candidateOrgId,
          collections: collections,

          sourceCollectionId: sourceCollectionId,
          sourceCollectionOrgId: sourceCollectionOrgId,
        },
      })
      .pipe(map((x) => x.data.addCandidateToCollections));
  }

  deleteCandidateFromCollection(
    candidateId: string,
    candidateOrgId: string,
    collectionId: string,
    collectionOrgId: string
  ): Observable<boolean> {
    return this.rc
      .mutate({
        input: {
          candidate: { id: candidateId, orgId: candidateOrgId },
          collection: { id: collectionId, orgId: collectionOrgId },
        },
      })
      .pipe(map((x) => x.data.removeCandidateFromCollection.status));
  }

  getAllFeedbackForView(organizationId?: string, sharingId?: string): Observable<FeedbacksForView> {
    return this.allFeedbackForView
      .fetch({ orgId: organizationId, sharingId: sharingId })
      .pipe(map((val) => val.data.getAllFeedbackForView));
  }

  getFeedback(feedbackId: string, organizationId: string): Observable<FeedbacksForView[0]> {
    return this.feedback
      .fetch({ feedbackId: feedbackId, orgId: organizationId })
      .pipe(map((val) => val.data.getFeedback));
  }

  /**
   * To be used _solely_ by {@link CandidateFormComponent}. Not by you.
   */
  getCandidateForEdit(
    candidateId: string,
    organizationId: string,
    sharingId: string,
    sharingOrganizationId: string
  ): Observable<CandidateForEditFragment> {
    return this.candidateForEdit
      .fetch({ input: { candidateId, organizationId, sharingId, sharingOrganizationId } })
      .pipe(map((x) => x.data.getCandidateForEdit));
  }

  createFeedback(feedback: FeedbackCreateInput): Observable<Feedback> {
    const input: FeedbackCreateInput = clone(feedback);
    delete input["id"];
    delete input["_etag"];
    delete input["comments"];
    return this.feedbackCreate.mutate({ input: input }).pipe(map((val) => val.data.createFeedback));
  }

  updateFeedback(feedback: FeedbackUpdateInput): Observable<Feedback> {
    const input: FeedbackUpdateInput = clone(feedback);
    delete input["comments"];
    delete input["__typename"];
    return this.feedbackUpdate.mutate({ input: input }).pipe(map((val) => val.data.updateFeedback));
  }

  createComment(comment: CommentCreateInput): Observable<Comment> {
    const input: CommentCreateInput = clone(comment);
    delete input["id"];
    delete input["_etag"];
    return this.commentCreate.mutate({ input: input }).pipe(map((val) => val.data.createComment));
  }

  updateComment(comment: CommentUpdateInput): Observable<Comment> {
    const input: CommentUpdateInput = clone(comment);
    delete input["__typename"];
    return this.commentUpdate.mutate({ input: input }).pipe(map((val) => val.data.updateComment));
  }

  deleteComment(comment: CommentDeleteInput): Observable<MutationResult> {
    const input = new CommentDeleteInput();
    input.id = comment.id;
    input.organizationId = comment.organizationId;
    input._etag = comment._etag;
    return this.commentDelete.mutate({ input: input }).pipe(map((val) => val.data.deleteComment));
  }

  getCollectionForPreselection(candidateId: string, organizationId: string): Observable<CollectionId> {
    return this.collectionForPreselection
      .fetch({ input: { organizationId, candidateId } })
      .pipe(map((val) => val.data.getCollectionForPreselection));
  }

  getReplacementOfCandidate(
    candidateId: string,
    organizationId: string
  ): Observable<ReplacementForCandidateFragment[]> {
    return this.getReplacementsForCandidate
      .fetch({ input: { id: candidateId, organizationId: organizationId } }, { fetchPolicy: "cache-first" })
      .pipe(map((val) => val.data.getReplacementsForCandidate));
  }

  readCandidateContextInfoFromApolloCache(
    candidateId: string,
    organizationId: string
  ): Observable<CandidateContextInfoFragment> {
    if (this.candidateContextCache.has(candidateId)) {
      return of(this.candidateContextCache.get(candidateId));
    }
    try {
      return of(
        this.apollo.client.readFragment<CandidateContextInfoFragment>({
          id: `Candidate:${candidateId}`,
          fragment: CandidateContextInfoFragmentDoc,
        })
      );
    } catch (error) {
      return this.getCandidateStaticDataContextInformation
        .fetch({
          candidateId: candidateId,
          organizationId: organizationId,
        })
        .pipe(
          map(
            (x) =>
              ({
                ...x.data.getCandidateStaticDataContextInformation,
                __typename: "Candidate",
              }) as CandidateContextInfoFragment
          )
        )
        .pipe(tap((x) => this.candidateContextCache.set(candidateId, x)));
    }
  }
}

function updateAppolloCache(refs, helper, candidateInput, cache, mutationResult): any[] {
  if (!helper.storeFieldName.includes(candidateInput.organizationId)) return refs;
  const ref = cache.writeFragment({
    data: mutationResult.data.createCandidate,
    fragment: CandidateFragmentDoc,
    fragmentName: "Candidate",
  });
  if (refs != null && refs.length > 0) {
    return [...refs, ref];
  } else {
    return [ref];
  }
}
