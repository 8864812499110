import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserFeedbackCreateInput } from "@ankaadia/graphql";
import { PrimeTemplate } from "primeng-v17/api";
import { Button } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { InputTextareaModule } from "primeng-v17/inputtextarea";
import { RatingModule } from "primeng-v17/rating";
import { EscapeButtonDirective } from "../../../shared/directives/escape-button.directive";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-user-feedback-edit-dialog",
  templateUrl: "./user-feedback-edit-dialog.component.html",
  imports: [
    TranslateDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    RatingModule,
    FormElementMapModule,
    TestIdDirective,
    InputTextareaModule,
    PrimeTemplate,
    Button,
    EscapeButtonDirective,
  ],
})
export class UserFeedbackEditDialogComponent implements OnInit {
  @Input()
  disabled: boolean;

  @Output()
  readonly saved = new EventEmitter<UserFeedbackCreateInput>();

  @Output()
  readonly closed = new EventEmitter<void>();

  form: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      overallRating: [null],
      usabilityRating: [null],
      functionalityRating: [null],
      performanceRating: [null],
      comment: [""],
    });
  }
}
