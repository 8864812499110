import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { Component, DestroyRef, OnInit, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { StaticDataModel } from "@ankaadia/graphql";
import { FieldType } from "@ngx-formly/core";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { PrimeTemplate } from "primeng-v17/api";
import { ButtonDirective } from "primeng-v17/button";
import { ChipModule } from "primeng-v17/chip";
import { DialogService } from "primeng-v17/dynamicdialog";
import { EditorModule } from "primeng-v17/editor";
import { MultiSelectChangeEvent, MultiSelectModule } from "primeng-v17/multiselect";
import { OverlayPanel, OverlayPanelModule } from "primeng-v17/overlaypanel";
import { TooltipModule } from "primeng-v17/tooltip";
import Quill from "quill";
import { startWith } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { CandidateTranslationDialogComponent } from "../../features/candidates/candidate-translation-dialog/candidate-translation-dialog.component";
import { TranslationMapping } from "../../features/metered-translations/metered-translations.model";
import { MeteredTranslationsService } from "../../features/metered-translations/metered-translations.service";
import { AppendToBodyDirective } from "../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { StaticDataPipe } from "../../shared/static-data/static-data.pipe";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  imports: [
    TranslateDirective,
    NgFor,
    ChipModule,
    NgIf,
    ButtonDirective,
    OverlayPanelModule,
    AppendToBodyDirective,
    MultiSelectModule,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    FormsModule,
    TooltipModule,
    EditorModule,
    PrimeTemplate,
    AsyncPipe,
    StaticDataPipe,
    FormlySelectModule,
  ],
})
export class FormlyChipsComponent extends FieldType implements OnInit {
  translationMapping: TranslationMapping;

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly translationService: MeteredTranslationsService,
    private readonly dialogService: DialogService
  ) {
    super();
  }

  @ViewChild("chip") chip: OverlayPanel;
  protected selectedChip: any;

  protected selectedOptions: StaticDataModel[];

  private editor: Quill;

  ngOnInit(): void {
    this.field.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(startWith(this.field.formControl.value))
      .subscribe((value) => {
        this.selectedOptions = value?.map((item) => item.experienceField) ?? [];
      });
  }

  change(event: MultiSelectChangeEvent): void {
    if (event.value.includes(event.itemValue.value)) {
      this.field.formControl.setValue([
        ...(this.field.formControl.value || []),
        { id: uuidv4(), experienceField: event.itemValue.value, experienceNotes: null },
      ]);
    } else {
      this.field.formControl.setValue(
        this.field.formControl.value.filter((item) => item.experienceField !== event.itemValue.value)
      );
    }
  }

  clear(): void {
    this.field.formControl.reset([]);
  }

  displayOverlay(event: MouseEvent, field: any): void {
    this.selectedChip = field;
    this.updateEditor(this.editor);
    this.chip.toggle(event);
  }

  updateEditor(editor: Quill): void {
    if (editor) {
      this.editor = editor;
      this.editor.setContents(this.selectedChip?.experienceNotes?.delta);
      this.setUpTranslation();
    }
  }

  updateHtml(): void {
    const value = this.field.formControl.value;
    value.find((item) => item.id === this.selectedChip.id).experienceNotes = {
      contents: this.editor.getText(),
      delta: this.editor.getContents(),
    };
    this.field.formControl.setValue(value);
  }

  setUpTranslation(): void {
    this.translationMapping = null;
    const chipId = this.selectedChip?.id;
    if (!chipId) return null;
    const currentTranslation = this.translationService
      .getFieldTranslationsWithState()
      .find((t) => t.propertyPath.some((p) => p === chipId));
    if (currentTranslation) {
      this.translationMapping = { id: currentTranslation.id, formControl: this.field.formControl };
    }
  }

  openDialog(event: Event): void {
    this.dialogService.open(CandidateTranslationDialogComponent, {
      closeOnEscape: false,
      dismissableMask: false,
      styleClass: "p-dialog-compact",
      modal: true,
      width: "50%",
      appendTo: this.getElementToAppend(event.target as Element),
      data: { sourceText: { delta: this.editor.getContents() }, itemId: this.translationMapping.id },
    });
  }

  private getElementToAppend(element: Element): Element {
    let lastElement: Element = element;
    while (element && !element.className.includes("layout-wrapper")) {
      lastElement = element;
      element = element.parentElement;
    }
    return element ?? lastElement;
  }
}
