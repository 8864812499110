import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Button } from "primeng-v17/button";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng-v17/dynamicdialog";
import { FieldsetModule } from "primeng-v17/fieldset";
import { InputTextModule } from "primeng-v17/inputtext";
import { MessageModule } from "primeng-v17/message";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { ITranslationProposal } from "../translationProposal.model";

@Component({
  selector: "app-translation-proposal-gathering",
  templateUrl: "./translationProposalGathering.component.html",
  styleUrl: "./translationProposalGathering.component.scss",
  imports: [TranslateDirective, MessageModule, FieldsetModule, FormsModule, InputTextModule, Button],
})
export class TranslationProposalGatheringComponent {
  textToCorrect = null;
  textCorrected = null;
  reasonForCorrection = null;
  comment = null;

  constructor(
    config: DynamicDialogConfig,
    private readonly dialog: DynamicDialogRef
  ) {
    this.textToCorrect = config.data.textToTranslate;
    this.textCorrected = this.textToCorrect;
  }

  cancel(_event: MouseEvent): void {
    this.dialog.close(null);
  }

  submit(_event: MouseEvent): void {
    this.dialog.close({
      textToCorrect: this.textToCorrect,
      translationProposal: this.textCorrected,
      reasonForCorrection: this.reasonForCorrection,
    } as ITranslationProposal);
  }
}
