import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { SettingsService } from "../../shared/services/settings.service";
import { ShortLinkService } from "./shortLink.service";

@Component({
  selector: "app-protected-short-link",
  template: "<p>File downloaded...</p>",
})
export class ProtectedShortLinkComponent implements OnInit {
  constructor(
    private readonly settings: SettingsService,
    private readonly shortLinkService: ShortLinkService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.shortLinkService.resolveProtectedShortLink(location.href).subscribe((url) => {
      if (url) {
        this.document.defaultView.location.href = url;
      } else {
        this.settings.navigateToNotFound();
      }
    });
  }
}
