import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { TranslocoService } from "@jsverse/transloco";
import { ButtonDirective } from "primeng-v17/button";
import { CardModule } from "primeng-v17/card";
import { ImageModule } from "primeng-v17/image";
import { Observable } from "rxjs";
import { TranslateDirective } from "../../shared/transloco/translate.directive";
import { CustomMenuItem } from "../../structure/menu/menu.model";
import { SSOLoginService } from "./sso-login-service";

export interface AppState {
  target: string;
  connection: string;
  hash: string;
  userId: string;
  organizationId: string;
  expiry: string;
  attempts: string;
  isCandidate: string;
}
@Component({
  selector: "app-first-login-page",
  templateUrl: "./firstLoginPage.html",
  styleUrl: "./firstLoginPage.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, CardModule, ImageModule, ButtonDirective, AsyncPipe],
})
export class FirstLoginComponent implements OnInit {
  sections: CustomMenuItem[] = [];

  constructor(
    private readonly auth: AuthService,
    private readonly route: ActivatedRoute,
    private readonly transloco: TranslocoService,
    private readonly ssoLoginService: SSOLoginService
  ) {}

  private urlParams: Params;
  loginLogoUrl: Observable<string>;

  ngOnInit(): void {
    this.urlParams = this.route.snapshot.queryParams;
    const orgIdOfCreator = this.urlParams.orgIdOfCreator ?? null;
    this.loginLogoUrl = this.ssoLoginService.getLoginLogoUrl(this.urlParams.organizationId, orgIdOfCreator);
    this.transloco.getDefaultLang();
  }

  facebookLogin(): void {
    const origin = window.location.origin;
    this.auth.loginWithRedirect({
      authorizationParams: {
        connection: "facebook",
        redirect_uri: `${origin}/sso-login-redirect`,
        scope: "public_profile,user_messenger_contact",
      },
      appState: <AppState>{
        target: "/sso-login-redirect",
        userId: this.urlParams.userId,
        organizationId: this.urlParams.organizationId,
        connection: "facebook",
        hash: this.urlParams.hash,
        attempts: this.urlParams.attempts,
        expiry: this.urlParams.expiry,
        isCandidate: this.urlParams.isCandidate,
      },
    });
  }

  userNamePasswordLogin(): void {
    window.location.href = this.urlParams.setPasswordUrl;
  }
}
