import { Directive, ElementRef, OnInit } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { FormElementMapService } from "../from-element-map/form-element-map.service";

@Directive({
  selector: "[formControl], [formControlName], formly-field, app-date-time",
})
export class TestIdDirective implements OnInit {
  constructor(
    private readonly host: ElementRef<HTMLFormElement>,
    private readonly elementMapService: FormElementMapService
  ) {}

  ngOnInit(): void {
    const formControl = this.elementMapService.getFormControlByElement(this.host.nativeElement);
    const testId = this.getTestId(formControl);

    if (testId) {
      this.host.nativeElement.setAttribute("data-testid", `form-control-${testId}`);
    }
  }

  private getTestId(formControl: AbstractControl): string {
    if (!formControl?.parent) {
      return null;
    }

    const [formControlName] = Object.entries(formControl.parent.controls).find(([_, value]) => value === formControl);

    if (!formControlName) {
      return null;
    }

    return [formControlName, this.getTestId(formControl.parent)].reverse().filter(Boolean).join("-");
  }
}
