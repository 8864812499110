import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { ErrorComponent } from "./error/error.component";
import { LoginDeniedComponent } from "./login-denied/login-denied.component";
import { FirstLoginComponent } from "./login/firstLoginPage";
import { LoginComponent } from "./login/login.component";
import { SSOLinkAccountAfterSSOLoginComponent } from "./login/sso-linkaccount-after-sso-login";
import { SSOLoginRedirectComponent } from "./login/sso-login-redirect.component";
import { LogoutComponent } from "./logout/logout.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { OrgSwitchComponent } from "./org-switch/org-switch.component";
import { ShortLinkComponent } from "./shortLink/shortLink.component";
import { UnsubscribeComponent } from "./unsubscribe/unsubscribe.component";

@NgModule({
  imports: [SharedModule],
  declarations: [
    AccessDeniedComponent,
    ErrorComponent,
    LoginComponent,
    LogoutComponent,
    NotFoundComponent,
    SSOLoginRedirectComponent,
    FirstLoginComponent,
    SSOLinkAccountAfterSSOLoginComponent,
    ShortLinkComponent,
    UnsubscribeComponent,
    OrgSwitchComponent,
    LoginDeniedComponent,
  ],
  exports: [
    AccessDeniedComponent,
    ErrorComponent,
    LoginComponent,
    LogoutComponent,
    NotFoundComponent,
    SSOLoginRedirectComponent,
    FirstLoginComponent,
    SSOLinkAccountAfterSSOLoginComponent,
    ShortLinkComponent,
    UnsubscribeComponent,
    OrgSwitchComponent,
    LoginDeniedComponent,
  ],
})
export class PagesModule {}
