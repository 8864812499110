import { inject } from "@angular/core";
import { ProcessStateFragment, ProcessTaskFragment } from "@ankaadia/graphql";
import { ApolloClientOptions, DefaultOptions, from, InMemoryCache, split } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpLink } from "apollo-angular/http";
import { createPersistedQueryLink } from "apollo-angular/persisted-queries";
import { sha256 } from "crypto-hash";
import { createClient } from "graphql-ws";
import { environment } from "../../../environments/environment";
import { SettingsService } from "../services/settings.service";
import { WsConnectionParamsProvider } from "./ws-connection-params.provider";

export function apolloFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const connectionParamsProvider = inject(WsConnectionParamsProvider);

  const removeTypenameLink = removeTypenameFromVariables();
  const link = from([removeTypenameLink, httpLink.create({ uri: environment.graphqlURI })]);
  const persistedQueryLink = createPersistedQueryLink({ sha256 }).concat(link);
  const retry = new RetryLink({
    delay: {
      initial: 500,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: environment.production ? 8 : 2, // Do not retry on 500 Internal Server Error
      retryIf: (error, _operation): boolean =>
        error?.status == 0 /*Network*/ ||
        error?.status == 502 /*Bad Gateway*/ ||
        error?.status == 503 /*Service Unavailable*/ ||
        error?.status == 504 /*Gateway Timeout*/,
    },
  }).concat(persistedQueryLink);
  const error = errorFunc.concat(retry);

  //wsURI
  const wsLink = new GraphQLWsLink(
    createClient({
      url: environment.wsURI,
      connectionParams: async () => {
        return {
          Authorization: await connectionParamsProvider.getToken(),
          "Accept-Language": connectionParamsProvider.getAcceptLanguage(),
          "X-App-Version": connectionParamsProvider.getXAppVersion(),
        };
      },
      keepAlive: 10000,
    })
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    error
  );

  return {
    connectToDevTools: true,
    assumeImmutableResults: true,
    link: splitLink,
    cache: new InMemoryCache({
      typePolicies: {
        PdfFormTemplate: {
          keyFields: ["pdfFormToken", "key"],
        },
        StaticDataModel: {
          keyFields: false,
        },
        StaticDataResult: {
          keyFields: false,
        },
        RecognitionAuthority: {
          keyFields: ["respId", "respState"],
        },
        DocumentRequirement: {
          keyFields: ["id", "type", "organizationId"],
        },
        SharingPreset: {
          fields: {
            sharing: {
              // no matter what, respect the new state
              merge: (_existing, incoming) => incoming,
            },
          },
        },
      },
      possibleTypes: {
        ProcessTask: allTypenames<ProcessTaskFragment>()([
          "ProcessTaskInformationSendOut",
          "ProcessTaskInviteToPlatform",
          "ProcessTaskReminder",
          "ProcessTaskUpload",
          "ProcessTaskWork",
          "ProcessTaskDocumentCheck",
          "ProcessTaskOrganizationAudit",
          "ProcessTaskRoleSetup",
          "ProcessTaskAutoOperation",
          "ProcessTaskTriggerEvent",
          "ProcessTaskAssignmentException",
          "ProcessTaskEmailSend",
          "ProcessTaskInformationCollection",
          "ProcessTaskUpdateCandidateProfile",
          "ProcessTaskPdfFormGeneration",
          "ProcessTaskEnterMissingInformation",
          "ProcessTaskEnterMissingInformationV2",
          "ProcessTaskDeactivateProcess",
        ]),
        ProcessState: allTypenames<ProcessStateFragment>()([
          "ProcessStateInformationSendOut",
          "ProcessStateInviteToPlatform",
          "ProcessStateReminder",
          "ProcessStateUpload",
          "ProcessStateWork",
          "ProcessStateDocumentCheck",
          "ProcessStateOrganizationAudit",
          "ProcessStateRoleSetup",
          "ProcessStateAutoOperation",
          "ProcessStateAssignmentException",
          "ProcessStateEmailSend",
          "ProcessStateInformationCollection",
          "ProcessStateUpdateCandidateProfile",
          "ProcessStatePdfFormGeneration",
          "ProcessStateEnterMissingInformation",
          "ProcessStateEnterMissingInformationV2",
          "ProcessStateDeactivateProcess",
        ]),
        DocumentConfigurationData: ["DocumentConfigurationDataDE", "DocumentConfigurationDataAT"],
        ProcessOperationResult: [
          "ProcessOperationCreateProcess",
          "ProcessOperationCreateDelegation",
          "ProcessOperationRemoveFromList",
          "ProcessOperationAddToList",
        ],
      },
    }),
    defaultOptions: defaultOptions,
  };
}

// compile time error if not all type names are provided
const allTypenames =
  <T extends { __typename: string }>() =>
  <U extends T["__typename"][]>(array: U & ([T["__typename"]] extends [U[number]] ? unknown : "Invalid")): U =>
    array;

//https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "none",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "none",
  },
  mutate: {
    errorPolicy: "none",
  },
};

// GraphQL Error Handling
const errorFunc = onError((errorResponse) => {
  SettingsService.graphQLError$.next(errorResponse);
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path, extensions }) =>
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message},Extension: ${extensions["code"]} ,Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});
