import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { getSetFiles, getSetLabel, getSets, safeJoin } from "@ankaadia/ankaadia-shared";
import {
  CompletionState,
  Document,
  DocumentDeliveryFormat,
  DocumentFile,
  DocumentMode,
  DocumentRelatedFormatsFragment,
  DocumentStatusEnum,
  GetOverviewOfRequiredDocumentsForCandidateQuery,
  GetOverviewOfRequiredDocumentsForCollectionQuery,
  RequiredDocumentForListFragment,
  StaticDataModel,
  StaticDataType,
} from "@ankaadia/graphql";
import { translate, TranslocoService } from "@jsverse/transloco";
import { clone, isNil, some } from "lodash";
import { FilterService, MenuItem } from "primeng/api";
import { ContextMenu } from "primeng/contextmenu";
import { filter, forkJoin, map, Observable, of, Subject, switchMap, tap } from "rxjs";
import { EnumPipe } from "../../../shared/pipes/enum.pipe";
import { SettingsService } from "../../../shared/services/settings.service";
import {
  StaticDataContext,
  StaticDataRequest,
  StaticDataService,
} from "../../../shared/static-data/static-data.service";
import { CandidatesService } from "../../candidates/candidates.service";
import { OrganizationsService } from "../../organizations/organizations.service";
import { RequiredDocumentService } from "../../required-documents/required-document.service";
import { DocumentsService } from "../documents.service";

interface Col {
  documentStatus: DocumentStatusEnum;
  completionState: CompletionState;
  missingFormats: string[];
  missingPhysicalFormats: string[];
  additionalFormatInDocumentSets: DocumentRelatedFormatsFragment[];
}
interface Row {
  documentName: string;
  documentType: string;
  isOptional: boolean;
  cols: Col[];
}

@Component({
  selector: "app-document-overview",
  templateUrl: "./document-overview.component.html",
  styleUrl: "./document-overview.component.scss",
})
export class DocumentOverviewComponent implements OnChanges, OnInit {
  protected loaded = false;
  protected CompletionState = CompletionState;
  protected DocumentStatusAvailable = DocumentStatusEnum.Available;
  protected organizations: StaticDataModel[];
  StaticDataType: typeof StaticDataType = StaticDataType;
  requiredDocumentSets: Partial<RequiredDocumentForListFragment[]>;
  selectedSet: FormControl<string[] | null> = new FormControl<string[] | null>(null, { updateOn: "blur" });
  selectedOrganization: FormControl<string> = new FormControl<string>(null, { updateOn: "blur" });
  retrievedData:
    | GetOverviewOfRequiredDocumentsForCollectionQuery["getOverviewOfRequiredDocumentsForCollection"]
    | GetOverviewOfRequiredDocumentsForCandidateQuery["getOverviewOfRequiredDocumentsForCandidate"];

  rowData: Row[];
  candidateData: GetOverviewOfRequiredDocumentsForCandidateQuery["getOverviewOfRequiredDocumentsForCandidate"]["candidateData"] =
    [];

  showSidebar = false;
  personName: string;
  documentMode: typeof DocumentMode = DocumentMode;
  selectedDocument: Document;
  selectedFile: DocumentFile;
  menueItems: MenuItem[];
  fileUrl: string;
  filterText = new FormControl();
  documentDeliveryFormat = new FormControl();
  editedCol: Col;
  editedRow: Row;
  editedIndex: number;
  editedDocType: string;
  documentFormats: StaticDataModel[];
  knownFiles: StaticDataModel[];
  knownDeliveryFormats: StaticDataModel[] = [
    {
      value: DocumentDeliveryFormat.Paper,
      label: translate("enum.DocumentDeliveryFormat.Paper"),
    },
    {
      value: DocumentDeliveryFormat.Digital,
      label: translate("enum.DocumentDeliveryFormat.Digital"),
    },
  ];

  @Input()
  organizationId: string;

  @Input()
  tabIsShared: boolean;

  @Input()
  processId: string;

  @Input()
  collectionId: { collectionId: string; organizationId: string };

  @Input()
  candidateId: { candidateId: string; organizationId: string };

  @Input()
  candidateImmigrationCountry: string;

  @Input()
  candidateFunction: string;

  @Input()
  language: string;

  @ViewChild(ContextMenu)
  contextMenu: ContextMenu;

  protected selectedSetInitialValue: string[] = [];

  getCandidateEditLink(candidateId: string, organizationId: string): string[] {
    return this.candidateService.getCandidateEditLink(candidateId, organizationId);
  }

  protected get stateKey(): string {
    return `ankaadia_documentOverview_requiredDocumentSets-${this.candidateImmigrationCountry}-${this.candidateFunction}`;
  }

  constructor(
    private readonly staticDataService: StaticDataService,
    private readonly service: RequiredDocumentService,
    private readonly trans: TranslocoService,
    private readonly documentService: DocumentsService,
    private readonly filterService: FilterService,
    private readonly candidateService: CandidatesService,
    private readonly orgService: OrganizationsService,
    private readonly settings: SettingsService,
    private readonly enumPipe: EnumPipe
  ) {
    this.selectedOrganization.valueChanges.subscribe((orgId) => {
      this.loadRequiredDocuments(this.candidateId, orgId).subscribe((data) => {
        this.requiredDocumentSets = data;
        this.selectedSet.setValue(data.map((x) => x.id));
      });
    });
  }

  loadStaticData(request?: StaticDataRequest | StaticDataContext): Observable<StaticDataModel[][]> {
    return forkJoin([
      this.staticDataService.getStaticData(StaticDataType.DocumentFormats, this.language, request),
      this.staticDataService.getStaticData(StaticDataType.AllowedUploadFileTypes, this.language, request),
    ]).pipe(
      tap(([documentFormats, knownFiles]) => {
        this.documentFormats = documentFormats;
        this.knownFiles = knownFiles;
      })
    );
  }

  ngOnInit(): void {
    this.loaded = true;
    this.language = this.trans.getActiveLang();
    this.loadStaticData(this.candidateId ?? { immigrationCountry: this.settings.standardImmigrationCountry }).subscribe(
      ([documentFormats, knownFiles]) => {
        this.documentFormats = documentFormats;
        this.knownFiles = knownFiles;
        this.selectedSet.valueChanges.subscribe(() => this.updateTable(false));

        this.filterText.valueChanges.subscribe(() => this.transposeAndFilterRowData());
        this.documentDeliveryFormat.valueChanges.subscribe(() => this.updateTable(false));
        this.updateTable(false);
      }
    );
  }

  getDocumentFormat(documentFormats: string[]): string {
    const result: string[] = [];
    documentFormats.forEach((format) => {
      result.push(this.documentFormats.find((x) => x.value == format)?.label);
    });
    return result.join(", ");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.collectionId ||
      changes.candidateId ||
      changes.organizationId ||
      changes.language ||
      changes.processId
    ) {
      if (this.loaded) {
        this.updateTable(false);
      }
      if (changes.organizationId) {
        (this.tabIsShared && this.settings.organizationId != this.organizationId
          ? this.getOrganizationSelection()
          : of(null)
        ).subscribe((x) => {
          this.organizations = [
            ...(x != null ? x : []),
            ...(this.settings.isLicensed
              ? [
                  {
                    value: this.settings.organizationId,
                    label: this.settings.organizationName,
                  },
                ]
              : []),
          ];
          this.selectedOrganization.setValue(this.organizations[0]?.value);
        });
      }
    }
  }

  private getOrganizationSelection(): Observable<StaticDataModel[]> {
    if (this.organizationId) {
      return this.orgService.getOrganization(this.organizationId).pipe(
        map((organization) => [
          {
            value: organization.id,
            label: organization.name,
          },
        ])
      );
    }
    if (this.candidateId.candidateId) {
      return this.orgService
        .getOrganizationsSharingTabToCandidate(
          this.candidateId.candidateId,
          this.candidateId.organizationId,
          "requiredDocument"
        )
        .pipe(
          map((organizations) =>
            organizations.map((organization) => ({
              label: organization.name,
              value: organization.id,
            }))
          )
        );
    }
    return of();
  }

  updateTable(partial = false): Observable<void> {
    const retVal = new Subject<void>();
    const mergeData = (data: typeof this.retrievedData): void => {
      if (!partial) {
        this.candidateData = data?.candidateData;
        this.retrievedData = data;
      } else {
        data?.documentStatus.forEach((status) => {
          const docStatusIdx = this.retrievedData.documentStatus.findIndex(
            (x) => x.documentType === status.documentType
          );
          if (docStatusIdx >= 0) {
            this.retrievedData = {
              candidateData: this.retrievedData.candidateData,
              documentStatus: clone(this.retrievedData.documentStatus),
            };
            this.retrievedData.documentStatus[docStatusIdx] = clone(this.retrievedData.documentStatus[docStatusIdx]);
            const docStatus = this.retrievedData.documentStatus[docStatusIdx];
            const idx = docStatus.candidateStatus.findIndex(
              (cand) => cand.candidateId === status.candidateStatus[0].candidateId
            );
            docStatus.candidateStatus = clone(docStatus.candidateStatus);
            docStatus.candidateStatus[idx] = status.candidateStatus[0];
          }
        });
      }
      this.loadStaticData(
        this.retrievedData?.candidateData.map((x) => ({ candidateId: x.candidateId, organizationId: x.organizationId }))
      );
      this.transposeAndFilterRowData();
      retVal.next();
      retVal.complete();
    };
    const candidatesIds = !partial ? null : [this.candidateData[this.editedIndex].candidateId];
    const documentTypes = !partial ? null : [this.editedDocType];
    if (this.processId) {
      this.service
        .getOverviewOfRequiredDocumentsForProcess({
          language: this.language,
          processId: this.processId,
          consideredDeliveryFormats: this.documentDeliveryFormat.value ?? [],
          organizationId: this.organizationId,
          candidateIds: candidatesIds ?? (this.candidateId ? [this.candidateId.candidateId] : null),
          documentTypes: documentTypes,
        })
        .subscribe((data) => {
          mergeData(data);
        });
    } else if (this.collectionId && this.selectedSet.value?.length > 0 && this.language) {
      this.service
        .getOverviewOfRequiredDocumentsForCollection({
          language: this.language,
          consideredDeliveryFormats: this.documentDeliveryFormat.value ?? [],
          collectionId: this.collectionId.collectionId,
          organizationId: this.collectionId.organizationId,
          requiredDocumentSetIds: this.selectedSet.value,
          requiredDocumentSetOrganizationId: this.requiredDocumentSets.find((x) => x.id == this.selectedSet.value[0])
            .organizationId,
          candidateIds: candidatesIds,
          documentTypes: documentTypes,
        })
        .subscribe((data) => {
          mergeData(data);
        });
    } else if (this.candidateId?.candidateId && this.selectedSet.value?.length > 0 && this.language) {
      this.service
        .getOverviewOfRequiredDocumentsForCandidate({
          language: this.language,
          candidateId: this.candidateId.candidateId,
          consideredDeliveryFormats: this.documentDeliveryFormat.value ?? [],
          organizationId: this.candidateId.organizationId,
          requiredDocumentSetIds: this.selectedSet.value,
          requiredDocumentSetOrganizationId: this.requiredDocumentSets.find((x) => x.id == this.selectedSet.value[0])
            .organizationId,
          documentTypes: documentTypes,
        })
        .subscribe((data) => {
          mergeData(data);
        });
    } else {
      mergeData(null);
    }
    return retVal;
  }

  transposeAndFilterRowData(): void {
    const transposed = this.retrievedData?.documentStatus.map((x) => ({
      documentName: x.documentName,
      documentType: x.documentType,
      isOptional: x.isOptional,
      cols: x.candidateStatus.map<Col>((y) => ({
        documentStatus: y.status,
        missingFormats: y.missingFormats,
        missingPhysicalFormats: y.missingPhysicalFormats,
        completionState: y.completionState,
        additionalFormatInDocumentSets: y.additionalFormatsInDocumentSets,
      })),
    }));
    this.rowData =
      !this.filterText.value || this.filterText.value == ""
        ? transposed
        : this.filterService.filter(transposed, ["documentName"], this.filterText.value, "contains");
  }

  saveDocument(doc: Document): void {
    if (doc != null) {
      this.updateTable(true).subscribe(() => {
        this.showSidebar = false;
      });
    } else {
      this.showSidebar = false;
    }
  }

  closeDocument(): void {
    this.showSidebar = false;
  }

  closePreview(): void {
    this.showSidebar = false;
    this.selectedFile = null;
  }

  openMenue(row: Row, col: Col, index: number, event: MouseEvent): void {
    if (col.documentStatus == DocumentStatusEnum.NotShared) {
      return;
    }

    forkJoin([
      this.documentService.getByType(
        row.documentType,
        this.candidateData[index].organizationId,
        this.candidateData[index].candidateId
      ),
      ...col.additionalFormatInDocumentSets.map((x) =>
        this.documentService.getByType(
          x.documentType,
          this.candidateData[index].organizationId,
          this.candidateData[index].candidateId
        )
      ),
    ]).subscribe((data) => {
      const document = data[0];
      const sets = getSets(document);
      this.menueItems = [
        {
          label: this.trans.translate("common.preview"),
          items: sets.flatMap((set) => {
            const setLabel = getSetLabel(set, (key) => this.trans.translate(key));
            const postfix = sets.length > 1 ? ` (${setLabel})` : "";
            return getSetFiles(set).map((file) => {
              return {
                label: file.name + postfix,
                command: (): void => this.previewDocument(row, col, index, document, file),
              };
            });
          }),
        },
        {
          label: this.trans.translate("common.edit"),
          command: (): void => this.openDocument(row, col, index, document),
        },
        ...data
          .slice(1)
          .filter((doc) => doc != null)
          .map((doc) => ({
            label:
              this.trans.translate("common.edit") + ` (${this.knownFiles.find((x) => x.value == doc.type)?.label})`,
            command: (): void => this.openDocument(row, col, index, doc),
          })),
      ];
      this.menueItems = this.menueItems.filter(
        (menuItem) => menuItem.command || some(menuItem.items ?? [], (item) => item.command)
      );

      this.contextMenu.model = this.menueItems;
      if (!this.showSidebar) this.contextMenu.show(event);
    });
  }

  previewDocument(_row: Row, col: Col, index: number, document: Document, file: DocumentFile): any {
    if (col.documentStatus == DocumentStatusEnum.NotShared) {
      return;
    }
    this.documentService
      .canReadDocument(document)
      .pipe(
        filter((hasAccess) => hasAccess),
        switchMap(() =>
          this.documentService.getDownloadUrl(
            file.blob,
            this.candidateData[index].organizationId,
            this.candidateData[index].candidateId
          )
        )
      )
      .subscribe((url) => {
        this.selectedDocument = document;
        this.selectedFile = file;
        this.fileUrl = url;
        this.showSidebar = true;
      });
  }

  openDocument(row: Row, col: Col, index: number, document?: Document): void {
    if (col.documentStatus == DocumentStatusEnum.NotShared) {
      return;
    }
    this.editedCol = col;
    this.editedRow = row;
    this.editedIndex = index;
    this.personName = this.candidateData[index].displayId + " - " + this.candidateData[index].displayName;
    this.selectedFile = null;
    this.selectedDocument = document;
    this.editedDocType = row.documentType;
    if (this.selectedDocument) {
      this.showSidebar = true;
    } else {
      this.documentService
        .getByType(row.documentType, this.candidateData[index].organizationId, this.candidateData[index].candidateId)
        .pipe(switchMap((document) => (!isNil(document) ? of(document) : this.createEmptyDocument(index, row))))
        .subscribe((document) => {
          this.selectedDocument = document;
          this.showSidebar = true;
        });
    }
  }

  buildTooltip(col: Col): string {
    const labels = [
      this.buildCompletionStateLabel(col),
      this.buildDocumentStatusLabel(col),
      this.buildMissingDigitalFormatsLabel(col),
      this.buildMissingPhysicalFormatsLabel(col),
    ];

    return safeJoin(labels, "\n");
  }

  private createEmptyDocument(index: number, row: Row): Observable<Document> {
    return this.documentService.createEmptyDocument(
      DocumentMode.Candidate,
      this.candidateData[index].organizationId,
      this.candidateData[index].candidateId,
      null,
      row.documentType
    );
  }

  private loadRequiredDocuments(
    candidate: typeof this.candidateId,
    organizationId: string
  ): Observable<RequiredDocumentForListFragment[]> {
    return candidate?.candidateId
      ? this.service.getAllForCandidate(candidate.candidateId, candidate.organizationId, organizationId)
      : organizationId
        ? this.service.getAllForOrganization(organizationId)
        : of([]);
  }

  private buildCompletionStateLabel(col: Col): string {
    switch (col.completionState) {
      case CompletionState.Complete:
        return translate("completionState.complete", {}, this.language);
      case CompletionState.Incomplete:
        return translate("completionState.incomplete", {}, this.language);
      default:
        return null;
    }
  }

  private buildDocumentStatusLabel(col: Col): string {
    return this.enumPipe.transform(col.documentStatus, "DocumentStatusEnum", this.language);
  }

  private buildMissingDigitalFormatsLabel(col: Col): string {
    if (col.missingFormats?.length > 0 && col.documentStatus != DocumentStatusEnum.Available) {
      const deliveryFormat = translate("enum.DocumentDeliveryFormat.Digital", {}, this.language);
      const documentFormat = this.getDocumentFormat(col.missingFormats);
      return `(${deliveryFormat}: ${documentFormat})`;
    }
    return null;
  }

  private buildMissingPhysicalFormatsLabel(col: Col): string {
    if (col.missingPhysicalFormats?.length > 0 && col.documentStatus != DocumentStatusEnum.Available) {
      const deliveryFormat = translate("enum.DocumentDeliveryFormat.Paper", {}, this.language);
      const documentFormat = this.getDocumentFormat(col.missingPhysicalFormats);
      return `(${deliveryFormat}: ${documentFormat})`;
    }
    return null;
  }
}
