import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Button } from "primeng-v17/button";
import { TranslateDirective } from "../../shared/transloco/translate.directive";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateDirective, RouterLink, Button],
})
export class ErrorComponent {}
