import { AsyncPipe, NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { FormlySelectModule } from "@ngx-formly/core/select";
import { RadioButtonModule } from "primeng-v17/radiobutton";
import { FormElementMapModule } from "../../shared/from-element-map/form-element-map.module";
import { RadioButtonReadonlyDirective } from "../../shared/primeng/radio-button-readonly/radio-button-readonly.directive";
import { TestIdDirective } from "../../shared/test-id/test-id.directive";

@Component({
  selector: "app-formly-radio-button",
  templateUrl: "./formly-radio-button.component.html",
  styleUrl: "./formly-radio-button.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    RadioButtonModule,
    RadioButtonReadonlyDirective,
    FormsModule,
    ReactiveFormsModule,
    FormElementMapModule,
    TestIdDirective,
    AsyncPipe,
    FormlySelectModule,
  ],
})
export class FormlyRadioButtonComponent extends FieldType implements OnInit {
  ngOnInit(): void {
    if (this.props.defaultValue != null && this.formControl.value == null) {
      this.formControl.setValue(this.props.defaultValue);
    }
  }

  get disabledControl(): FormControl {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }
}
