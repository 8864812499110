import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { EmailsModule } from "../emails/emails.module";
import { CommunicationLanguageComponent } from "./communication-language/communication-language.component";
import { AppLoginDatePipe } from "./login-date.pipe";
import { MessagesReceptionComponent } from "./messages-reception/messages-reception.component";
import { UserDialogComponent } from "./user-dialog/user-dialog.component";
import { UserEmailsComponent } from "./user-emails/user-emails.component";
import { UsersComponent } from "./users.component";
import { UsersService } from "./users.service";

@NgModule({
  imports: [SharedModule, EmailsModule],
  declarations: [
    UserDialogComponent,
    UsersComponent,
    MessagesReceptionComponent,
    CommunicationLanguageComponent,
    UserEmailsComponent,
    AppLoginDatePipe,
  ],
  providers: [UsersService, AppLoginDatePipe],
  exports: [
    UsersComponent,
    MessagesReceptionComponent,
    CommunicationLanguageComponent,
    UserEmailsComponent,
    AppLoginDatePipe,
  ],
})
export class UsersModule {}
