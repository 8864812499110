import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ENABLE_TALENT_POOL } from "@ankaadia/ankaadia-shared";
import { GueteGemeinschaftLinkHandlingComponent } from "./organization-specific/guetegemeinschaft/gueteGemeinschaftLinkHandling.component";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { ErrorComponent } from "./pages/error/error.component";
import { LoginDeniedComponent } from "./pages/login-denied/login-denied.component";
import { FirstLoginComponent } from "./pages/login/firstLoginPage";
import { LoginComponent } from "./pages/login/login.component";
import { SSOLinkAccountAfterSSOLoginComponent } from "./pages/login/sso-linkaccount-after-sso-login";
import { SSOLoginRedirectComponent } from "./pages/login/sso-login-redirect.component";
import { LogoutComponent } from "./pages/logout/logout.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { OrgSwitchComponent } from "./pages/org-switch/org-switch.component";
import { PagesModule } from "./pages/pages.module";
import { ShortLinkComponent } from "./pages/shortLink/shortLink.component";
import { UnsubscribeComponent } from "./pages/unsubscribe/unsubscribe.component";
import { PermissionGuard } from "./shared/guards/permission.guard";
import { Auth0CallbackComponent } from "./structure/auth0Callback/auth0Callback.component";
import { LandingComponent } from "./structure/landing/landing.component";
import { MainComponent } from "./structure/main/main.component";

const routes: Routes = [
  { path: "notfound", component: NotFoundComponent },
  { path: "accessdenied", component: AccessDeniedComponent },
  { path: "logindenied", component: LoginDeniedComponent },
  { path: "error", component: ErrorComponent },
  { path: "login", component: LoginComponent },
  { path: "logout", component: LogoutComponent },
  { path: "callback", component: Auth0CallbackComponent },
  { path: "sso-login-redirect", component: SSOLoginRedirectComponent },
  { path: "firstlogin", component: FirstLoginComponent },
  {
    path: "guetegemeinschaft/recertification/:orgId",
    component: GueteGemeinschaftLinkHandlingComponent,
    canActivate: [PermissionGuard],
  },
  {
    path: "guetegemeinschaft/recertification",
    component: GueteGemeinschaftLinkHandlingComponent,
    canActivate: [PermissionGuard],
  },
  { path: "guetegemeinschaft/newRecertification", component: GueteGemeinschaftLinkHandlingComponent },
  { path: "switch/:orgId", component: OrgSwitchComponent, canActivate: [PermissionGuard] },
  { path: "switch/:orgId/:href", component: OrgSwitchComponent, canActivate: [PermissionGuard] },
  { path: "sso-link-account-afterlogin", component: SSOLinkAccountAfterSSOLoginComponent },
  { path: "unsubscribe", component: UnsubscribeComponent, canActivate: [PermissionGuard] },
  { path: "s/:hash", component: ShortLinkComponent },
  ...(ENABLE_TALENT_POOL
    ? [
        {
          path: "talent-pool",
          loadChildren: () =>
            import("./features/talent-pool/talent-pool-public-routing.module").then(
              (m) => m.TalentPoolPublicRoutingModule
            ),
        },
      ]
    : []),
  {
    path: "app",
    component: MainComponent,
    canActivate: [PermissionGuard],
    children: [
      { path: "", component: LandingComponent, canActivate: [PermissionGuard] },
      {
        path: "candidates",
        loadChildren: () =>
          import("./features/candidates/candidates-routing.module").then((m) => m.CandidatesRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "collections",
        loadChildren: () =>
          import("./features/collections/collections-routing.module").then((m) => m.CollectionsRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "settings",
        children: [
          {
            path: "organizations",
            loadChildren: () =>
              import("./features/organizations/organizations-routing.module").then((m) => m.OrganizationsRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "user-groups",
            loadChildren: () =>
              import("./features/user-groups/user-groups-routing.module").then((m) => m.UserGroupsRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "users",
            loadChildren: () => import("./features/users/users-routing.module").then((m) => m.UsersRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "consent",
            loadChildren: () =>
              import("./features/consent/consents-routing.module").then((m) => m.ConsentsRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "audit-definitions",
            loadChildren: () =>
              import("./features/audit-definitions/audit-definitions-routing.module").then(
                (m) => m.AuditDefinitionsRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "document-requirements",
            loadChildren: () =>
              import("./features/document-requirements/document-requirement-routing.module").then(
                (m) => m.DocumentRequirementRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "system",
            loadChildren: () =>
              import("./features/organizations/organizations-routing.module").then((m) => m.OrganizationsRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "candidate-profile-config",
            loadChildren: () =>
              import("./features/candidate-profile-config/candidate-profile-config-routing.module").then(
                (m) => m.CandidateProfileRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "local-partner-profile-config",
            loadChildren: () =>
              import("./features/local-partner-profile-config/local-partner-profile-config-routing.module").then(
                (m) => m.LocalPartnerProfileConfigRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "vaccination-config",
            loadChildren: () =>
              import("./features/required-vaccinations/required-vaccinations-routing.module").then(
                (m) => m.RequiredVaccinationsRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "process-models",
            loadChildren: () =>
              import("./features/process-models/process-models-routing.module").then(
                (m) => m.ProcessModelsRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "professions",
            loadChildren: () =>
              import("./features/profession/profession-routing.module").then((m) => m.ProfessionRoutingModule),
            canActivate: [PermissionGuard],
          },
          {
            path: "ankaadia-tools",
            loadChildren: () =>
              import("./features/ankaadia-tools/ankaadia-tools-routing.module").then(
                (m) => m.AnkaadiaToolsRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "professional-fields",
            loadChildren: () =>
              import("./features/professional-field/professional-field-routing.module").then(
                (m) => m.ProfessionalFieldRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
          {
            path: "sharing-presets",
            loadChildren: () =>
              import("./features/sharing-preset/sharing-preset-routing.module").then(
                (m) => m.SharingPresetRoutingModule
              ),
            canActivate: [PermissionGuard],
          },
        ],
      },
      {
        path: "activity",
        loadChildren: () => import("./features/activity/activity-routing.module").then((m) => m.ActivityRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./features/dashboard/dashboard-routing.module").then((m) => m.DashboardRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "processes",
        loadChildren: () => import("./features/process/processes-routing.module").then((m) => m.ProcessesRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "process-models",
        loadChildren: () =>
          import("./features/process-models/process-models-routing.module").then((m) => m.ProcessModelsRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "process-actions",
        loadChildren: () =>
          import("./features/process-actions/process-actions-routing.module").then(
            (m) => m.ProcessActionsRoutingModule
          ),
      },
      {
        path: "email-send-out-rules",
        loadChildren: () =>
          import("./features/email-send-out-rules/email-send-out-rules-routing.module").then(
            (m) => m.EmailSendOutRulesRoutingModule
          ),
        canActivate: [PermissionGuard],
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./features/candidate-profile/candidate-profile-routing.module").then(
            (m) => m.CandidateProfileRoutingModule
          ),
        canActivate: [PermissionGuard],
      },
      {
        path: "employers",
        loadChildren: () => import("./features/employer/employer-routing.module").then((m) => m.EmployerRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "documents",
        loadChildren: () =>
          import("./features/candidate-documents/candidate-documents-routing.module").then(
            (m) => m.CandidateDocumentsRoutingModule
          ),
        canActivate: [PermissionGuard],
      },
      {
        path: "terms-and-conditions",
        loadChildren: () =>
          import("./features/user-consent/user-consent-routing.module").then((m) => m.UserConsentRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "documents",
        loadChildren: () =>
          import("./features/documents/documents-routing.module").then((m) => m.DocumentsRoutingModule),
        canActivate: [PermissionGuard],
      },
      {
        path: "education",
        loadChildren: () =>
          import("./features/education/education-routing.module").then((m) => m.EducationRoutingModule),
        canActivate: [PermissionGuard],
      },
      ...(ENABLE_TALENT_POOL
        ? [
            {
              path: "talent-pools",
              loadChildren: () =>
                import("./features/talent-pool/talent-pool-routing.module").then((m) => m.TalentPoolRoutingModule),
              canActivate: [PermissionGuard],
            },
          ]
        : []),
      {
        path: "inbox",
        loadChildren: () => import("./features/inbox/inbox-routing.module").then((m) => m.InboxRoutingModule),
        canActivate: [PermissionGuard],
      },
    ],
  },
  { path: "", redirectTo: "/app", pathMatch: "full" },
  { path: "**", redirectTo: "/notfound" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
      paramsInheritanceStrategy: "always",
    }),
    PagesModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
