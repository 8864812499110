import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DocumentActivityTypes } from "@ankaadia/ankaadia-shared";
import { NotificationScope, NotificationSettingsFragment } from "@ankaadia/graphql";
import { intersection } from "lodash";
import { Button } from "primeng-v17/button";
import { CheckboxModule } from "primeng-v17/checkbox";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { map } from "rxjs";
import { EscapeButtonDirective } from "../../../shared/directives/escape-button.directive";
import { FormElementMapModule } from "../../../shared/from-element-map/form-element-map.module";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { valuesOf } from "../../../shared/services/form.helper";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TestIdDirective } from "../../../shared/test-id/test-id.directive";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { ActivityTypeSelectorComponent } from "../../activity/activity-type-selector/activity-type-selector.component";
import { DocumentTypeSelectorComponent } from "../../activity/document-type-selector/document-type-selector.component";
import { NotificationSettingsFormService } from "./notification-configuration-form.service";

@Component({
  selector: "app-notification-configuration",
  templateUrl: "./notification-configuration.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateDirective,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    FormElementMapModule,
    TestIdDirective,
    NgIf,
    ActivityTypeSelectorComponent,
    MultiSelectModule,
    AppendToBodyDirective,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    DocumentTypeSelectorComponent,
    Button,
    EscapeButtonDirective,
  ],
})
export class NotificationConfigurationComponent implements OnInit {
  readonly form = this.formService.createForm();
  readonly scopes = this.staticDataService.transformEnumToStaticDataModel("NotificationScope", NotificationScope);

  @Input()
  set settings(settings: NotificationSettingsFragment) {
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue(settings);
  }

  @Output()
  readonly changed = new EventEmitter<NotificationSettingsFragment>();

  @Output()
  readonly closed = new EventEmitter<void>();

  showDocumentTypeFilter: boolean;

  constructor(
    private readonly formService: NotificationSettingsFormService,
    private readonly staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    valuesOf(this.form.controls.activities)
      .pipe(map((types) => types ?? []))
      .subscribe((types) => {
        if (!intersection(types, DocumentActivityTypes).length) {
          this.form.controls.documents.reset();
          this.showDocumentTypeFilter = false;
        } else {
          this.showDocumentTypeFilter = true;
        }
      });
  }

  save(): void {
    this.changed.emit(this.form.value);
    this.close();
  }

  close(): void {
    this.closed.emit();
  }
}
