import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { PrimeIcons } from "primeng-v17/api";
import { TooltipModule } from "primeng-v17/tooltip";
import { SidebarAdditionalComponentService } from "../../../shared/primeng/sidebar-additional-component/sidebar-additional-component.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";
import { TranslationProposalService } from "../translationProposal.service";

@Component({
  selector: "app-translation-proposal-button",
  templateUrl: "./translationProposalButton.component.html",
  styleUrl: "./translationProposalButton.component.scss",
  imports: [TranslateDirective, NgIf, TooltipModule],
})
export class TranslationProposalButtonComponent {
  protected icon = PrimeIcons.ERASER + " text-xl";

  constructor(
    protected readonly translationProposalService: TranslationProposalService,
    private readonly additionalComponentService: SidebarAdditionalComponentService
  ) {
    this.additionalComponentService.registerComponentType(TranslationProposalButtonComponent);
  }

  onClick(event: MouseEvent): void {
    this.translationProposalService.toggle(event.target);
    if (this.translationProposalService.isOn) {
      this.icon = PrimeIcons.POWER_OFF + " text-xl";
    } else {
      this.icon = PrimeIcons.ERASER + " text-xl";
    }
  }
}
